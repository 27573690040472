import { Component, OnInit, Input } from '@angular/core';
import { DataSharedService } from 'src/app/shared/services/data-shared.service';
import { EmitterService } from 'src/app/shared/services/event-service';
import { DashboardService } from 'src/app/shared/services/dashboard.service';

@Component({
  selector: 'zainergy-recursive-control-panel',
  templateUrl: "./recursive-control-panel.component.html"
})

export class RecursiveControlPanelComponent implements OnInit {

  @Input() device_hierarchy;

  constructor(private eventEmitter: EmitterService, private dataShared: DataSharedService, private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.getDeviceStatus();
  }

  toggleCollapse(childrens: any) {
    childrens.expanded = !childrens.expanded;
  }

  getDeviceStatus() {
    let body = {
      'action': 'getDeviceActivity',
      'user_id': localStorage.getItem('user'),
      'account_id': localStorage.getItem('account')
    };

    this.dashboardService.httpPost(body).subscribe((res: any) => {
      if (res.status == 200) {
        for (let i = 0; i < res.data.activity.length; i++) {
          for (let j = 0; j < this.device_hierarchy?.length; j++) {
            if (!this.device_hierarchy[j].childrens) {
              if (this.device_hierarchy[j].id == res.data.activity[i].device_id) {
                this.device_hierarchy[j].status = res.data.activity[i].status
              }
            }
          }
        }
      }
    })
  }

  selectedDevice(device_id, location) {
    let exclude_menu = [
      'dashboard~collective',
      'analysis~energy~collective',
      'dashboard~comparative'
    ];

    let exclude_tab_menu = [
      'pv-string-monitoring'
    ];

    let exclude_tab = [
      'tab-comparative',
      'tab-comparative-table',
      'tab-generation-table'
    ];

    if (exclude_menu.includes(this.dataShared.curr_menu) || (exclude_tab_menu.includes(this.dataShared.curr_menu) && exclude_tab.includes(this.dataShared.curr_tab))) {
      this.eventEmitter.emit('selectedGroupDevices', { device_id: device_id, location: location });
    } else {
      this.dataShared.device_id = device_id;
      this.eventEmitter.emit('selectedDevice', device_id);
    }
  }
}