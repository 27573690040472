import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { EmitterService } from 'src/app/shared/services/event-service';
import { DataSharedService } from 'src/app/shared/services/data-shared.service';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  selector: 'app-min-max',
  templateUrl: './min-max.component.html',
  styleUrls: ['./min-max.component.scss']
})
export class MinMaxComponent implements OnInit {

  public device_id: any;
  public parameters: any = [];

  public date: String = '';
  public export_data: any = [];
  public data_param_el: String = '';
  public collective_devices: any = [];

  public vl_l_min: any = 0.00; public vl_l_min_date: String = '';
  public vl_l_max: any = 0.00; public vl_l_max_date: String = '';
  public curr_min: any = 0.00; public curr_min_date: String = '';
  public curr_max: any = 0.00; public curr_max_date: String = '';
  public curr_r_min: any = 0.00; public curr_r_min_date: String = '';
  public curr_r_max: any = 0.00; public curr_r_max_date: String = '';
  public curr_y_min: any = 0.00; public curr_y_min_date: String = '';
  public curr_y_max: any = 0.00; public curr_y_max_date: String = '';
  public curr_b_min: any = 0.00; public curr_b_min_date: String = '';
  public curr_b_max: any = 0.00; public curr_b_max_date: String = '';
  public w_r_min: any = 0.00; public w_r_min_date: String = '';
  public w_r_max: any = 0.00; public w_r_max_date: String = '';
  public w_y_min: any = 0.00; public w_y_min_date: String = '';
  public w_y_max: any = 0.00; public w_y_max_date: String = '';
  public w_b_min: any = 0.00; public w_b_min_date: String = '';
  public w_b_max: any = 0.00; public w_b_max_date: String = '';
  public tot_min: any = 0.00; public tot_min_date: String = '';
  public tot_max: any = 0.00; public tot_max_date: String = '';
  public pf_min: any = 0.00; public pf_min_date: String = '';
  public pf_max: any = 0.00; public pf_max_date: String = '';
  public vl_rn_min: any = 0.00; public vl_rn_min_date: string = '';
  public vl_rn_max: any = 0.00; public vl_rn_max_date: string = '';
  public vl_yn_min: any = 0.00; public vl_yn_min_date: string = '';
  public vl_yn_max: any = 0.00; public vl_yn_max_date: string = '';
  public vl_bn_min: any = 0.00; public vl_bn_min_date: string = '';
  public vl_bn_max: any = 0.00; public vl_bn_max_date: string = '';
  public vl_ry_min: any = 0.00; public vl_ry_min_date: string = '';
  public vl_ry_max: any = 0.00; public vl_ry_max_date: string = '';
  public vl_yb_min: any = 0.00; public vl_yb_min_date: string = '';
  public vl_yb_max: any = 0.00; public vl_yb_max_date: string = '';
  public vl_br_min: any = 0.00; public vl_br_min_date: string = '';
  public vl_br_max: any = 0.00; public vl_br_max_date: string = '';
  public frequency_min: any = 0.00; public frequency_min_date: string = '';
  public frequency_max: any = 0.00; public frequency_max_date: string = '';
  public total_va_min: any = 0.00; public total_va_min_date: String = '';
  public total_va_max: any = 0.00; public total_va_max_date: String = '';
  public total_var_min: any = 0.00; public total_var_min_date: String = '';
  public total_var_max: any = 0.00; public total_var_max_date: String = '';
  public data: any = [];
  public location: String = 'N/A';
  public main_logo = '';
  public user: any;
  public curr_date: string = '';

  constructor(private authService: LoginService, private eventEmitter: EmitterService, private dashboardService: DashboardService, private dataShared: DataSharedService) {
    this.parameters = ["avg_vl_pp", "avg_curr_3p", "total_w", "total_va", "total_var", "avg_pf_3p", "vl_rn", "vl_yn", "frequency", "vl_bn", "vl_ry", "vl_yb", "vl_br", "curr_r", "curr_y", "curr_b", "w_r", "w_y", "w_b"]
  }

  ngOnInit(): void {
    this.setMainLogo(); this.currentUser();
    this.eventEmitter.listen('getSelectedSingleDevice', (device_id) => {      
      // if (this.dataShared.curr_menu == 'analysis~min-max') {
        this.device_id = device_id
        this.resetSinleDevice()
        this.getMinMaxValue()
      // }
    });

    this.eventEmitter.listen('groupDevices', () => {      
      // if (this.dataShared.curr_menu == 'analysis~min-max') {
        this.collective_devices = [];
        this.dataShared.panel_devices.map(elem => this.collective_devices.push(elem.device_id));
      // }
    });

    this.getCurrDate()
  }

  resetSinleDevice() {
    this.date = ''; this.getCurrDate();
  }

  getCurrDate() {

    let date = new Date();
    this.date = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
    this.curr_date = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' +  date.getFullYear();
  }

  setMainLogo() {
    let body = {
      "action": "getLogo",
      "account_id": localStorage.getItem('account')
    }
    this.dashboardService.httpPost(body).subscribe((res: any) => {
      this.main_logo = (res.data[0]) ? 'https://app.zainergy.com/backend/zainergy/assets/' + res.data[0].private_hash + '?key=directus-large-contain' : 'assets/images/zainergy-logo.svg';
    })
  }

  currentUser() {
    this.authService.getUser().subscribe((res: any) => {
      if (res?.data || res?.user) {
        this.user = (res.data) ? res.data : res.user;
      }
    })
  }

  getMinMaxValue() {

    let vl_l_data = [];
    let curr_data = [];
    let curr_r_data = [];
    let curr_y_data = [];
    let curr_b_data = [];
    let tot_data = [];
    let pf_data = [];
    let vl_rn_data = [];
    let vl_yn_data = [];
    let vl_bn_data = [];
    let vl_ry_data = [];
    let vl_yb_data = [];
    let vl_br_data = [];
    let frequency_data = [];
    let w_r_data = [];
    let w_y_data = [];
    let w_b_data = [];
    let total_va_data = [];
    let total_var_data = [];

    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      res.data.buckets[0].date_agg.buckets.forEach(element => {

        vl_l_data.push(element.val_avg_vl_pp.value)
        curr_r_data.push(element.val_curr_r.value)
        curr_y_data.push(element.val_curr_y.value)
        curr_b_data.push(element.val_curr_b.value)
        curr_data.push(element.val_avg_curr_3p.value)
        tot_data.push(element.val_total_w.value)
        total_va_data.push(element.val_total_va.value)
        total_var_data.push(element.val_total_var.value)
        pf_data.push(element.val_avg_pf_3p.value)
        vl_rn_data.push(element.val_vl_rn.value)
        vl_yn_data.push(element.val_vl_yn.value)
        vl_bn_data.push(element.val_vl_bn.value)
        vl_ry_data.push(element.val_vl_ry.value)
        vl_yb_data.push(element.val_vl_yb.value)
        vl_br_data.push(element.val_vl_br.value)
        frequency_data.push(element.val_frequency.value)
        w_r_data.push(element.val_w_r.value)
        w_y_data.push(element.val_w_y.value)
        w_b_data.push(element.val_w_b.value)

        this.data.push(element)

      });

      this.vl_l_min = Math.min(...vl_l_data)
      this.vl_l_max = Math.max(...vl_l_data)

      this.curr_min = Math.min(...curr_data)
      this.curr_max = Math.max(...curr_data)

      this.curr_r_min = Math.min(...curr_r_data)
      this.curr_r_max = Math.max(...curr_r_data)

      this.curr_y_min = Math.min(...curr_y_data)
      this.curr_y_max = Math.max(...curr_y_data)

      this.curr_b_min = Math.min(...curr_b_data)
      this.curr_b_max = Math.max(...curr_b_data)

      this.tot_min = Math.min(...tot_data)
      this.tot_max = Math.max(...tot_data)

      this.pf_min = Math.min(...pf_data)
      this.pf_max = Math.max(...pf_data)

      this.vl_rn_min = Math.min(...vl_rn_data)
      this.vl_rn_max = Math.max(...vl_rn_data)

      this.vl_yn_min = Math.min(...vl_yn_data)
      this.vl_yn_max = Math.max(...vl_yn_data)

      this.vl_bn_min = Math.min(...vl_bn_data)
      this.vl_bn_max = Math.max(...vl_bn_data)

      this.vl_ry_min = Math.min(...vl_ry_data)
      this.vl_ry_max = Math.max(...vl_ry_data)

      this.vl_yb_min = Math.min(...vl_yb_data)
      this.vl_yb_max = Math.max(...vl_yb_data)

      this.vl_br_min = Math.min(...vl_br_data)
      this.vl_br_max = Math.max(...vl_br_data)

      this.w_r_min = Math.min(...w_r_data)
      this.w_r_max = Math.max(...w_r_data)

      this.w_y_min = Math.min(...w_y_data)
      this.w_y_max = Math.max(...w_y_data)

      this.w_b_min = Math.min(...w_r_data)
      this.w_b_max = Math.max(...w_r_data)

      this.frequency_min = Math.min(...frequency_data)
      this.frequency_max = Math.max(...frequency_data)

      this.total_va_min = Math.min(...total_va_data)
      this.total_va_max = Math.max(...total_va_data)

      this.total_var_min = Math.min(...total_var_data)
      this.total_var_max = Math.max(...total_var_data)

      for (let j = 0; j < this.data.length; j++) {
        if (this.data[j].val_avg_vl_pp.value == this.vl_l_min) this.vl_l_min_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_avg_vl_pp.value == this.vl_l_max) this.vl_l_max_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_avg_curr_3p.value == this.curr_min) this.curr_min_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_avg_curr_3p.value == this.curr_max) this.curr_max_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_total_w.value == this.tot_min) this.tot_min_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_total_w.value == this.tot_max) this.tot_max_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_avg_pf_3p.value == this.pf_min) this.pf_min_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_avg_pf_3p.value == this.pf_max) this.pf_max_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_vl_rn.value == this.vl_rn_min) { this.vl_rn_min_date = this.data[j].key_as_string.slice(11, 19); }
        if (this.data[j].val_vl_rn.value == this.vl_rn_max) this.vl_rn_max_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_vl_yn.value == this.vl_yn_min) { this.vl_yn_min_date = this.data[j].key_as_string.slice(11, 19); }
        if (this.data[j].val_vl_yn.value == this.vl_yn_max) this.vl_yn_max_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_vl_bn.value == this.vl_bn_min) { this.vl_bn_min_date = this.data[j].key_as_string.slice(11, 19); }
        if (this.data[j].val_vl_bn.value == this.vl_bn_max) this.vl_bn_max_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_vl_ry.value == this.vl_ry_min) { this.vl_ry_min_date = this.data[j].key_as_string.slice(11, 19); }
        if (this.data[j].val_vl_ry.value == this.vl_ry_max) this.vl_ry_max_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_vl_yb.value == this.vl_yb_min) { this.vl_yb_min_date = this.data[j].key_as_string.slice(11, 19); }
        if (this.data[j].val_vl_yb.value == this.vl_yb_max) this.vl_yb_max_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_vl_br.value == this.vl_br_min) { this.vl_br_min_date = this.data[j].key_as_string.slice(11, 19); }
        if (this.data[j].val_vl_br.value == this.vl_br_max) this.vl_br_max_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_avg_vl_pp.value == this.vl_l_min) this.vl_l_min_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_avg_vl_pp.value == this.vl_l_max) this.vl_l_max_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_curr_r.value == this.curr_r_min) this.curr_r_min_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_curr_r.value == this.curr_r_max) this.curr_r_max_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_curr_y.value == this.curr_y_min) this.curr_y_min_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_curr_y.value == this.curr_y_max) this.curr_y_max_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_curr_b.value == this.curr_b_min) this.curr_b_min_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_curr_b.value == this.curr_b_max) this.curr_b_max_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_frequency.value == this.frequency_min) this.frequency_min_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_frequency.value == this.frequency_max) this.frequency_max_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_w_r.value == this.w_r_min) this.w_r_min_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_w_r.value == this.w_r_max) this.w_r_max_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_w_y.value == this.w_y_min) this.w_y_min_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_w_y.value == this.w_y_max) this.w_y_max_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_w_b.value == this.w_b_min) this.w_b_min_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_w_b.value == this.w_b_max) this.w_b_max_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_total_va.value == this.total_va_min) this.total_va_min_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_total_va.value == this.total_va_max) this.total_va_max_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_total_var.value == this.total_var_min) this.total_var_min_date = this.data[j].key_as_string.slice(11, 19);
        if (this.data[j].val_total_var.value == this.total_var_max) this.total_var_max_date = this.data[j].key_as_string.slice(11, 19);


      }
      let paramters = [ { description:"Phase Voltage V1-N", unit: "VOLTS" }, { description:"Phase Voltage V2-N", unit: "VOLTS" }, { description:"Power Factor", unit: "" }, { description:"Voltage RN", unit: "" }, { description:"Voltage YN", unit: "" }, { description:"Voltage BN", unit: "" }, { description:"Voltage RY", unit: "" }, { description:"Avg. Voltage VL-L", unit: "" }, { description:"Curr R", unit: "" }, { description:"Curr Y", unit: "" }, { description:"Curr B", unit: "" }, { description:"Frequency", unit: "" }, { description:"Active Power L1", unit: "" }, { description:"Active Power L2", unit: "" }, { description:"Active Power L3", unit: "" }, { description:"Total Apparent Power", unit: "" }]
      this.export_data.push({ Paramters: paramters })
      let data = [];
      data.push(this.export_data)
      this.location = this.dataShared.device_detail.location;
    })
  }

  getBody() {

    let body = {
      'action': 'getChartDataByAccount',
      'user_id': [localStorage.getItem('user')],
      'device_id': [this.device_id],
      'date': this.date,
      'fixed_interval': '1s',
      'parameters': this.parameters
    };

    return body;
  }

  onDateSelection(e) {
    this.date = e.target.value
    this.getMinMaxValue()
  }

  exportAsCSV() {
    let data = [];
    if (data = this.getDataByParam()) this.dataShared.exportDataAsCsvFormat(data);
  }

  getDataByParam() {
    return this.export_data;
  }

  exportAsPDF(e) {
    let data = [];
    if (data = this.getDataByParam()) this.dataShared.exportDataAsPdfFormat(data, e, this.data_param_el);
  }

}