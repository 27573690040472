import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../shared/services/dashboard.service';

@Component({
  selector: 'app-alarm-logs',
  templateUrl: './alarm-logs.component.html',
  styleUrls: ['./alarm-logs.component.scss']
})
export class AlarmLogsComponent implements OnInit {

  public action = '';
  public tabular_data: any = [];
  search_text: any;

  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.getAlarmLogTable();
  }

  getBody() {
    let body = {
      "action": this.action,
      "user_id": [localStorage.getItem('user')],
      "device_id": ["20201338"],
      "date": "2023-07-25",
      "parameters": [ "alarm_descrip", "device_id" ],
      "from": 0,
      "size": 10000,
      "sort": "desc"
    }
    return body;
  }

  getAlarmLogTable() {
    this.action = 'getAlarmLog'; let tabular_data = [];
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {
      
      let data = res.data.hits;

      data.forEach(e => {

        if (e.alarm_descrip) {
          tabular_data.push({
            location: e.location,
            device_id: e.device_id,
            alarm_descrip: e.alarm_descrip,
            timestamp: e['@timestamp']
          })
        }

        this.tabular_data = tabular_data;
      })
    })
  }

}
