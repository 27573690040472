import { Component, OnInit, Injectable } from '@angular/core';
import { DashboardService } from 'src/app/shared/services/dashboard.service';

@Component({
  selector: 'app-api-functions',
  templateUrl: './api-functions.component.html',
  styleUrls: ['./api-functions.component.scss']
})

@Injectable({
  providedIn: 'root'
})

export class ApiFunctionsComponent implements OnInit {

  public action = '';
  public setting;

  constructor(public dashboardService: DashboardService) { 
    this.getSettings() 
  }

  ngOnInit(): void {
  }

  getBody() {
    let body = {}
    if (this.action == 'getSettings') {
      body['action'] = 'getSettings';
      body['meta_key'] = 'config';
      body['user_id'] = localStorage.getItem('user');
      body['account_id'] = [localStorage.getItem('account')]
    }
    return body;
  }

  getSettings() {
    this.action = 'getSettings';
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {
      if (res.status == 200) this.setting = res.data;
    })
  }
}