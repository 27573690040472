<!-- partial:partials/_sidebar.html -->
<nav class="sidebar">

    <div class="sidebar-header">
        <a href="#" class="sidebar-brand">
            <img *ngIf="!company_logo_show" width="130" src="{{ main_logo }}">
            <img *ngIf="company_logo_show" width="200" height="60" style="margin-left: -32px;" src="{{ main_logo }}">
            <!-- <img width="200" height="60" style="margin-left: -32px;" src="{{ main_logo }}"> -->
        </a>
        <div class="sidebar-toggler" [ngClass]="[ toggleSidebar == true ? 'not-active' : 'active']"
            (click)="toggleClass()">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>

    <div class="sidebar-body">

        <!-- <ul class="nav">
            <zainergy-recursive-sidebar-menu [sidebar_menu_hierarchy]="sidebar_menu_hierarchy">
            </zainergy-recursive-sidebar-menu>
        </ul> -->

        <ul class="nav">
            <li *ngFor="let menu of menu_list" class="nav-item" routerLinkActive="active">
                <div *ngIf="menu.childrens; else withoutChildrens">
                    <a *ngIf="menu.status" class="nav-link" data-toggle="collapse" href="#{{ menu.key }}" role="button"
                        aria-expanded="false" [attr.aria-controls]="menu.name">
                        <i class="link-icon" [ngClass]="menu.icon"></i>
                        <span class="link-title">{{ menu.name }}</span>
                        <i class="link-arrow feather feather-chevron-down"></i>
                    </a>
                </div>
                <!-- [attr.data-feather]="menu.icon" -->
                <ng-template #withoutChildrens>
                    <a href="javascript:void(0);" *ngIf="menu.status" [routerLink]="menu.url" class="nav-link"
                        (click)="activeMenuClass(menu.key)" routerLinkActive="active">
                        <i class="link-icon" [ngClass]="menu.icon"></i>
                        <span class="link-title">{{ menu.name }}</span>
                    </a>
                </ng-template>

                <div *ngIf="menu.childrens" class="collapse show" id="{{ menu.key }}">
                    <ul class="nav sub-menu">

                        <li *ngFor="let children of menu.childrens" class="nav-item">
                            <div *ngIf="children.childrens; else withoutChild">
                                <a *ngIf="children.status" class="nav-link" data-toggle="collapse"
                                    href="#{{ children.key }}" role="button" aria-expanded="false"
                                    [attr.aria-controls]="children.name" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">
                                    <!-- <i class="link-icon" [attr.data-feather]="children.icon"></i> -->
                                    <span>{{ children.name }}</span>
                                    <i class="link-arrow feather feather-chevron-down"></i>
                                </a>
                            </div>


                            <ng-template #withoutChild>
                                <a href="javascript:void(0);" *ngIf="children.status" [routerLink]="children.url"
                                    class="nav-link" (click)="activeMenuClass(children.key)" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">
                                    <span>{{ children.name }}</span>
                                </a>
                            </ng-template>

                            <div *ngIf="children.childrens" class="collapse show" id="{{ children.key }}">
                                <ul class="nav sub-menu">
                                    <li *ngFor="let child of children.childrens" class="nav-item">
                                        <a href="javascript:void(0);" *ngIf="child.status" [routerLink]="child.url"
                                            class="nav-link" (click)="activeMenuClass(child.key)"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{
                                            child.name }}</a>
                                    </li>
                                </ul>
                            </div>

                        </li>

                    </ul>

                </div>

            </li>

        </ul>

        <div style="height: 100px; width: 100px; margin-left: -40px;">
            <img *ngIf="company_logo_show" style="width: 300px; margin-top: 250px;" src="{{ company_logo }}">
        </div>

        <!-- Logout button trigger modal -->
        <i class="feather-log-out log-out-btn" (click)="openVerticallyCentered(content)">
        </i>

        <!-- Logout modal -->
        <ng-template #content let-modal>
            
            <div class="modal-header">
                <h4 class="modal-title">Logout</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"><span
                        aria-hidden="true">&times;</span></button>
            </div>
            
            <div class="modal-body">
                <p>Are you sure you wat to logout?</p>
            </div>
            
            <div class="modal-footer">
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
                <button type="button" class="btn btn-danger" (click)="logOut()">Ok</button>
            </div>
        
        </ng-template>

    </div>

</nav>