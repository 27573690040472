<div *ngIf="show_panel">
    <nav id="sidebar" class="control-panel">
        <button class="btn manage-job-btn" id="sidebarCollapse" type="button" (click)="toggleClass()">
            <i></i>
        </button>

        <div class="control-panel-title">
            <h6 class="mt-4">Control Panel</h6>
        </div>

        <div class="control-panel-tree">
            <div class="text-normal tree-tabs">
                <ngb-tabset #t="ngbTabset" (tabChange)="tabChange($event)" #tabset>

                    <ngb-tab *ngIf="tab_energy_status" id="tab-energy" title="Energy" tooltip="test">
                        <ng-template ngbTabContent>
                            <zainergy-recursive-control-panel [device_hierarchy]="device_hierarchy">
                            </zainergy-recursive-control-panel>
                        </ng-template>
                    </ngb-tab>

                    <ngb-tab *ngIf="tab_flow_status" id="tab-flow" title="Flow">
                        <ng-template ngbTabContent>
                            <zainergy-recursive-control-panel [device_hierarchy]="device_hierarchy"></zainergy-recursive-control-panel>
                        </ng-template>
                    </ngb-tab>

                    <ngb-tab *ngIf="tab_sensor_status" id="tab-sensor" title="Sensor">
                        <ng-template ngbTabContent>
                            <zainergy-recursive-control-panel [device_hierarchy]="device_hierarchy"></zainergy-recursive-control-panel>
                        </ng-template>
                    </ngb-tab>

                    <ngb-tab *ngIf="tab_level_status" id="tab-level" title="Level" tooltip="test">
                        <ng-template ngbTabContent>
                            <zainergy-recursive-control-panel [device_hierarchy]="device_hierarchy"></zainergy-recursive-control-panel>
                        </ng-template>
                    </ngb-tab>

                </ngb-tabset>
            </div>

        </div>

        <div class="control-panel-info" *ngIf="panel_info == 'single'">

            <div class="info-row">
                <h6>Location :</h6>
                <p>{{ location }}</p>
            </div>
            <div class="info-row">
                <h6>Category :</h6>
                <p>{{ category }}</p>
            </div>
            <div class="info-row">
                <h6>Type :</h6>
                <p>{{ type | titlecase }}</p>
            </div>
            <div class="info-row">
                <h6>Source :</h6>
                <p>{{ source }}</p>
            </div>
            <div class="info-row">
                <h6>Device ID :</h6>
                <p>{{ id }}</p>
            </div>
            <div class="info-row">
                <h6>Device Status :</h6>
                <p><span class="device-status" [ngClass]="device_class"></span></p>
            </div>
            <div class="info-row">
                <h6>Favourite / Summary :</h6>
                <span class="favourite-icon" [ngClass]="favorite_class" (click)="toggleFavorite(id)"></span>
            </div>
<!-- 
            <div class="info-row">
                <h6>Interval (x-axis)</h6>
                <select [(ngModel)]="single_interval" (change)="selectedInterval()" class="p-1">
                    <option value="" disabled>Select interval</option>
                    <option *ngFor="let data of intervals">{{ data }}</option>
                </select>
            </div> -->

            <!-- <div class="info-row">
                <h6>Interval (x-axis)</h6>
                <p>Dropdown</p>
            </div>

            <div class="info-row">
                <h6>Parameter</h6>
                <p>Dropdown</p>
            </div>

            <div class="control-panel-btn">
                <button class="btn mt-3 theme-primary-btn">Apply Setting</button>
            </div> -->
            <!-- <div class="control-panel-btn">
                <button class="btn mt-2 theme-secondary-btn"></i> Favourite</button>
            </div> -->

        </div>

        <div class="control-panel-info panel-devices-area" *ngIf="panel_info == 'group' && panel_devices.length > 0">
            <a href="javascript:void(0);" class="d-flex justify-content-end" (click)="removeDevices()">Clear All ( {{ panel_devices.length }} / 10 )</a>
            <ul class="panel-devices" style="max-height: 145px;">
                <li *ngFor="let device of panel_devices">
                    <p class="mb-0">{{ device.location }}</p>
                     <!-- <div class="d-flex"><i class="favourite-icon" [ngClass]="favorite_class"
                            (click)="toggleFavorite(id)"></i> </div> -->
                            <i class="remove-icon feather feather-x"
                            (click)="removeDevice(device.device_id)"></i>
                </li>
            </ul>
 
            <!-- <div class="info-row">
                <h6>Interval (x-axis)</h6>
                <select [(ngModel)]="group_interval">
                    <option value="" disabled>Select interval</option>
                    <option *ngFor="let data of intervals">{{ data }}</option>
                </select>
            </div> -->

            <!-- <div class="control-panel-btn">
                <button class="btn mt-2 theme-secondary-btn">Save Device</button>
            </div> -->

            <div class="control-panel-btn">
                <button class="btn mt-2 theme-secondary-btn" (click)="getDevices()">Get Data</button>
            </div>
        </div>
    </nav>
</div>