<div class="row {{ source.source_display }} justify-content-end w-100">
    <div>
        <h6 class="text-right ">
            <b class="mr-1 mb-2 btn {{source.source_color}} {{ source.source_type_display.alarm_display }}">
                Alarm:  {{ alarm1 }}
            </b>
            <b class="mr-1 mb-2 btn {{source.source_color}} {{ source.source_type_display.state_display }}">
                State:  {{ state1 }}
            </b>
            <b class="btn mb-2 {{source.source_color}} {{ source.source_type_display.source_display }}">
                Source:  {{ source.source_type }}
            </b>
        </h6>
    </div>
</div>

<!-- Default dropup button -->
<div class="dropdown {{ level.dropdown }} justify-content-end" style="display: flex !important; position: relative !important;">
    <button style="background-color: #5DADE2" type="button" class="btn dropdown-toggle text-white  {{ level.dropdown }} "
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {{level_analytics}}
    </button>
    <div class="dropdown-menu">
        <!-- Dropdown menu links -->
        <a class="dropdown-item" href="javascript:void(0);"
            (click)="level_analytics = 'Refelling'; refellingLevelAnalyticsCards(data)">Refelling</a>
        <a class="dropdown-item" href="javascript:void(0);"
            (click)="level_analytics = 'Consumption'; consumptionlevelAnalyticsCards(data)">Consumption</a>
        <a class="dropdown-item" href="javascript:void(0);"
            (click)="level_analytics = 'Combine'; combinelevelAnalyticsCards(data)">Combine</a>
    </div>
</div>

<div class="row">

    <div class="col-12 col-xl-12 stretch-card">
        <div class="row flex-grow">

            <div class="card-row-adjust col-sm-6 col-lg grid-margin" *ngFor="let kpi of kpis">
                <div class="card text-white h-100" style="background-color: #5DADE2;">

                    <!-- header -->
                    <div class="card-header text-center font-weight-bold text-center" style="
                    background-color: rgba(4, 105, 187, 0.445);
                    font-size: 15px;
                    padding: 6px;
                    " *ngIf="for == 'level_analytics_com'">
                        {{ kpi.key | titlecase }}
                    </div>
                    <div class="row h-100">
                        <div class="col-12">

                            <div class="mr-2 d-flex float-right">
                                <!-- icon -->
                                <i *ngIf="kpi.icon" class="{{ kpi.icon }}"></i>

                                <!-- img -->
                                <img *ngIf="kpi.icon_img" class="theme-icon" src="{{ kpi.icon_img }}">
                            </div>

                            <!-- icon value -->
                            <h4 *ngIf="!kpi.icon_img" class="ml-4 mt-2 {{ level.single }} text-left">{{
                                kpi.value |
                                currency: ' ' }}</h4>

                            <!-- img value -->
                            <h4 *ngIf="kpi.icon_img" class="ml-4 mt-2 {{ level.single }} text-left">{{
                                kpi.value }}</h4>

                            <!-- key -->
                            <h6 class="card-title ml-4 {{ level.single }} text-white text-left"
                            >{{
                                kpi.key | titlecase }}</h6>

                            <!-- level combine value -->
                            <h4 class="card-title ml-4 mt-4 {{ level.combine }} text-center text-white"
                                *ngIf="kpi.key == 'Available Volume ' + '(' + this.level_unit + ')' ">{{
                                kpi.value }}</h4>

                            <!-- refelling -->
                            <div class="row {{ level.combine }}" *ngIf="kpi.key != 'Available Volume ' + '(' + this.level_unit + ')'">

                                <h5 class="ml-4 text-white text-left"
                                    style="margin-top: 11px;">
                                    {{'Ref :' | titlecase }}</h5>

                                <h4 *ngIf="for == 'level_analytics_com'" style="margin-top: 12px; margin-left: 5px;">{{ kpi.combine.refelling }}</h4>
                            </div>

                            <!-- consumption -->
                            <div class="row {{ level.combine }}" style="margin-bottom: 2px;"
                                *ngIf="kpi.key != 'Available Volume ' + '(' + this.level_unit + ')'">

                                <h5 class="ml-4 text-left text-white"
                                    style="margin-top: 1px;">{{
                                    'Con :' | titlecase }}</h5>
                                <h4 *ngIf="for == 'level_analytics_com'" style="margin-top: 2px; margin-left: 5px;">{{ kpi.combine.consumption }}</h4>
                            </div>
                            <!-- end level combine value -->
                            </div>
                            <!-- footer -->
                            <div class="col-12 text-center" style="margin-top: auto;">
                            <div class="card-footer col-sm-12 p-1" style="
                                color: rgb(255, 255, 255);
                                margin-top: auto !important;
                                font-size: x-small;
                                background-color: rgba(4, 105, 187, 0.445);
                                padding: 3px !important;" *ngIf="timestamp != ''">
                                Last Updated: {{timestamp}}</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
