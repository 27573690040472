<ngb-tabset #t="ngbTabset" (tabChange)="tabChange($event)" #tabset>

    <ngb-tab *ngIf="tab_graph_status" id="tab-graph" title="Graph">
        <ng-template ngbTabContent>

            <div class="row mt-4">

                <div class="col-md-12 col-xl-6 grid-margin stretch-card" id="chart_voltage">
                    <div class="d-grid row flex-grow">
                        <div class="col grid-margin stretch-card">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="card-title mb-0 ">AVG. LINE VOLTAGE VL-L (VOLTS)</h6>

                            <div class="header-right d-flex align-items-center">

                                    <button class="btn pr-1" type="button" data-toggle="collapse"
                                        data-target="#collapseVoltage" aria-expanded="false"
                                        aria-controls="collapseVoltage">
                                        <i class="feather-sliders"
                                            style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                                    </button>

                                <a href="javascript:void(0);" (click)="tabLinkChart('voltage')">
                                    <i class="icon-sm card-header-icon mr-1 ml-1 pb-3px feather feather-trending-up"
                                        [ngClass]="{'active': vol_chart_active == 'line'}"></i>
                                </a>

                                <span class="divider"></span>

                                <a href="javascript:void(0);" (click)="tabBarChart('voltage')">
                                    <i class="icon-sm card-header-icon ml-1 pb-3px feather feather-bar-chart-2"
                                        [ngClass]="{'active': vol_chart_active == 'bar'}"></i>
                                </a>

                                <i class="icon-sm card-header-icon mr-2 pl-2 pt-1 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Print"
                                            (click)="data_param = 'voltage'; data_param_el = 'chart_voltage'; exportAsPDF($event)">
                                            <i class="feather feather-printer icon-sm mr-2"></i>
                                            <span class="">Print</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'voltage'; exportAsCSV()">
                                            <i class="feather feather-download icon-sm mr-2"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf"
                                            (click)="data_param = 'voltage'; data_param_el = 'chart_voltage'; exportAsPDF($event)">
                                            <i class="feather feather-file-text icon-sm mr-2"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="collapse px-2" id="collapseVoltage">    
                            <div class="card-body overflow-auto">
                                <div class="w-max-content d-flex align-items-center justify-content-end">
                                    <div class="form-group mb-0 mr-2">
                                        <h6 class="text-nowrap">Select Date:</h6>
                                    </div>
                                    <div class="form-group mb-0 mr-2">
                                        <input type="date" [(ngModel)]="vl_from_date_selection" autocomplete="off"
                                            class="form-control" (change)="onFromDateSelection($event, 'voltage')">
                                    </div>

                                    <span class="mr-2 from-to">-</span>

                                    <div class="form-group mb-0">
                                        <input type="date" [(ngModel)]="vl_to_date_selection" autocomplete="off"
                                            class="form-control" (change)="onToDateSelection($event, 'voltage')">
                                    </div>

                                </div>
                            </div>
                        </div>
                            <div class="row align-items-start mb-2">

                            </div>
                            <div class="flot-wrapper">
                                <div id="chartVoltage" style="width: 100%; height: 450px"></div>
                            </div>

                    </div>
                </div>
            </div>
        </div>

                <div class="col-md-12 col-xl-6 grid-margin stretch-card" id="chart_current">
                    <div class="d-grid row flex-grow">
                        <div class="col grid-margin stretch-card">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="card-title mb-0">AVG. 3 PHASE CURRENT (AMPS)</h6>

                            <div class="header-right d-flex align-items-center">

                                <p>
                                    <button class="btn pr-1" type="button" data-toggle="collapse"
                                        data-target="#collapseCurrent" aria-expanded="false"
                                        aria-controls="collapseCurrent">
                                        <i class="feather-sliders"
                                            style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                                    </button>
                                </p>

                                <a href="javascript:void(0);" (click)="tabLinkChart('current')">
                                    <i class="icon-sm card-header-icon  mr-1 ml-1 pb-3px feather feather-trending-up"
                                        [ngClass]="{'active': curr_chart_active == 'line'}"></i>
                                </a>

                                <span class="divider"></span>

                                <a href="javascript:void(0);" (click)="tabBarChart('current')">
                                    <i class="icon-sm card-header-icon ml-1 pb-3px feather feather-bar-chart-2"
                                        [ngClass]="{'active': curr_chart_active == 'bar'}"></i>
                                </a>

                                <i class="icon-sm card-header-icon mr-2 pl-2 pt-1 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Print"
                                            (click)="data_param = 'current'; data_param_el = 'chart_current'; exportAsPDF($event)">
                                            <i class="feather feather-printer icon-sm mr-2"></i>
                                            <span class="">Print</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'current'; exportAsCSV()">
                                            <i class="feather feather-download icon-sm mr-2"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf"
                                            (click)="data_param = 'current'; data_param_el = 'chart_current'; exportAsPDF($event)">
                                            <i class="feather feather-file-text icon-sm mr-2"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="collapse px-2" id="collapseCurrent">
                            <div class="card-body overflow-auto">
                                <div class="w-max-content d-flex align-items-center justify-content-end">
                                    <div class="form-group mb-0 mr-2">
                                        <h6 class="text-nowrap">Select Date:</h6>
                                    </div>

                                    <div class="form-group mb-0 mr-2">
                                        <input type="date" [(ngModel)]="curr_from_date_selection" autocomplete="off"
                                            class="form-control" (change)="onFromDateSelection($event, 'current')">
                                    </div>

                                    <span class="mr-2 from-to">-</span>

                                    <div class="form-group mb-0">
                                        <input type="date" [(ngModel)]="curr_to_date_selection" autocomplete="off"
                                            class="form-control" (change)="onToDateSelection($event, 'current')">
                                    </div>

                                </div>
                            </div>
                        </div>
                            <div class="row align-items-start mb-2">
                            </div>
                            <div class="flot-wrapper">
                                <div id="chartCurr" style="width: 100%; height: 450px"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

                <div class="col-md-12 col-xl-6 grid-margin stretch-card" id="chart_kilo_watt">
                    <div class="d-grid row flex-grow">
                        <div class="col grid-margin stretch-card">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="card-title mb-0">TOTAL ACTIVE POWER (KW)</h6>

                            <div class="header-right d-flex align-items-center">

                                <p>
                                    <button class="btn pr-1" type="button" data-toggle="collapse"
                                        data-target="#collapsekiloWatt" aria-expanded="false"
                                        aria-controls="collapsekiloWatt">
                                        <i class="feather-sliders"
                                            style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                                    </button>
                                </p>

                                <a href="javascript:void(0);" (click)="tabLinkChart('kilo_watt')">
                                    <i class="icon-sm card-header-icon  mr-1 ml-1 pb-3px feather feather-trending-up"
                                        [ngClass]="{'active': kw_chart_active == 'line'}"></i>
                                </a>
                                <span class="divider"></span>

                                <a href="javascript:void(0);" (click)="tabBarChart('kilo_watt')">
                                    <i class="icon-sm card-header-icon ml-1 pb-3px feather feather-bar-chart-2"
                                        [ngClass]="{'active': kw_chart_active == 'bar'}"></i>
                                </a>

                                <i class="icon-sm card-header-icon mr-2 pl-2 pt-1 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Print"
                                            (click)="data_param = 'kilo_watt'; data_param_el = 'chart_kilo_watt'; exportAsPDF($event)">
                                            <i class="feather feather-printer icon-sm mr-2"></i>
                                            <span class="">Print</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'kilo_watt'; exportAsCSV()">
                                            <i class="feather feather-download icon-sm mr-2"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf"
                                            (click)="data_param = 'kilo_watt'; data_param_el = 'chart_kilo_watt'; exportAsPDF($event)">
                                            <i class="feather feather-file-text icon-sm mr-2"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="collapse px-2" id="collapsekiloWatt">
                            <div class="card-body overflow-auto">
                                <div class="w-max-content d-flex align-items-center justify-content-end">
                                    <div class="form-group mb-0 mr-2">
                                        <h6 class="text-nowrap">Select Date:</h6>
                                    </div>

                                    <div class="form-group mb-0 mr-2">
                                        <input type="date" [(ngModel)]="kw_from_date_selection" autocomplete="off"
                                            class="form-control" (change)="onFromDateSelection($event, 'kilo_watt')">
                                    </div>

                                    <span class="mr-2 from-to">-</span>

                                    <div class="form-group mb-0">
                                        <input type="date" [(ngModel)]="kw_to_date_selection" autocomplete="off"
                                            class="form-control" (change)="onToDateSelection($event, 'kilo_watt')">
                                    </div>

                                </div>
                            </div>
                        </div>
                            <div class="row align-items-start mb-2">
                            </div>
                            <div class="flot-wrapper">
                                <div id="chartKiloWatt" style="width: 100%; height: 450px"></div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

                <div class="col-md-12 col-xl-6 grid-margin stretch-card" id="chart_power_factor">
                    <div class="d-grid row flex-grow">
                        <div class="col grid-margin stretch-card">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="card-title mb-0">POWER FACTOR 3 PHASE (AVG)</h6>

                            <div class="header-right d-flex align-items-center justify-content-between">

                                    <button class="btn pr-1" type="button" data-toggle="collapse"
                                        data-target="#collapsePowerfactor" aria-expanded="false"
                                        aria-controls="collapsePowerfactor">
                                        <i class="feather-sliders"
                                            style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                                    </button>

                                <a href="javascript:void(0);" (click)="tabLinkChart('power_factor')">
                                    <i class="icon-sm card-header-icon  mr-1 ml-1 pb-3px feather feather-trending-up"
                                        [ngClass]="{'active': pf_chart_active == 'line'}"></i>
                                </a>

                                <span class="divider"></span>

                                <a href="javascript:void(0);" (click)="tabBarChart('power_factor')">
                                    <i class="icon-sm card-header-icon ml-1 pb-3px feather feather-bar-chart-2"
                                        [ngClass]="{'active': pf_chart_active == 'bar'}"></i>
                                </a>

                                <i class="icon-sm card-header-icon mr-2 pl-2 pt-1 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Print"
                                            (click)="data_param = 'power_factor'; data_param_el = 'chart_power_factor'; exportAsPDF($event)">
                                            <i class="feather feather-printer icon-sm mr-2"></i>
                                            <span class="">Print</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'power_factor'; exportAsCSV()">
                                            <i class="feather feather-download icon-sm mr-2"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf"
                                            (click)="data_param = 'power_factor'; data_param_el = 'chart_power_factor'; exportAsPDF($event)">
                                            <i class="feather feather-file-text icon-sm mr-2"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="collapse px-2" id="collapsePowerfactor">
                            <div class="card-body overflow-auto">
                                <div class="w-max-content d-flex align-items-center justify-content-end">
                                    <div class="form-group mb-0 mr-2">
                                        <h6 class="text-nowrap">Select Date:</h6>
                                    </div>

                                    <div class="form-group mb-0 mr-2">
                                        <input type="date" [(ngModel)]="pf_from_date_selection" autocomplete="off"
                                            class="form-control" (change)="onFromDateSelection($event, 'power_factor')">
                                    </div>

                                    <span class="mr-2 from-to">-</span>

                                    <div class="form-group mb-0">
                                        <input type="date" [(ngModel)]="pf_to_date_selection" autocomplete="off"
                                            class="form-control" (change)="onToDateSelection($event, 'power_factor')">
                                    </div>

                                </div>
                            </div>
                        </div>
                            <div class="row align-items-start mb-2">
                            </div>
                            <div class="flot-wrapper">
                                <div id="chartPowerFactor" style="width: 100%; height: 450px"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            </div>
        </ng-template>
    </ngb-tab>

    <ngb-tab *ngIf="tab_table_status" id="tab-table" title="Table">
        <ng-template ngbTabContent>

            <div class="row mt-4">

                <div class="col-lg-12 col-xl-12 stretch-card" id="tab-table">
                    <div class="d-grid row flex-grow">

                        <div class="col grid-margin stretch-card">
                            <div class="card">
                                <div class="d-flex justify-content-between card-header align-items-center mb-4 mb-md-3">
                                    <h6 class="card-title mb-0 ">Table</h6>

                                    <div class="d-flex align-items-center justify-content-end">

                                        <p>
                                            <button class="btn ml-1 pr-2" type="button" data-toggle="collapse"
                                                data-target="#collapseExample" aria-expanded="false"
                                                aria-controls="collapseExample">
                                                <i class="feather-sliders"
                                                    style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                                            </button>
                                        </p>

                                        <div class="dropdown ml-2">
                                            <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                                    name="Print"
                                                    (click)="data_param = 'table'; data_param_el = 'tab-table'; exportAsPDF($event)">
                                                    <i class="feather feather-printer icon-sm mr-2"></i>
                                                    <span class="">Print</span>
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                                    (click)="data_param = 'table'; exportAsCSV()">
                                                    <i class="feather feather-download icon-sm mr-2"></i>
                                                    <span class="">Export as CSV</span>
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                                    name="Pdf"
                                                    (click)="data_param = 'table'; data_param_el = 'tab-table'; exportAsPDF($event)">
                                                    <i class="feather feather-file-text icon-sm mr-2"></i>
                                                    <span class="">Export as PDF</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="collapse px-2" id="collapseExample">
                                    <div class="card-body overflow-auto">
                                        <div class="w-max-content d-inline-flex align-items-center">
                                            <div class="form-group mb-0 mr-2">
                                                <h6 class="text-nowrap">Select Date:</h6>
                                            </div>
                                            <div class="form-group mb-0 mr-2">
                                                <input type="date" [(ngModel)]="tab_from_date_selection" autocomplete="off"
                                                    class="form-control" (change)="onTabularFromDateSelection($event)">
                                            </div>
                                            <span class="mr-2 from-to">
                                                -
                                            </span>
                                            <div class="form-group mb-0 mr-2">
                                                <input type="date" [(ngModel)]="tab_to_date_selection" autocomplete="off"
                                                    class="form-control" (change)="onTabularToDateSelection($event)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width="20%">AVG. LINE VOLTAGE VL-L (VOLTS)</th>
                                                    <th width="20%">AVG. 3 PHASE CURRENT (AMPS)</th>
                                                    <th width="20%">TOTAL ACTIVE POWER (KW)</th>
                                                    <th width="20%">POWER FACTOR 3 PHASE (AVG)</th>
                                                    <th width="20%">TIMESTAMP</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr
                                                    *ngFor="let data of tablular_data | slice: (page-1) * pageSize : page * pageSize">
                                                    <td>{{ data.voltage.toFixed(2) }}</td>
                                                    <td>{{ data.current.toFixed(2) }}</td>
                                                    <td>{{ data.kw.toFixed(2) }}</td>
                                                    <td>{{ data.power_factor.toFixed(2) }}</td>
                                                    <td>{{ data.date | date: 'medium' }}</td>
                                                </tr>

                                            </tbody>

                                        </table>

                                    </div>
                                    <br>

                                    <ngb-pagination class="d-flex justify-content-end"
                                        [collectionSize]="tablular_data.length" [(page)]="page" [maxSize]="2"
                                        [rotate]="true" [pageSize]="pageSize" [boundaryLinks]="true">

                                    </ngb-pagination>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- row -->

        </ng-template>
    </ngb-tab>

</ngb-tabset>