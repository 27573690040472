<div class="" id="favorite_devices">
    
    <div class="row">
        <div class="col-12 col-xl-12 stretch-card" *ngIf="favorite_devices.length == 0">
            <h3>No device favorite yet.</h3>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-xl-12 stretch-card" >
            <div class="row flex-grow">
                <div class="col col-lg-3 grid-margin stretch-card" *ngFor="let device of favorite_devices">
                    <div class="card">
                        <div class="card-body">
                            <div class="row m-0">
                                <div class="col-12 p-0">
                                    <h5 class="mb-2 device_location d-flex align-items-center justify-content-between"
                                        (click)="gotoDevice(device.device_id)">{{ device.location }}<span
                                            (click)="toggleFavorite(device.device_id)"
                                            class="d-flex float-right heart-fill favourite-icon"></span>
                                    </h5>
                                    <h6 class="card-title mb-0 mt-1 echo-blue-text">Voltage (L-L Volt): {{ device.voltage }}</h6>
                                    <h6 class="card-title mb-0 mt-1 echo-blue-text">Current (Avg A): {{ device.current }}</h6>
                                    <h6 class="card-title mb-0 mt-1 echo-blue-text">Power (Avg Kw): {{ device.power }}</h6>
                                    <h6 class="card-title mb-0 mt-1 echo-blue-text">Frequency (Hz): {{ device.frequency }}</h6>
                                    <h6 class="card-title mb-0 mt-1 echo-blue-text">Power Factor: {{ device.power_factor }}</h6>
                                    <h6 class="card-title mb-0 mt-1 echo-blue-text">Energy: {{ device.energy }}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- row -->