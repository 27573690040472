<ngb-tabset #t="ngbTabset" id="analytics_heat_map">

    <ngb-tab id="Graph" title="Graph">
        <ng-template ngbTabContent>
            <zainergy-dashboard-cards for="analytics"></zainergy-dashboard-cards>
            <div class="row">

                <div class="col-md-24 col-xl-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center mb-4 mb-md-3">
                            <h5 class="card-title mb-0 ">Heat Map</h5>

                            <div class="header-right d-flex align-items-center">
                                <i class="icon-sm card-header-icon ml-4 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="#"><i
                                                class="icon-sm mr-2 feather feather-printer"></i> <span
                                                class="">Print</span></a>
                                        <a class="dropdown-item d-flex align-items-center" href="#"><i
                                                class="icon-sm mr-2 feather feather-download"></i> <span
                                                class="">Download</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">

                            <div class="flot-wrapper">
                                <div id="chartdiv" style="width: 100%; height: 500px"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngb-tab>


</ngb-tabset>