import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { DataSharedService } from 'src/app/shared/services/data-shared.service';
import { EmitterService } from 'src/app/shared/services/event-service';
import { environment } from '../../../environments/environment'

@Component({
  selector: 'zainergy-solar-cards',
  templateUrl: './solar-cards.component.html',
  styleUrls: ['./solar-cards.component.scss']
})
export class SolarCardsComponent implements OnInit {

  @Input() for: String;

  public action: string = '';
  public cards: any = [];
  public device_id: string = '';
  public weather_endpoint: string = 'https://api.openweathermap.org/data/2.5/weather';
  public kpis: any = [];

  // nm
  public total_load: any = 0.00;
  public pv_yield_imp: any = 0.00;
  public gs: any = 0.00;
  public ds: any = 0.00;
  public ke_exp: any = 0.00;
  public ke_imp: any = 0.00;
  public nm_site: string = '';

  // pv
  public voltage: any = 0.00;
  public current: any = 0.00;
  public kw_now: any = 0.00;
  public todays_energy: any = 0.00;
  public weather: any = 0.00;
  public card_interval: number;
  public timestamp: any = '';

  constructor(
    private dashboardService: DashboardService,
    private toastr: ToastrService,
    private dataShared: DataSharedService,
    private eventEmitter: EmitterService,
  ) { }

  ngOnInit(): void {

     this.getAllowSetting();
    // this.getKpi();

    this.eventEmitter.listen('getSelectedSingleDevice', (device_id) => {
      // if (this.dataShared.curr_menu == 'pv-string-monitoring' || this.dataShared.curr_menu == 'pv-net-metering') {
        this.device_id = device_id;
        this.getKpi();
      // }  
    });
    
    this.eventEmitter.listen('nmDevices', nm => {
      this.nm_site = (nm[1]) ? nm[1]: "";
      this.getKpi();
    })

    if (localStorage.getItem('user') == '20') {
      // run in every 5 minutus
      this.card_interval = + setInterval(() => {
        this.getKpi();
      }, 300000);
    }

  }

  getAllowSetting() {
    
    let setting_body = {
      action: 'getSettings',
      meta_key: 'config',
      user_id: localStorage.getItem('user'),
      account_id: [localStorage.getItem('account')]
    }

    this.dashboardService.httpPost(setting_body).subscribe((res: any) => {

      if (res.status == 200) {

        let data = (localStorage.getItem('sub_user') == '0') ? JSON.parse(res.data?.config[0].kpi_setting) : JSON.parse(res.data?.config[0].sub_kpi_setting);
        let nm = (JSON.parse(res.data?.config[0].meta_value).nm) ? JSON.parse(res.data?.config[0].meta_value).nm : undefined; 
      
        if (this.for == 'net-monitoring') {

          this.fillNMCards(data.pv_net_metering_kpis)
          this.action = 'getDeviceNetMeteringKPI';
          this.nm_site = this.dataShared.nm_site;

        }  else if (this.for == 'pv-string') {

          this.action = 'getPVStringKPI';
          this.fillPVCards(data.pv_string_monitoring_kpi);
          // this.getWeather();
        }
        this.getKpi();
      }
    }) 
  }
  
  fillNMCards(cards) {
    this.cards = [
      {
        'key': cards.kpi_1.name,
        'value': `${this.total_load} (KWh)`,
        'icon': 'theme-icon dashboard-icon',
        'status': cards.kpi_1.status,
        'unit': ''
      },
      {
        'key': cards.kpi_2.name,
        'value': `${this.pv_yield_imp} (KWh)`,
        'icon': 'theme-icon dashboard-icon',
        'button_icon': {
          'class': 'equal-icon',
          'symbol': '='
        },
        'status': cards.kpi_2.status,
        'unit': ''
      },
      {
        'key': cards.kpi_3.name,
        'value': `${this.ds} (KWh)`,
        'icon': 'theme-icon dashboard-icon',
        'button_icon': {
          'class': 'plus-icon',
          'symbol': '+'
        },
        'status': cards.kpi_3.status,
        'unit': ''
      },
      {
        'key': cards.kpi_4.name,
        'value': `${this.ke_exp} (KWh)`,
        'icon': 'theme-icon dashboard-icon',
        'status': cards.kpi_4.status,
        'unit': ''
      },
      {
        'key': cards.kpi_5.name,
        'value': `${this.ke_imp} (KWh)`,
        'icon': 'theme-icon dashboard-icon',
        'status': cards.kpi_5.status,
        'unit': ''
      },
      {
        'key': cards.kpi_6.name,
        'value': `${this.gs}`,
        'icon': 'theme-icon dashboard-icon',
        'status': cards.kpi_6.status,
        'unit': ''
      }
    ]
    for (let i = 0; i < this.cards.length; i++ ) {
      if(this.cards[i].status == true) {
        this.kpis.push(this.cards[i])
      }
    }
  }

  fillPVCards(cards) {
    this.cards = [
      {
        'key': cards.kpi_1.name,
        'value': `${this.voltage}`,
        'icon': 'theme-icon dashboard-icon',
        'status': cards.kpi_1.status,
        'unit': 'V'
      },
      {
        'key': cards.kpi_2.name,
        'value': `${this.current}`,
        'icon': 'theme-icon dashboard-icon',
        'status': cards.kpi_2.status,
        'unit': 'A'
      },
      {
        'key': cards.kpi_3.name,
        'value': `${this.kw_now}`,
        'icon': 'theme-icon dashboard-icon',
        'status': cards.kpi_3.status,
        'unit': 'Kw'        
      },
      {
        'key': cards.kpi_4.name,
        'value': `${this.todays_energy}`,
        'icon': 'theme-icon dashboard-icon',
        'status': cards.kpi_4.status,
        'unit': 'Kwh'
      },
      {
        'key': cards.kpi_5.name,
        'value': `${this.weather}`,
        'icon_img': 'theme-icon dashboard-icon',
        'status': cards.kpi_5.status,
        'unit': '\xB0C'
      }
    ]
    for (let i = 0; i < this.cards.length; i++ ) {
      if(this.cards[i].status == true) {
        this.kpis.push(this.cards[i])
      }
    }
  }

  NMCards(data) {
    
    this.cards[0].value = (parseFloat(data.kwh_today) < 0) ? 0.00 : data.kwh_today.toFixed(2);
    this.cards[1].value = (parseFloat(data.pv_yield_imp) < 0) ? 0.00 : data.pv_yield_imp.toFixed(2);
    this.cards[2].value = (parseFloat(data.ds) < 0) ? 0.00 : data.ds.toFixed(2);
    this.cards[3].value = (parseFloat(data.ke_grid_exp) < 0) ? 0.00 : data.ke_grid_exp.toFixed(2);
    this.cards[4].value = (parseFloat(data.ke_grid_imp) < 0) ? 0.00 : data.ke_grid_imp.toFixed(2);
    this.cards[5].value = this.dataShared.today_genset_kwh = data.gs.toFixed(2);
  }

  PVCards(data) {

    this.cards[0].value = (parseFloat(data.voltage) < 0) ? 0.00 : `${data.voltage.toFixed(2)}`;
    this.cards[1].value = `${data.current.toFixed(2)}`;
    this.cards[2].value = `${data.kw_now.toFixed(2)}`;
    this.cards[3].value = (parseFloat(data.today_energy) < 0) ? 0.00 : `${data.today_energy.toFixed(2)}`;
    this.timestamp = data.timestamp.slice(0, 10) + '   ' + data.timestamp.slice(13, 20);
  }

  getKpi() {
    this.resetCards();

    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      // if (res.status == 404 && res.message) this.toastr.error(res.message);

      if (res.status == 200 && res.data) {
        if (this.for == 'net-monitoring') this.NMCards(res.data);
        else if (this.for == 'pv-string') {
          this.PVCards(res.data);
          if(res.status == 200 && res.data.weather_temp) {
      
            this.cards[4].key = `${res.data.weather_description}`;
            this.cards[4].value = `${res.data.weather_temp}`;
            this.cards[4].icon_img = 'https://openweathermap.org/img/w/' + res.data.weather_icon + '.png';
          }
        }
        if ('device_status' in res.data) this.eventEmitter.emit('deviceStatus', res.data);
      }
    });
  }

  getBody() {
    let body = {
      'action': this.action,
      'account_id': localStorage.getItem('account'),
      'user_id': localStorage.getItem('user')
    };

    if (this.for == 'net-monitoring') {
      body['site'] = this.nm_site;
    }

    if(this.for == 'pv-string') {
      body['user_id'] = localStorage.getItem('user'),
      body['account_id'] = localStorage.getItem('account'),
      body['device_id'] = this.dataShared.device_id,
      body['parameters'] = ["avg_vl_pp", "avg_curr_3p", "total_w", "total_wh"],
      body['status'] = true
    }

    return body;
  }

  /* getWeather() {
    let url = this.weather_endpoint + `?lang=en&units=metric&APPID=${environment.weather_app_id}&q=karachi,sindh,pk`;
    this.dashboardService.httpGet(url).subscribe((res: any) => {
      if(!res.code || !res.error) this.cards[4].value = res.main.temp;
    });
  } */

  resetCards() {
    if (this.cards[0]) this.cards[0].value = 0.00;
    if (this.cards[1]) this.cards[1].value = 0.00;
    if (this.cards[2]) this.cards[2].value = 0.00;
    if (this.cards[3]) this.cards[3].value = 0.00;
    if (this.cards[4]) this.cards[4].value = 0.00;
  }
}
