<ul>

    <li *ngFor="let childrens of device_hierarchy">

        <ng-container *ngIf="!childrens.childrens; else withChildrens">

            <div class="d-flex align-items-center">
                <span
                    [ngClass]="(childrens.status) ? 'device-active' : childrens.status === false ? 'device-warning' : 'device-disable'"
                    class="device-status"></span>

                <a href="javascript:void(0);" (click)="selectedDevice(childrens.id, childrens.name)">
                    {{ childrens.name }}
                </a>

            </div>

        </ng-container>

        <ng-template #withChildrens>
            
            <div class="d-flex align-items-center" (click)="toggleCollapse(childrens)">
                <i class="tree-icon"
                    [ngClass]="{'feather-chevron-right': !childrens.expanded, 'feather-chevron-down': childrens.expanded}"></i>
                <span>{{ childrens.name }}</span>
            </div>

            <zainergy-recursive-control-panel *ngIf="!childrens.expanded"
                [device_hierarchy]="childrens.childrens"></zainergy-recursive-control-panel>

        </ng-template>
    </li>
</ul>