import { Component, OnInit, Renderer2 } from "@angular/core";
import { DashboardService } from "src/app/shared/services/dashboard.service";
import { EmitterService } from "src/app/shared/services/event-service";
import { DataSharedService } from "src/app/shared/services/data-shared.service";
import { ToastrService } from "ngx-toastr";
import { NgbTabChangeEvent } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "zainergy-control-panel",
  templateUrl: "./control-panel.component.html",
  styleUrls: ["./control-panel.component.scss"],
})
export class ControlPanelComponent implements OnInit {
  constructor(
    private dashboardService: DashboardService,
    private eventEmitter: EmitterService,
    private renderer: Renderer2,
    private dataShared: DataSharedService,
    private toastr: ToastrService
  ) {
    this.intervals = ["1s", "1m", "5m", "15m", "30m", "1h"];
  }

  public user: any;
  public device_hierarchy: any;
  public device_id: any;
  public intervals: any = [];
  public single_interval: String = "";
  public group_interval: String = "";

  public selected_device: any;
  public location: string = "N/A";
  public category: any = "N/A";
  public type: string = "N/A";
  public source: string = "N/A";
  public id: string = "N/A";
  public favorite: number = 0;
  public favorite_class: string = "heart";
  public show_panel: boolean = true;
  public panel_info: string = "single";
  public panel_devices: any = [];
  public device_class: String = "default-status";
  public togglePanel: boolean = false;
  public curr_tab = "";
  public device_type: any = [];

  public tab_energy_status: boolean = false;
  public tab_flow_status: boolean = false;
  public tab_sensor_status: boolean = false;
  public tab_level_status: boolean = false;

  ngOnInit(): void {
    this.getDevicetype();

    this.eventEmitter.listen("selectedDevice", (device_id) => {
      this.panel_info = "single";
      this.device_id = device_id;
      this.selectedDevice();
    });

    this.eventEmitter.listen("selectedGroupDevices", (data) => {
      this.groupDevices(data);
    });

    this.eventEmitter.listen("deviceStatus", (data) => {
      this.deviceStatus(data);
    });

    this.eventEmitter.listen("menuFromPanelControl", (status) => {
      this.device_id = this.id;
      this.selectedDevice();
      this.show_panel = !status;
      if (status) this.addClassInbodyTag();
    });
  }

  getDevicetype() {
    let device_type = [];
    this.device_type = [];

    let body = {
      action: "getDevices",
      account_id: localStorage.getItem("account"),
    };

    this.dashboardService.httpPost(body).subscribe((res: any) => {
      // for sort by device type
      res.data.sort((a, b) =>
        a.device_type > b.device_type
          ? 1
          : b.device_type > a.device_type
          ? -1
          : 0
      );

      // for add non duplicate device type in array
      for (let i = 0; i < res.data.length; i++) {
        if (i == 0) {
          device_type.push(res.data[i].device_type);
        } else if (res.data[i].device_type != res.data[i - 1].device_type) {
          device_type.push(res.data[i].device_type);
        }

        // set curr_tab
        if (res.data[i].device_type == "energy") this.curr_tab = "tab-energy";
        else if (res.data[i].device_type == "flow") this.curr_tab = "tab-flow";
        else if (res.data[i].device_type == "sensor")
          this.curr_tab = "tab-sensor";
        else if (res.data[i].device_type == "level")
          this.curr_tab = "tab-level";

        // allow tab
        if (res.data[i].device_type == "energy") this.tab_energy_status = true;
        if (res.data[i].device_type == "flow") this.tab_flow_status = true;
        if (res.data[i].device_type == "sensor") this.tab_sensor_status = true;
        if (res.data[i].device_type == "level") this.tab_level_status = true;

        this.device_type = device_type;
      }
      this.dataShared.device_type = this.device_type[0];
      this.deviceLocationHierarchy(this.device_type[0]);
    });
  }

  tabChange($event: NgbTabChangeEvent) {
    this.curr_tab = $event.nextId;

    if (this.curr_tab == "tab-energy") {
      this.deviceLocationHierarchy("energy");
    } else if (this.curr_tab == "tab-flow") {
      this.deviceLocationHierarchy("flow");
    } else if (this.curr_tab == "tab-sensor") {
      this.deviceLocationHierarchy("sensor");
    } else if (this.curr_tab == "tab-level") {
      this.deviceLocationHierarchy("level");
    }
  }

  addClassInbodyTag() {
    this.renderer.addClass(document.body, "close-infobar");
  }

  toggleClass() {
    this.togglePanel = !this.togglePanel;
    if (!this.togglePanel)
      this.renderer.addClass(document.body, "close-infobar");
    else this.renderer.removeClass(document.body, "close-infobar");
  }

  deviceLocationHierarchy(device_type) {
    this.eventEmitter.emit('device_type', device_type);
    this.removeDevices();
    let data = {
      action: "getDeviceLocationHierarchy",
      account_id: localStorage.getItem("account"),
      sub_user: localStorage.getItem("sub_user"),
      device_type: device_type,
    };

    this.dashboardService.httpPost(data).subscribe((res: any) => {
      if (res.data) {
        this.device_hierarchy = res.data;
        this.firstDeviceID(res.data[0]);
      }
    });
  }

  /** get first device ID from nested array */
  firstDeviceID(data) {
    if (data.childrens) {
      this.firstDeviceID(data.childrens[0]);
    } else {
      this.device_id = this.dataShared.device_id = data.id;
      this.eventEmitter.emit("selectedDevice", this.device_id);
    }
  }

  selectedDevice() {
    this.eventEmitter.emit("getSelectedSingleDevice", this.device_id);
    this.getSelectedDeviceInfo();
  }

  groupDevices(data) {
    this.panel_info = "group";

    if (this.panel_devices.length == 10) {
      this.toastr.info("Limit! you exceed the limit");
      return;
    }

    this.panel_devices.push(data);
    let devices = new Set();
    this.panel_devices = this.panel_devices.filter((el) => {
      const duplicate = devices.has(el.device_id);
      devices.add(el.device_id);
      return !duplicate;
    });

    this.dataShared.panel_devices = this.panel_devices;
  }

  getSelectedDeviceInfo() {
    let body = {
      action: "getDeviceByID",
      device_id: [this.device_id],
    };

    this.dashboardService.httpPost(body).subscribe((res: any) => {
      if (res.status === 200 && res.data[this.device_id]) {
        let device_info = (this.dataShared.device_detail =
          res.data[this.device_id]);

        this.location = this.dataShared.device_location = device_info.location;
        this.category = device_info.device_category == "2" ? "Meter" : "Sensor";
        this.type = device_info.device_type;
        this.source = device_info.device_model;
        this.id = device_info.device_id;
        this.favorite = device_info.favorite;
        this.favorite_class = this.favClass();
        this.dataShared.device_params = device_info.parameters;
      }
    });
  }

  favClass() {
    return this.favorite == 1 ? "heart-fill" : "heart";
  }

  deviceStatus(data) {
    this.device_class =
      data.device_status === true ? "device-active" : "device-warning";
  }

  removeDevice(device) {
    this.dataShared.panel_devices.filter(function (value, key, devices) {
      if (value.device_id == device) devices.splice(key, 1);
    });
  }

  removeDevices() {
    this.dataShared.panel_devices.splice(
      0,
      this.dataShared.panel_devices.length
    );
  }

  getDevices() {
    this.dataShared.group_interval = this.group_interval;
    if (this.panel_devices.length > 1 && this.panel_devices.length < 11) {
      this.eventEmitter.emit("groupDevices", []);
    } else {
      return this.toastr.info("Select minimum 2 or maximum 10 devices", "", {
        timeOut: 10000, // 10sec
      });
    }
  }

  toggleFavorite(device_id) {
    let body = {
      action: "updateDeviceById",
      account_id: localStorage.getItem("account"),
      device_id: device_id,
      data: {
        favorite: this.favorite == 0 ? 1 : 0,
      },
    };

    this.dashboardService.httpPost(body).subscribe((res: any) => {
      if (res.status === 200) {
        this.toastr.success("device favorite updated");
        this.favorite = this.favorite == 0 ? 1 : 0;
        this.favorite_class = this.favClass();
      } else this.toastr.error(res.data.message);
    });
  }

  selectedInterval() {
    this.dataShared.single_interval = this.single_interval;
    if (this.panel_info == "single") this.deviceLocationHierarchy("energy");
  }
}
