import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { EmitterService } from 'src/app/shared/services/event-service';
import { DataSharedService } from 'src/app/shared/services/data-shared.service';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})

export class ChartsComponent implements OnInit {

  // string variable
  public action: String = 'getEnergyHourlyData';
  public kpi: string = '';

  // array variable
  public usage_data: any = [];
  public device_id: any;

  constructor(private dashboardService: DashboardService, private eventEmitter: EmitterService, private dataShared: DataSharedService) {
  }

  ngOnInit(): void {
    this.eventEmitter.listen('getSelectedSingleDevice', (device_id) => {
      this.device_id = device_id;
      if (this.dataShared.device_id != null) {
        this.getUsage();
      }
    });
  }

  getBody() {

    let body = {
      'action': this.action,
      'user_id': [localStorage.getItem('user')],
      'device_id': [this.dataShared.device_id],
      'range': { 'from': '2023-06-01', 'to': '2023-06-01' },
      'parameters': ['total_wh']
    }

    return body;
  }

  getUsage() {

    this.usage_data = []; this.action = 'getEnergyHourlyData';
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      if (res.data.first) {

        let data = res.data.last.device_1;

        if (data[0] == 'Flow Meter') this.kpi = 'flow';
        else if (data[0] == 'Level') this.kpi = 'level_analytics_ref';
        else this.kpi = 'analytics';

      }
    })
  }
}