<div class="row">
    <div class="col-md-12 col-xl-12 grid-margin stretch-card">
        <div class="card">
            <div class="d-flex justify-content-between card-header align-items-center mb-4 mb-md-3">

                <h6 class="card-title mb-0 ">Billing and Summary Table</h6>

                <div class="d-flex align-items-center justify-content-end">

                    <p>
                        <button class="btn pr-1" type="button" data-toggle="collapse"
                            data-target="#collapseExample" aria-expanded="false"
                            aria-controls="collapseExample">
                            <i class="feather-sliders"
                            style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                        </button>
                    </p>

                    <div class="dropdown ml-2">
                        <button class="btn p-0" type="button" id="dropdownMenuButton3" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                            <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);" name="Print">
                                <i class="icon-sm mr-2 feather feather-printer"></i>
                                <span class="">Print</span>
                            </a>
                            <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);">
                                <i class="icon-sm mr-2 feather feather-download"></i>
                                <span class="">Export as CSV</span>
                            </a>
                            <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);" name="Pdf">
                                <i class="icon-sm mr-2 feather feather-file-text"></i>
                                <span class="">Export as PDF</span>
                            </a>
                        </div>
                    </div>

                </div>
            </div>

            <div class="collapse px-2" id="collapseExample">
                <div class="card-body overflow-auto">
                    <div class="w-max-content header-right-inner-left d-flex">

                        <div class="text-nowrap form-group mb-0 mr-2">
                            <h6>Select Bill Type:</h6>
                        </div>

                        <div class="form-group mb-0 mr-4" style="max-width: 120px;">
                            <select 
                                class="form-select" 
                                aria-label="Default select example"
                                [(ngModel)]="device_model" 
                                (change)="onChange('device_model', device_model)">
                                <option disabled>Select Bill Type</option>
                                <option>Prepaid</option>
                                <option>Postpaid</option>
                              </select>
                        </div>

                        <div class="text-nowrap form-group mb-0 mr-2 {{ customer_device_model }}">
                            <h6>Select Customer:</h6>
                        </div>
                        <div class="form-group mb-0 mr-4 {{ customer_device_model }}">
                            <ng-multiselect-dropdown [placeholder]="'Select a customer'" [settings]="customerDropdownSettings"
                                [data]="customers" [(ngModel)]="selected_customer" (onSelect)="onItemSelect($event, 'customer')"
                                (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelect($event, 'customer')"
                                (onDeSelectAll)="onDeSelectAll()">
                            </ng-multiselect-dropdown>
                        </div>

                        <div class="text-nowrap form-group mb-0 mr-2">
                            <h6>Select Report Type:</h6>
                        </div>

                        <div class="form-group mb-0 mr-4" style="max-width: 120px;">
                            <select 
                                class="form-select" 
                                aria-label="Default select example"
                                [(ngModel)]="report_type" 
                                (change)="onChange('report_type', report_type)">
                                <option disabled>Select Report Type</option>
                                <option>Monthly</option>
                                <option>Yearly</option>
                              </select>
                        </div>

                        <div class="text-nowrap form-group mb-0 mr-2">
                            <h6>Select Parameters:</h6>
                        </div>

                        <div class="mr-4">
                            <ng-multiselect-dropdown [placeholder]="'Select parameters'"
                                [settings]="billDropdownSettings" [data]="bill_list"
                                [(ngModel)]="selected_items" (onSelect)="onItemSelect($event, 'bill')"
                                (onDeSelect)="onItemDeSelect($event, 'bill')">
                            </ng-multiselect-dropdown>
                        </div>

                            <div class="form-group mb-0 mr-2 {{ monthly_report_type }}">
                                <h6>Select Month:</h6>
                            </div>
                            <div class="form-group mb-0 mr-4 {{ monthly_report_type }}">
                               <input class="text-center" type="month" style="
                                border: 2px solid #D7D8FC;
                                border-radius: 5px;
                                outline: none;
                                color: #c9c8c8;
                                height: 40px; width: 150px;" [(ngModel)]="month"
                               (change)="onChange('monthly', $event)">
                            </div>

                            <div class="text-nowrap form-group mb-0 mr-2 {{ yearly_report_type }}">
                                <h6>Select Year:</h6>
                            </div>

                            <div class="text-nowrap form-group mb-0 mr-4 {{ yearly_report_type }}" style="max-width: 120px;">
                            <select 
                            class="form-select" 
                            aria-label="Default select example"
                            [(ngModel)]="year" 
                            (change)="onChange('yearly', year)">
                            <option disabled>Select Year</option>
                            <option *ngFor="let year of years">{{ year }}</option>
                          </select>
                          </div>

                        <div style="text-align: end;">
                            <button class="btn theme-primary-btn mb-0 ml-2" type="button"
                                (click)="onClick('bill')" style="width: auto;">view</button>
                        </div>

                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="flot-wrapper">
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th *ngFor="let key of bill_key">{{
                                        getTitleBySummaryCode(key) }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of bill_summary">
                                    <td *ngFor="let key of bill_key">{{ data[key] }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>