import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { AnalyticsRoutingModule } from './analytics-routing.module';
import { GoogleMapsModule } from '@angular/google-maps'
// import { ChartsModule } from 'ng2-charts';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { WidgetsModule } from '../widgets/widgets.module';
// import { EnergyConsumptionComponent } from './energy-consumption/energy-consumption.component';
import { HeatMapComponent } from './heat-map/heat-map.component';
import { MinMaxComponent } from './min-max/min-max.component';
import { PowerTrendComponent } from './power-trend/power-trend.component';
import { ShareConsumptionComponent } from './share-consumption/share-consumption.component';
import { NgxPrintModule } from 'ngx-print';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

@NgModule({
  declarations: [/* EnergyConsumptionComponent, */ HeatMapComponent, MinMaxComponent, PowerTrendComponent, ShareConsumptionComponent],
  imports: [
    CommonModule,
    AnalyticsRoutingModule,
    NgMultiSelectDropDownModule.forRoot(),
    GoogleMapsModule,
    // ChartsModule,
    FormsModule,
    NgbModule,
    ScrollingModule,
    WidgetsModule,
    NgxPrintModule,
    NgxDaterangepickerMd.forRoot()
  ],
  exports: [
    
  ]
})

export class AnalyticsModule { }
