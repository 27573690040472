import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as $ from 'jquery';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})

export class ConfigurationComponent implements OnInit {

  public action = 'getAccounts';
  public account: any = {};
  public device_id: string = '';
  public user_id: String = '';

  public accounts: any = [];
  public devices: any = [];
  public device;
  public collections: any = [];
  password: string;

  public search_text: any;
  
  selected: any = { start: moment(), end: moment() };
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'This Week': [moment().startOf('week'), moment().endOf('days')],
    'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
    'This Month': [moment().startOf('month'), moment().endOf('days')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year').format('YYYY'), moment().endOf('days')],
    'Last Year': [moment().subtract(1, 'year').startOf('year').format('YYYY'), moment().subtract(1, 'year').endOf('year')]
  }


  public date_from: string = '';
  public date_to: string = '';

  public DropdownSettings: IDropdownSettings;

  constructor(private dashboardService: DashboardService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getAccounts();
  }

  // api's bodies
  getBody() {
    let body = {
      "action": this.action
    }
    return body;
  }

  // for veiw all accounts
  getAccounts() {
    this.action = 'getAccounts'; this.accounts = [];
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {
      this.accounts = res.data;
      this.accountDropdownSettings()
    })
  }
  
  accountDropdownSettings() {
    this.DropdownSettings = {
      singleSelection: false,
      idField: 'device_id',
      textField: 'location',
      showSelectedItemsAtTop: true,
      itemsShowLimit: 6,
      allowSearchFilter: true,
      clearSearchFilter: true,
      limitSelection: 5,
    };
  }

  
  onItemSelect() {
  }

  onDeSelect() {
  }

  // for all devices against account
  getDeviceByAccount(account) {
    this.account = account;
    let body = {
      "action": "getDevices",
      "account_id": account.id
    }

    this.dashboardService.httpPost(body).subscribe((res: any) => {
      this.devices = res.data;
    })

  }

  onChangeDevice(e) {
    this.device_id = e.target.value;
  }

  dateSelection(e) {
    this.date_from = this.formatDate(e.start['_d']);
    this.date_to = this.formatDate(e.end['_d']);
  }

  formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  confirmDelete(event: Event) {
    event.preventDefault();
    if (this.password === '23090') {
      this.deleteUserData();
    } 
    
    if (this.password === '') {
      this.toastr.error('Password is required');
    }

    if (this.password !== '23090' && this.password !== '') {
      this.toastr.error('Incorrect Password');
    }
    
  }

  deleteUserData() {
    let body = {
      "action": "deleteElasticDocument",
      "user_id": [this.account.user],
      "device_id": [this.device_id],
      "range": {
        "from": this.date_from,
        "to": this.date_to
      }
    }

    if (!this.validateBody(body)) {
      return
    }
    this.dashboardService.httpPost(body).subscribe((res: any) => {
      this.toastr.success(`${res.data.deleted} record deleted successfully`)
      this.password = '';
      window.location.reload();
    })
  }

  validateBody(body) {

    if (body.user_id.length === 0) {
      this.toastr.error('something went wrong please refresh your screen')
      return false
    }

    if (body.device_id.length === 0 || body.device_id[0] === "" ) {
      this.toastr.error('please select a device')
      return false
    }

    if (body.range.from.length == 0) {
      this.toastr.error('please select a date range')
      return false
    }

    return true
  }
}