import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { DataSharedService } from 'src/app/shared/services/data-shared.service';
import { EmitterService } from 'src/app/shared/services/event-service';
import { ApiFunctionsComponent } from 'src/app/widgets/api-functions/api-functions.component';

@Component({
  selector: 'zainergy-dashboard-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit {

  @Input() for: String;

  public device_id: any;
  public cards: any = [];
  public kpis: any = [];
  public data: any = [];

  public avg_vl_pp: any = 0.00;
  public avg_curr_3p: any = 0.00;
  public kw_now: any = 0.00;
  public frequency: any = 0.00;
  public avg_pf_3p: any = 0.00;
  public VFD: any = 0.00;
  public timestamp: any = '';

  public today: any = 0.00;
  public monthly: any = 0.00;
  public yearly: any = 0.00;
  public total: any = 0.00;

  public action: String;
  public parameters: any = [];
  public status: Boolean = false;

  public card_interval: number;
  public config_interval: number;
  public source: any = { source_display: 'd-none', source_type: '', source_color: '', source_type_display: { alarm_display: 'd-none', state_display: 'd-none', source_display: 'd-none' } }
  public level: any = { dropdown: 'd-none', combine: 'd-none', single: 'd-block' };
  public alarm1: any = '';
  public state1: any = '';
  public level_analytics: any = 'Refelling';
  public level_unit: string = '';

  constructor(private dashboardService: DashboardService, private eventEmitter: EmitterService, private dataShared: DataSharedService, private api: ApiFunctionsComponent) { }

  ngOnInit(): void {

    this.getConfiguration(); this.fillDefaultCards()

    this.eventEmitter.listen('getSelectedSingleDevice', (device_id) => {
      this.device_id = device_id;
      this.selectedDeviceKPI(false);
    });
  }

  getConfiguration() {
    // run in every 1 seconds
    this.config_interval = + setInterval(() => {
      if (this.api.setting) {

        clearInterval(this.config_interval);

        let kpi_setting = JSON.parse(this.api.setting?.config[0].kpi_setting);
        // this.level_unit = JSON.parse(this.api.setting?.config[0].meta_value).level_unit;
        let card_type;

        // run in every 5 seconds
        this.card_interval = + setInterval(() => {

          if (card_type != this.for) {
            this.kpis = [];
            if (this.for == 'dashboard'  && kpi_setting.dashboard_powertrend_kpis) this.fillDashboardCards(kpi_setting.dashboard_powertrend_kpis)
            else if (this.for == 'analytics' && kpi_setting.energy_trend_kpis) this.fillAnalyticsCards(kpi_setting.energy_trend_kpis);
            else if (this.for == 'flow' && kpi_setting.dashboard_flowmeter_kpis) this.fillFlowCards(kpi_setting.dashboard_flowmeter_kpis);
            else if (this.for == 'sensor' && kpi_setting.dashboard_sensor_kpis) this.fillSensorCards(kpi_setting.dashboard_sensor_kpis);
            else if (this.for == 'level') this.fillLevelCards();
            else if (this.for == 'level_analytics_ref' || this.for == 'level_analytics_con' || this.for == 'level_analytics_com') this.fillLevelAnalyticsCards();
            card_type = this.for;
          }

          this.selectedDeviceKPI(false);
        }, 5000);

      }
    }, 1000);
  }

  getBody() {
    let body = {
      'user_id': localStorage.getItem('user'),
      'account_id': localStorage.getItem('account'),
      'device_id': this.dataShared.device_id,
      'status': true
    };

    if (this.for == 'dashboard' || this.for == 'sensor') {
      body['action'] = 'getLatestStatsByDevice';
      body['parameters'] = (this.for == 'sensor') ? ["temp", "humid", "airp", "co_2", "set_temp"] : ["avg_vl_pp", "avg_curr_3p", "total_w", "frequency", "avg_pf_3p", "mot_torq", "mot_spd", "mot_spd_est", "dc_vl", "genset_status", "state1", "alarm1", "relay_status"];
    }

    if (this.for == 'analytics' || this.for == 'flow') {
      body['action'] = 'getDeviceAnalysisKPI';
      body['parameters'] = (this.for == 'flow') ? ["flow"] : [];
    }

    if (this.for == 'level' || this.for == 'level_analytics_ref' || this.for == 'level_analytics_con' || this.for == 'level_analytics_com') {
      body['action'] = 'getDeviceLevelKPI';
      body['parameters'] = ["total_cc", "total_cr", "volume", "@timestamp"];
    }
    return body;
  }

  // cards
  fillDefaultCards() {
    this.cards = [
      {
        'key': '-------',
        'value': 0.00,
        'icon': 'theme-icon dashboard-icon',
        'status': true
      },
      {
        'key': '-------',
        'value': 0.00,
        'icon': 'theme-icon dashboard-icon',
        'status': true
      },
      {
        'key': '-------',
        'value': 0.00,
        'icon': 'theme-icon dashboard-icon',
        'status': true
      },
      {
        'key': '-------',
        'value': 0.00,
        'icon': 'theme-icon dashboard-icon',
        'status': true
      },
      {
        'key': '-------',
        'value': 0.00,
        'icon': 'theme-icon dashboard-icon',
        'status': true
      },
    ]

    for (let i = 0; i < this.cards.length; i++) {
      if (this.cards[i].status == true) {
        this.kpis.push(this.cards[i])
      }
    }

  }

  fillDashboardCards(data) {
    this.cards = [
      {
        'key': data.kpi_1.name,
        'value': this.avg_vl_pp,
        'icon': 'theme-icon dashboard-icon',
        'status': data.kpi_1.status
      },
      {
        'key': data.kpi_2.name,
        'value': this.avg_curr_3p,
        'icon': 'theme-icon dashboard-icon',
        'status': data.kpi_1.status
      },
      {
        'key': data.kpi_3.name,
        'value': this.kw_now,
        'icon': 'theme-icon dashboard-icon',
        'status': data.kpi_3.status
      },
      {
        'key': data.kpi_4.name,
        'value': this.frequency,
        'icon': 'theme-icon dashboard-icon',
        'status': data.kpi_4.status
      },
      {
        'key': (this.dataShared.device_detail.device_model == 'VFD') ? 'DC Voltage' : data.kpi_5.name,
        'value': this.avg_pf_3p,
        'icon': 'theme-icon dashboard-icon',
        'status': data.kpi_5.status
      },
    ]

    for (let i = 0; i < this.cards.length; i++) {
      if (this.cards[i].status == true) {
        this.kpis.push(this.cards[i])
      }
    }

  }

  fillAnalyticsCards(data) {
    this.cards = [
      {
        'key': data.kpi_1.name,
        'value': this.kw_now,
        'icon': 'theme-icon dashboard-icon',
        'status': data.kpi_1.status
      },
      {
        'key': data.kpi_2.name,
        'value': this.today,
        'icon': 'theme-icon dashboard-icon',
        'status': data.kpi_2.status
      },
      {
        'key': data.kpi_3.name,
        'value': this.monthly,
        'icon': 'theme-icon dashboard-icon',
        'status': data.kpi_3.status
      },
      {
        'key': data.kpi_4.name,
        'value': this.yearly,
        'icon': 'theme-icon dashboard-icon',
        'status': data.kpi_4.status
      },
      {
        'key': data.kpi_5.name,
        'value': this.total,
        'icon': 'theme-icon dashboard-icon',
        'status': data.kpi_5.status
      }
    ]
    for (let i = 0; i < this.cards.length; i++) {
      if (this.cards[i].status == true) {
        this.kpis.push(this.cards[i])
      }
    }
  }

  fillFlowCards(data) {
    this.cards = [
      {
        'key': data.kpi_1.name,
        'value': this.kw_now,
        'icon': 'theme-icon water-drop-icon',
        'status': data.kpi_1.status
      },
      {
        'key': data.kpi_2.name,
        'value': this.today,
        'icon': 'theme-icon water-drop-icon',
        'status': data.kpi_2.status
      },
      {
        'key': data.kpi_3.name,
        'value': this.monthly,
        'icon': 'theme-icon water-drop-icon',
        'status': data.kpi_3.status
      },
      {
        'key': data.kpi_4.name,
        'value': this.yearly,
        'icon': 'theme-icon water-drop-icon',
        'status': data.kpi_4.status
      },
      {
        'key': data.kpi_5.name,
        'value': this.total,
        'icon': 'theme-icon water-drop-icon',
        'status': data.kpi_5.status
      }
    ]

    for (let i = 0; i < this.cards.length; i++) {
      if (this.cards[i].status == true) {
        this.kpis.push(this.cards[i])
      }
    }
  }

  fillSensorCards(data) {
    this.cards = [
      {
        'key': data.kpi_1.name,
        'value': 0.00,
        'icon': 'theme-icon temp-icon',
        'status': data.kpi_1.status
      },
      {
        'key': data.kpi_2.name,
        'value': 0.00,
        'icon': 'theme-icon humidity-icon',
        'status': data.kpi_2.status
      },
      {
        'key': data.kpi_3.name,
        'value': 0.00,
        'icon': 'theme-icon pressure-icon',
        'status': data.kpi_3.status
      },
      {
        'key': data.kpi_4.name,
        'value': 0.00,
        'icon': 'theme-icon carbon-icon',
        'status': data.kpi_4.status
      },
      {
        'key': data.kpi_5.name,
        'value': 0.00,
        'icon_img': '',
        'status': data.kpi_5.status
      }
    ]

    for (let i = 0; i < this.cards.length; i++) {
      if (this.cards[i].status == true) {
        this.kpis.push(this.cards[i])
      }
    }
  }

  fillLevelCards() {
    this.cards = [
      {
        'key': 'Total Tank Capacity ' + '(' + this.level_unit + ')',
        'value': 0.00,
        'icon': 'theme-icon tank-capacity-icon',
        'status': true
      },
      {
        'key': 'Available Volume ' + '(' + this.level_unit + ')',
        'value': 0.00,
        'icon': 'theme-icon tank-capacity-icon',
        'status': true
      },
      {
        'key': 'Filled Percentage (%)',
        'value': 0.00,
        'icon': 'theme-icon humidity-icon',
        'status': true
      },
      {
        'key': 'Today Refill ' + '(' + this.level_unit + ')',
        'value': 0.00,
        'icon': 'theme-icon tank-capacity-icon',
        'status': true
      },
      {
        'key': 'Today Consumption ' + '(' + this.level_unit + ')',
        'value': 0.00,
        'icon': 'theme-icon tank-capacity-icon',
        'status': true
      }
    ]

    for (let i = 0; i < this.cards.length; i++) {
      if (this.cards[i].status == true) {
        this.kpis.push(this.cards[i])
      }
    }
  }

  fillLevelAnalyticsCards() {
    this.cards = [
      {
        'key': 'Available Volume ' + '(' + this.level_unit + ')',
        'value': 0.00,
        'icon': 'theme-icon tank-capacity-icon',
        'status': true
      },
      {
        'key': "Today's",
        'value': 0.00,
        'combine': {
          "refelling": 0.00,
          "consumption": 0.00
        },
        'icon': 'theme-icon tank-capacity-icon',
        'status': true
      },
      {
        'key': "Monthly's",
        'value': 0.00,
        'combine': {
          "refelling": 0.00,
          "consumption": 0.00
        },
        'icon': 'theme-icon tank-capacity-icon',
        'status': true
      },
      {
        'key': "Yearly's",
        'value': 0.00,
        'combine': {
          "refelling": 0.00,
          "consumption": 0.00
        },
        'icon': 'theme-icon tank-capacity-icon',
        'status': true
      },
      {
        'key': 'Counter',
        'value': 0.00,
        'combine': {
          "refelling": 0.00,
          "consumption": 0.00
        },
        'icon': 'theme-icon tank-capacity-icon',
        'status': true
      }
    ]

    for (let i = 0; i < this.cards.length; i++) {
      if (this.cards[i].status == true) {
        this.kpis.push(this.cards[i])
      }
    }
  }

  selectedDeviceKPI(reset = true) {

    if (reset) this.resetDeviceKpi();
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      if (res.status == 200 && res.data.timestamp) {

        this.data = res.data;

        if (this.for == 'dashboard') this.dashboardCards(res.data);
        else if (this.for == 'analytics') this.analyticsCards(res.data);
        else if (this.for == 'flow') this.flowCards(res.data);
        else if (this.for == 'sensor') this.sensorCards(res.data);
        else if (this.for == 'level') this.levelCards(res.data);
        // else if (this.for == 'level_analytics')  this.LevelAnalyticsCards(res.data);
        else if (this.for == 'level_analytics_ref') this.refellingLevelAnalyticsCards(res.data);
        else if (this.for == 'level_analytics_con') this.consumptionlevelAnalyticsCards(res.data);
        else if (this.for == 'level_analytics_com') this.combinelevelAnalyticsCards(res.data);

        if ('device_status' in res.data) this.eventEmitter.emit('deviceStatus', res.data);
      }

      if (this.for == 'sensor' && res.status == 200 && res.data.weather_temp) {

        if (res.data.weather_temp) {
          this.cards[4].key = res.data.weather_description;
          this.cards[4].value = `${res.data.weather_temp}\xB0C` + ' / ' + `${res.data.weather_humid}%`;
          this.cards[4].icon_img = 'https://openweathermap.org/img/w/' + res.data.weather_icon + '.png';
        }
      }
    });
  }

  getSource(data) {

    if (data.source != null && data.source == 'Dual Source') {

      this.source.source_display = 'd-inline-flex';
      this.source.source_type_display.alarm_display = 'd-none';
      this.source.source_type_display.state_display = 'd-none';
      this.source.source_type_display.source_display = 'd-inline-block';

      this.source.source_type = (data.genset_status == 0) ? 'Utility' : 'Genset';
      this.source.source_color = (data.genset_status == 0) ? 'btn-outline-success' : 'btn-outline-danger';

    } else if (data.source != null && data.source == 'Inverter') {

      this.source.source_display = 'd-inline-flex';
      this.source.source_type_display.alarm_display = 'd-inline-block';
      this.source.source_type_display.state_display = 'd-inline-block';
      this.source.source_type_display.source_display = 'd-inline-block';

      this.source.source_type = data.source;
      this.source.source_color = 'btn-outline-success';

      this.alarm1 = (data.alarm1 && data.alarm1 != 0) ? data.alarm1 : 'Not Available';
      this.state1 = (data.state1 && data.state1 != 0) ? data.state1 : 'Not Available';

    } else if (data.source != null && data.source == 'Level') {
      
      this.source.source_display = 'd-inline-flex';
      this.source.source_type_display.alarm_display = 'd-inline-block';
      this.source.source_type_display.state_display = 'd-none';
      this.source.source_type_display.source_display = 'd-none';

      this.source.source_color = 'btn-outline-success';

    } else this.source.source_display = 'd-none';
  }

  dashboardCards(data) {

    this.level.dropdown = 'd-none';
    this.level.single = 'd-block';
    this.level.combine = 'd-none';

    this.getSource(data);

    this.dataShared.animated_gauge_value = data.mot_torq;
    this.dataShared.mot_spd = data.mot_spd;
    this.dataShared.mot_spd_estm = data.mot_spd_est;
    this.dataShared.geo_location = data.geo_location;
    this.dataShared.map_status = data.map_status;

    this.cards[0].value = (parseFloat(data.avg_vl_pp) < 0) ? 0.00 : data.avg_vl_pp.toFixed(2);
    this.cards[1].value = data.avg_curr_3p.toFixed(2);
    this.cards[2].value = data.total_w.toFixed(2);
    this.cards[3].value = data.frequency.toFixed(2);
    this.cards[4].value = (this.dataShared.device_detail.device_model == 'VFD') ? data.dc_vl.toFixed(2) : data.avg_pf_3p.toFixed(2);
    this.timestamp = data.timestamp.slice(0, 10) + '   ' + data.timestamp.slice(13, 20);
  }

  analyticsCards(data) {

    this.level.dropdown = 'd-none';
    this.level.single = 'd-block';
    this.level.combine = 'd-none';

    this.getSource(data);

    this.cards[0].value = data.kw_now.toFixed(2);
    this.cards[1].value = (parseFloat(data.today) < 0) ? 0.00 : data.today.toFixed(2);
    this.cards[2].value = (parseFloat(data.monthly) < 0) ? 0.00 : data.monthly.toFixed(2);
    this.cards[3].value = (parseFloat(data.yearly) < 0) ? 0.00 : data.yearly.toFixed(2);
    this.cards[4].value = data.energy_counter.toFixed(2);
    this.timestamp = data.timestamp.slice(0, 10) + '   ' + data.timestamp.slice(13, 20);

  }

  flowCards(data) {

    this.level.dropdown = 'd-none';
    this.level.single = 'd-block';
    this.level.combine = 'd-none';

    this.dataShared.animated_gauge_value = data.instant_flow;
    this.cards[0].value = data.instant_flow.toFixed(2);
    this.cards[1].value = data.today.toFixed(2);
    this.cards[2].value = data.monthly.toFixed(2);
    this.cards[3].value = data.yearly.toFixed(2);
    this.cards[4].value = data.counter.toFixed(2);
    this.timestamp = data.timestamp.slice(0, 10) + '   ' + data.timestamp.slice(13, 20);

  }

  sensorCards(data) {

    this.level.dropdown = 'd-none';
    this.level.single = 'd-block';
    this.level.combine = 'd-none';

    this.dataShared.temp = data.temp;
    this.dataShared.set_temp = data.set_temp;
    this.dataShared.humid = data.humid;
    this.dataShared.geo_location = data.geo_location;

    this.cards[0].value = data.temp.toFixed(2);
    this.cards[1].value = data.humid.toFixed(2);
    this.cards[2].value = data.airp.toFixed(2);
    this.cards[3].value = data.co_2.toFixed(2);
    this.timestamp = data.timestamp.slice(0, 10) + '   ' + data.timestamp.slice(13, 20);
  }

  levelCards(data) {
    
    this.level.dropdown = 'd-none';
    this.level.single = 'd-block';
    this.level.combine = 'd-none';
    this.level_unit = data.device_unit;
   
    this.dataShared.tank_capacity = data.tank_capacity;
    this.dataShared.volume_filled = data.volume.toFixed(2);

    // key 
    this.cards[0].key = 'Total Tank Capacity ' + '(' + data.device_unit + ')';
    this.cards[1].key = 'Available Volume ' + '(' + data.device_unit + ')';
    this.cards[3].key = 'Today Refill ' + '(' + data.device_unit + ')';
    this.cards[4].key = 'Today Consumption ' + '(' + data.device_unit + ')';

    // value
    this.cards[0].value = data.tank_capacity;
    this.cards[1].value = data.volume.toFixed(2);
    this.cards[2].value = (parseFloat(data.volume) / parseFloat(data.tank_capacity)) * 100;
    this.cards[3].value = data.refelling.daily.toFixed(2);
    this.cards[4].value = data.consumption.daily.toFixed(2);
    this.timestamp = data.timestamp.slice(0, 10) + '   ' + data.timestamp.slice(13, 20);
  }

  LevelAnalyticsCards(data) {

    this.level.dropdown = 'd-flex';
    this.level_unit = data.device_unit;

    // key 
    this.cards[0].key = 'Available Volume ' + '(' + data.device_unit + ')';

    if (this.level_analytics == 'Refelling') this.refellingLevelAnalyticsCards(data);
    else if (this.level_analytics == 'Consumption') this.consumptionlevelAnalyticsCards(data);
    else if (this.level_analytics == 'Combine') this.combinelevelAnalyticsCards(data);

  }

  refellingLevelAnalyticsCards(data) {

    this.level.single = 'd-block';
    this.level.combine = 'd-none';

    this.cards[0].value = data.volume.toFixed(2);
    this.cards[1].value = data.refelling.daily.toFixed(2);
    this.cards[2].value = data.refelling.monthly.toFixed(2);
    this.cards[3].value = data.refelling.yearly.toFixed(2);
    this.cards[4].value = data.refelling.counter.toFixed(2);

    this.timestamp = data.timestamp.slice(0, 10) + '   ' + data.timestamp.slice(13, 20);
  }

  consumptionlevelAnalyticsCards(data) {

    this.level.single = 'd-block';
    this.level.combine = 'd-none';

    this.cards[0].value = data.volume.toFixed(2);
    this.cards[1].value = data.consumption.daily.toFixed(2);
    this.cards[2].value = data.consumption.monthly.toFixed(2);
    this.cards[3].value = data.consumption.yearly.toFixed(2);
    this.cards[4].value = data.consumption.counter.toFixed(2);

    this.timestamp = data.timestamp.slice(0, 10) + '   ' + data.timestamp.slice(13, 20);
  }

  combinelevelAnalyticsCards(data) {

    this.level.single = 'd-none';
    this.level.combine = 'd-flex';

    this.cards[0].value = data.volume.toFixed(2);

    // refelling
    this.cards[1].combine.refelling = data.refelling.daily.toFixed(2);
    this.cards[2].combine.refelling = data.refelling.monthly.toFixed(2);
    this.cards[3].combine.refelling = data.refelling.yearly.toFixed(2);
    this.cards[4].combine.refelling = data.refelling.counter.toFixed(2);

    // consumption
    this.cards[1].combine.consumption = data.consumption.daily.toFixed(2);
    this.cards[2].combine.consumption = data.consumption.monthly.toFixed(2);
    this.cards[3].combine.consumption = data.consumption.yearly.toFixed(2);
    this.cards[4].combine.consumption = data.consumption.counter.toFixed(2);

    this.timestamp = data.timestamp.slice(0, 10) + '   ' + data.timestamp.slice(13, 20);
  }

  resetDeviceKpi() {
    this.cards[0].value = this.cards[1].value = this.cards[2].value = this.cards[3].value = this.cards[4].value = 0.00;
  }

  ngOnDestroy() {
    if (this.card_interval) {
      clearInterval(this.card_interval);
    }
  }
}
