import { Component, Input, OnInit } from '@angular/core';
import { LoginService } from '../../shared/services/login.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  
  emailId: any;
  password: any;
  showError = false;
  submitted = false;
  emailPrompt: boolean;
  passwordPrompt: boolean;
  forgotPassword = false
  emailRememberPrompt = false
  reqiureRegister = false
  public eye: any = 'feather-eye-off'

  constructor(private authService: LoginService) { }

  ngOnInit(): void {
    this.loginView()
  }

  loginView() {

    const body = document.querySelector("body");
    const modal = document.querySelector(".modal");
    const modalButton = document.querySelector(".modal-button");
    const closeButton = document.querySelector(".close-button");
    const scrollDown = document.querySelector(".scroll-down");
    let isOpened = false;

    const openModal = () => {
      modal.classList.add("is-open");
      body.style.overflow = "hidden";
    };

    const closeModal = () => {
      modal.classList.remove("is-open");
      body.style.overflow = "initial";
    };

    window.addEventListener("scroll", () => {
      if (window.scrollY > window.innerHeight / 3 && !isOpened) {
        isOpened = true;
        // scrollDown.style.display = "none";
        openModal();
      }
    });

    modalButton.addEventListener("click", openModal);
    closeButton.addEventListener("click", closeModal);

    document.onkeydown = evt => {
      // evt = evt || window.event;
      evt.keyCode === 27 ? closeModal() : false;
    };
  }

  validate() {
    if (!this.emailId) {
      this.emailPrompt = true
    }
    else {
      this.emailPrompt = false
    }
    if (!this.password) {
      this.passwordPrompt = true
    }
    else {
      this.passwordPrompt = false
    }
    return !this.passwordPrompt && !this.emailPrompt
  }

  login() {
    this.showError = false
    if (this.validate()) {

      let body = {
        email: this.emailId,
        password: this.password
      }

      this.authService.login(body).subscribe((res: any) => {
        /* if(res.data.user) {
          this.authService.getUserAccount();
        } */
      },
        error => {
          this.showError = true
        })
    }
  }

  Input() {
    const pass_input = document.getElementById('password') as HTMLInputElement;
    if (pass_input.type == 'password') {
      pass_input.type = 'text';
      this.eye = 'feather-eye';
    } else {
      pass_input.type = 'password';
      this.eye = 'feather-eye-off';
    }
  }
}
