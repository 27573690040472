import { ProfileComponent } from './profile/profile.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { MasterComponent } from './layouts/master/master.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { PvNetMeteringComponent } from './pv-net-metering/pv-net-metering.component';
import { PvStringMonitoringComponent } from './pv-string-monitoring/pv-string-monitoring.component';
import { ChartsComponent } from './charts/charts.component';
import { StatusComponent } from './status/status.component';
import { AlarmLogsComponent } from './alarm-logs/alarm-logs.component';
import { SettingsComponent } from './settings/settings.component';
import { FavoriteComponent } from './favorite/favorite.component';
import { BillingSummaryComponent } from './billing-summary/billing-summary.component';
import { ButtonManagementComponent } from './button-management/button-management.component';
import { PowerMonitoringComponent } from './power-monitoring/power-monitoring.component';
import { PvGenrationReportComponent } from './pv-genration-report/pv-genration-report.component';

export const routes: Routes = [
  {
    path: '',
    // component: FullComponent,
    component: MasterComponent,
    children: [
      { path: '', redirectTo: 'dashboard/single', pathMatch: 'full' },
      { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(mod => mod.DashboardModule) },
      { path: 'analysis', loadChildren: () => import('./analytics/analytics.module').then(mod => mod.AnalyticsModule) },
      { path: 'analysis/energy', loadChildren: () => import('./analytics/energy-consumption/energy-consumption.module').then(mod => mod.EnergyConsumptionModule) },
      { path: 'customer-management', loadChildren: () => import('./customer-management/customer-management.module').then(mod => mod.CustomerManagementModule) },
      { path: 'pv-net-metering', component: PvNetMeteringComponent },
      { path: 'pv-generation-report', component: PvGenrationReportComponent },
      { path: 'pv-string-monitoring', component: PvStringMonitoringComponent },
      { path: 'button-management', component: ButtonManagementComponent },
      { path: 'charts', component: ChartsComponent },
      { path: 'status', component: StatusComponent },
      { path: 'alarm-logs', component: AlarmLogsComponent },
      { path: 'favorite', component: FavoriteComponent },
      { path: 'power-monitoring', component: PowerMonitoringComponent },
      { path: 'billing-summary', component: BillingSummaryComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'profile', component: ProfileComponent },
    ]
    , canActivate: [AuthGuard]
  },

  { path: '', component: BlankComponent, children: [{ path: 'login', loadChildren: './auth/auth.module#AuthModule' }]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }