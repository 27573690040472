<ngb-tabset #t="ngbTabset" id="pv-net-metering" (tabChange)="tabChange($event)" #tabset>

    <ngb-tab *ngIf="tab_graph_status" id="tab-graph" title="Graph">
        <ng-template ngbTabContent>

            <zainergy-solar-cards for="net-monitoring"></zainergy-solar-cards>

            <div class="row">

                <div class="{{ power_class }}" id="power_chart" *ngIf="power_status">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="card-title mb-0 ">{{ power_header }}</h6>

                            <div class="header-right d-flex align-items-center">

                                <button class="btn pr-2" type="button" data-toggle="collapse"
                                    data-target="#collapsePowerTrend" aria-expanded="false"
                                    aria-controls="collapsePowerTrend">
                                    <i class="feather-sliders"
                                        style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                                </button>

                                <a href="javascript:void(0);" (click)="tabBarChart('power_trend')" class="graph">
                                    <span [ngClass]="{'active': pv_power_chart_active == 'bar'}"><i
                                            class="icon-sm card-header-icon mr-2 pb-3px feather feather-bar-chart-2"></i></span>
                                </a>

                                <span class="divider"></span>

                                <a href="javascript:void(0);" (click)="tabLineChart('power_trend')" class="graph">
                                    <span [ngClass]="{'active': pv_power_chart_active == 'line'}"><i
                                            class="icon-sm card-header-icon ml-2 pb-3px feather feather-trending-up"></i></span>
                                </a>

                                <i class="icon-sm card-header-icon ml-2 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Print"
                                            (click)="data_param = 'power_trend'; data_param_el = 'power_chart'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-printer"></i>
                                            <span class="">Print</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'power_trend'; exportAsCSV()">
                                            <i class="icon-sm mr-2 feather feather-download"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf"
                                            (click)="data_param = 'power_trend'; data_param_el = 'power_chart'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-file-text"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="collapse px-2" id="collapsePowerTrend">
                            <div class="card-body overflow-auto">
                                <div class="w-max-content header-right-inner-left d-flex">
                                    <h6 class="text-nowrap mr-2">
                                        Date select:
                                    </h6>
                                    <input type="date" [(ngModel)]="pv_power_date" autocomplete="off"
                                        class="form-control" (change)="onDateSelection($event, 'power_trend')">
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row align-items-start mb-2">

                            </div>
                            <div class="flot-wrapper">
                                <div id="chartPvPower" style="width: 100%; height: 450px"></div>
                            </div>
                            <div class="last-update" style="text-align: right;">
                                <span *ngIf="last_update_chart_data != 'not available'"
                                    style="color: darkgray; margin-right: 10px;">
                                    Last Update: {{ last_update_chart_data | date:'medium' }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="{{ state_class }}" id="pv_current_state" *ngIf="state_status">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="card-title mb-0 ">{{ state_header }}</h6>
                            <div class="header-right d-flex align-items-center">

                                <i class="icon-sm card-header-icon ml-4 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                            </div>

                        </div>

                        <div class="card-body">

                            <div *ngIf="show_gs_power" class="current-state">

                                <svg class="pvLayer" version="1.1" id="show-power" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 487.3 487.3"
                                    style="enable-background:new 0 0 487.3 487.3;" xml:space="preserve">

                                    <rect x="238.2" y="126.2" class="g-current-state-01" width="10.5" height="48.5" />
                                    <rect x="238.2" y="311" class="g-current-state-01" width="10.5" height="48.5" />
                                    <rect x="311.3" y="238.4" class="g-current-state-01" width="48.5" height="10.5" />
                                    <rect x="127.1" y="238.4" class="g-current-state-01" width="48.5" height="10.5" />
                                    <path *ngIf="show_pv_power" d="M2.3,23.9L36.9,3.9"
                                        class="g-animate-line g-top-line"></path>
                                    <path *ngIf="show_ke_power" d="M2.3,23.9L36.9,3.9"
                                        class="g-animate-line g-left-line"></path>
                                    <path *ngIf="show_ke_power_feed_out" d="M2.3,23.9L36.9,3.9"
                                        class="g-animate-line g-right-line"></path>
                                    <path *ngIf="show_gs_power_line" d="M2.3,23.9L36.9,3.9"
                                        class="g-animate-line g-bottom-line"></path>
                                    <g>
                                        <polygon class="g-current-state-02"
                                            points="423,238.7 423,245.5 426.3,242.2 	" />
                                        <polygon class="g-current-state-02"
                                            points="431,237.5 424.2,237.5 427.6,240.8 	" />
                                        <polygon class="g-current-state-02"
                                            points="424.2,246.8 431,246.8 427.6,243.5 	" />
                                        <polygon class="g-current-state-02" points="421.1,223 421.1,215 413,215 	" />
                                        <polygon class="g-current-state-02" points="431,215 424.2,215 427.6,218.3 	" />
                                        <polygon class="g-current-state-02"
                                            points="432.3,245.5 432.3,238.7 429,242.2 	" />
                                        <polygon class="g-current-state-02"
                                            points="430.5,248.7 424.8,248.7 427.6,250.9 	" />
                                        <polygon class="g-current-state-02" points="436.4,259.9 427.6,253.3 418.9,259.9 418.3,259.9 418.3,259.9 420.1,259.9 420.1,259.9 437,259.9 
                                        437,259.9 	" />
                                        <polygon class="g-current-state-02"
                                            points="426.1,252.1 422.4,249.3 422.4,249.4 419.4,257.2 	" />
                                        <polygon class="g-current-state-02" points="423,216.3 423,223 426.3,219.6 	" />
                                        <polygon class="g-current-state-02"
                                            points="435.8,257.2 432.9,249.4 432.8,249.3 429.2,252.1 	" />
                                        <polygon class="g-current-state-02"
                                            points="424.2,235.6 431,235.6 427.6,232.2 	" />
                                        <polygon class="g-current-state-02"
                                            points="423,227.5 423,234.3 426.3,230.9 	" />
                                        <polygon class="g-current-state-02" points="434.2,223 442.2,215 434.2,215 	" />
                                        <polygon class="g-current-state-02"
                                            points="424.2,224.4 431,224.4 427.6,221 	" />
                                        <polygon class="g-current-state-02"
                                            points="432.3,234.3 432.3,227.5 429,230.9 	" />
                                        <polygon class="g-current-state-02"
                                            points="427.6,207.3 423.7,213.1 431.5,213.1 	" />
                                        <polygon class="g-current-state-02"
                                            points="431,226.2 424.2,226.2 427.6,229.6 	" />
                                        <polygon class="g-current-state-02"
                                            points="432.3,223 432.3,216.3 429,219.6 	" />
                                        <polygon class="g-current-state-03"
                                            points="435.9,257.2 432.9,249.4 435.8,257.2 	" />
                                        <polygon class="g-current-state-03" points="437,259.9 437,259.9 437,259.9 	" />
                                        <polygon class="g-current-state-03"
                                            points="434.2,247.8 434.2,247.6 434.2,247.6 	" />
                                        <polygon class="g-current-state-03"
                                            points="418.3,259.9 418.3,259.9 418.3,259.9 	" />
                                        <polygon class="g-current-state-03"
                                            points="422.4,249.4 419.4,257.2 419.4,257.2 	" />
                                        <polygon class="g-current-state-04"
                                            points="431,237.5 427.6,240.8 424.2,237.5 423,237.5 423,238.7 426.3,242.2 423,245.5 423,246.8 424.2,246.8 
                                        427.6,243.5 431,246.8 432.3,246.8 432.3,245.5 429,242.2 432.3,238.7 432.3,237.5 	" />
                                        <polygon class="g-current-state-04"
                                            points="431,226.2 427.6,229.6 424.2,226.2 423,226.2 423,227.5 426.3,230.9 423,234.3 423,235.6 424.2,235.6 
                                        427.6,232.2 431,235.6 432.3,235.6 432.3,234.3 429,230.9 432.3,227.5 432.3,226.2 	" />
                                        <polygon class="g-current-state-04" points="418.9,259.9 427.6,253.3 436.4,259.9 437,259.9 437,259.9 435.9,257.2 435.8,257.2 429.2,252.1 
                                        432.8,249.3 432.6,248.7 430.5,248.7 427.6,250.9 424.8,248.7 422.6,248.7 422.4,249.3 426.1,252.1 419.4,257.2 419.4,257.2 
                                        418.3,259.9 418.3,259.9 	" />
                                        <polygon class="g-current-state-04" points="431,215 427.6,218.3 424.2,215 423,215 423,216.3 426.3,219.6 423,223 423,224.4 424.2,224.4 
                                        427.6,221 431,224.4 432.3,224.4 432.3,223 429,219.6 432.3,216.3 432.3,215 	" />
                                        <path class="g-current-state-05"
                                            d="M434.2,225.6l10.9-10.9c0.3-0.3,0.4-0.7,0.2-1c-0.2-0.4-0.5-0.6-0.9-0.6h-10.7l-5.3-8c-0.4-0.5-1.2-0.5-1.5,0
                                        l-5.3,8h-10.8c-0.4,0-0.8,0.2-0.9,0.6c-0.2,0.4-0.1,0.8,0.2,1l10.9,10.9v22l-4.8,12.4h2l0,0l1-2.8l3-7.9v-0.1l0.2-0.6h2.1h5.7h2.1
                                        l0.2,0.6v0.1l3,7.9l1,2.8l0,0h2l-4.7-12.2l-0.1-0.2l0,0v-22H434.2z M421.1,223L413,215h8.1V223z M432.3,245.5v1.3H431h-6.8h-1.3
                                        v-1.3v-6.8v-1.2h1.3h6.8h1.3v1.3V245.5z M432.3,223v1.3H431h-6.8h-1.3V223v-6.8V215h1.3h6.8h1.3v1.3V223z M432.3,234.3v1.3H431
                                        h-6.8h-1.3v-1.3v-6.8v-1.3h1.3h6.8h1.3v1.3V234.3z M434.2,223V215h8.1L434.2,223z M427.6,207.3l3.9,5.8h-7.7L427.6,207.3z" />
                                        <path class="g-current-state-06"
                                            d="M414.5,260.9c0-0.3,0.1-0.5,0.3-0.7C414.6,260.4,414.5,260.6,414.5,260.9v1.9l0,0V260.9z" />
                                        <path class="g-current-state-06"
                                            d="M442.7,259.9h-0.9h-2l0,0H437h-0.9H434l0,0h-11l0,0h-1.8l0,0h-2h-0.9l0,0h-1l0,0h-1.8l0,0h-2h-0.9
                                        c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7v1.9h4.7h1h4.7h15.8h5.8v-1.9C443.6,260.3,443.2,259.9,442.7,259.9z" />
                                    </g>
                                    <g>
                                        <polygon class="g-current-state-02"
                                            points="54.4,238.7 54.4,245.5 57.7,242.2 	" />
                                        <polygon class="g-current-state-02"
                                            points="62.4,237.5 55.7,237.5 59.1,240.8 	" />
                                        <polygon class="g-current-state-02"
                                            points="55.7,246.8 62.4,246.8 59.1,243.5 	" />
                                        <polygon class="g-current-state-02" points="52.5,223 52.5,215 44.4,215 	" />
                                        <polygon class="g-current-state-02" points="62.4,215 55.7,215 59.1,218.3 	" />
                                        <polygon class="g-current-state-02"
                                            points="63.7,245.5 63.7,238.7 60.4,242.2 	" />
                                        <polygon class="g-current-state-02"
                                            points="61.9,248.7 56.2,248.7 59.1,250.9 	" />
                                        <polygon class="g-current-state-02" points="67.8,259.9 59.1,253.3 50.3,259.9 49.8,259.9 49.8,259.9 51.6,259.9 51.6,259.9 68.4,259.9 
                                        68.4,259.9 	" />
                                        <polygon class="g-current-state-02"
                                            points="57.6,252.1 53.8,249.3 53.8,249.4 50.8,257.2 	" />
                                        <polygon class="g-current-state-02" points="54.4,216.3 54.4,223 57.7,219.6 	" />
                                        <polygon class="g-current-state-02"
                                            points="67.3,257.2 64.3,249.4 64.2,249.3 60.6,252.1 	" />
                                        <polygon class="g-current-state-02"
                                            points="55.7,235.6 62.4,235.6 59.1,232.2 	" />
                                        <polygon class="g-current-state-02"
                                            points="54.4,227.5 54.4,234.3 57.7,230.9 	" />
                                        <polygon class="g-current-state-02" points="65.6,223 73.6,215 65.6,215 	" />
                                        <polygon class="g-current-state-02" points="55.7,224.4 62.4,224.4 59.1,221 	" />
                                        <polygon class="g-current-state-02"
                                            points="63.7,234.3 63.7,227.5 60.4,230.9 	" />
                                        <polygon class="g-current-state-02"
                                            points="59.1,207.3 55.2,213.1 62.9,213.1 	" />
                                        <polygon class="g-current-state-02"
                                            points="62.4,226.2 55.7,226.2 59.1,229.6 	" />
                                        <polygon class="g-current-state-02" points="63.7,223 63.7,216.3 60.4,219.6 	" />
                                        <polygon class="g-current-state-03"
                                            points="67.3,257.2 64.3,249.4 67.3,257.2 	" />
                                        <polygon class="g-current-state-03"
                                            points="68.4,259.9 68.4,259.9 68.4,259.9 	" />
                                        <polygon class="g-current-state-03"
                                            points="65.6,247.8 65.6,247.6 65.6,247.6 	" />
                                        <polygon class="g-current-state-03"
                                            points="49.8,259.9 49.8,259.9 49.8,259.9 	" />
                                        <polygon class="g-current-state-03"
                                            points="53.8,249.4 50.8,257.2 50.8,257.2 	" />
                                        <polygon class="g-current-state-04"
                                            points="62.4,237.5 59.1,240.8 55.7,237.5 54.4,237.5 54.4,238.7 57.7,242.2 54.4,245.5 54.4,246.8 
                                        55.7,246.8 59.1,243.5 62.4,246.8 63.7,246.8 63.7,245.5 60.4,242.2 63.7,238.7 63.7,237.5 	" />
                                        <polygon class="g-current-state-04"
                                            points="62.4,226.2 59.1,229.6 55.7,226.2 54.4,226.2 54.4,227.5 57.7,230.9 54.4,234.3 54.4,235.6 
                                        55.7,235.6 59.1,232.2 62.4,235.6 63.7,235.6 63.7,234.3 60.4,230.9 63.7,227.5 63.7,226.2 	" />
                                        <polygon class="g-current-state-04" points="50.3,259.9 59.1,253.3 67.8,259.9 68.4,259.9 68.4,259.9 67.3,257.2 67.3,257.2 60.6,252.1 
                                        64.2,249.3 64,248.7 61.9,248.7 59.1,250.9 56.2,248.7 54,248.7 53.8,249.3 57.6,252.1 50.8,257.2 50.8,257.2 49.8,259.9 
                                        49.8,259.9 	" />
                                        <polygon class="g-current-state-04" points="62.4,215 59.1,218.3 55.7,215 54.4,215 54.4,216.3 57.7,219.6 54.4,223 54.4,224.4 55.7,224.4 
                                        59.1,221 62.4,224.4 63.7,224.4 63.7,223 60.4,219.6 63.7,216.3 63.7,215 	" />
                                        <path class="g-current-state-05"
                                            d="M65.6,225.6l10.9-10.9c0.3-0.3,0.4-0.7,0.2-1c-0.2-0.4-0.5-0.6-0.9-0.6H65.2l-5.3-8c-0.4-0.5-1.2-0.5-1.5,0
                                        l-5.3,8H42.2c-0.4,0-0.8,0.2-0.9,0.6c-0.2,0.4-0.1,0.8,0.2,1l10.9,10.9v22l-4.8,12.4h2l0,0l1-2.8l3-7.9v-0.1l0.2-0.6H56h5.7h2.1
                                        l0.2,0.6v0.1l3,7.9l1,2.8l0,0h2l-4.7-12.2l-0.1-0.2l0,0v-22H65.6z M52.5,223l-8.1-8.1h8.1V223z M63.7,245.5v1.3h-1.3h-6.8h-1.3
                                        v-1.3v-6.8v-1.2h1.3h6.8h1.3v1.3V245.5z M63.7,223v1.3h-1.3h-6.8h-1.3V223v-6.8V215h1.3h6.8h1.3v1.3V223z M63.7,234.3v1.3h-1.3
                                        h-6.8h-1.3v-1.3v-6.8v-1.3h1.3h6.8h1.3v1.3V234.3z M65.6,223V215h8.1L65.6,223z M59.1,207.3l3.9,5.8h-7.7L59.1,207.3z" />
                                        <path class="g-current-state-06"
                                            d="M46,260.9c0-0.3,0.1-0.5,0.3-0.7C46,260.4,46,260.6,46,260.9v1.9l0,0V260.9z" />
                                        <path class="current-state-06"
                                            d="M74.1,259.9h-0.9h-2l0,0h-2.9h-0.9h-2.1l0,0h-11l0,0h-1.8l0,0h-2h-0.9l0,0h-1l0,0h-1.8l0,0h-2h-0.9
                                        c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7v1.9h4.7h1h4.7h15.8h5.8v-1.9C75.1,260.3,74.7,259.9,74.1,259.9z" />
                                    </g>
                                    <g>
                                        <polygon class="g-current-state-02"
                                            points="229.6,58.5 229.6,58.5 229.6,58.5 242.4,58.5 242.4,58.5 	" />
                                        <polygon class="g-current-state-02"
                                            points="257,58.5 248.6,58.5 244.3,58.5 244.3,58.5 248.6,58.5 257,58.5 	" />
                                        <path class="current-state-07"
                                            d="M240.2,25.1c0.1,0,0.2-0.1,0.3-0.1C240.4,25.1,240.3,25.1,240.2,25.1z" />
                                        <path class="g-current-state-07"
                                            d="M240.9,24.9c0.1,0,0.2-0.1,0.3-0.1C241,24.8,240.9,24.8,240.9,24.9z" />
                                        <path class="g-current-state-07"
                                            d="M241.6,24.7c0.1,0,0.2,0,0.3-0.1C241.7,24.6,241.6,24.6,241.6,24.7z" />
                                        <path class="g-current-state-07"
                                            d="M242.4,24.5c0.1,0,0.2,0,0.3,0C242.5,24.5,242.4,24.5,242.4,24.5z" />
                                        <path class="g-current-state-07"
                                            d="M239.5,25.5c0.1,0,0.2-0.1,0.3-0.1C239.7,25.4,239.6,25.4,239.5,25.5z" />
                                        <path class="g-current-state-07" d="M244.3,37.1h1c0.4-0.9,0.7-2,0.7-3.1c0-4.2-3.3-7.7-7.5-7.8c-1.7,1.4-2.8,3.6-2.8,6c0,1.8,0.7,3.5,1.7,4.8h5.1
                                        L244.3,37.1L244.3,37.1z" />
                                        <path class="g-current-state-07"
                                            d="M249.4,37.1c0.6-0.7,0.9-1.4,1.2-2.3C250.3,35.7,249.9,36.5,249.4,37.1L249.4,37.1z" />
                                        <path class="g-current-state-07"
                                            d="M239,25.9c0.1,0,0.1-0.1,0.2-0.1C239.1,25.8,239,25.8,239,25.9z" />
                                        <path class="g-current-state-07" d="M238.4,26.2L238.4,26.2L238.4,26.2z" />
                                        <path class="g-current-state-08"
                                            d="M255.9,37.1h11c1,0,1.8,0.8,2,1.7l0.9,4.5l0,0l-0.9-4.5c-0.2-0.9-0.9-1.6-2-1.7h-12.5H255.9L255.9,37.1z" />
                                        <polygon class="g-current-state-08"
                                            points="242.4,45.2 231.4,45.2 230.7,50.5 242.4,50.5 	" />
                                        <polygon class="g-current-state-08"
                                            points="229.6,45.2 216.5,45.2 215.6,50.5 228.8,50.5 	" />
                                        <polygon class="g-current-state-08"
                                            points="215.3,52.3 214.1,58.5 227.7,58.5 228.5,52.3 	" />
                                        <polygon class="g-current-state-08"
                                            points="242.4,52.3 230.4,52.3 229.6,58.5 242.4,58.5 	" />
                                        <path class="g-current-state-08" d="M230.7,37.1h1.9l-0.9,6.3h10.6v-6.3h1.8v6.3h6.9c-0.6-2.3-1.5-4.4-2.8-6.3h-3l0,0h-1h-1.8h-5.1h-4.7H230.7h-11
                                        c-1,0-1.8,0.8-2,1.7l-0.9,4.5h13L230.7,37.1z" />
                                        <path class="g-current-state-08"
                                            d="M251.3,52.3h-7v6.3h4.5C249.9,56.6,250.8,54.6,251.3,52.3z" />
                                        <path class="g-current-state-08"
                                            d="M251.7,48c0-0.9-0.1-2-0.2-2.8l0,0h-7.3v5.3h7.4C251.7,49.7,251.7,48.9,251.7,48z" />
                                        <polygon class="g-current-state-08"
                                            points="249.4,37.1 249.4,37.1 254.1,37.1 254.1,37.1 254.1,37.1 249.4,37.1 	" />
                                        <path class="g-current-state-07" d="M228.3,33.2h3.3c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-3.3c-0.5,0-0.9,0.4-0.9,0.9
                                        S227.8,33.2,228.3,33.2z" />
                                        <path class="g-current-state-07" d="M254.2,32.2c0,0.5,0.4,0.9,0.9,0.9h3.3c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-3.3
                                        C254.6,31.4,254.2,31.7,254.2,32.2z" />
                                        <path class="g-current-state-07" d="M251.7,24.8L251.7,24.8c0.2,0,0.5-0.1,0.7-0.3l2.4-2.4c0.4-0.4,0.4-0.9,0-1.3s-0.9-0.4-1.3,0l-2.4,2.4
                                        c-0.4,0.4-0.4,0.9,0,1.3C251.2,24.7,251.4,24.8,251.7,24.8z" />
                                        <path class="g-current-state-07" d="M234.3,24.5c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3c0.4-0.4,0.4-0.9,0-1.3l-2.4-2.4
                                        c-0.4-0.4-0.9-0.4-1.3,0s-0.4,0.9,0,1.3L234.3,24.5z" />
                                        <path class="g-current-state-07" d="M243.3,21.4c0.5,0,0.9-0.4,0.9-0.9v-3.3c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9v3.3
                                        C242.4,21,242.8,21.4,243.3,21.4z" />
                                        <polygon class="g-current-state-09"
                                            points="240.2,63.6 240.2,68.7 243.5,68.7 243.5,63.6 243.8,63.6 246.4,63.6 246.3,63.6 240.3,63.6 	" />
                                        <polygon class="g-current-state-10"
                                            points="271.4,52.3 258,52.3 258.9,58.5 272.5,58.5 	" />
                                        <path class="g-current-state-10"
                                            d="M266.9,37.1h-11l0.9,6.3h13l-0.9-4.5C268.7,37.9,267.9,37.1,266.9,37.1z" />
                                        <path class="g-current-state-10"
                                            d="M257,58.5l-0.9-6.3h-4.8c-0.5,2.3-1.4,4.4-2.7,6.3H257z" />
                                        <path class="g-current-state-10"
                                            d="M249.4,37.1L249.4,37.1L249.4,37.1h-1l0,0c1.3,1.9,2.3,4,2.8,6.3h3.8l-0.9-6.3l0,0H249.4z" />
                                        <path class="g-current-state-10"
                                            d="M251.7,48c0,0.9-0.1,1.6-0.2,2.5h4.3l-0.8-5.3h-3.6l0,0C251.7,46.1,251.7,47.1,251.7,48z" />
                                        <polygon class="g-current-state-10"
                                            points="270.1,45.2 257.1,45.2 257.7,50.5 271.1,50.5 	" />
                                        <path class="g-current-state-11" d="M229.6,58.5L229.6,58.5l0.9-6.3h11.9v6.3l0,0h0.9h0.9l0,0v-6.3h7h4.8l0.9,6.3l0,0l0,0h1.9l0,0l-0.9-6.3h13.4
                                        l0,0l-0.3-1.8l0,0h-13.3l-0.8-5.3h13.1l0,0l-0.3-1.8l0,0h-13l-0.9-6.3l0,0h-1.5h-0.4l0,0l0,0l0.9,6.3h-3.8h-6.9v-6.3h-1.9v6.3
                                        h-10.7l0.9-6.3h-1.9l-0.9,6.3h-13l0,0l-0.3,1.8l0,0h13.1l-0.8,5.3h-13.3l-0.3,1.8h13.4l-0.9,6.3l0,0H229.6L229.6,58.5z M244.3,45.2
                                        h7.3h3.6l0.8,5.3h-4.3h-7.4V45.2z M231.4,45.2h11v5.3h-11.7L231.4,45.2z" />
                                        <path class="g-current-state-12" d="M272.9,60.9l-0.4-2.4h-13.6H257h-8.3h-4.4h-0.9h-0.9h-12.8h-1.9h-13.5l-0.4,2.4c-0.3,1.5,0.9,2.7,2.4,2.7
                                        h24.2h6.2h24.2C272,63.6,273.2,62.4,272.9,60.9z" />
                                        <polygon class="g-current-state-13"
                                            points="243.5,63.6 243.5,68.7 246.2,68.7 246.4,68.7 246.4,63.6 243.8,63.6 	" />
                                        <path class="g-current-state-14" d="M245.9,34c0,1.1-0.2,2.2-0.7,3.1l0,0h3l0,0h1l0,0l0,0c0.6-0.7,0.9-1.4,1.2-2.3c0.3-0.9,0.5-1.7,0.5-2.7
                                        c0-4.3-3.5-7.8-7.8-7.8c-0.3,0-0.5,0-0.8,0c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.5,0.1c-0.1,0-0.2,0-0.3,0.1c-0.2,0-0.3,0.1-0.5,0.1
                                        c-0.1,0-0.2,0.1-0.3,0.1s-0.3,0.1-0.4,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0-0.2,0.1-0.3,0.1
                                        c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.4,0.3-0.5,0.4c0,0,0,0-0.1,0l0,0l0,0C242.6,26.4,245.9,29.8,245.9,34
                                        z" />
                                        <path class="g-current-state-12" d="M243.5,68.7h-3.3l0,0h-2.7c-1.2,0-2.2,0.9-2.2,2.2v2.4l0,0h15.5h0.4l0,0v-2.4c0-1.2-0.9-2.2-2.2-2.2h-2.7
                                        h-0.3H243.5z" />
                                    </g>
                                    <g>
                                        <g>
                                            <path class="g-current-state-15" d="M261.5,255.6c0-1.8,1.5-3.3,3.3-3.3c0.5,0,1.1,0.1,1.5,0.4c0.2-0.4,0.4-0.7,0.7-1.1v-23.8h-46.7v23.8
                                            c0.3,0.3,0.5,0.7,0.7,1.1c0.5-0.2,1-0.4,1.5-0.4c1.8,0,3.3,1.5,3.3,3.3s-1.5,3.3-3.3,3.3h10l2.2-4.5h3.3v-15.6
                                            c0-3.1,2.5-5.6,5.6-5.6s5.6,2.5,5.6,5.6v15.6h3.3l2.2,4.5h10C263,258.9,261.5,257.4,261.5,255.6z M228.1,228.9h2.2v2.2h-2.2V228.9
                                            z M232.5,240v2.2v3.3h-6.7v-3.3V240v-5.6h6.7V240z M257,228.9h2.2v2.2H257V228.9z M254.8,242.2V240v-5.6h6.7v5.6v2.2v3.3h-6.7
                                            V242.2z" />
                                            <rect x="220.3" y="225.5" class="g-current-state-16" width="46.8"
                                                height="2.2" />
                                            <polygon class="g-current-state-17"
                                                points="272.6,225.5 268.2,211.1 219.2,211.1 214.7,225.5 220.3,225.5 267,225.5 		" />
                                            <polygon class="g-current-state-16"
                                                points="249.2,254.5 238.1,254.5 234.8,254.5 232.5,258.9 254.8,258.9 252.6,254.5 		" />
                                            <path class="g-current-state-17" d="M249.2,238.9c0-3.1-2.5-5.6-5.6-5.6s-5.6,2.5-5.6,5.6v15.6h11.1v-15.6H249.2z M243.7,244.5h-2.2v-2.2h2.2
                                            V244.5z" />
                                            <rect x="225.8" y="242.2" class="g-current-state-17" width="6.7"
                                                height="3.3" />
                                            <rect x="225.8" y="234.4" class="g-current-state-17" width="6.7"
                                                height="5.6" />
                                            <rect x="225.8" y="240" class="g-current-state-18" width="6.7"
                                                height="2.2" />
                                            <rect x="228.1" y="228.9" class="g-current-state-19" width="2.2"
                                                height="2.2" />
                                            <path class="g-current-state-20"
                                                d="M225.8,255.6c0-1.8-1.5-3.3-3.3-3.3c-0.5,0-1.1,0.1-1.5,0.4c-0.2-0.4-0.4-0.7-0.7-1.1
                                            c-1.3-1.4-3.4-2-5.2-1.1c-2.2,1-3.2,3.7-2.2,5.9c0.7,1.6,2.3,2.6,4,2.6h3.3h2.2C224.4,258.9,225.8,257.4,225.8,255.6z" />
                                            <path class="g-current-state-20"
                                                d="M272.2,250.4c-1.8-0.8-3.9-0.3-5.2,1.1c-0.3,0.3-0.5,0.7-0.7,1.1c-0.5-0.2-1-0.4-1.5-0.4
                                            c-1.8,0-3.3,1.5-3.3,3.3s1.5,3.3,3.3,3.3h2.2h3.3c1.7,0,3.3-1,4-2.6C275.5,254.1,274.5,251.5,272.2,250.4z" />
                                            <rect x="241.4" y="242.2" class="g-current-state-21" width="2.2"
                                                height="2.2" />
                                            <rect x="254.8" y="234.4" class="g-current-state-17" width="6.7"
                                                height="5.6" />
                                            <rect x="254.8" y="242.2" class="g-current-state-17" width="6.7"
                                                height="3.3" />
                                            <rect x="254.8" y="240" class="g-current-state-18" width="6.7"
                                                height="2.2" />
                                            <rect x="257" y="228.9" class="g-current-state-19" width="2.2"
                                                height="2.2" />
                                        </g>
                                        <path class="g-current-state-01" d="M292.9,214.1c-15.8-27.5-51.1-36.9-78.5-21c-27.5,15.8-36.9,51.1-21,78.5c15.8,27.5,51.1,36.9,78.5,21
                                        S308.8,241.5,292.9,214.1z M201.5,266.8c-13.2-22.9-5.4-52.3,17.6-65.6c22.9-13.2,52.3-5.4,65.6,17.6c13.2,22.9,5.4,52.3-17.6,65.6
                                        C244.2,297.6,214.8,289.7,201.5,266.8z" />
                                    </g>
                                    <path class="g-current-state-01" d="M477.4,215.2c-15.8-27.5-51.1-36.9-78.5-21c-27.5,15.8-36.9,51.1-21,78.5c15.8,27.5,51.1,36.9,78.5,21
                                    S493.3,242.6,477.4,215.2z M386,267.9c-13.2-22.9-5.4-52.3,17.6-65.6c22.9-13.2,52.3-5.4,65.6,17.6c13.2,22.9,5.4,52.3-17.6,65.6
                                    C428.7,298.7,399.3,290.8,386,267.9z" />
                                    <path class="g-current-state-01" d="M108.8,215.2c-15.8-27.5-51.1-36.9-78.5-21C2.8,210-6.6,245.3,9.3,272.7c15.8,27.5,51.1,36.9,78.5,21
                                    S124.7,242.6,108.8,215.2z M17.4,267.9C4.2,245,12,215.6,35,202.3c22.9-13.2,52.3-5.4,65.6,17.6c13.2,22.9,5.4,52.3-17.6,65.6
                                    C60.1,298.7,30.7,290.8,17.4,267.9z" />
                                    <path class="g-current-state-01" d="M293,399.6c-15.8-27.5-51.1-36.9-78.5-21c-27.5,15.8-36.9,51.1-21,78.5c15.8,27.5,51.1,36.9,78.5,21
                                    S308.9,427,293,399.6z M201.6,452.3c-13.2-22.9-5.4-52.3,17.6-65.6c22.9-13.2,52.3-5.4,65.6,17.6c13.2,22.9,5.4,52.3-17.6,65.6
                                    C244.3,483.1,214.9,475.2,201.6,452.3z" />
                                    <path class="g-current-state-01" d="M293.2,29.5C277.4,2,242.1-7.4,214.7,8.5c-27.5,15.8-36.9,51.1-21,78.5c15.8,27.5,51.1,36.9,78.5,21
                                    S309.1,56.9,293.2,29.5z M201.8,82.2c-13.2-22.9-5.4-52.3,17.6-65.6c22.9-13.2,52.3-5.4,65.6,17.6c13.2,22.9,5.4,52.3-17.6,65.6
                                    C244.5,113,215.1,105.1,201.8,82.2z" />
                                    <path class="g-current-state-01" d="M308.4,75.7c-0.1,0.3-0.2,0.6-0.3,0.9c46.7,18.2,84.1,55.4,102.3,102.1c3.5-0.9,7.2-1.6,10.8-1.9
                                    c-19.3-51-60-91.6-111-110.9C309.9,69.2,309.3,72.4,308.4,75.7z" />
                                    <path class="g-current-state-01" d="M76,178.7c18.3-46.6,55.6-83.8,102.2-102c-1-3.5-1.7-7.1-2.1-10.7c-51,19.3-91.7,59.9-111,110.9
                                    C68.9,177.1,72.5,177.7,76,178.7z" />
                                    <path class="g-current-state-01" d="M178,411.2C131.4,393,94.3,355.9,76,309.3c-3.5,0.9-7.2,1.5-10.8,1.9c19.3,51,59.9,91.5,110.9,110.8
                                    C176.4,418.4,177,414.8,178,411.2z" />
                                    <path class="g-current-state-01" d="M410.5,309.2c-18.2,46.6-55.4,83.7-102,102c0.9,3.6,1.6,7.2,1.9,10.8c51-19.3,91.5-59.9,110.8-110.9
                                    C417.6,310.8,414,310.1,410.5,309.2z" />
                                    <g>
                                        <rect x="214.3" y="398.4" class="g-current-state-22" width="58.2"
                                            height="38.8" />
                                        <polyline class="g-current-state-23"
                                            points="224,437.2 224,440.5 216.3,440.5 216.3,437.2 	" />
                                        <polyline class="g-current-state-23"
                                            points="270.6,437.2 270.6,440.5 262.8,440.5 262.8,437.2 	" />
                                        <rect x="245.3" y="402.3" class="g-current-state-24" width="23.3" height="31" />
                                        <rect x="224" y="402.3" class="g-current-state-24" width="17.5" height="31" />
                                        <polyline class="g-current-state-25"
                                            points="224,413 218.2,413 218.2,409.1 224,409.1 	" />
                                        <polyline class="g-current-state-25"
                                            points="224,425.6 218.2,425.6 218.2,421.7 224,421.7 	" />
                                        <polygon class="g-current-state-26" points="214.3,432.4 214.3,433.3 214.3,434.3 214.3,437.2 272.5,437.2 272.5,434.3 272.5,433.3 272.5,432.4 	
                                        " />
                                        <polygon class="current-state-036"
                                            points="252.1,413 255,413.9 254.1,419.8 259.9,413 257,412 261.8,406.2 256,406.2 	" />
                                        <rect x="248.3" y="424.6" class="g-current-state-28" width="17.5"
                                            height="1.9" />
                                        <rect x="248.3" y="428.5" class="g-current-state-28" width="17.5"
                                            height="1.9" />
                                        <rect x="227.9" y="406.2" class="g-current-state-25" width="9.7" height="5.8" />
                                        <rect x="226.9" y="424.6" class="g-current-state-036" width="11.6"
                                            height="1.9" />
                                        <rect x="226.9" y="420.7" class="g-current-state-036" width="11.6"
                                            height="1.9" />
                                        <rect x="226.9" y="428.5" class="g-current-state-036" width="3.9"
                                            height="1.9" />
                                        <rect x="234.7" y="428.5" class="g-current-state-036" width="3.9"
                                            height="1.9" />
                                    </g>
                                </svg>
                                <!-- g-yield -->
                                <div class="g-yield" style=" top: -50px; left: 200px;">
                                    <h6 class="g-yield-title"
                                        style="top: 29px; font-size: 13px; color: #073C5C !important;">Yield</h6>
                                    <p class="g-yield-value" *ngIf="show_pv_power">{{ current_state_pv.toFixed(2) }} Kw
                                    </p>
                                    <p class="g-yield-value" *ngIf="!show_pv_power">0Kw</p>
                                </div>
                                <!-- g-load -->
                                <div class="g-load" style=" top: 111px; left: 200px;">
                                    <p class="g-load-value">{{ current_state_load.toFixed(2) }} Kw</p>
                                </div>

                                <!-- g-feed-in -->
                                <div class="g-feed-in" style=" top: 114px; left: 50px;">
                                    <h6 class="g-feed-in-title"
                                        style="top: 140px; left: -49px; font-size: 13px; color: #073C5C;">Feed-in</h6>
                                    <p class="g-feed-in-value" *ngIf="show_ke_power">{{ current_state_ke.toFixed(2) }}
                                        Kw</p>
                                    <p class="g-feed-in-value" *ngIf="!show_ke_power">0Kw</p>
                                </div>

                                <!-- g-feed-out -->
                                <div class="g-feed-out" style=" top: 114px; right: 48px;">
                                    <h6 class="g-feed-out-title"
                                        style=" top: 140px; right: -56px; font-size: 13px; color: #073C5C;">Feed-out
                                    </h6>
                                    <p class="g-feed-out-value" *ngIf="show_ke_power_feed_out">{{
                                        current_state_ke.toFixed(2) }} Kw</p>
                                    <p class="g-feed-out-value" *ngIf="!show_ke_power_feed_out">0Kw</p>
                                </div>

                                <!-- g-genset  -->
                                <div class="g-genset" style="bottom: 33px; left: 200px;">
                                    <h6 class="g-genset-title" style="top: 39px; font-size: 13px; color: #073C5C;">
                                        Genset</h6>
                                    <p class="g-genset-value">{{ current_state_gs.toFixed(2) }} Kw</p>
                                </div>
                            </div>

                            <div *ngIf="!show_gs_power" class="current-state">

                                <svg version="1.1" id="show-power" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 460 460"
                                    style="enable-background:new 0 0 460 460;" xml:space="preserve">

                                    <!-- Yield Circle -->
                                    <path class="current-state-01" d="M230.4,141.1c31.7,0,57.5-25.8,57.5-57.5c0-31.7-25.8-57.5-57.5-57.5s-57.5,25.8-57.5,57.5
                                C172.9,115.3,198.7,141.1,230.4,141.1z M230.4,35.6c26.5,0,48,21.5,48,48c0,26.5-21.5,48-48,48s-48-21.5-48-48
                                C182.4,57.1,203.9,35.6,230.4,35.6z" />

                                    <!-- Load Circle -->
                                    <path class="current-state-01" d="M230.4,317.3c31.7,0,57.5-25.8,57.5-57.5c0-31.7-25.8-57.5-57.5-57.5s-57.5,25.8-57.5,57.5
                                C172.9,291.6,198.7,317.3,230.4,317.3z M230.4,211.8c26.5,0,48,21.5,48,48c0,26.5-21.5,48-48,48s-48-21.5-48-48
                                C182.4,233.4,203.9,211.8,230.4,211.8z" />

                                    <!-- Feed-in Circle -->
                                    <path class="current-state-01" d="M127,319c-15.8-27.5-51.1-36.9-78.5-21c-27.5,15.8-36.9,51.1-21,78.5c15.8,27.5,51.1,36.9,78.5,21
                                C133.4,381.6,142.9,346.4,127,319z M35.6,371.7c-13.2-22.9-5.4-52.3,17.6-65.6c22.9-13.2,52.3-5.4,65.6,17.6
                                c13.2,22.9,5.4,52.3-17.6,65.6C78.3,402.5,48.9,394.6,35.6,371.7z" />

                                    <!-- Yield to Feed-out Line -->
                                    <path class="current-state-01"
                                        d="M410.8,259.9c0,8.6-0.6,17-1.8,25.3c2.6,1.1,5.2,2.4,7.7,3.8c0.5,0.3,1.1,0.6,1.6,1c1.6-9.8,2.4-19.8,2.4-30.1
                                c0-80.9-50.7-150.2-121.9-177.8c0,0.5,0,1.1,0,1.6c0,3-0.2,5.9-0.6,8.8C364.2,119.4,410.8,184.3,410.8,259.9z" />

                                    <!-- Feed-out Circle -->
                                    <path class="current-state-01" d="M332.7,319.5c-15.8,27.5-6.4,62.7,21,78.5c27.5,15.8,62.7,6.4,78.5-21s6.4-62.7-21-78.5
                                C383.8,282.6,348.6,292.1,332.7,319.5z M424.1,372.3c-13.2,22.9-42.7,30.8-65.6,17.6c-22.9-13.2-30.8-42.7-17.6-65.6
                                c13.2-22.9,42.7-30.8,65.6-17.6C429.4,319.9,437.3,349.4,424.1,372.3z" />

                                    <!-- Yield to Feed-in Line -->
                                    <path class="current-state-01" d="M43,288.4c2.6-1.5,5.2-2.8,7.9-3.9c-1.1-8-1.7-16.3-1.7-24.6c0-75.8,46.9-140.8,113.2-167.7
                                c-0.4-2.8-0.6-5.7-0.6-8.6c0-0.6,0-1.2,0-1.8C90.3,109.2,39.3,178.7,39.3,259.9c0,10,0.8,19.8,2.3,29.4
                                C42.1,288.9,42.5,288.7,43,288.4z" />

                                    <!-- Feed-out to Feed-in Line -->
                                    <path class="current-state-01" d="M348.3,407.6c-2.6-1.5-5.1-3.2-7.4-5c-30.6,23.8-69.1,38-110.8,38c-42,0-80.6-14.4-111.4-38.5
                                c-2.3,1.7-4.7,3.4-7.2,4.8c-0.5,0.3-1,0.6-1.5,0.8c32.8,26.7,74.6,42.7,120.1,42.7c45.2,0,86.8-15.8,119.5-42.2
                                C349.1,408.1,348.7,407.8,348.3,407.6z" />

                                    <!-- Left Line -->
                                    <rect x="134" y="299.4" transform="matrix(0.866 -0.5 0.5 0.866 -131.3247 117.7389)"
                                        class="current-state-01" width="40" height="9" />

                                    <path class="animate-line left-line" d="M2.3,23.9L36.9,3.9" *ngIf="show_ke_power">
                                    </path>
                                    <path class="animate-line left-line-return line-hide" d="M2.3,23.9L36.9,3.9"></path>

                                    <!-- Right Line -->
                                    <rect x="302.2" y="283.9"
                                        transform="matrix(0.5 -0.866 0.866 0.5 -109.8652 417.5573)"
                                        class="current-state-01" width="9" height="40" />

                                    <path class="animate-line right-line line-hide" d="M2.3,23.9L36.9,3.9"></path>
                                    <path class="animate-line right-line-return" d="M2.3,23.9L36.9,3.9"
                                        *ngIf="show_ke_power_feed_out"></path>

                                    <!-- top Line -->
                                    <rect x="225.9" y="151.7" class="current-state-01" width="9" height="40" />

                                    <path class="animate-line top-line" d="M2.3,23.9L36.9,3.9" *ngIf="show_pv_power">
                                    </path>
                                    <path class="animate-line top-line-return line-hide" d="M2.3,23.9L36.9,3.9"></path>

                                    <!-- Feed-in Logo -->
                                    <g>
                                        <polygon class="current-state-02" points="72.4,343.3 72.4,350.4 75.9,346.9 	" />
                                        <polygon class="current-state-02" points="80.8,342 73.7,342 77.3,345.5 	" />
                                        <polygon class="current-state-02" points="73.7,351.8 80.8,351.8 77.3,348.3 	" />
                                        <polygon class="current-state-02" points="70.4,326.8 70.4,318.3 61.9,318.3 	" />
                                        <polygon class="current-state-02" points="80.8,318.3 73.7,318.3 77.3,321.8 	" />
                                        <polygon class="current-state-02" points="82.2,350.4 82.2,343.3 78.7,346.9 	" />
                                        <polygon class="current-state-02" points="80.3,353.8 74.3,353.8 77.3,356.1 	" />
                                        <polygon class="current-state-02" points="86.5,365.6 77.3,358.6 68.1,365.6 67.5,365.6 67.5,365.6 69.4,365.6 69.4,365.6 87.1,365.6 
                                    87.1,365.6 	" />
                                        <polygon class="current-state-02"
                                            points="75.7,357.3 71.8,354.4 71.8,354.5 68.6,362.7 	" />
                                        <polygon class="current-state-02" points="72.4,319.7 72.4,326.8 75.9,323.2 	" />
                                        <polygon class="current-state-02"
                                            points="85.9,362.7 82.8,354.5 82.7,354.4 78.9,357.3 	" />
                                        <polygon class="current-state-02" points="73.7,340 80.8,340 77.3,336.4 	" />
                                        <polygon class="current-state-02" points="72.4,331.5 72.4,338.6 75.9,335.1 	" />
                                        <polygon class="current-state-02" points="84.2,326.8 92.6,318.3 84.2,318.3 	" />
                                        <polygon class="current-state-02" points="73.7,328.2 80.8,328.2 77.3,324.6 	" />
                                        <polygon class="current-state-02" points="82.2,338.6 82.2,331.5 78.7,335.1 	" />
                                        <polygon class="current-state-02" points="77.3,310.2 73.2,316.3 81.3,316.3 	" />
                                        <polygon class="current-state-02" points="80.8,330.1 73.7,330.1 77.3,333.7 	" />
                                        <polygon class="current-state-02" points="82.2,326.8 82.2,319.7 78.7,323.2 	" />
                                        <polygon class="current-state-03" points="86,362.7 82.8,354.5 85.9,362.7 	" />
                                        <polygon class="current-state-03"
                                            points="87.1,365.6 87.1,365.6 87.1,365.6 87.1,365.6 	" />
                                        <polygon class="current-state-03" points="84.2,352.8 84.2,352.6 84.2,352.6 	" />
                                        <polygon class="current-state-03"
                                            points="67.5,365.6 67.5,365.6 67.5,365.6 67.5,365.6 	" />
                                        <polygon class="current-state-03" points="71.8,354.5 68.6,362.7 68.6,362.7 	" />
                                        <polygon class="current-state-04" points="80.8,342 77.3,345.5 73.7,342 72.4,342 72.4,343.3 75.9,346.9 72.4,350.4 72.4,351.8 73.7,351.8 
                                    77.3,348.3 80.8,351.8 82.2,351.8 82.2,350.4 78.7,346.9 82.2,343.3 82.2,342 	" />
                                        <polygon class="current-state-04" points="80.8,330.1 77.3,333.7 73.7,330.1 72.4,330.1 72.4,331.5 75.9,335.1 72.4,338.6 72.4,340 73.7,340 
                                    77.3,336.4 80.8,340 82.2,340 82.2,338.6 78.7,335.1 82.2,331.5 82.2,330.1 	" />
                                        <polygon class="current-state-04"
                                            points="68.1,365.6 77.3,358.6 86.5,365.6 87.1,365.6 87.1,365.6 86,362.7 85.9,362.7 78.9,357.3 82.7,354.4 
                                    82.5,353.8 80.3,353.8 77.3,356.1 74.3,353.8 72,353.8 71.8,354.4 75.7,357.3 68.6,362.7 68.6,362.7 67.5,365.6 67.5,365.6 	" />
                                        <polygon class="current-state-04"
                                            points="80.8,318.3 77.3,321.8 73.7,318.3 72.4,318.3 72.4,319.7 75.9,323.2 72.4,326.8 72.4,328.2 
                                    73.7,328.2 77.3,324.6 80.8,328.2 82.2,328.2 82.2,326.8 78.7,323.2 82.2,319.7 82.2,318.3 	" />
                                        <path class="current-state-05" d="M84.2,329.5L95.7,318c0.3-0.3,0.4-0.7,0.2-1.1c-0.2-0.4-0.5-0.6-0.9-0.6H83.7l-5.6-8.4c-0.4-0.5-1.3-0.5-1.6,0
                                    l-5.6,8.4H59.5c-0.4,0-0.8,0.2-0.9,0.6c-0.2,0.4-0.1,0.8,0.2,1.1l11.5,11.5l0,23.1l-5,13h2.1l0,0l1.1-2.9l3.2-8.3l0-0.1l0.2-0.6
                                    h2.2h6h2.2l0.2,0.6l0,0.1l3.2,8.3l1.1,2.9l0,0h2.1l-4.9-12.8l-0.1-0.2v0V329.5z M70.4,326.8l-8.5-8.5h8.5V326.8z M82.2,350.4v1.4
                                    h-1.4h-7.1h-1.4v-1.4v-7.1V342h1.4h7.1h1.4v1.4V350.4z M82.2,326.8v1.4h-1.4h-7.1h-1.4v-1.4v-7.1v-1.4h1.4h7.1h1.4v1.4V326.8z
                                     M82.2,338.6v1.4h-1.4h-7.1h-1.4v-1.4v-7.1v-1.4h1.4h7.1h1.4v1.4V338.6z M84.2,326.8v-8.5h8.5L84.2,326.8z M77.3,310.2l4.1,6.1
                                    h-8.1L77.3,310.2z" />
                                        <path class="current-state-06"
                                            d="M63.5,366.6c0-0.3,0.1-0.5,0.3-0.7C63.6,366.1,63.5,366.3,63.5,366.6l0,2h0V366.6z" />
                                        <path class="current-state-06"
                                            d="M93.1,365.6h-0.9h-2.1h0h-3h-0.9H84h0H72.4c0,0,0,0,0,0h-1.9h0h-2.1h-0.9c0,0,0,0,0,0h-1.1c0,0,0,0,0,0h-1.9h0
                                    h-2.1h-0.9c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7v2h4.9h1.1h4.9H88h6.1v-2C94.1,366,93.7,365.6,93.1,365.6z" />
                                    </g>

                                    <!-- Feed-out Logo -->
                                    <g>
                                        <polygon class="current-state-02"
                                            points="377.7,343.3 377.7,350.4 381.3,346.9 	" />
                                        <polygon class="current-state-02" points="386.2,342 379.1,342 382.7,345.5 	" />
                                        <polygon class="current-state-02"
                                            points="379.1,351.8 386.2,351.8 382.7,348.3 	" />
                                        <polygon class="current-state-02"
                                            points="375.8,326.8 375.8,318.3 367.3,318.3 	" />
                                        <polygon class="current-state-02"
                                            points="386.2,318.3 379.1,318.3 382.7,321.8 	" />
                                        <polygon class="current-state-02"
                                            points="387.6,350.4 387.6,343.3 384.1,346.9 	" />
                                        <polygon class="current-state-02"
                                            points="385.7,353.8 379.7,353.8 382.7,356.1 	" />
                                        <polygon class="current-state-02" points="391.9,365.6 382.7,358.6 373.4,365.6 372.9,365.6 372.9,365.6 374.8,365.6 374.8,365.6 392.4,365.6 
                                    392.4,365.6 	" />
                                        <polygon class="current-state-02"
                                            points="381,357.3 377.2,354.4 377.2,354.5 374,362.7 	" />
                                        <polygon class="current-state-02"
                                            points="377.7,319.7 377.7,326.8 381.3,323.2 	" />
                                        <polygon class="current-state-02"
                                            points="391.3,362.7 388.2,354.5 388.1,354.4 384.3,357.3 	" />
                                        <polygon class="current-state-02" points="379.1,340 386.2,340 382.7,336.4 	" />
                                        <polygon class="current-state-02"
                                            points="377.7,331.5 377.7,338.6 381.3,335.1 	" />
                                        <polygon class="current-state-02"
                                            points="389.6,326.8 398,318.3 389.6,318.3 	" />
                                        <polygon class="current-state-02"
                                            points="379.1,328.2 386.2,328.2 382.7,324.6 	" />
                                        <polygon class="current-state-02"
                                            points="387.6,338.6 387.6,331.5 384.1,335.1 	" />
                                        <polygon class="current-state-02"
                                            points="382.7,310.2 378.6,316.3 386.7,316.3 	" />
                                        <polygon class="current-state-02"
                                            points="386.2,330.1 379.1,330.1 382.7,333.7 	" />
                                        <polygon class="current-state-02"
                                            points="387.6,326.8 387.6,319.7 384.1,323.2 	" />
                                        <polygon class="current-state-03"
                                            points="391.3,362.7 388.2,354.5 391.3,362.7 	" />
                                        <polygon class="current-state-03"
                                            points="392.4,365.6 392.5,365.6 392.5,365.6 392.4,365.6 	" />
                                        <polygon class="current-state-03"
                                            points="389.6,352.8 389.6,352.6 389.6,352.6 	" />
                                        <polygon class="current-state-03"
                                            points="372.9,365.6 372.9,365.6 372.9,365.6 372.9,365.6 	" />
                                        <polygon class="current-state-03" points="377.2,354.5 374,362.7 374,362.7 	" />
                                        <polygon class="current-state-04"
                                            points="386.2,342 382.7,345.5 379.1,342 377.7,342 377.7,343.3 381.3,346.9 377.7,350.4 377.7,351.8 
                                    379.1,351.8 382.7,348.3 386.2,351.8 387.6,351.8 387.6,350.4 384.1,346.9 387.6,343.3 387.6,342 	" />
                                        <polygon class="current-state-04"
                                            points="386.2,330.1 382.7,333.7 379.1,330.1 377.7,330.1 377.7,331.5 381.3,335.1 377.7,338.6 377.7,340 
                                    379.1,340 382.7,336.4 386.2,340 387.6,340 387.6,338.6 384.1,335.1 387.6,331.5 387.6,330.1 	" />
                                        <polygon class="current-state-04" points="373.4,365.6 382.7,358.6 391.9,365.6 392.4,365.6 392.5,365.6 391.3,362.7 391.3,362.7 384.3,357.3 
                                    388.1,354.4 387.9,353.8 385.7,353.8 382.7,356.1 379.7,353.8 377.4,353.8 377.2,354.4 381,357.3 374,362.7 374,362.7 372.9,365.6 
                                    372.9,365.6 	" />
                                        <polygon class="current-state-04"
                                            points="386.2,318.3 382.7,321.8 379.1,318.3 377.7,318.3 377.7,319.7 381.3,323.2 377.7,326.8 377.7,328.2 
                                    379.1,328.2 382.7,324.6 386.2,328.2 387.6,328.2 387.6,326.8 384.1,323.2 387.6,319.7 387.6,318.3 	" />
                                        <path class="current-state-05" d="M389.6,329.5l11.5-11.5c0.3-0.3,0.4-0.7,0.2-1.1c-0.2-0.4-0.5-0.6-0.9-0.6h-11.3l-5.6-8.4
                                    c-0.4-0.5-1.3-0.5-1.6,0l-5.6,8.4h-11.3c-0.4,0-0.8,0.2-0.9,0.6c-0.2,0.4-0.1,0.8,0.2,1.1l11.5,11.5l0,23.1l-5,13h2.1l0,0l1.1-2.9
                                    l3.2-8.3l0-0.1l0.2-0.6h2.2h6h2.2l0.2,0.6l0,0.1l3.2,8.3l1.1,2.9l0,0h2.1l-4.9-12.8l-0.1-0.2v0V329.5z M375.8,326.8l-8.5-8.5h8.5
                                    V326.8z M387.6,350.4v1.4h-1.4h-7.1h-1.4v-1.4v-7.1V342h1.4h7.1h1.4v1.4V350.4z M387.6,326.8v1.4h-1.4h-7.1h-1.4v-1.4v-7.1v-1.4
                                    h1.4h7.1h1.4v1.4V326.8z M387.6,338.6v1.4h-1.4h-7.1h-1.4v-1.4v-7.1v-1.4h1.4h7.1h1.4v1.4V338.6z M389.6,326.8v-8.5h8.5
                                    L389.6,326.8z M382.7,310.2l4.1,6.1h-8.1L382.7,310.2z" />
                                        <path class="current-state-06"
                                            d="M368.9,366.6c0-0.3,0.1-0.5,0.3-0.7C369,366.1,368.9,366.3,368.9,366.6l0,2h0V366.6z" />
                                        <path class="current-state-06" d="M398.5,365.6h-0.9h-2.1h0h-3h-0.9h-2.1h0h-11.6c0,0,0,0,0,0h-1.9h0h-2.1h-0.9c0,0,0,0,0,0h-1.1c0,0,0,0,0,0
                                    h-1.9h0h-2.1h-0.9c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7v2h4.9h1.1h4.9h16.6h6.1v-2C399.5,366,399.1,365.6,398.5,365.6z
                                    " />
                                    </g>

                                    <polygon class="current-state-07"
                                        points="258.8,79.9 259.9,86.3 258.8,79.9 258.8,79.9 " />
                                    <polygon class="current-state-07"
                                        points="257.5,72.6 258.5,78 258.5,78 257.5,72.6 257.5,72.6 " />

                                    <!-- Yeild Logo -->
                                    <g>
                                        <polygon class="current-state-02"
                                            points="215.5,87.7 215.5,87.7 215.5,87.7 229,87.7 229,87.7 	" />
                                        <polygon class="current-state-02"
                                            points="244.4,87.7 235.6,87.7 231,87.7 231,87.7 235.6,87.7 244.4,87.7 244.4,87.7 	" />
                                        <path class="current-state-08"
                                            d="M226.7,52.4c0.1,0,0.2-0.1,0.3-0.1C226.9,52.4,226.8,52.4,226.7,52.4z" />
                                        <path class="current-state-08"
                                            d="M227.4,52.2c0.1,0,0.2-0.1,0.3-0.1C227.6,52.1,227.5,52.1,227.4,52.2z" />
                                        <path class="current-state-08"
                                            d="M228.2,52c0.1,0,0.2,0,0.3-0.1C228.3,51.9,228.2,51.9,228.2,52z" />
                                        <path class="current-state-08"
                                            d="M229,51.8c0.1,0,0.2,0,0.3,0C229.1,51.8,229,51.8,229,51.8z" />
                                        <path class="current-state-08"
                                            d="M226,52.8c0.1,0,0.2-0.1,0.3-0.1C226.2,52.7,226.1,52.7,226,52.8z" />
                                        <path class="current-state-08" d="M231,65.1l1.1,0c0.4-1,0.7-2.1,0.7-3.3c0-4.4-3.5-8.1-7.9-8.2c-1.8,1.5-3,3.8-3,6.3c0,1.9,0.7,3.7,1.8,5.1
                                    l5.4,0H231z" />
                                        <path class="current-state-08"
                                            d="M236.4,65.1c0.6-0.7,1-1.5,1.3-2.4C237.4,63.6,237,64.4,236.4,65.1L236.4,65.1z" />
                                        <path class="current-state-08"
                                            d="M225.4,53.2c0.1,0,0.1-0.1,0.2-0.1C225.5,53.1,225.4,53.1,225.4,53.2z" />
                                        <path class="current-state-08"
                                            d="M224.8,53.6C224.8,53.6,224.8,53.6,224.8,53.6C224.8,53.6,224.8,53.6,224.8,53.6z" />
                                        <path class="current-state-07" d="M243.3,65.1l11.6,0c1.1,0,1.9,0.8,2.1,1.8l0.9,4.8h0l-0.9-4.8c-0.2-0.9-1-1.7-2.1-1.8l-13.2,0L243.3,65.1
                                    L243.3,65.1z" />
                                        <polygon class="current-state-07"
                                            points="229,73.6 217.4,73.6 216.7,79.2 229,79.2 	" />
                                        <polygon class="current-state-07"
                                            points="215.5,73.6 201.7,73.6 200.7,79.2 214.7,79.2 	" />
                                        <polygon class="current-state-07"
                                            points="200.4,81.1 199.2,87.7 213.5,87.7 214.4,81.1 	" />
                                        <polygon class="current-state-07"
                                            points="229,81.1 216.4,81.1 215.5,87.7 229,87.7 	" />
                                        <path class="current-state-07" d="M216.7,65.1h2l-0.9,6.6H229v-6.6h1.9v6.6h7.3c-0.6-2.4-1.6-4.6-3-6.6h-3.2c0,0,0,0,0,0l-1.1,0H229l-5.4,0l-5,0
                                    L216.7,65.1l-11.6,0c-1.1,0-1.9,0.8-2.1,1.8l-0.9,4.8h13.7L216.7,65.1z" />
                                        <path class="current-state-07"
                                            d="M238.4,81.1H231v6.6h4.7C236.9,85.7,237.9,83.5,238.4,81.1z" />
                                        <path class="current-state-07"
                                            d="M238.9,76.6c0-1-0.1-2.1-0.2-3c0,0,0,0,0,0H231v5.6h7.8C238.9,78.4,238.9,77.5,238.9,76.6z" />
                                        <polygon class="current-state-07"
                                            points="236.4,65.1 236.4,65.1 241.4,65.1 241.4,65.1 241.4,65.1 236.4,65.1 236.4,65.1 	" />
                                        <path class="current-state-08"
                                            d="M214.1,60.9h3.5c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1h-3.5c-0.5,0-1,0.4-1,1C213.1,60.5,213.6,60.9,214.1,60.9z" />
                                        <path class="current-state-08"
                                            d="M241.5,59.9c0,0.5,0.4,1,1,1h3.5c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1h-3.5C241.9,59,241.5,59.4,241.5,59.9z" />
                                        <path class="current-state-08" d="M238.8,52.1L238.8,52.1c0.2,0,0.5-0.1,0.7-0.3l2.5-2.5c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-2.5,2.5
                                    c-0.4,0.4-0.4,1,0,1.4C238.3,52,238.5,52.1,238.8,52.1z" />
                                        <path class="current-state-08" d="M220.5,51.8c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4l-2.5-2.5
                                    c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4L220.5,51.8z" />
                                        <path class="current-state-08"
                                            d="M230,48.5c0.5,0,1-0.4,1-1V44c0-0.5-0.4-1-1-1s-1,0.4-1,1v3.5C229,48.1,229.5,48.5,230,48.5z" />
                                        <polygon class="current-state-09"
                                            points="226.7,93 226.7,98.4 230.2,98.4 230.2,93 230.5,93 233.3,93 233.2,93 226.8,93 	" />
                                        <polygon class="current-state-10"
                                            points="259.6,81.1 245.5,81.1 246.4,87.7 260.8,87.7 259.6,81.1 	" />
                                        <path class="current-state-10"
                                            d="M254.9,65.1l-11.6,0l0.9,6.6h13.7l-0.9-4.8C256.8,65.9,256,65.1,254.9,65.1z" />
                                        <path class="current-state-10"
                                            d="M244.4,87.7l-0.9-6.6h-5.1c-0.5,2.4-1.5,4.6-2.8,6.6H244.4z" />
                                        <path class="current-state-10" d="M236.4,65.1L236.4,65.1C236.4,65.1,236.4,65.1,236.4,65.1l-1.1,0h0c1.4,2,2.4,4.2,3,6.6h4l-0.9-6.6h0
                                    L236.4,65.1z" />
                                        <path class="current-state-10"
                                            d="M238.9,76.6c0,0.9-0.1,1.7-0.2,2.6h4.5l-0.8-5.6h-3.8c0,0,0,0,0,0C238.8,74.6,238.9,75.6,238.9,76.6z" />
                                        <polygon class="current-state-10"
                                            points="258.3,73.6 244.5,73.6 245.2,79.2 259.3,79.2 258.3,73.6 	" />
                                        <path class="current-state-11" d="M215.5,87.7L215.5,87.7l0.9-6.6H229v6.6v0h1h1v0v-6.6h7.4h5.1l0.9,6.6v0l0,0h2l0,0l-0.9-6.6h14.1h0l-0.3-1.9
                                    h0h-14l-0.8-5.6h13.8h0l-0.3-1.9h0h-13.7l-0.9-6.6l0,0h-1.6h-0.4v0l0,0l0.9,6.6h-4H231v-6.6H229v6.6h-11.3l0.9-6.6h-2l-0.9,6.6
                                    h-13.7h0l-0.3,1.9h0h13.8l-0.8,5.6h-14l-0.3,1.9h14.1l-0.9,6.6l0,0L215.5,87.7L215.5,87.7z M231,73.6h7.7h3.8l0.8,5.6h-4.5H231
                                    V73.6z M217.4,73.6H229v5.6h-12.4L217.4,73.6z" />
                                        <path class="current-state-12" d="M261.2,90.2l-0.4-2.5h-14.4h-2h-8.8H231h-1h-1h-13.5h-2h-14.3l-0.4,2.5c-0.3,1.6,0.9,2.8,2.5,2.8h25.5h6.5
                                    h25.5C260.3,93,261.5,91.8,261.2,90.2z" />
                                        <polygon class="current-state-13"
                                            points="230.2,93 230.2,98.4 233,98.4 233.3,98.4 233.3,93 230.5,93 	" />
                                        <path class="current-state-14" d="M232.7,61.8c0,1.2-0.2,2.3-0.7,3.3c0,0,0,0,0,0h3.2h0h1.1c0,0,0,0,0,0c0,0,0,0,0,0c0.6-0.7,1-1.5,1.3-2.4
                                    c0.3-0.9,0.5-1.8,0.5-2.8c0-4.5-3.7-8.2-8.2-8.2c-0.3,0-0.5,0-0.8,0c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.5,0.1c-0.1,0-0.2,0-0.3,0.1
                                    c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.3,0.1-0.4,0.2
                                    c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.4,0.3-0.5,0.4c0,0,0,0-0.1,0c0,0,0,0,0,0
                                    c0,0,0,0,0,0C229.2,53.8,232.7,57.4,232.7,61.8z" />
                                        <path class="current-state-12" d="M230.2,98.4h-3.5h0h-2.8c-1.3,0-2.3,1-2.3,2.3v2.5l0,0H238h0.4l0,0v-2.5c0-1.3-1-2.3-2.3-2.3h-2.8H233H230.2z
                                    " />
                                    </g>

                                    <!-- Load Logo -->
                                    <g>
                                        <path class="current-state-15" d="M248.2,276.2c0-1.8,1.5-3.3,3.3-3.3c0.5,0,1.1,0.1,1.5,0.4c0.2-0.4,0.4-0.7,0.7-1.1v-23.8H207v23.8
                                    c0.3,0.3,0.5,0.7,0.7,1.1c0.5-0.2,1-0.4,1.5-0.4c1.8,0,3.3,1.5,3.3,3.3c0,1.8-1.5,3.3-3.3,3.3h10l2.2-4.5h3.3v-15.6
                                    c0-3.1,2.5-5.6,5.6-5.6c3.1,0,5.6,2.5,5.6,5.6v15.6h3.3l2.2,4.5h10C249.7,279.5,248.2,278,248.2,276.2z M214.8,249.5h2.2v2.2h-2.2
                                    V249.5z M219.2,260.6v2.2v3.3h-6.7v-3.3v-2.2V255h6.7V260.6z M243.7,249.5h2.2v2.2h-2.2V249.5z M241.5,262.8v-2.2V255h6.7v5.6v2.2
                                    v3.3h-6.7V262.8z" />
                                        <rect x="207" y="246.1" class="current-state-16" width="46.8" height="2.2" />
                                        <polygon class="current-state-17"
                                            points="259.3,246.1 254.9,231.7 205.9,231.7 201.4,246.1 207,246.1 253.7,246.1 	" />
                                        <polygon class="current-state-16"
                                            points="235.9,275.1 224.8,275.1 221.5,275.1 219.2,279.5 241.5,279.5 239.3,275.1 	" />
                                        <path class="current-state-17" d="M235.9,259.5c0-3.1-2.5-5.6-5.6-5.6c-3.1,0-5.6,2.5-5.6,5.6v15.6h11.1V259.5z M230.4,265.1h-2.2v-2.2h2.2
                                    V265.1z" />
                                        <rect x="212.5" y="262.8" class="current-state-17" width="6.7" height="3.3" />
                                        <rect x="212.5" y="255" class="current-state-17" width="6.7" height="5.6" />
                                        <rect x="212.5" y="260.6" class="current-state-18" width="6.7" height="2.2" />
                                        <rect x="214.8" y="249.5" class="current-state-19" width="2.2" height="2.2" />
                                        <path class="current-state-20"
                                            d="M212.5,276.2c0-1.8-1.5-3.3-3.3-3.3c-0.5,0-1.1,0.1-1.5,0.4c-0.2-0.4-0.4-0.7-0.7-1.1c-1.3-1.4-3.4-2-5.2-1.1
                                    c-2.2,1-3.2,3.7-2.2,5.9c0.7,1.6,2.3,2.6,4,2.6h3.3h2.2C211.1,279.5,212.5,278,212.5,276.2z" />
                                        <path class="current-state-20"
                                            d="M258.9,271c-1.8-0.8-3.9-0.3-5.2,1.1c-0.3,0.3-0.5,0.7-0.7,1.1c-0.5-0.2-1-0.4-1.5-0.4
                                    c-1.8,0-3.3,1.5-3.3,3.3c0,1.8,1.5,3.3,3.3,3.3h2.2h3.3c1.7,0,3.3-1,4-2.6C262.2,274.7,261.2,272.1,258.9,271z" />




                                        <rect x="228.1" y="262.8" class="current-state-21" width="2.2" height="2.2" />
                                        <rect x="241.5" y="255" class="current-state-17" width="6.7" height="5.6" />
                                        <rect x="241.5" y="262.8" class="current-state-17" width="6.7" height="3.3" />
                                        <rect x="241.5" y="260.6" class="current-state-18" width="6.7" height="2.2" />
                                        <rect x="243.7" y="249.5" class="current-state-19" width="2.2" height="2.2" />
                                    </g>
                                </svg>

                                <div class="yield" style="top: 0px; left: 200px;">
                                    <h6 class="yield-title" style=" top: 0px; font-size: 13px; color: #073C5C;">Yield
                                    </h6>
                                    <p class="yield-value" *ngIf="show_pv_power">{{ current_state_pv.toFixed(2) }}Kw</p>
                                    <p class="yield-value" *ngIf="!show_pv_power">0Kw</p>
                                </div>

                                <div class="load" style="top: 154px; left: 200px;">
                                    <h6 class="load-title" style="top: 132px; font-size: 13px;">Load</h6>
                                    <p class="load-value">{{ current_state_load.toFixed(2) }}Kw</p>
                                </div>

                                <div class="feed-in" style=" top: 230px; left: 66px;">
                                    <h6 class="feed-in-title" style=" top: 132px; font-size: 13px;">Feed-in</h6>
                                    <p class="feed-in-value" *ngIf="show_ke_power">{{ current_state_ke.toFixed(2) }}Kw
                                    </p>
                                    <p class="feed-in-value" *ngIf="!show_ke_power">0Kw</p>
                                </div>

                                <div class="feed-out" style="top: 230px; left: 332px;">
                                    <h6 class="feed-out-title" style=" top: 132px; font-size: 13px; color: #073C5C;">
                                        Feed-out</h6>
                                    <p class="feed-out-value" *ngIf="show_ke_power_feed_out">{{
                                        current_state_ke.toFixed(2) }}Kw
                                    </p>
                                    <p class="feed-out-value" *ngIf="!show_ke_power_feed_out">0Kw</p>
                                </div>
                            </div>
                        </div>
                        <div class="last-update" style="text-align: right;">
                            <span *ngIf="last_update_chart_data != 'not available'"
                                style="color: darkgray; margin-right: 20px; margin-bottom: 15px;">
                                Last Update: {{ last_update_chart_data | date:'medium' }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-xl-12 stretch-card" id="tab_current_state_table">
                    <div class="row flex-grow">

                        <div class="col grid-margin stretch-card">
                            <div class="card" id="tab-table">
                                <div class="d-grid card-body">
                                    <div class="table-responsive text-nowrap">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style="text-align: center;" width="auto">Location</th>
                                                    <th style="text-align: center;" width="auto">Total Power (KW)</th>
                                                    <th style="text-align: center;" width="auto">Total Pv-Yeild (KWh)
                                                    </th>
                                                    <th style="text-align: center;" width="auto">Total Pv-self Use (KWh)
                                                    </th>
                                                    <th style="text-align: center;" width="auto">Total Export To Gird
                                                        (KWh)</th>
                                                    <th style="text-align: center;" width="auto">Total Import From Gird
                                                        (KWh)</th>
                                                    <th style="text-align: center;" width="auto" *ngIf="show_gs_power">
                                                        Total Genset (KWh)</th>
                                                    <th style="text-align: center;" width="auto">Last Update</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of curr_state_table">
                                                    <td><i [ngClass]="data.icon"></i>
                                                        {{ data.location }}</td>
                                                    <td class="text-center">{{data.total_power}} ({{
                                                        data.total_power_percentage.toFixed(2) }}) %</td>
                                                    <td class="text-center">{{ data.pv_yeild }}</td>
                                                    <td class="text-center">{{ data.pv_self_use }}</td>
                                                    <td class="text-center">{{ data.total_exp }}</td>
                                                    <td class="text-center">{{ data.total_imp }}</td>
                                                    <td class="text-center" *ngIf="show_gs_power">{{ data.total_genset
                                                        }}</td>
                                                    <td class="text-center">{{ data.last_update }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-center font-weight-bold text-uppercase text-xl">
                                                        Total</td>
                                                    <td class="text-center font-weight-bold">{{
                                                        curr_state_table_power_sum.toFixed(2) }}</td>
                                                    <td class="text-center font-weight-bold">{{
                                                        curr_state_table_yeild_sum.toFixed(2) }}</td>
                                                    <td class="text-center font-weight-bold">{{
                                                        curr_state_table_self_sum.toFixed(2) }}</td>
                                                    <td class="text-center font-weight-bold">{{
                                                        curr_state_table_exp_sum.toFixed(2) }}</td>
                                                    <td class="text-center font-weight-bold">{{
                                                        curr_state_table_imp_sum.toFixed(2) }}</td>
                                                    <td class="text-center font-weight-bold" *ngIf="show_gs_power">{{
                                                        curr_state_table_gs_sum.toFixed(2) }}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="{{ stock_class }}" id="pv_chart" *ngIf="stock_status">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="card-title mb-0 ">{{ graph }}
                                <span>KWh {{
                                    sum.toFixed(2) | currency: ' ' }}</span>
                            </h6>

                            <div class="d-flex align-items-center">

                                <button class="btn pr-0" type="button" data-toggle="collapse" data-target="#collapsekwh"
                                    aria-expanded="false" aria-controls="collapsekwh">
                                    <i class="feather-sliders"
                                        style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                                </button>

                                <i class="icon-sm card-header-icon ml-2 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Print"
                                            (click)="data_param = 'pv'; data_param_el = 'pv_chart'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-printer"></i>
                                            <span class="">Print</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'pv'; exportAsCSV()">
                                            <i class="icon-sm mr-2 feather feather-download"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf"
                                            (click)="data_param = 'pv'; data_param_el = 'pv_chart'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-file-text"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="collapse px-2" id="collapsekwh">
                            <div class="card-body overflow-auto">
                                <div class="w-max-content header-right-inner-left d-flex">

                                    <h6 class="mr-2 text-nowrap">Graph select:</h6>

                                    <select class="mr-4" [(ngModel)]="graph" (change)="onChangeGraph(graph)">
                                        <option [ngValue]="null" [disabled]="true">Select Graph</option>
                                        <option *ngFor="let data of graph_list">{{ data }}</option>
                                    </select>

                                    <h6 class="mr-2 text-nowrap">Date:</h6>

                                    <input type="date" [(ngModel)]="pv_date" autocomplete="off" class="form-control"
                                        (change)="onDateSelection($event, 'pv')">
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <span class="form-group mr-4" *ngIf="stock_export_to_grid"><strong>Total Export To
                                    Grid KWh : {{export_to_grid_sum.toFixed(2) | currency: ' '}}</strong></span>

                            <span class="divider" *ngIf="stock_export_to_grid"></span>

                            <span class="form-group ml-4 mr-4" *ngIf="stock_pv_self_use"><strong>Total Pv-Self Use
                                    KWh : {{pv_self_use_sum.toFixed(2) | currency: ' '}}</strong></span>

                            <span class="divider" *ngIf="stock_pv_self_use"></span>

                            <span class="form-group ml-4 mr-4" *ngIf="stock_import_from_grid"><strong>Total Import
                                    From Grid KWh : {{import_from_grid_sum.toFixed(2) | currency: ' '}}</strong></span>

                            <span class="divider" *ngIf="stock_genset"></span>

                            <span class="form-group ml-4 mr-4" *ngIf="stock_genset"><strong>Total Genset
                                    KWh : {{gs_sum.toFixed(2) | currency: ' '}}</strong></span>

                            <div class="d-flex align-items-center justify-content-end">


                            </div>
                            <div class="row align-items-start mb-2">

                            </div>
                            <div class="flot-wrapper">
                                <h3 *ngIf="stock_graph_heading" style="opacity: 50%; text-align: center;">Kindly Select
                                    Graph Type From Drop Down</h3>
                                <div *ngIf="!stock_graph_heading" id="chartPv" style="width: 100%; height: 450px"></div>
                            </div>
                        </div>
                        <div class="last-update" style="text-align: right;">
                            <span *ngIf="last_update_chart_data != 'not available'"
                                style="color: darkgray; margin-right: 20px; margin-bottom: 15px;">
                                Last Update: {{ last_update_chart_data | date:'medium' }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="{{ weather_class }}" id="pv_weather" *ngIf="weather_status">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="card-title mb-0 ">{{ weather_header }}</h6>
                        </div>

                        <div class="card-body">
                            <div class="d-flex align-items-center justify-content-end"></div>
                            <div class="row align-items-start mb-2"></div>
                            <div class="flot-wrapper">
                                <div id="chartPvEnergy" style="width: 100%; height: 600px">
                                    <div class="card-body">
                                        <div class="row m-0">
                                            <div class="col-12 p-0 d-flex justify-content-between mb-5">
                                                <h3 class="mb-0 uppercase-font"><strong>{{city_location}}</strong></h3>
                                                <h6 class="card-title mb-0 mt-2 echo-blue-text">WEEK</h6>
                                            </div>
                                        </div>
                                        <div class="row mx-0">
                                            <div class="col-12 p-0">
                                                <div class="mr-2 d-flex float-left mb-3"><img width="80px" height="80px"
                                                        src="{{ weather_icon_today }}"></div>
                                                <h2 class="mb-0">{{ weather_temp_today}} <span>&#8451;</span> </h2>
                                                <h4 class="card-title mb-0 mt-2 echo-blue-text">{{ weather_day }}</h4>
                                            </div>
                                        </div>
                                        <div class="row mx-0">
                                            <div class="col-12 p-0">
                                                <div id="chartWeather"
                                                    style="height: 200px; display: flex; align-items: center">

                                                </div>
                                            </div>
                                        </div>
                                        <div class="row m-0">

                                            <div class="col-12 p-0 my-3"
                                                style="border-bottom: 1px solid #ADB4D2; padding: 5px 0 !important;">
                                                <h6 class="card-title m-0 echo-blue-text">TEMPERATURE</h6>
                                            </div>

                                            <div class="col-3 p-0 text-center">
                                                <div class="d-flex mb-1"><img class="weather_icons"
                                                        src="{{weather_icon_1}}"></div>
                                                <h3 class="mb-0">{{ weather_temp_1 }} <span>&#8451;</span> </h3>
                                                <h6 class="card-title mb-0 mt-2 echo-blue-text">{{ weather_day_1 }}</h6>
                                            </div>
                                            <div class="col-3 p-0 text-center">
                                                <div class="d-flex mb-1"><img class="weather_icons"
                                                        src="{{weather_icon_2}}"></div>
                                                <h3 class="mb-0">{{ weather_temp_2 }} <span>&#8451;</span> </h3>
                                                <h6 class="card-title mb-0 mt-2 echo-blue-text">{{ weather_day_2 }}</h6>
                                            </div>
                                            <div class="col-3 p-0 text-center">
                                                <div class="d-flex mb-1"><img class="weather_icons"
                                                        src="{{weather_icon_3}}"></div>
                                                <h3 class="mb-0">{{ weather_temp_3 }} &#8451; </h3>
                                                <h6 class="card-title mb-0 mt-2 echo-blue-text">{{ weather_day_3 }}</h6>
                                            </div>
                                            <div class="col-3 p-0 text-center">
                                                <div class="d-flex mb-1"><img class="weather_icons"
                                                        src="{{weather_icon_4}}"></div>
                                                <h3 class="mb-0">{{ weather_temp_4 }} &#8451; </h3>
                                                <h6 class="card-title mb-0 mt-2 echo-blue-text">{{ weather_day_4 }}</h6>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </ng-template>
    </ngb-tab>

    <ngb-tab *ngIf="tab_billing_and_income_table_status" id="tab-bill-income-table" title="Billing and Income Table">
        <ng-template ngbTabContent>
            <div class="row mt-4">

                <div class="col-lg-12 col-xl-12 stretch-card" id="tab_billing_and_income_data">
                    <div class="d-grid row flex-grow">

                        <div class="col grid-margin stretch-card">
                            <div class="card" id="tab-table">
                                <div class="d-flex justify-content-between card-header align-items-center mb-md-3">
                                    <h4 class="card-title mb-0" style="color: red;">{{ month }} Billing and Income Table
                                    </h4>

                                    <div class="d-flex align-items-center justify-content-end">

                                        <button class="btn pr-0" type="button" data-toggle="collapse"
                                            data-target="#collapseExample" aria-expanded="false"
                                            aria-controls="collapseExample">
                                            <i class="feather-sliders"
                                                style="font-size: x-large; color: #ff0000;; font-weight: 500;"></i>
                                        </button>

                                        <div class="dropdown ml-2">
                                            <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i
                                                    class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                                <button [useExistingCss]="true" printSectionId="Table-print" ngxPrint
                                                    type="button" class="dropdown-item d-flex align-items-center"
                                                    name="Print">
                                                    <i class="icon-sm mr-2 feather feather-printer"></i>
                                                    <span class="">Print</span>
                                                </button>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);"
                                                    (click)="data_param = 'tab_billing_and_income'; exportAsCSV()">
                                                    <i class="icon-sm mr-2 feather feather-download"></i>
                                                    <span class="">Export as CSV</span>
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);" name="Pdf"
                                                    (click)="data_param = 'tab_billing_and_income'; data_param_el = 'tab_billing_and_income_data'; exportAsPDF($event)">
                                                    <i class="icon-sm mr-2 feather feather-file-text"></i>
                                                    <span class="">Export as PDF</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="collapse px-2" id="collapseExample">
                                    <div class="card-body overflow-auto">
                                        <div class="w-max-content header-right-inner-left d-flex">

                                            <div class="form-group mb-0 mr-2">
                                                <h6>Range:</h6>
                                            </div>

                                            <div class="form-group mb-0 mr-2">
                                                <input type="text" class="form-control"
                                                    (change)="onTabularDateSelection(tabular_selected)"
                                                    style="width: 325px; height: 36px; border:  1px solid rgb(204, 204, 204); border-radius: 5px 5px 5px 5px; color: red;"
                                                    ngxDaterangepickerMd [(ngModel)]="tabular_selected" startKey="start"
                                                    [timePicker]="true" [timePickerSeconds]="false"
                                                    [timePicker24Hour]="true" endKey="end" [showCustomRangeLabel]="true"
                                                    [alwaysShowCalendars]="true" [customRangeDirection]="true"
                                                    [locale]="{ displayFormat: 'DD MMM YYYY  HH:00', separator: '    To    ',  cancelLabel: 'Cancel', firstDay: 1}"
                                                    [showClearButton]="true" [showCancel]="true"
                                                    [showWeekNumbers]="true" [ranges]="ranges" [linkedCalendars]="true"
                                                    placeholder="Select Range">

                                            </div>

                                            <div class="form-group mb-0 mt-2 mr-2 ml-2">
                                                <span>
                                                    <strong class="text-nowrap">Buying Rate ( From Grid )</strong>
                                                </span>
                                            </div>

                                            <div class="form-group mb-0 mr-4">
                                                <input type="text" style="width: 70px;" class="form-control mb-0"
                                                    [(ngModel)]="buy_from_grid" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Enter Unit Buy From Grid...">
                                            </div>

                                            <span class="divider"></span>

                                            <div class="form-group mb-0 mr-1 ml-2">
                                                <span>
                                                    <strong class="text-nowrap">Selling Rate ( To Grid )</strong>
                                                </span>
                                            </div>

                                            <div class="form-group mb-0 mr-2">
                                                <input type="text" style="width: 70px;" class="form-control"
                                                    [(ngModel)]="sale_to_grid" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Enter Unit Sale To Grid...">
                                            </div>

                                            <div class="form-group mb-0 ml-2">
                                                <button class="btn theme-primary-btn" type="button"
                                                    (click)="calculateCost()" style="width: auto;">Calculate</button>
                                            </div>

                                            <div class="form-group mb-0 ml-2">
                                                <button class="btn theme-primary-btn" type="button" (click)="onClick()"
                                                    style="width: auto;">View</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div id="Table-print" class="card-body">
                                    <header style="display: none;">
                                        <div class="row">

                                            <div class="col-sm">
                                                <img width="180" src="{{ main_logo }}">
                                            </div>

                                            <div class="col-sm d-flex justify-content-center">
                                                <h3>{{ nm_location }}</h3>
                                            </div>

                                            <div class="col-sm d-flex justify-content-end">
                                                <h4>{{ user?.company }}</h4>
                                            </div>
                                        </div>

                                        <div class="row" style="margin-top: -10px;">

                                            <div class="col-sm d-flex justify-content-end">
                                                <h6>{{ curr_date }}</h6>
                                            </div>
                                        </div>
                                    </header>
                                    <h4 id="card-title" class="card-title mb-0 text-left"
                                        style="display: none; color: red;">{{ month }} Billing and Income Table</h4>
                                    <div class="table-responsive text-nowrap">
                                        <table width="100%" class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style="text-align: center;" width="auto">Date</th>
                                                    <th style="text-align: center;" width="auto">Import From Grid ( KWh
                                                        )
                                                    </th>
                                                    <th style="text-align: center;" width="auto">Buying From Grid ( Bill
                                                        )</th>
                                                    <th style="text-align: center;" width="auto">Export To Grid ( KWh )
                                                    </th>
                                                    <th style="text-align: center;" width="auto">Selling To Grid (
                                                        Income
                                                        )</th>
                                                    <th style="text-align: center;" width="auto">Balance</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr *ngFor="let data of tabular_billing_and_income_data">
                                                    <td style="text-align: center;">{{ data.date | date:'fullDate'}}
                                                    </td>
                                                    <td style="text-align: center;">{{ data.import_from_grid.toFixed(2)
                                                        | currency: ' '}}</td>
                                                    <td style="text-align: center;">{{ data.buy_from_grid.toFixed(2) |
                                                        currency: ' ' }}
                                                    </td>
                                                    <td style="text-align: center;">{{ data.export_to_grid.toFixed(2) |
                                                        currency: ' ' }}
                                                    </td>
                                                    <td style="text-align: center;">{{ data.sale_to_grid.toFixed(2) |
                                                        currency: ' ' }}
                                                    </td>
                                                    <td style="text-align: center; color: red;">{{
                                                        data.balance.toFixed(2) | currency: ' ' }}</td>
                                                </tr>

                                                <tr>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>Total</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{tabular_import_sum.toFixed(2) | currency: ' '}}</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{tabular_buy_sum.toFixed(2) | currency: ' '}}</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{tabular_exprot_sum.toFixed(2) | currency: ' '}}</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{tabular_sale_sum.toFixed(2) | currency: ' '}}</h5>
                                                        </strong></td>
                                                    <td style="text-align: center; color: red;"><strong>
                                                            <h5>{{tabular_balance_sum.toFixed(2) | currency: ' '}}</h5>
                                                        </strong></td>
                                                </tr>

                                            </tbody>

                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- row -->

        </ng-template>
    </ngb-tab>

    <ngb-tab *ngIf="tab_saving_table_status" id="tab-saving-table" title="Saving Table">
        <ng-template ngbTabContent>

            <div class="row mt-4">

                <div class="col-lg-12 col-xl-12 stretch-card" id="tab_saving_data">
                    <div class="d-grid row flex-grow">

                        <div class="col grid-margin stretch-card">
                            <div class="card" id="tab-table">

                                <div class="d-flex justify-content-between card-header align-items-center mb-md-3">
                                    <h4 class="card-title mb-0 " style="color: red;">{{ month }} Saving Table</h4>

                                    <div class="d-flex align-items-center justify-content-end">

                                        <button class="btn pr-0" type="button" data-toggle="collapse"
                                            data-target="#collapseExample" aria-expanded="false"
                                            aria-controls="collapseExample">
                                            <i class="feather-sliders"
                                                style="font-size: x-large; color: #ff0000;; font-weight: 500;"></i>
                                        </button>

                                        <div class="dropdown ml-2">
                                            <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i
                                                    class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                                <button [useExistingCss]="true" printSectionId="Table-print" ngxPrint
                                                    type="button" class="dropdown-item d-flex align-items-center"
                                                    name="Print">
                                                    <i class="feather feather-printer icon-sm mr-2"></i>
                                                    <span class="">Print</span>
                                                </button>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);"
                                                    (click)="data_param = 'tab_saving'; exportAsCSV()">
                                                    <i class="icon-sm mr-2 feather feather-download"></i>
                                                    <span class="">Export as CSV</span>
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);" name="Pdf"
                                                    (click)="data_param = 'tab_saving'; data_param_el = 'tab_saving_data'; exportAsPDF($event)">
                                                    <i class="icon-sm mr-2 feather feather-file-text"></i>
                                                    <span class="">Export as PDF</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="collapse px-2" id="collapseExample">
                                    <div class="card-body overflow-auto">

                                        <div class="w-max-content header-right-inner-left d-flex">

                                            <div class="form-group mb-0 mr-2">
                                                <h6>Range:</h6>
                                            </div>

                                            <div class="form-group mb-0 mr-2">
                                                <input type="text" class="form-control"
                                                    (change)="onTabularDateSelection(tabular_selected)"
                                                    style="width: 325px; height: 36px; border:  1px solid rgb(204, 204, 204); border-radius: 5px 5px 5px 5px; color: red;"
                                                    ngxDaterangepickerMd [(ngModel)]="tabular_selected" startKey="start"
                                                    [timePicker]="true" [timePickerSeconds]="false"
                                                    [timePicker24Hour]="true" endKey="end" [showCustomRangeLabel]="true"
                                                    [alwaysShowCalendars]="true" [customRangeDirection]="true"
                                                    [locale]="{ displayFormat: 'DD MMM YYYY  HH:00', separator: '    To    ',  cancelLabel: 'Cancel', firstDay: 1}"
                                                    [showClearButton]="true" [showCancel]="true"
                                                    [showWeekNumbers]="true" [ranges]="ranges" [linkedCalendars]="true"
                                                    placeholder="Select Range">

                                            </div>

                                            <div class="form-group mb-0 mr-2 ml-2">
                                                <span>
                                                    <strong class="text-nowrap">Buying Rate ( From Grid )</strong>
                                                </span>
                                            </div>

                                            <div class="form-group mb-0 mr-4">
                                                <input type="text" style="width: 70px;" class="form-control mb-0"
                                                    [(ngModel)]="buy_from_grid" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Enter Unit Buy From Grid...">
                                            </div>

                                            <span class="divider mt-2"></span>

                                            <div class="form-group mb-0 mr-2 ml-2">
                                                <span>
                                                    <strong class="text-nowrap">Selling Rate ( To Grid )</strong>
                                                </span>
                                            </div>

                                            <div class="form-group mb-0 mr-2">
                                                <input type="text" style="width: 70px;" class="form-control"
                                                    [(ngModel)]="sale_to_grid" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Enter Unit Sale To Grid...">
                                            </div>

                                            <div class="form-group mb-0 ml-2">
                                                <button class="btn theme-primary-btn" type="button"
                                                    (click)="calculateCost()" style="width: auto;">Calculate</button>
                                            </div>

                                            <div class="form-group mb-0 ml-2">
                                                <button class="btn theme-primary-btn" type="button" (click)="onClick()"
                                                    style="width: auto;">View</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div id="Table-print" class="card-body">

                                    <header style="display: none;">
                                        <div class="row">

                                            <div class="col-sm">
                                                <img width="180" src="{{ main_logo }}">
                                            </div>

                                            <div class="col-sm d-flex justify-content-center">
                                                <h3>{{ nm_location }}</h3>
                                            </div>

                                            <div class="col-sm d-flex justify-content-end">
                                                <h4>{{ user?.company }}</h4>
                                            </div>
                                        </div>

                                        <div class="row" style="margin-top: -10px;">

                                            <div class="col-sm d-flex justify-content-end">
                                                <h6>{{ curr_date }}</h6>
                                            </div>
                                        </div>
                                    </header>
                                    <h4 id="card-title" class="card-title mb-0 text-left"
                                        style="display: none; color: red;">{{ month }} Saving Table</h4>
                                    <div class="table-responsive text-nowrap">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style="text-align: center;" width="10%">Date</th>
                                                    <th style="text-align: center;" width="10%">Export To Grid ( KWh )
                                                    </th>
                                                    <th style="text-align: center;" width="10%">Selling To Grid ( Income
                                                        ) - A</th>
                                                    <th style="text-align: center;" width="10%">Pv-Self Use( KWh )</th>
                                                    <th style="text-align: center;" width="10%">Saving (Pv-Self Use) - B
                                                    </th>
                                                    <th style="text-align: center;" width="10%">Total Saving - A + B
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr *ngFor="let data of tabular_saving_data">
                                                    <td style="text-align: center;">{{ data.date | date:'fullDate'}}
                                                    </td>
                                                    <td style="text-align: center;">{{ data.export_to_grid.toFixed(2) |
                                                        currency: ' ' }}
                                                    </td>
                                                    <td style="text-align: center;">{{ data.sale_to_grid.toFixed(2) |
                                                        currency: ' ' }}
                                                    </td>
                                                    <td style="text-align: center;">{{ data.pv_self_use.toFixed(2) |
                                                        currency: ' ' }}
                                                    </td>
                                                    <td style="text-align: center;">{{ data.saving.toFixed(2) |
                                                        currency: ' ' }}</td>
                                                    <td style="text-align: center; color: rgb(8, 216, 8);">{{
                                                        data.total_saving.toFixed(2) | currency: ' ' }}</td>
                                                </tr>

                                                <tr>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>Total</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{tabular_exprot_sum.toFixed(2) | currency: ' '}}</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{tabular_sale_sum.toFixed(2) | currency: ' '}}</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{tabular_pv_sum.toFixed(2) | currency: ' '}}</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{tabular_saving_sum.toFixed(2) | currency: ' '}}</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;  color: rgb(8, 216, 8);"><strong>
                                                            <h5>{{tabular_total_saving_sum.toFixed(2) | currency: ' '}}
                                                            </h5>
                                                        </strong></td>
                                                </tr>

                                            </tbody>

                                        </table>

                                    </div>

                                    <!-- <br>
                                    <h5>Total Record: {{ table_data.length }}</h5>
                                    <ngb-pagination class="d-flex justify-content-end"
                                        [collectionSize]="table_data.length" [(page)]="page" [maxSize]="2"
                                        [rotate]="true" [pageSize]="pageSize" [boundaryLinks]="true">

                                    </ngb-pagination> -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- row -->

        </ng-template>
    </ngb-tab>

    <ngb-tab *ngIf="tab_pv_yeild_energy_status" id="tab-pv-yeild-energy" title="Pv-Yeild Energy">
        <ng-template ngbTabContent>

            <div class="row mt-4">

                <div class="col-lg-12 col-xl-12 stretch-card" id="tab_pv_yeild_energy_table">
                    <div class="row flex-grow d-grid">

                        <div class="col grid-margin stretch-card">
                            <div class="card" id="tab-table">
                                <!-- <div id="charttest" style="width: 100%; height: 450px"></div> -->

                                <div class="d-flex justify-content-between card-header align-items-center mb-md-3">

                                    <h4 class="card-title mb-0" style="color: red;">{{ month }} Pv-Yeild Energy</h4>

                                    <div class="d-flex align-items-center justify-content-end">

                                        <button class="btn pr-0" type="button" data-toggle="collapse"
                                            data-target="#collapseExample" aria-expanded="false"
                                            aria-controls="collapseExample">
                                            <i class="feather-sliders"
                                                style="font-size: x-large; color: #ff0000;; font-weight: 500;"></i>
                                        </button>

                                        <div class="dropdown ml-2">
                                            <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i
                                                    class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                                <button [useExistingCss]="true" printSectionId="Table-print" ngxPrint
                                                    type="button" class="dropdown-item d-flex align-items-center"
                                                    name="Print">
                                                    <i class="feather feather-printer icon-sm mr-2"></i>
                                                    <span class="">Print</span>
                                                </button>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);"
                                                    (click)="data_param = 'tab_pv_yeild_energy'; exportAsCSV()">
                                                    <i class="icon-sm mr-2 feather feather-download"></i>
                                                    <span class="">Export as CSV</span>
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);" name="Pdf"
                                                    (click)="data_param = 'tab_pv_yeild_energy'; data_param_el = 'tab_pv_yeild_energy_table'; exportAsPDF($event)">
                                                    <i class="icon-sm mr-2 feather feather-file-text"></i>
                                                    <span class="">Export as PDF</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="collapse px-2" id="collapseExample">
                                    <div class="card-body overflow-auto">
                                        <div class="w-max-content header-right-inner-left d-flex">

                                            <div class="form-group mb-0 mr-2">
                                                <h6>Range:</h6>
                                            </div>

                                            <div class="form-group mb-0 mr-2">
                                                <input type="text" class="form-control"
                                                    (change)="onTabularDateSelection(tabular_selected)"
                                                    style="width: 325px; height: 36px; border:  1px solid rgb(204, 204, 204); border-radius: 5px 5px 5px 5px; color: red;"
                                                    ngxDaterangepickerMd [(ngModel)]="tabular_selected" startKey="start"
                                                    [timePicker]="true" [timePickerSeconds]="false"
                                                    [timePicker24Hour]="true" endKey="end" [showCustomRangeLabel]="true"
                                                    [alwaysShowCalendars]="true" [customRangeDirection]="true"
                                                    [locale]="{ displayFormat: 'DD MMM YYYY  HH:00', separator: '    To    ',  cancelLabel: 'Cancel', firstDay: 1}"
                                                    [showClearButton]="true" [showCancel]="true"
                                                    [showWeekNumbers]="true" [ranges]="ranges" [linkedCalendars]="true"
                                                    placeholder="Select Range">

                                            </div>

                                            <div class="form-group mb-0 mr-2 ml-2">
                                                <span>
                                                    <strong class="text-nowrap">Buying Rate ( From Grid )</strong>
                                                </span>
                                            </div>

                                            <div class="form-group mb-0 mr-4">
                                                <input type="text" style="width: 70px;" class="form-control mb-0"
                                                    [(ngModel)]="buy_from_grid" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Enter Unit Buy From Grid...">
                                            </div>

                                            <span class="divider mt-2"></span>

                                            <div class="form-group mb-0 mr-2 ml-2">
                                                <span>
                                                    <strong class="text-nowrap">Selling Rate ( To Grid )</strong>
                                                </span>
                                            </div>

                                            <div class="form-group mb-0 mr-2">
                                                <input type="text" style="width: 70px;" class="form-control"
                                                    [(ngModel)]="sale_to_grid" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Enter Unit Sale To Grid...">
                                            </div>

                                            <div class="form-group mb-0 ml-2">
                                                <button class="btn theme-primary-btn" type="button"
                                                    (click)="calculateCost()" style="width: auto;">Calculate</button>
                                            </div>

                                            <div class="form-group mb-0 ml-2 mr-2">
                                                <button class="btn theme-primary-btn" type="button"
                                                    (click)="getGraphInterface('pv_yeild_energy_graph')"
                                                    style="width: 9em; padding-bottom: 2.1em;">Get Graphical
                                                    Interface</button>
                                            </div>

                                            <div class="form-group mb-0 ml-2">
                                                <button class="btn theme-primary-btn" type="button" (click)="onClick()"
                                                    style="width: auto;">View</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div id="Table-print" class="card-body">
                                    <header style="display: none;">
                                        <div class="row">

                                            <div class="col-sm">
                                                <img width="180" src="{{ main_logo }}">
                                            </div>

                                            <div class="col-sm d-flex justify-content-center">
                                                <h3>{{ nm_location }}</h3>
                                            </div>

                                            <div class="col-sm d-flex justify-content-end">
                                                <h4>{{ user?.company }}</h4>
                                            </div>
                                        </div>

                                        <div class="row" style="margin-top: -10px;">

                                            <div class="col-sm d-flex justify-content-end">
                                                <h6>{{ curr_date }}</h6>
                                            </div>
                                        </div>
                                    </header>
                                    <h4 id="card-title" class="card-title mb-0 text-left"
                                        style="display: none; color: red;">{{ month }} Pv-Yeild Energy</h4>
                                    <div class="table-responsive text-nowrap">
                                        <div class="row" *ngIf="tab_pv_yeild_energy_graph">

                                            <div class="col-md-6 col-xl-12 col-lg-4 grid-margin stretch-card">
                                                <div class="card">
                                                    <div class="card-body">

                                                        <div class="flot-wrapper">
                                                            <div id="chartPvYeild" style="width: auto; height: 200px;">
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style="text-align: center;" width="10%">Date</th>
                                                    <th style="text-align: center;" width="10%">Pv-Yeild Energy ( KWh )
                                                    </th>
                                                    <th style="text-align: center;" width="10%">Pv-Self Use ( KWh )</th>
                                                    <th style="text-align: center;" width="10%">Export To Grid ( KWh )
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr *ngFor="let data of pv_yeild_energy">
                                                    <td style="text-align: center;">{{ data.date | date:'fullDate' }}
                                                    </td>
                                                    <td style="text-align: center;">{{ data.pv_yeild_energy.toFixed(2) |
                                                        currency: ' '
                                                        }}</td>
                                                    <td style="text-align: center;">{{ data.pv_self_use.toFixed(2) |
                                                        currency: ' ' }}
                                                    </td>
                                                    <td style="text-align: center;">{{ data.export_to_grid.toFixed(2) |
                                                        currency: ' ' }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>Total</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{ pv_yeild_use_sum.toFixed(2) | currency: ' ' }}</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{tabular_pv_sum.toFixed(2) | currency: ' '}}</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{tabular_exprot_sum.toFixed(2) | currency: ' '}}</h5>
                                                        </strong></td>
                                                </tr>

                                            </tbody>

                                        </table>
                                        <br>
                                        <br>
                                        <h5 style="color: darkred; width: 400px;"> Total Saving</h5>

                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style="text-align: center;" width="10%">Pv-Yeild Energy ( Rs )
                                                    </th>
                                                    <th style="text-align: center;" width="10%">Pv-Self Use ( Rs )</th>
                                                    <th style="text-align: center;" width="10%">Export To Grid ( Rs )
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <td
                                                        style="text-align: center; font-weight: 1000; font-size: large;">
                                                        {{ pv_yeild_rate | currency: ' ' }}</td>
                                                    <td
                                                        style="text-align: center; font-weight: 1000; font-size: large;">
                                                        {{ pv_rate | currency:' ' }}</td>
                                                    <td
                                                        style="text-align: center; font-weight: 1000; font-size: large;">
                                                        {{ export_rate | currency:' ' }}</td>
                                                </tr>

                                            </tbody>

                                        </table>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- row -->

        </ng-template>
    </ngb-tab>

    <ngb-tab *ngIf="tab_consumption_status" id="tab-consumption" title="Consumption">
        <ng-template ngbTabContent>

            <div class="row mt-4">

                <div class="col-lg-12 col-xl-12 stretch-card" id="tab_consumption_data">
                    <div class="d-grid row flex-grow">

                        <div class="col grid-margin stretch-card">
                            <div class="card" id="tab-table">
                                <div class="d-flex justify-content-between card-header align-items-center mb-md-3">
                                    <h4 class="card-title mb-0" style="color: red;">{{ month }} Consumption</h4>
                                    <div class="d-flex align-items-center justify-content-end">

                                        <button class="btn pr-0" type="button" data-toggle="collapse"
                                            data-target="#collapseExample" aria-expanded="false"
                                            aria-controls="collapseExample">
                                            <i class="feather-sliders"
                                                style="font-size: x-large; color: #ff0000;; font-weight: 500;"></i>
                                        </button>

                                        <div class="dropdown ml-2">
                                            <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i
                                                    class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                                <button [useExistingCss]="true" printSectionId="Table-print" ngxPrint
                                                    type="button" class="dropdown-item d-flex align-items-center"
                                                    name="Print">
                                                    <i class="feather feather-printer icon-sm mr-2"></i>
                                                    <span class="">Print</span>
                                                </button>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);"
                                                    (click)="data_param = 'tab_consumption'; exportAsCSV()">
                                                    <i class="icon-sm mr-2 feather feather-download"></i>
                                                    <span class="">Export as CSV</span>
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);" name="Pdf"
                                                    (click)="data_param = 'tab_consumption'; data_param_el = 'tab_consumption_data'; exportAsPDF($event)">
                                                    <i class="icon-sm mr-2 feather feather-file-text"></i>
                                                    <span class="">Export as PDF</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="collapse px-2" id="collapseExample">
                                    <div class="card-body overflow-auto">

                                        <div class="w-max-content header-right-inner-left d-flex">

                                            <div class="form-group mb-0 mr-2">
                                                <h6>Range:</h6>
                                            </div>

                                            <div class="form-group mb-0 mr-2">
                                                <input type="text" class="form-control"
                                                    (change)="onTabularDateSelection(tabular_selected)"
                                                    style="width: 325px; height: 36px; border:  1px solid rgb(204, 204, 204); border-radius: 5px 5px 5px 5px; color: red;"
                                                    ngxDaterangepickerMd [(ngModel)]="tabular_selected" startKey="start"
                                                    [timePicker]="true" [timePickerSeconds]="false"
                                                    [timePicker24Hour]="true" endKey="end" [showCustomRangeLabel]="true"
                                                    [alwaysShowCalendars]="true" [customRangeDirection]="true"
                                                    [locale]="{ displayFormat: 'DD MMM YYYY  HH:00', separator: '    To    ',  cancelLabel: 'Cancel', firstDay: 1}"
                                                    [showClearButton]="true" [showCancel]="true"
                                                    [showWeekNumbers]="true" [ranges]="ranges" [linkedCalendars]="true"
                                                    placeholder="Select Range">

                                            </div>

                                            <div class="form-group mb-0 mr-2 ml-2">
                                                <span>
                                                    <strong class="text-nowrap">Buying Rate ( From Grid )</strong>
                                                </span>
                                            </div>

                                            <div class="form-group mb-0 mr-4">
                                                <input type="text" style="width: 70px;" class="form-control mb-0"
                                                    [(ngModel)]="buy_from_grid" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Enter Unit Buy From Grid...">
                                            </div>

                                            <span class="divider mt-2"></span>

                                            <div class="form-group mb-0 mr-2 ml-4">
                                                <span>
                                                    <strong class="text-nowrap">Selling Rate ( To Grid )</strong>
                                                </span>
                                            </div>

                                            <div class="form-group mb-0 mr-4">
                                                <input type="text" style="width: 70px;" class="form-control"
                                                    [(ngModel)]="sale_to_grid" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Enter Unit Sale To Grid...">
                                            </div>

                                            <!--   -->
                                            <span *ngIf="show_gs_power" class="divider mt-2 ml-2"></span>

                                            <div *ngIf="show_gs_power"
                                                class="text-nowrap form-group mb-0 ml-4 mr-2 res-margin-left mt-2">
                                                <span>
                                                    <strong>Genset Unit Rate</strong>
                                                </span>
                                            </div>

                                            <div *ngIf="show_gs_power" class="form-group mb-0 mr-4">
                                                <input type="text" style="width: 70px;" class="form-control"
                                                    [(ngModel)]="gs_unit_rate" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Enter Genset Unit Rate...">
                                            </div>


                                            <div class="form-group mb-0 ml-2">
                                                <button class="btn theme-primary-btn" type="button"
                                                    (click)="calculateCost()" style="width: auto;">Calculate</button>
                                            </div>

                                            <div class="form-group mb-0 ml-2 mr-2">
                                                <button class="btn theme-primary-btn" type="button"
                                                    (click)="getGraphInterface('consumption_graph')"
                                                    style="width: 9em; padding-bottom: 2.1em;">Get Graphical
                                                    Interface</button>
                                            </div>

                                            <div class="form-group mb-0 ml-2">
                                                <button class="btn theme-primary-btn" type="button" (click)="onClick()"
                                                    style="width: auto;">View</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div id="Table-print" class="card-body">
                                    <header style="display: none;">
                                        <div class="row">

                                            <div class="col-sm">
                                                <img width="180" src="{{ main_logo }}">
                                            </div>

                                            <div class="col-sm d-flex justify-content-center">
                                                <h3>{{ nm_location }}</h3>
                                            </div>

                                            <div class="col-sm d-flex justify-content-end">
                                                <h4>{{ user?.company }}</h4>
                                            </div>
                                        </div>

                                        <div class="row" style="margin-top: -10px;">

                                            <div class="col-sm d-flex justify-content-end">
                                                <h6>{{ curr_date }}</h6>
                                            </div>
                                        </div>
                                    </header>
                                    <h4 id="card-title" class="card-title mb-0 text-left"
                                        style="display: none; color: red;">{{ month }} Consumption</h4>
                                    <div class="table-responsive text-nowrap">
                                        <div class="row" *ngIf="tab_consumption_graph">

                                            <div class="col-md-6 col-xl-12 col-lg-4 grid-margin stretch-card">
                                                <div class="card">
                                                    <div class="card-body">

                                                        <div class="flot-wrapper">
                                                            <div id="chartConsumption"
                                                                style="width: auto; height: 200px;"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style="text-align: center;" width="10%">Date</th>
                                                    <th style="text-align: center;" width="10%">Consumption ( KWh )</th>
                                                    <th style="text-align: center;" width="10%">Pv-Self Use ( KWh )</th>
                                                    <th style="text-align: center;" width="10%">Import From Grid ( KWh )
                                                    </th>
                                                    <th *ngIf="show_gs_power" style="text-align: center;" width="10%">
                                                        Genset ( KWh )
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr *ngFor="let data of consumption">
                                                    <td style="text-align: center;">{{ data.date | date:'fullDate' }}
                                                    </td>
                                                    <td style="text-align: center;">{{ data.consumption.toFixed(2) |
                                                        currency: ' ' }}
                                                    </td>
                                                    <td style="text-align: center;">{{ data.pv_self_use.toFixed(2) |
                                                        currency: ' ' }}
                                                    </td>
                                                    <td style="text-align: center;">{{ data.import_from_grid.toFixed(2)
                                                        | currency: ' '
                                                        }}</td>
                                                    <td *ngIf="show_gs_power" style="text-align: center;">{{
                                                        data.gs.toFixed(2) | currency: ' '
                                                        }}</td>
                                                </tr>

                                                <tr>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>Total</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{ consumption_sum.toFixed(2) | currency: ' ' }}</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{tabular_pv_sum.toFixed(2) | currency: ' '}}</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{tabular_import_sum.toFixed(2) | currency: ' '}}</h5>
                                                        </strong></td>
                                                    <td *ngIf="show_gs_power" style="text-align: center;"><strong>
                                                            <h5>{{tabular_gs_sum.toFixed(2) | currency: ' '}}</h5>
                                                        </strong></td>
                                                </tr>

                                            </tbody>

                                        </table>

                                        <br>
                                        <br>
                                        <h5 style="color: darkred; width: 400px;"> Total Consumption (Rs)</h5>
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style="text-align: center;" width="10%">Consumption ( Rs )</th>
                                                    <th style="text-align: center;" width="10%">Pv-Self Use ( Rs )</th>
                                                    <th style="text-align: center;" width="10%">Import To Grid ( Rs )
                                                    </th>
                                                    <th *ngIf="show_gs_power" style="text-align: center;" width="10%">
                                                        Genset ( Rs )
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <td
                                                        style="text-align: center; font-weight: 1000; font-size: large;">
                                                        {{ consumption_rate | currency: ' ' }}</td>
                                                    <td
                                                        style="text-align: center; font-weight: 1000; font-size: large;">
                                                        {{ pv_rate | currency:' ' }}</td>
                                                    <td
                                                        style="text-align: center; font-weight: 1000; font-size: large;">
                                                        {{ import_rate | currency:' ' }}</td>
                                                    <td *ngIf="show_gs_power"
                                                        style="text-align: center; font-weight: 1000; font-size: large;">
                                                        {{ gs_rate | currency:' ' }}</td>
                                                </tr>

                                            </tbody>

                                        </table>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- row -->

        </ng-template>
    </ngb-tab>

    <ngb-tab *ngIf="tab_saving_pv_knx_status" id="tab-saving-pv-knx" title="Saving-PV-KNX">
        <ng-template ngbTabContent>

            <div class="row mt-4">

                <div class="col-lg-12 col-xl-12 stretch-card" id="tab_saving_pv_knx">
                    <div class="d-grid row flex-grow">

                        <div class="col grid-margin stretch-card">
                            <div class="card" id="tab-table">
                                <div class="d-flex justify-content-between card-header align-items-center mb-md-3">
                                    <h4 class="card-title mb-0" style="color: red;">{{ month }} Saving-PV-KNX</h4>
                                    <h5 class="card-title mb-0">Shop Timing: {{sh_timing.open}} - {{sh_timing.close}}</h5>
                                    <div class="d-flex align-items-center justify-content-end">

                                        <button class="btn pr-0" type="button" data-toggle="collapse"
                                            data-target="#collapseExample" aria-expanded="false"
                                            aria-controls="collapseExample">
                                            <i class="feather-sliders"
                                                style="font-size: x-large; color: #ff0000;; font-weight: 500;"></i>
                                        </button>

                                        <div class="dropdown ml-2">
                                            <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i
                                                    class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                                <button [useExistingCss]="true" printSectionId="Table-print" ngxPrint
                                                    type="button" class="dropdown-item d-flex align-items-center"
                                                    name="Print">
                                                    <i class="feather feather-printer icon-sm mr-2"></i>
                                                    <span class="">Print</span>
                                                </button>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);"
                                                    (click)="data_param = 'tab_saving_pv_knx'; exportAsCSV()">
                                                    <i class="icon-sm mr-2 feather feather-download"></i>
                                                    <span class="">Export as CSV</span>
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);" name="Pdf"
                                                    (click)="data_param = 'tab_saving_pv_knx'; data_param_el = 'tab_saving_pv_knx'; exportAsPDF($event)">
                                                    <i class="icon-sm mr-2 feather feather-file-text"></i>
                                                    <span class="">Export as PDF</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="collapse px-2" id="collapseExample">
                                    <div class="card-body overflow-auto">

                                        <div class="w-max-content header-right-inner-left d-flex">

                                            <div class="form-group mb-0 mr-2">
                                                <h6>Range:</h6>
                                            </div>

                                            <div class="form-group mb-0 mr-2">
                                                <input type="text" class="form-control"
                                                    (change)="onTabularDateSelection(tabular_selected)"
                                                    style="width: 325px; height: 36px; border:  1px solid rgb(204, 204, 204); border-radius: 5px 5px 5px 5px; color: red;"
                                                    ngxDaterangepickerMd [(ngModel)]="tabular_selected" startKey="start"
                                                    [timePicker]="true" [timePickerSeconds]="false"
                                                    [timePicker24Hour]="true" endKey="end" [showCustomRangeLabel]="true"
                                                    [alwaysShowCalendars]="true" [customRangeDirection]="true"
                                                    [locale]="{ displayFormat: 'DD MMM YYYY  HH:00', separator: '    To    ',  cancelLabel: 'Cancel', firstDay: 1}"
                                                    [showClearButton]="true" [showCancel]="true"
                                                    [showWeekNumbers]="true" [ranges]="ranges" [linkedCalendars]="true"
                                                    placeholder="Select Range">

                                            </div>

                                            <div class="form-group mb-0 mr-2 ml-2">
                                                <span>
                                                    <strong class="text-nowrap">Buying Rate ( From Grid )</strong>
                                                </span>
                                            </div>

                                            <div class="form-group mb-0 mr-4">
                                                <input type="text" style="width: 70px;" class="form-control mb-0"
                                                    [(ngModel)]="buy_from_grid" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Enter Unit Buy From Grid...">
                                            </div>

                                            <span class="divider mt-2"></span>

                                            <div class="form-group mb-0 mr-2 ml-4">
                                                <span>
                                                    <strong class="text-nowrap">Selling Rate ( To Grid )</strong>
                                                </span>
                                            </div>

                                            <div class="form-group mb-0 mr-4">
                                                <input type="text" style="width: 70px;" class="form-control"
                                                    [(ngModel)]="sale_to_grid" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Enter Unit Sale To Grid...">
                                            </div>

                                            <!--   -->
                                            <span *ngIf="show_gs_power" class="divider mt-2 ml-2"></span>

                                            <div *ngIf="show_gs_power"
                                                class="text-nowrap form-group mb-0 ml-4 mr-2 res-margin-left mt-2">
                                                <span>
                                                    <strong>Genset Unit Rate</strong>
                                                </span>
                                            </div>

                                            <div *ngIf="show_gs_power" class="form-group mb-0 mr-4">
                                                <input type="text" style="width: 70px;" class="form-control"
                                                    [(ngModel)]="gs_unit_rate" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Enter Genset Unit Rate...">
                                            </div>


                                            <div class="form-group mb-0 ml-2">
                                                <button class="btn theme-primary-btn" type="button"
                                                    (click)="calculateCost()" style="width: auto;">Calculate</button>
                                            </div>

                                            <div class="form-group mb-0 ml-2 mr-2">
                                                <button class="btn theme-primary-btn" type="button"
                                                    (click)="getGraphInterface('consumption_graph')"
                                                    style="width: 9em; padding-bottom: 2.1em;">Get Graphical
                                                    Interface</button>
                                            </div>

                                            <div class="form-group mb-0 ml-2">
                                                <button class="btn theme-primary-btn" type="button" (click)="onClick()"
                                                    style="width: auto;">View</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div id="Table-print" class="card-body">
                                    <header style="display: none;">
                                        <div class="row">

                                            <div class="col-sm">
                                                <img width="180" src="{{ main_logo }}">
                                            </div>

                                            <div class="col-sm d-flex justify-content-center">
                                                <h3>{{ nm_location }}</h3>
                                            </div>

                                            <div class="col-sm d-flex justify-content-end">
                                                <h4>{{ user?.company }}</h4>
                                            </div>
                                        </div>

                                        <div class="row" style="margin-top: -10px;">

                                            <div class="col-sm d-flex justify-content-end">
                                                <h6>{{ curr_date }}</h6>
                                            </div>
                                        </div>
                                    </header>
                                    <h4 id="card-title" class="card-title mb-0 text-left"
                                        style="display: none; color: red;">{{ month }} Saving-PV-KNX</h4>
                                    <div class="table-responsive text-nowrap">
                                        <div class="row" *ngIf="tab_consumption_graph">

                                            <div class="col-md-6 col-xl-12 col-lg-4 grid-margin stretch-card">
                                                <div class="card">
                                                    <div class="card-body">

                                                        <div class="flot-wrapper">
                                                            <div id="chartConsumption"
                                                                style="width: auto; height: 200px;"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style="text-align: center;" width="10%">Date</th>
                                                    <th style="text-align: center;" width="10%">Import From Grid ( KWh )
                                                    </th>
                                                    <th style="text-align: center;" width="10%">Pv-Self Use ( KWh )</th>

                                                    <th *ngIf="show_gs_power" style="text-align: center;" width="10%">
                                                        Genset ( KWh )
                                                    </th>
                                                    <th style="text-align: center;" width="10%">Consumption ( KWh )</th>
                                                    <th style="text-align: center;" width="10%">Full Load ( KWh )</th>
                                                    <th style="text-align: center;" width="10%">Saving ( KWh )</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr *ngFor="let data of saving_pv_knx">
                                                    <td style="text-align: center;">{{ data.date | date:'fullDate' }}
                                                    </td>
                                                    <td style="text-align: center;">{{ data.import_from_grid.toFixed(2)
                                                        | currency: ' '
                                                        }}</td>

                                                    <td style="text-align: center;">{{ data.pv_self_use.toFixed(2) |
                                                        currency: ' ' }}
                                                    </td>
                                                    <td *ngIf="show_gs_power" style="text-align: center;">{{
                                                        data.gs.toFixed(2) | currency: ' '
                                                        }}</td>
                                                    <td style="text-align: center;">{{ data.consumption.toFixed(2) |
                                                        currency: ' ' }}
                                                    </td>
                                                    <td style="text-align: center;">{{ data.full_load |
                                                        currency: ' ' }}
                                                    </td>

                                                    <td style="text-align: center;">{{ data.saving |
                                                        currency: ' ' }}
                                                    </td>

                                                </tr>

                                                <tr>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>Total</h5>
                                                        </strong></td>

                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{saving_pv_knx_import_sum.toFixed(2) | currency: ' '}}</h5>
                                                        </strong></td>

                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{saving_pv_knx_pv_sum.toFixed(2) | currency: ' '}}</h5>
                                                        </strong></td>

                                                    <td *ngIf="show_gs_power" style="text-align: center;"><strong>
                                                            <h5>{{saving_pv_knx_gs_sum.toFixed(2) | currency: ' '}}</h5>
                                                        </strong></td>

                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{ saving_pv_knx_consumption_sum.toFixed(2) | currency: ' ' }}</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{ saving_pv_knx_full_load_sum | currency: ' ' }}</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{ saving_pv_knx_saving_sum.toFixed(2) | currency: ' ' }}</h5>
                                                        </strong></td>
                                                </tr>

                                            </tbody>

                                        </table>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- row -->

        </ng-template>
    </ngb-tab>

    <ngb-tab *ngIf="tab_energy_balance_status" id="tab-energy-balance" title="Energy Balance">
        <ng-template ngbTabContent>

            <div class="row mt-4">

                <div class="col-lg-12 col-xl-12 stretch-card" id="tab_energy_balance_data">
                    <div class="d-grid row flex-grow">

                        <div class="col grid-margin stretch-card">
                            <div class="card" id="tab-table">
                                <div class="d-flex justify-content-between card-header align-items-center mb-md-3">
                                    <h4 class="card-title mb-0" style="color: red;">{{ month }} Energy Balance</h4>
                                    <div class="d-flex align-items-center justify-content-end">

                                        <button class="btn pr-0" type="button" data-toggle="collapse"
                                            data-target="#collapseExample" aria-expanded="false"
                                            aria-controls="collapseExample">
                                            <i class="feather-sliders"
                                                style="font-size: x-large; color: #ff0000;; font-weight: 500;"></i>
                                        </button>

                                        <div class="dropdown ml-2">
                                            <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i
                                                    class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                                <button [useExistingCss]="true" printSectionId="Table-print" ngxPrint
                                                    type="button" class="dropdown-item d-flex align-items-center"
                                                    name="Print">
                                                    <i class="feather feather-printer icon-sm mr-2"></i>
                                                    <span class="">Print</span>
                                                </button>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);"
                                                    (click)="data_param = 'tab_billing_and_income'; exportAsCSV()">
                                                    <i class="icon-sm mr-2 feather feather-download"></i>
                                                    <span class="">Export as CSV</span>
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);" name="Pdf"
                                                    (click)="data_param = 'tab_energy_balance'; data_param_el = 'tab_energy_balance_data'; exportAsPDF($event)">
                                                    <i class="icon-sm mr-2 feather feather-file-text"></i>
                                                    <span class="">Export as PDF</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="collapse px-2" id="collapseExample">
                                    <div class="card-body overflow-auto">
                                        <div class="w-max-content header-right-inner-left d-flex">

                                            <div class="form-group mb-0 mr-2">
                                                <h6>Range:</h6>
                                            </div>

                                            <div class="form-group mb-0 mr-2">
                                                <input type="text" class="form-control"
                                                    (change)="onTabularDateSelection(tabular_selected)"
                                                    style="width: 325px; height: 36px; border:  1px solid rgb(204, 204, 204); border-radius: 5px 5px 5px 5px; color: red;"
                                                    ngxDaterangepickerMd [(ngModel)]="tabular_selected" startKey="start"
                                                    [timePicker]="true" [timePickerSeconds]="false"
                                                    [timePicker24Hour]="true" endKey="end" [showCustomRangeLabel]="true"
                                                    [alwaysShowCalendars]="true" [customRangeDirection]="true"
                                                    [locale]="{ displayFormat: 'DD MMM YYYY  HH:00', separator: '    To    ',  cancelLabel: 'Cancel', firstDay: 1}"
                                                    [showClearButton]="true" [showCancel]="true"
                                                    [showWeekNumbers]="true" [ranges]="ranges" [linkedCalendars]="true"
                                                    placeholder="Select Range">

                                            </div>

                                            <div class="form-group mb-0 mr-2 ml-2">
                                                <span>
                                                    <strong class="text-nowrap">Buying Rate ( From Grid )</strong>
                                                </span>
                                            </div>

                                            <div class="form-group mb-0 mr-4">
                                                <input type="text" style="width: 70px;" class="form-control mb-0"
                                                    [(ngModel)]="buy_from_grid" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Enter Unit Buy From Grid...">
                                            </div>

                                            <span class="divider mt-2"></span>

                                            <div class="form-group mb-0 mr-2 ml-4">
                                                <span>
                                                    <strong class="text-nowrap">Selling Rate ( To Grid )</strong>
                                                </span>
                                            </div>

                                            <div class="form-group mb-0 mr-4">
                                                <input type="text" style="width: 70px;" class="form-control"
                                                    [(ngModel)]="sale_to_grid" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Enter Unit Sale To Grid...">
                                            </div>

                                            <div class="form-group mb-0 ml-2">
                                                <button class="btn theme-primary-btn" type="button"
                                                    (click)="calculateCost()" style="width: auto;">Calculate</button>
                                            </div>

                                            <div class="form-group mb-0 ml-2 mr-2">
                                                <button class="btn theme-primary-btn" type="button"
                                                    (click)="getGraphInterface('energy_balance_graph')"
                                                    style="width: 9em; padding-bottom: 2.1em;">Get Graphical
                                                    Interface</button>
                                            </div>

                                            <div class="form-group mb-0 ml-2">
                                                <button class="btn theme-primary-btn" type="button" (click)="onClick()"
                                                    style="width: auto;">View</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div id="Table-print" class="card-body">
                                    <header style="display: none;">
                                        <div class="row">

                                            <div class="col-sm">
                                                <img width="180" src="{{ main_logo }}">
                                            </div>

                                            <div class="col-sm d-flex justify-content-center">
                                                <h3>{{ nm_location }}</h3>
                                            </div>

                                            <div class="col-sm d-flex justify-content-end">
                                                <h4>{{ user?.company }}</h4>
                                            </div>
                                        </div>

                                        <div class="row" style="margin-top: -10px;">

                                            <div class="col-sm d-flex justify-content-end">
                                                <h6>{{ curr_date }}</h6>
                                            </div>
                                        </div>
                                    </header>
                                    <h4 id="card-title" class="card-title mb-0 text-left"
                                        style="display: none; color: red;">{{ month }} Energy Balance</h4>
                                    <div class="table-responsive text-nowrap">
                                        <div class="row" *ngIf="tab_energy_balance_graph">

                                            <div class="col-md-6 col-xl-12 col-lg-4 grid-margin stretch-card">
                                                <div class="card">
                                                    <div class="card-body">

                                                        <div class="flot-wrapper">
                                                            <div id="chartEnergyBalance"
                                                                style="width: auto; height: 200px;"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style="text-align: center;" width="10%">Date</th>
                                                    <th style="text-align: center;" width="10%">Energy Balance ( KWh )
                                                    </th>
                                                    <th style="text-align: center;" width="10%">Import From Grid ( KWh )
                                                    </th>
                                                    <th style="text-align: center;" width="10%">Export To Grid ( KWh )
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr *ngFor="let data of energy_balance">
                                                    <td style="text-align: center;">{{ data.date | date:'fullDate' }}
                                                    </td>
                                                    <td style="text-align: center;">{{ data.energy_balance.toFixed(2) |
                                                        currency: ' ' }}
                                                    </td>
                                                    <td style="text-align: center;">{{ data.import_from_grid.toFixed(2)
                                                        | currency: ' '
                                                        }}</td>
                                                    <td style="text-align: center;">{{ data.export_to_grid.toFixed(2) |
                                                        currency: ' ' }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>Total</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{ energy_balance_sum.toFixed(2) | currency: ' ' }}</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{tabular_import_sum.toFixed(2) | currency: ' '}}</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{tabular_exprot_sum.toFixed(2) | currency: ' '}}</h5>
                                                        </strong></td>
                                                </tr>

                                            </tbody>

                                        </table>
                                        <br>
                                        <br>
                                        <h5 style="color: darkred; width: 400px;"> Energy Balance (Rs) </h5>

                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style="text-align: center;" width="10%">Energy Balance ( Rs )
                                                    </th>
                                                    <th style="text-align: center;" width="10%">Import From Grid ( Rs )
                                                    </th>
                                                    <th style="text-align: center;" width="10%">Export To Grid ( Rs )
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <td
                                                        style="text-align: center; font-weight: 1000; font-size: large;">
                                                        {{ energy_balance_rate | currency: ' ' }}</td>
                                                    <td
                                                        style="text-align: center; font-weight: 1000; font-size: large;">
                                                        {{ import_rate | currency:' ' }}</td>
                                                    <td
                                                        style="text-align: center; font-weight: 1000; font-size: large;">
                                                        {{ export_rate | currency:' ' }}</td>
                                                </tr>

                                            </tbody>

                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- row -->

        </ng-template>
    </ngb-tab>

    <ngb-tab *ngIf="tab_plant_performance_by_table_status && tab_solar_perfomance_status"
        id="tab-plant-performance-by-table" title="Plant Performance By Table">
        <ng-template ngbTabContent>

            <div class="row mt-4">

                <div class="col-lg-12 col-xl-12 stretch-card" id="tab_plant_performance_by_table_data">
                    <div class="d-grid row flex-grow">

                        <div class="col grid-margin stretch-card">
                            <div class="card" id="tab-table">
                                <div class="d-flex justify-content-between card-header align-items-center mb-md-3">
                                    <h4 class="card-title mb-0" style="color: red;">{{ month }} Plant Performance Table
                                    </h4>
                                    <div class="d-flex align-items-center justify-content-end">

                                        <button class="btn pr-0" type="button" data-toggle="collapse"
                                            data-target="#collapseExample" aria-expanded="false"
                                            aria-controls="collapseExample">
                                            <i class="feather-sliders"
                                                style="font-size: x-large; color: #ff0000;; font-weight: 500;"></i>
                                        </button>

                                        <div class="dropdown ml-2">
                                            <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i
                                                    class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                                <button [useExistingCss]="true" printSectionId="Table-print" ngxPrint
                                                    type="button" class="dropdown-item d-flex align-items-center"
                                                    name="Print">
                                                    <i class="feather feather-printer icon-sm mr-2"></i>
                                                    <span class="">Print</span>
                                                </button>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);"
                                                    (click)="data_param = 'tab_plant_performance_by_table'; exportAsCSV()">
                                                    <i class="icon-sm mr-2 feather feather-download"></i>
                                                    <span class="">Export as CSV</span>
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);" name="Pdf"
                                                    (click)="data_param = 'tab_plant_performance_by_table'; data_param_el = 'tab_plant_performance_by_table_data'; exportAsPDF($event)">
                                                    <i class="icon-sm mr-2 feather feather-file-text"></i>
                                                    <span class="">Export as PDF</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="collapse px-2" id="collapseExample">
                                    <div class="card-body overflow-auto">
                                        <div class="w-max-content header-right-inner-left d-flex">

                                            <div class="form-group mb-0 mr-2">
                                                <h6>Range:</h6>
                                            </div>

                                            <div class="form-group mb-0 mr-2">
                                                <input type="text" class="form-control"
                                                    (change)="onTabularDateSelection(tabular_selected)"
                                                    style="width: 325px; height: 36px; border:  1px solid rgb(204, 204, 204); border-radius: 5px 5px 5px 5px; color: red;"
                                                    ngxDaterangepickerMd [(ngModel)]="tabular_selected" startKey="start"
                                                    [timePicker]="true" [timePickerSeconds]="false"
                                                    [timePicker24Hour]="true" endKey="end" [showCustomRangeLabel]="true"
                                                    [alwaysShowCalendars]="true" [customRangeDirection]="true"
                                                    [locale]="{ displayFormat: 'DD MMM YYYY  HH:00', separator: '    To    ',  cancelLabel: 'Cancel', firstDay: 1}"
                                                    [showClearButton]="true" [showCancel]="true"
                                                    [showWeekNumbers]="true" [ranges]="ranges" [linkedCalendars]="true"
                                                    placeholder="Select Range">

                                            </div>

                                            <div class="form-group mb-0 ml-2">
                                                <button class="btn theme-primary-btn" type="button" (click)="onClick()"
                                                    style="width: auto;">View</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div id="Table-print" class="card-body">
                                    <header style="display: none;">
                                        <div class="row">

                                            <div class="col-sm">
                                                <img width="180" src="{{ main_logo }}">
                                            </div>

                                            <div class="col-sm d-flex justify-content-center">
                                                <h3>{{ nm_location }}</h3>
                                            </div>

                                            <div class="col-sm d-flex justify-content-end">
                                                <h4>{{ user?.company }}</h4>
                                            </div>
                                        </div>

                                        <div class="row" style="margin-top: -10px;">

                                            <div class="col-sm d-flex justify-content-end">
                                                <h6>{{ curr_date }}</h6>
                                            </div>
                                        </div>
                                    </header>
                                    <h4 id="card-title" class="card-title mb-0 text-left"
                                        style="display: none; color: red;">{{ month }} Plant Performance Table</h4>
                                    <div class="table-responsive text-nowrap">

                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style="text-align: center;" width="auto">Date</th>
                                                    <th style="text-align: center;" width="auto">Installed Capicity
                                                        (Kwp)</th>
                                                    <th style="text-align: center;" width="auto">Predictic Generation
                                                        (Kwh)</th>
                                                    <th style="text-align: center;" width="auto">Pv Generation (Kwh)
                                                    </th>
                                                    <th style="text-align: center;" width="auto">Carbon Saving (Kg)</th>
                                                    <th style="text-align: center;" width="auto">Difference in Kwh</th>
                                                    <th style="text-align: center;" width="auto">CUF (%)</th>
                                                    <th style="text-align: center;" width="auto">Yeild</th>
                                                    <th style="text-align: center;" width="auto">% of Actual Generation
                                                    </th>
                                                    <th style="text-align: center;" width="auto">Performance Ratio (By
                                                        Formula)</th>
                                                    <th style="text-align: center;" width="auto">Plant Loading Factor
                                                        (%)</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr *ngFor="let data of solar_performance">
                                                    <td style="text-align: center;">{{ data.date | date:'fullDate' }}
                                                    </td>
                                                    <td style="text-align: center;">{{
                                                        data.installed_capicity.toFixed(1) | currency: ' ' }} Kwp</td>
                                                    <td style="text-align: center;">{{
                                                        data.predictic_generation.toFixed(1) | currency: ' ' }} Kwh</td>
                                                    <td style="text-align: center;">{{ data.pv_generation.toFixed(1) |
                                                        currency: ' ' }} Kwh</td>
                                                    <td style="text-align: center;">{{ data.carbon_saving.toFixed(1) |
                                                        currency: ' ' }} Kg</td>
                                                    <td style="text-align: center;">{{ data.differnce.toFixed(1) |
                                                        currency: ' ' }} Kwh</td>
                                                    <td style="text-align: center;">{{ data.cuf.toFixed(1) | currency: '
                                                        ' }}%</td>
                                                    <td style="text-align: center;">{{ data.yeild.toFixed(1) | currency:
                                                        ' ' }}</td>
                                                    <td style="text-align: center;">{{ data.actual_generation.toFixed(1)
                                                        | currency: ' ' }}%</td>
                                                    <td style="text-align: center;">{{ data.performance_ratio.toFixed(1)
                                                        | currency: ' ' }}</td>
                                                    <td style="text-align: center;">{{
                                                        data.plant_loading_factor.toFixed(1) | currency: ' ' }}%</td>
                                                </tr>

                                                <tr>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>Total</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{ installed_capicity.toFixed(2) | currency: ' ' }} Kwp
                                                            </h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{ predictic_generation_avg.toFixed(2) | currency: ' '
                                                                }} Kwh</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{ pv_yeild_use_sum.toFixed(2) | currency: ' ' }} Kwh
                                                            </h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{ carbon_saving_sum.toFixed(2) | currency: ' ' }} Kg
                                                            </h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{ difference_sum.toFixed(2) | currency: ' ' }} Kwh</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{ cuf_avg.toFixed(2) | currency: ' ' }}%</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{ yeild_avg.toFixed(2) | currency: ' ' }}</h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{ actual_generation_avg.toFixed(2) | currency: ' ' }}%
                                                            </h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{ performance_ratio_avg.toFixed(2) | currency: ' ' }}
                                                            </h5>
                                                        </strong></td>
                                                    <td style="text-align: center;"><strong>
                                                            <h5>{{ plant_loading_factor_avg.toFixed(2) | currency: ' '
                                                                }}%</h5>
                                                        </strong></td>
                                                </tr>
                                            </tbody>

                                        </table>

                                        <br>
                                        <br>
                                        <h5 style="color:darkred; width: 400px;"> System Fixed Parameter </h5>

                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style="text-align: center;" width="10%">Installed Capicity (Kwp)
                                                    </th>
                                                    <th style="text-align: center;" width="10%">Predictic Generation
                                                        (Kwh / day)</th>
                                                    <th style="text-align: center;" width="10%">Inverter Make</th>
                                                    <th style="text-align: center;" width="10%">Panel Make</th>
                                                    <th style="text-align: center;" width="10%">Plant Location</th>
                                                    <th style="text-align: center;" width="10%">Solar Insolation (By
                                                        Solar Map)</th>
                                                    <th style="text-align: center;" width="10%">Solar Irradiance (By
                                                        Sensor)</th>
                                                    <th style="text-align: center;" width="10%">Average Sun hour/ day
                                                    </th>
                                                    <th style="text-align: center;" width="10%">Carbon Factor (Kg/Kwh)
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <td
                                                        style="text-align: center; font-weight: 1000; font-size: large;">
                                                        {{ installed_capicity | currency: ' ' }} Kwp</td>
                                                    <td
                                                        style="text-align: center; font-weight: 1000; font-size: large;">
                                                        {{ predictic_generation | currency: ' ' }} Kwh</td>
                                                    <td
                                                        style="text-align: center; font-weight: 1000; font-size: large;">
                                                        {{ inverter_make }}</td>
                                                    <td
                                                        style="text-align: center; font-weight: 1000; font-size: large;">
                                                        {{ panel_make }}</td>
                                                    <td
                                                        style="text-align: center; font-weight: 1000; font-size: large;">
                                                        {{ location }}</td>
                                                    <td
                                                        style="text-align: center; font-weight: 1000; font-size: large;">
                                                        {{ solar_insolation }}</td>
                                                    <td
                                                        style="text-align: center; font-weight: 1000; font-size: large;">
                                                        {{ solar_irradiance }}</td>
                                                    <td
                                                        style="text-align: center; font-weight: 1000; font-size: large;">
                                                        {{ average_sun_perday }} hr</td>
                                                    <td
                                                        style="text-align: center; font-weight: 1000; font-size: large;">
                                                        {{ carbon_factor }} kg / Kwh</td>
                                                </tr>

                                            </tbody>

                                        </table>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- row -->

        </ng-template>
    </ngb-tab>

    <ngb-tab *ngIf="tab_plant_performance_by_graph_status && tab_solar_perfomance_status"
        id="tab-plant-performance-by-graph" title="Plant Performance By Graph">
        <ng-template ngbTabContent>

            <div class="row mt-4">

                <div class="col-md-12 grid-margin stretch-card" id="tab_plant_performance_by_graph_data">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="pv-plant-graph-th card-title mb-0 "> Predictic Generation (Kwh) VS Pv Generation
                                (Kwh)</h6>

                            <div class="header-right d-flex align-items-center">

                                <button class="btn pr-0" type="button" data-toggle="collapse"
                                    data-target="#collapseExample" aria-expanded="false"
                                    aria-controls="collapseExample">
                                    <i class="feather-sliders"
                                        style="font-size: x-large; color: #ff0000;; font-weight: 500;"></i>
                                </button>

                                <span class="divider"></span>

                                <i class="icon-sm card-header-icon ml-4 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <button [useExistingCss]="true" printSectionId="Table-print" ngxPrint
                                            type="button" class="dropdown-item d-flex align-items-center" name="Print">
                                            <i class="feather feather-printer icon-sm mr-2"></i>
                                            <span class="">Print</span>
                                        </button>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'pv'; exportAsCSV()">
                                            <i class="icon-sm mr-2 feather feather-download"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf"
                                            (click)="data_param = 'pv'; data_param_el = 'tab_plant_performance_by_graph_data'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-file-text"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="collapse px-2" id="collapseExample">
                            <div class="card-body overflow-auto">
                                <div class="w-max-content header-right-inner-left d-flex">

                                    <div class="form-group mb-0 mr-2">
                                        <h6>Range:</h6>
                                    </div>

                                    <div class="form-group mb-0 mr-2">
                                        <input type="text" class="form-control"
                                            (change)="onTabularDateSelection(tabular_selected)"
                                            style="width: 325px; height: 36px; border:  1px solid rgb(204, 204, 204); border-radius: 5px 5px 5px 5px; color: red;"
                                            ngxDaterangepickerMd [(ngModel)]="tabular_selected" startKey="start"
                                            [timePicker]="true" [timePickerSeconds]="false" [timePicker24Hour]="true"
                                            endKey="end" [showCustomRangeLabel]="true" [alwaysShowCalendars]="true"
                                            [customRangeDirection]="true"
                                            [locale]="{ displayFormat: 'DD MMM YYYY  HH:00', separator: '    To    ',  cancelLabel: 'Cancel', firstDay: 1}"
                                            [showClearButton]="true" [showCancel]="true" [showWeekNumbers]="true"
                                            [ranges]="ranges" [linkedCalendars]="true" placeholder="Select Range">

                                    </div>

                                    <div class="form-group mb-0 ml-2">
                                        <button class="btn theme-primary-btn" type="button" (click)="onClick()"
                                            style="width: auto;">View</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="card-body">
                            <div class="flot-wrapper">
                                <div id="chartPvGeneration" style="width: 100%; height: 450px"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 grid-margin stretch-card" id="carbon_saving_data">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="card-title mb-0 ">Carbon Saving</h6>
                            <div class="header-right d-flex align-items-center">

                                <i class="icon-sm card-header-icon ml-2 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Print"
                                            (click)="data_param = 'carbon_saving'; data_param_el = 'carbon_saving_data'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-printer"></i>
                                            <span class="">Print</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'carbon_saving'; exportAsCSV()">
                                            <i class="icon-sm mr-2 feather feather-download"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf"
                                            (click)="data_param = 'carbon_saving'; data_param_el = 'carbon_saving_data'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-file-text"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="card-body">
                            <div class="flot-wrapper">
                                <div id="chartCarbonSaving" style="width: 100%; height: 450px"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 grid-margin stretch-card" id="cuf_data">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="card-title mb-0 ">CUF</h6>
                            <div class="header-right d-flex align-items-center">

                                <!-- <div class="form-group mb-0 mr-4">
                                    <input type="text" [(ngModel)]="graph_month" autocomplete="off" class="form-control"
                                        disabled>
                                </div>

                                <span class="divider"></span> -->

                                <i class="icon-sm card-header-icon ml-2 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Print"
                                            (click)="data_param = 'cuf'; data_param_el = 'cuf_data'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-printer"></i>
                                            <span class="">Print</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'cuf'; exportAsCSV()">
                                            <i class="icon-sm mr-2 feather feather-download"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf"
                                            (click)="data_param = 'cuf'; data_param_el = 'cuf_data'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-file-text"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="card-body">
                            <div class="flot-wrapper">
                                <div id="chartCUF" style="width: 100%; height: 450px"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 grid-margin stretch-card" id="yeild_data">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="card-title mb-0 ">Yeild</h6>
                            <div class="header-right d-flex align-items-center">

                                <div class="form-group mb-0 mr-4">
                                    <input type="text" [(ngModel)]="graph_month" autocomplete="off" class="form-control"
                                        disabled>
                                </div>

                                <span class="divider"></span>

                                <i class="icon-sm card-header-icon ml-2 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Print"
                                            (click)="data_param = 'yeild'; data_param_el = 'yeild_data'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-printer"></i>
                                            <span class="">Print</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'yeild'; exportAsCSV()">
                                            <i class="icon-sm mr-2 feather feather-download"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf"
                                            (click)="data_param = 'yeild'; data_param_el = 'yeild_data'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-file-text"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="card-body">
                            <div class="flot-wrapper">
                                <div id="chartYeild" style="width: 100%; height: 450px"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 grid-margin stretch-card" id="performance_ratio_data">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="card-title mb-0 ">Performance Ratio</h6>
                            <div class="header-right d-flex align-items-center">

                                <div class="form-group mb-0 mr-4">
                                    <input type="text" [(ngModel)]="graph_month" autocomplete="off" class="form-control"
                                        disabled>
                                </div>

                                <span class="divider"></span>

                                <i class="icon-sm card-header-icon ml-2 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Print"
                                            (click)="data_param = 'performance_ratio'; data_param_el = 'performance_ratio_data'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-printer"></i>
                                            <span class="">Print</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'performance_ratio'; exportAsCSV()">
                                            <i class="icon-sm mr-2 feather feather-download"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf"
                                            (click)="data_param = 'performance_ratio'; data_param_el = 'performance_ratio_data'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-file-text"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="card-body">
                            <div class="flot-wrapper">
                                <div id="chartPerformanceRatio" style="width: 100%; height: 450px"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 grid-margin stretch-card" id="plant_load_factor_data">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="card-title mb-0 ">Plant Loading Factor</h6>
                            <div class="header-right d-flex align-items-center">

                                <div class="form-group mb-0 mr-4">
                                    <input type="text" [(ngModel)]="graph_month" autocomplete="off" class="form-control"
                                        disabled>
                                </div>

                                <span class="divider"></span>

                                <i class="icon-sm card-header-icon ml-2 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Print"
                                            (click)="data_param = 'plant_load_factor'; data_param_el = 'plant_load_factor_data'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-printer"></i>
                                            <span class="">Print</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'plant_load_factor'; exportAsCSV()">
                                            <i class="icon-sm mr-2 feather feather-download"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf"
                                            (click)="data_param = 'plant_load_factor'; data_param_el = 'plant_load_factor_data'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-file-text"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="card-body">
                            <div class="flot-wrapper">
                                <div id="chartPlantLoadingFactor" style="width: 100%; height: 450px"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </ng-template>
    </ngb-tab>

    <ngb-tab *ngIf="tab_basis_of_plant_performance_calculation_status && tab_solar_perfomance_status"
        id="tab-basis-of-plant-performance-calculation" title="Basis of Plant Performance Calculation">
        <ng-template ngbTabContent>

            <div class="row mt-4">

                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h3 class="card-title mb-0 "> Basis of Plant Performance Calculation</h3>
                        </div>
                        <div class="card-body">
                            <h5 style="color: red; font-weight: bolder; font-size: 24px; margin-top: 40px;">1) Capacity
                                Utilization Factor (CFU)</h5>
                            <a href="http://www.solarmango.com/ask/2015/10/20/what-is-cuf-and-how-is-it-relevant-to-solar-farms/"
                                style="font-size: 18px; margin-left: 30px;">
                                http://www.solarmango.com/ask/2015/10/20/what-is-cuf-and-how-is-it-relevant-to-solar-farms/
                            </a>

                            <li style="font-size: 18px; margin-left: 30px;">Capacity Utilization Factor (CUF%) = Energy
                                measured (kWh) / (365*24*installed capacity of the plant)-for Year.</li>
                            <li style="font-size: 18px; margin-left: 30px;">Capacity Utilization Factor (CUF%) = Energy
                                measured (kWh) / (31*24*installed capacity of the plant)-for month.</li>
                            <li style="font-size: 18px; margin-left: 30px;">Capacity Utilization Factor (CUF%) = Energy
                                measured (kWh) / (1*24*installed capacity of the plant)-for Day.</li>

                            <p style="font-size: 18px; margin-left: 30px;">For further details you can visit,</p>
                            <a href="https://www.solarmango.com/ask/2015/10/20/what-is-cuf-and-how-is-it-relevant-to-solar-farms/"
                                style="font-size: 18px; margin-left: 30px;">
                                www.solarmango.com/ask/2015/10/20/what-is-cuf-and-how-is-it-relevant-to-solar-farms/</a>
                            <h5 style="color: red; font-weight: bolder; font-size: 24px; margin-top: 40px;">2) Yield
                            </h5>
                            <p style="font-size: 18px; margin-left: 30px;">How do you calculate the specific yield of a
                                solar panel?
                                However, due to different solar module design and efficiencies, it is easier to evaluate
                                the performance (and hence their capability) of various solar modules by determining its
                                specific energy yield (kWh/kWp) (i.e. by dividing the absolute yield (kWh) to its power
                                output PSTC (kWp)).</p>

                            <h5 style="color: red; font-weight: bolder; font-size: 24px; margin-top: 40px;">3) PR
                                (Energy Based) Calculation</h5>

                            <a href="https://trackso.in/knowledge-base/performance-ratio-calculation-trackso/"
                                style="font-size: 18px; margin-left: 30px;">
                                https://trackso.in/knowledge-base/performance-ratio-calculation-trackso/</a>
                            <p style="font-size: 18px; margin-left: 30px; color: blue;">
                                Energy Based PR
                                Performance Ration= Specific Yield * 100/ Solar Insolation.</p>
                            <p style="font-size: 18px; margin-left: 30px;">
                                How much solar radiation does Pakistan receive?
                                Pakistan is a high insolation country. It receives about 1KW of solar energy for square
                                meter of its landmass for 6- 7 hours on the average per day. The number of sunshine
                                hours amount almost to 3000-3300 per year. The weather is most favorable for the
                                utilization of solar energy.</p>
                            <img id="pakistan-map" src="../../assets/images/heat map.png"
                                style="width: auto;height: auto; margin-left: 400px; margin-top: 40px;">
                            <p style="font-size: 18px; margin-left: 30px; margin-top: 40px;">From Other Source</p>
                            <a href="https://www.researchgate.net/figure/Solar-Radiation-map-of-Pakistan_fig1_268150199"
                                style="font-size: 18px; margin-left: 30px;">https://www.researchgate.net/figure/Solar-Radiation-map-of-Pakistan_fig1_268150199</a>
                            <p style="font-size: 18px; margin-left: 30px;">Pakistan has an average solar global
                                insolation of 5-7 KWh/m 2 /day.
                                The annual average mean daily solar irradiation in Lahore ranges from 5.1 kWh/msq. to
                                5.4 kWh/ msq which is also promising for solar energy utilization. </p>
                            <img id="pakistan-map" src="../../assets/images/heat map 2.png"
                                style="width: auto;height: auto; margin-left: 400px; margin-top: 40px;">
                            <h5 style="color: red; font-weight: bolder; font-size: 24px; margin-top: 40px;">4) Plant
                                Loading Factor</h5>
                            <p style="font-size: 18px; margin-left: 30px; margin-bottom: 20px;">
                                It is the ratio of actual output produced from the solar power plant over the year to
                                the DC plant capacity multiplied by no. of hours in the year. In other words, PLF
                                indicates how well the DC system of plants is utilized in a particular year.</p>

                            <p style="font-size: 18px; margin-left: 30px; color: blue; margin-bottom: 20px;"> PLF =
                                Total Unit in a year
                                (Actual Measured*365)/Installed Capacity*365*24)</p>
                            <p style="font-size: 18px; margin-left: 30px;"> Other Definition </p>
                            <p style="font-size: 18px; margin-left: 30px;"> Plant Loading Factor (PLF) is the ratio of
                                average energy generated by the plant to the
                                maximum power that could have been generated for the given period of time. It normally
                                lies between 18-22% </p>
                            <p style="font-size: 18px; margin-left: 30px;">Reference: <a
                                    href="https://www.youtube.com/watch?v=enbWqUAWPAs&t=202s">https://www.youtube.com/watch?v=enbWqUAWPAs&t=202s</a>
                            </p>


                            <h5 style="color: red; font-weight: bolder; font-size: 24px; margin-top: 40px;"> 5) Carbon
                                Saving</h5>
                            <p style="font-size: 18px; margin-left: 30px;">Every 1 kWh of electricity
                                produced here 0.383 Kg of carbon.
                                For reference please contact:</p>
                            <a href="https://www.bostonsolar.us/solar-blog-resource-center/blog/how-much-co2-do-solar-panels-save/"
                                style="font-size: 18px; margin-left: 30px;">
                                https://www.bostonsolar.us/solar-blog-resource-center/blog/how-much-co2-do-solar-panels-save/</a>
                            <h5 style="color: red; font-weight: bolder; font-size: 24px; margin-top: 40px;">Note: Output
                                may vary with multiple varying factors such as shading, weather, temperature, Grid
                                unavailability, Voltage fluctuations, mishandling of equipment or lack of maintenance.
                            </h5>
                        </div>
                    </div>
                </div>

            </div>

        </ng-template>
    </ngb-tab>

</ngb-tabset>