<nav class="navbar">
    <a href="#" class="sidebar-toggler" (click)="toggleClass()">
        <i class="feather feather-menu"></i>
    </a>
    <div class="navbar-content">
         <div class="d-flex align-items-center">
            <h4>{{ user?.company }} {{ header_title | titlecase }} {{ separator }}</h4>

            <select id="nm-select"  (change)="onChangeNmSites($event)" class="h-auto border-0 rounded w-max-content pl-0" style="font-weight: 600; color: #073C5C; font-size: 1.25rem;" aria-label="Default select example">
                <option *ngFor="let site of nm_sites" value="{{ site.key }}">{{ site.value }}</option>
              </select>

        </div>
        <ul class="navbar-nav">
            <!-- <button (click)="myFunction()">View</button> -->

            <li class="nav-item mr-4">
                <span class="device-status" [ngClass]="device_class"></span>
                <span class="mr-2 device_location">{{ device_location }}</span>
                <!-- <p class="mr-2">Online</p> --><span class="divider"></span>
                <p class="ml-2 last_received">{{ last_received }}</p>
            </li>
            <li class="nav-item dropdown nav-profile">
                <a class="nav-link dropdown-toggle align-self-center" href="#" id="profileDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <img src="{{ profile_logo }}" alt="profile">
                </a>
                <div class="dropdown-menu" aria-labelledby="profileDropdown">
                    <div class="dropdown-header d-flex flex-column align-items-center">
                        <div class="figure mb-3">
                            <img src="{{ profile_logo }}" alt="">
                        </div>
                        <div class="info text-center">
                            <p class="name font-weight-bold mb-0">{{ user?.first_name }} {{ user?.last_name }}</p>
                            <p class="email text-muted mb-3">{{ user?.email }}</p>
                        </div>
                    </div>
                    <div class="dropdown-body">
                        <ul class="profile-nav p-0 pt-3">
                            <li class="nav-item">
                                <a href="javascript:void(0);" [routerLink]="profile_url" class="nav-link">
                                    <i class="feather feather-user mr-2"></i>
                                    <span>{{ profile }}</span>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a href="javascript:;" class="nav-link" (click)="openVerticallyCentered(content)">
                                    <i class="feather feather-log-out mr-2"></i>
                                    <span>{{ logout }}</span>
                                </a>
                            </li>
                            
                            <!-- Logout modal -->
                            <ng-template #content let-modal>

                                <div class="modal-header">
                                    <h4 class="modal-title">Logout</h4>
                                    <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')"><span
                                            aria-hidden="true">&times;</span></button>
                                </div>

                                <div class="modal-body">
                                    <p>Are you sure you wat to logout?</p>
                                </div>

                                <div class="modal-footer">
                                    <button type="button" class="btn btn-light"
                                        (click)="modal.close('Close click')">Close</button>
                                    <button type="button" class="btn btn-danger" (click)="logOut()">Ok</button>
                                </div>

                            </ng-template>

                        </ul>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</nav>