import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../shared/services/dashboard.service';
import { DataSharedService } from '../shared/services/data-shared.service';

@Component({
  selector: 'app-billing-summary',
  templateUrl: './billing-summary.component.html',
  styleUrls: ['./billing-summary.component.scss']
})
export class BillingSummaryComponent implements OnInit {

  //string
  public action: string = '';
  public monthly_report_type: string = 'd-block';
  public yearly_report_type: string = 'd-none';
  public customer_device_model: string = 'd-block';
  public billing_month: any = '';
  public report_type = 'Monthly';
  public device_model = 'Prepaid';

  //array
  public selected_customer: any = [];
  public customers = [];
  public customers_key = [];
  public bill_list = [];
  public selected_items = [];
  public bill_key = [];
  public bill_summary = [];
  public months: any = [];
  public years: any = [];

  //object
  public customerDropdownSettings: any = {};
  public billDropdownSettings = {};

  public month: any = null;
  public year: any = new Date().getFullYear();

  constructor(private dashboardService: DashboardService, private dataShared: DataSharedService) {
    this.months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    for (let i = 2021; i < (new Date().getFullYear() + 1); i++) {
      this.years.push(i)
    }
   }

  ngOnInit(): void {
    this.getCustomerList();
    this.billingDropdownSetting();
  }

  getBody() {
    let body = {
      'account_id': localStorage.getItem('account')
    }

    if (this.action == 'getDevices') {
      body['action'] = this.action;
      body['device_model'] = this.device_model;
    }

    if (this.action == 'getPaymentSummary') {
      delete body['account_id'];
      body['action'] = this.action;
      body['account'] = localStorage.getItem('account');
      body['user_id'] = localStorage.getItem('user');
      body['columns'] = this.bill_key;
      body['meter_id'] = this.customers_key;
      (this.monthly_report_type == 'd-block') ? body['billing_month'] = this.billing_month : body['billing_year'] = this.year;
      
    }

    return body;
  }

  getCustomerList() {

    this.action = 'getDevices'; this.customer_device_model = 'd-block'; this.customers = []; 
    this.selected_customer = [];
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      this.customers = res.data;
      this.customerDropdownSettings = {
        singleSelection: false,
        idField: 'device_id',
        textField: 'location',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true,
        clearSearchFilter: true,
        searchPlaceholderText: 'Find device',
        closeDropDownOnSelection: true
      };
    });
  }

  onChange(type, e) {

    if (type == 'report_type') {
      if (e == 'Monthly') {
        this.monthly_report_type = 'd-block';
        this.yearly_report_type = 'd-none';
      } else if (e == 'Yearly') {
        this.monthly_report_type = 'd-none';
        this.yearly_report_type = 'd-block';
      }
    }

    if (type == 'device_model') { e = this.device_model; this.getCustomerList();}
    if (type == 'monthly')  this.billing_month = this.months[parseInt(e.target.value.slice(5, 7)) -  1];
    if (type == 'yearly') this.year = e;
    
  }

  billingDropdownSetting() {

    this.bill_list = this.dataShared.billing_table_summary;
    this.bill_key = ['invoice_id', 'meter_id'];
    this.selected_items = [
      { 'key': 'invoice_id', 'value': 'Invoice ID' },
      { 'key': 'meter_id', 'value': 'Meter ID' }
    ];

    this.billDropdownSettings = {
      singleSelection: false,
      idField: 'key',
      textField: 'value',
      itemsShowLimit: 6,
      allowSearchFilter: true,
      showSelectedItemsAtTop: true,
      clearSearchFilter: true,
      limitSelection: 6,
      searchPlaceholderText: 'Select',
    };
  }

  onItemSelect(item: any, type) {
    if (type == 'bill') this.bill_key.push(item['key']);
    if (type == 'customer') this.customers_key.push(item['device_id']);
  }

  onItemDeSelect(item: any, type) {
    if (type == 'bill') {
      let index = this.bill_key.indexOf(item['key'], 0)
      if (index > -1) this.bill_key.splice(index, 1);
    } else if (type == 'customer') {
      let index = this.customers_key.indexOf(item['device_id'], 0)
      if (index > -1) this.customers_key.splice(index, 1);
    }
  }

  onSelectAll(items: any) {
    items.forEach(element => {
      this.customers_key.push(element['device_id']);
    });
  }

  onDeSelectAll() {
    this.customers_key = [];
  }

  getTitleBySummaryCode(key) {
    return this.dataShared.getTitleBySummaryCode(key);
  }

  onClick(e){
    if (e = 'bill') this.getBillSummary();
  }

  getBillSummary() {
    this.action = 'getPaymentSummary'; this.bill_summary = [];
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {
      res.data.forEach(element => {
        this.bill_summary.push(element)
      });
    })
  }
}