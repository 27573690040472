import { Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { DataSharedService } from 'src/app/shared/services/data-shared.service';
import { EmitterService } from 'src/app/shared/services/event-service';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { isPlatformBrowser } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

am4core.useTheme(am4themes_animated);
@Component({
  selector: 'app-share-consumption',
  templateUrl: './share-consumption.component.html',
  styleUrls: ['./share-consumption.component.scss']
})

export class ShareConsumptionComponent implements OnInit {

  private chart: am4charts.PieChart;
  public device_id: any;
  public param_list: any = [];
  public param: string = 'default';
  public param_data: any = [];

  public sum: any = 0.00;
  public show_total: Boolean = false;

  public date: String = '';
  public date_selection: String = '';
  public data_param_el: String = '';
  public location: string = '';
  public unit: string = '';
  public energy_status: boolean = false;
  public flow_status: boolean = false;

  constructor(private dashboardService: DashboardService, @Inject(PLATFORM_ID) private platformId, private zone: NgZone, private dataShared: DataSharedService, private eventEmitter: EmitterService, private toastr: ToastrService) {}

  ngOnInit(): void {

    this.eventEmitter.listen('getSelectedSingleDevice', (device_id) => {
      this.device_id = device_id;

      this.getSelectedDeviceInfo();
      this.resetSinleDevice();
      this.getPieGraph();
    });
    this.getCurrDate();
    this.toastr.info('Please select a device and parameter');

  }

  toggleCard = (e) => this.dataShared.toggleCardFullsreen(e);

  getSelectedDeviceInfo() {

    let body = {
      'action': 'getDeviceByID',
      'device_id': [this.dataShared.device_id]
    }
    this.dashboardService.httpPost(body).subscribe((res: any) => {
      if (res.status === 200 && res.data[this.device_id]) {
        let device_info = res.data[this.dataShared.device_id];
        this.location = device_info.location;

        if (device_info.device_model == 'Flow Meter') {
          
          this.param = 'default';
          this.flow_status = true;
          this.energy_status = false;

          this.param_list = [
            {
              description: 'Instant Flow Rate',
              parameter: 'total_f' 
            },
            {
              description: 'Total Flow',
              parameter: 'total_fh' 
            }
          ]

        } else {

          this.param = 'default';
          this.flow_status = false;
          this.energy_status = true;
          
          this.param_list = [
            { 
              description: "Total Active Power (KW)", 
              parameter: "total_w" 
            }, 
            { 
              description: "Total Active Energy (KWH)", 
              parameter: "total_wh" 
            }
          ]

        }
      }
    })

    this.getFLowUnit()
  }

  getFLowUnit() {

    let setting_body = {
      action: 'getSettings',
      meta_key: 'config',
      user_id: localStorage.getItem('user'),
      account_id: [localStorage.getItem('account')]
    }

    this.dashboardService.httpPost(setting_body).subscribe((res: any) => {
      if (res.status == 200) {

        let config = res.data?.config;
        let data = JSON.parse(config[0].meta_value);
        
        this.unit = (data.flow_unit) ? data.flow_unit : '';
      }
    })
  }

  resetSinleDevice() {
    this.date = ''; this.getCurrDate();
  }

  getCurrDate() {

    let date = new Date();
    this.date = this.date_selection = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
  }

  onDateSelection(e) {
    this.date = e.target.value;
    this.getPieGraph();
  }

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  getPieGraph() {
    // Chart code goes in here
    this.browserOnly(() => {
      if (this.param != 'default') this.getChartData();
    });
  }

  getChartData() {

    let total_data = []; this.sum = 0.00;

    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      if (res.data.buckets && res.data.buckets[0].date_agg) {

        let data = res.data.buckets[0].date_agg.buckets;

        if (data.length > 0) {

          let prev = 0; let diff = 0;

          for (let i = 0; i < data.length; i++) {

            if (this.param == 'total_wh' || this.param == 'total_fh') {

              if (data[i]['val_' + this.param].value >= prev) {

                if (i != 0 && data[i]['val_' + this.param].value != 0) diff = data[i]['val_' + this.param].value - prev;

                total_data.push({
                  date: data[i].key_as_string.slice(11, 16),
                  value: diff,
                });

                if (data[i]['val_' + this.param].value != 0) prev = data[i]['val_' + this.param].value;

              }

            } else {

              total_data.push({
                date: data[i].key_as_string.slice(11, 16),
                value: data[i]['val_' + this.param].value,
              });
            }
          }

          if (this.param == 'total_wh' || this.param == 'total_fh') {

            this.show_total = true;


            this.sum = total_data.map(a => a.value).reduce(function (a, b) {
              return a + b;
            });

          } else {

            this.show_total = false;
          }
        }
      }
      this.param_data = total_data;

      let props = this.getGraphProp(this.param_data);
      this.implementChart(props);
    });
  }

  getBody() {

    let body = {
      'action': 'getChartDataByAccount',
      'user_id': [localStorage.getItem('user')],
      'device_id': [this.device_id],
      'date': this.date,
      'fixed_interval': '1h',
      'parameters': [this.param]
    };

    return body;
  }

  getGraphProp(param_data) {

    let data = {
      element: 'chartKW',
      title: '',
      data: param_data,
      interval: {
        'interval': 'hour',
        'value': 1
      },
      tooltipFormat: 'HH:mm:ss, d MMMM',
      xScrollbar: true
    };

    return data;
  }

  implementChart(data) {

    am4core.useTheme(am4themes_animated);

    let chart = am4core.create(data.element, am4charts.PieChart);
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.formatOptions.getKey("csv").disabled = true;
    chart.exporting.formatOptions.getKey("json").disabled = true;
    chart.exporting.formatOptions.getKey("xlsx").disabled = true;
    chart.exporting.formatOptions.getKey("html").disabled = true;
    chart.exporting.formatOptions.getKey("print").disabled = true;
    chart.legend = new am4charts.Legend();
    chart.legend.maxHeight = 500;
    chart.legend.scrollable = true;
    chart.legend.maxWidth = 300;
    chart.legend.position = 'right';
    chart.data = data.data;

    let series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = 'value';
    series.dataFields.category = 'date';
    series.labels.template.text = "{category}: {value.value}";
    series.fillOpacity = 100;
    series.strokeWidth = 0;
    series.hiddenState.properties.opacity = 1;
    series.hiddenState.properties.endAngle = -90;
    series.hiddenState.properties.startAngle = -90;
    series.slices.template.propertyFields.fill = "color";
    series.slices.template.cornerRadius = 5;
  }

  onChangeParam(e) {
    this.param = e.target.value;
    if (this.device_id == undefined) this.toastr.info('Please select a device');
    else this.getChartData();
  }

  exportAsCSV() {
    let data = [];
    if (data = this.getDataByParam()) this.dataShared.exportDataAsCsvFormat(data);
  }

  getDataByParam() {
    return this.param_data;
  }

  exportAsPDF(e) {
    let data = [];
    if (data = this.getDataByParam()) this.dataShared.exportDataAsPdfFormat(data, e, this.data_param_el);
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }
}