<ngb-tabset #t="ngbTabset" id="pv-string-monitoring" (tabChange)="tabChange($event)" #tabset>

    <ngb-tab *ngIf="tab_graph_status" id="tab-graph" title="Graph">
        <ng-template ngbTabContent>

            <zainergy-solar-cards for="pv-string"></zainergy-solar-cards>

            <div class="row">

                <div class="col-md-12 col-xl-6 grid-margin stretch-card" id="power_chart" *ngIf="power_status">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="card-title mb-0 ">{{ power_header }} / {{ location }}</h6>

                            <div class="header-right d-flex align-items-center">

                                <button class="btn pr-1" type="button" data-toggle="collapse"
                                data-target="#collapsePvpower" aria-expanded="false"
                                aria-controls="collapsePvpower">
                                <i class="feather-sliders"
                                    style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                            </button>

                                <a href="javascript:void(0);" (click)="tabBarChart('pv_power')" class="graph ">
                                    <span [ngClass]="{'active': pv_power_chart_active == 'bar'}">
                                        <i
                                            class="icon-sm card-header-icon ml-1 mr-1 pb-3px feather feather-bar-chart-2"></i>
                                    </span>
                                </a>

                                <span class="divider"></span>

                                <a href="javascript:void(0);" (click)="tabBarVarianceChart('pv_power')"
                                    class="graph feather-up-down-arrow-icon ml-2">
                                    <span [ngClass]="{'active': pv_power_chart_active == 'bar-variance'}">
                                        <i class="icon-sm card-header-icon ml-1 pb-3px feather feather-repeat"></i>
                                    </span>
                                </a>

                                <i class="icon-sm card-header-icon ml-4 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Print"
                                            (click)="data_param = 'power'; data_param_el = 'power_chart'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-printer"></i>
                                            <span class="">Print</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'power'; exportAsCSV()">
                                            <i class="icon-sm mr-2 feather feather-download"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf"
                                            (click)="data_param = 'power'; data_param_el = 'power_chart'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-file-text"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="collapse px-2" id="collapsePvpower">
                            <div class="card-body overflow-auto">
                                <div class="w-max-content header-right-inner-left d-flex">
                                    <div class="form-group mb-0">
                                        <h6 class="text-nowrap mr-2">
                                            Select date:
                                        </h6>
                                    </div>
                                    <div class="form-group mb-0 mr-2" *ngIf="show_pv_power_from_date">
                                        <input type="date" [(ngModel)]="pv_power_from_date" autocomplete="off"
                                            class="form-control" (change)="onFromDateSelection($event, 'pv_power')">
                                    </div>
    
                                    <span class="mr-2 from-to" *ngIf="show_pv_power_from_date">-</span>
    
                                    <div class="form-group mb-0">
                                        <input type="date" [(ngModel)]="pv_power_to_date" autocomplete="off"
                                            class="form-control" (change)="onToDateSelection($event, 'pv_power')">
                                    </div>
    
                                </div>
                            </div>
                        </div>
                        <div class="card-body">

                            <div class="row align-items-start mb-2"></div>

                            <div class="flot-wrapper">
                                <div id="chartPvStPower" style="width: 100%; height: 450px"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-xl-6 grid-margin stretch-card" id="current_chart" *ngIf="current_status">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="card-title mb-0 ">{{ current_header }} / {{ location }}</h6>

                            <div class="header-right d-flex align-items-center">

                                <button class="btn pr-1" type="button" data-toggle="collapse"
                                data-target="#collapsepvCurrent" aria-expanded="false"
                                aria-controls="collapsepvCurrent">
                                <i class="feather-sliders"
                                    style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                            </button>

                                <a href="javascript:void(0);" (click)="tabBarChart('pv_current')" class="graph">
                                    <span [ngClass]="{'active': pv_current_chart_active == 'bar'}">
                                        <i
                                            class="icon-sm card-header-icon ml-1 mr-1 pb-3px feather feather-bar-chart-2"></i>
                                    </span>
                                </a>

                                <span class="divider"></span>

                                <a href="javascript:void(0);" (click)="tabBarVarianceChart('pv_current')"
                                    class="graph feather-up-down-arrow-icon ml-1">
                                    <span [ngClass]="{'active': pv_current_chart_active == 'bar-variance'}">
                                        <i class="icon-sm card-header-icon ml-1 pb-3px feather feather-repeat"></i>
                                    </span>
                                </a>

                                <i class="icon-sm card-header-icon pt-1 mr-2 pl-2 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Print"
                                            (click)="data_param = 'current'; data_param_el = 'current_chart'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-printer"></i>
                                            <span class="">Print</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'current'; exportAsCSV()">
                                            <i class="icon-sm mr-2 feather feather-download"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf"
                                            (click)="data_param = 'current'; data_param_el = 'current_chart'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-file-text"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="collapse px-2" id="collapsepvCurrent">
                            <div class="card-body overflow-auto">
                                <div class="w-max-content header-right-inner-left d-flex">
                                    <div class="form-group mb-0">
                                        <h6 class="text-nowrap mr-2">
                                            Select date:
                                        </h6>
                                    </div>
                                    <div class="form-group mb-0 mr-2" *ngIf="show_pv_current_from_date">
                                        <input type="date" [(ngModel)]="pv_current_from_date" autocomplete="off"
                                            class="form-control" (change)="onFromDateSelection($event, 'pv_current')">
                                    </div>
    
                                    <span class="mr-2 from-to" *ngIf="show_pv_current_from_date">-</span>
    
                                    <div class="form-group mb-0">
                                        <input type="date" [(ngModel)]="pv_current_to_date" autocomplete="off"
                                            class="form-control" (change)="onToDateSelection($event, 'pv_current')">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                                                
                            <div class="row align-items-start mb-2">

                            </div>
                            <div class="flot-wrapper">
                                <div id="chartPvCurrent" style="width: 100%; height: 450px"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-xl-6 grid-margin stretch-card" id="energy_chart" *ngIf="energy_status">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="card-title mb-0 ">{{ energy_header }} / {{ location }}</h6>

                            <div class="header-right d-flex align-items-center">

                                <button class="btn pr-1" type="button" data-toggle="collapse"
                                data-target="#collapsepvenergy" aria-expanded="false"
                                aria-controls="collapsepvenergy">
                                <i class="feather-sliders"
                                    style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                            </button>

                                <a href="javascript:void(0);" (click)="tabBarChart('pv_energy')" class="graph">
                                    <span [ngClass]="{'active': pv_energy_chart_active == 'bar'}">
                                        <i
                                            class="icon-sm card-header-icon ml-1 mr-1 pb-3px feather feather-bar-chart-2"></i>
                                    </span>
                                </a>

                                <span class="divider"></span>

                                <a href="javascript:void(0);" (click)="tabBarVarianceChart('pv_energy')"
                                    class="graph feather-up-down-arrow-icon ml-2">
                                    <span [ngClass]="{'active': pv_energy_chart_active == 'bar-variance'}">
                                        <i class="icon-sm card-header-icon ml-1 pb-3px feather feather-repeat"></i>
                                    </span>
                                </a>

                                <i class="icon-sm card-header-icon mr-2 pl-2 pt-1  pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Print"
                                            (click)="data_param = 'energy'; data_param_el = 'energy_chart'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-printer"></i>
                                            <span class="">Print</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'energy'; exportAsCSV()">
                                            <i class="icon-sm mr-2 feather feather-download"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf"
                                            (click)="data_param = 'energy'; data_param_el = 'energy_chart'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-file-text"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="collapse px-2" id="collapsepvenergy">
                            <div class="card-body overflow-auto">
                                <div class="w-max-content header-right-inner-left d-flex">
                                    <div class="form-group mb-0">
                                        <h6 class="text-nowrap mr-2">
                                            Select date:
                                        </h6>
                                    </div>
                                    <div class="form-group mb-0 mr-2" *ngIf="show_pv_energy_from_date">
                                        <input type="date" [(ngModel)]="pv_energy_from_date" autocomplete="off"
                                            class="form-control" (change)="onFromDateSelection($event, 'pv_energy')">
                                    </div>
    
                                    <span class="mr-2 from-to" *ngIf="show_pv_energy_from_date">-</span>
    
                                    <div class="form-group mb-0">
                                        <input type="date" [(ngModel)]="pv_energy_to_date" autocomplete="off"
                                            class="form-control" (change)="onToDateSelection($event, 'pv_energy')">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div *ngIf="show_energy_sum" class="mr-2"><strong>Periodic KWh :
                                    {{pv_energy_sum.toFixed(2)}}</strong></div>

                            <div class="row align-items-start mb-2">

                            </div>
                            <div class="flot-wrapper">
                                <div id="chartPvEnergy" style="width: 100%; height: 450px"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-xl-6 grid-margin stretch-card" id="voltage_chart" *ngIf="voltage_status">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="card-title mb-0 ">{{ voltage_header }} / {{ location }}</h6>

                            <div class="header-right d-flex align-items-center">

                                <button class="btn pr-1" type="button" data-toggle="collapse"
                                data-target="#collapsepvVoltage" aria-expanded="false"
                                aria-controls="collapsepvVoltage">
                                <i class="feather-sliders"
                                    style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                            </button>

                                <a href="javascript:void(0);" (click)="tabBarChart('pv_voltage')" class="graph">
                                    <span [ngClass]="{'active': pv_voltage_chart_active == 'bar'}">
                                        <i
                                            class="icon-sm card-header-icon ml-1 mr-1 pb-3px feather feather-bar-chart-2"></i>
                                    </span>
                                </a>

                                <span class="divider"></span>

                                <a href="javascript:void(0);" (click)="tabBarVarianceChart('pv_voltage')"
                                    class="graph feather-up-down-arrow-icon ml-2">
                                    <span [ngClass]="{'active': pv_voltage_chart_active == 'bar-variance'}">
                                        <i class="icon-sm card-header-icon ml-1 feather feather-repeat"></i>
                                    </span>
                                </a>

                                <i class="icon-sm card-header-icon pl-2 mr-2 pt-1 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Print"
                                            (click)="data_param = 'voltage'; data_param_el = 'voltage_chart'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-printer"></i>
                                            <span class="">Print</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'voltage'; exportAsCSV()">
                                            <i class="icon-sm mr-2 feather feather-download"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf"
                                            (click)="data_param = 'voltage'; data_param_el = 'voltage_chart'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-file-text"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="collapse px-2" id="collapsepvVoltage">
                            <div class="card-body overflow-auto">
                                <div class="w-max-content header-right-inner-left d-flex">
                                    <div class="form-group mb-0">
                                        <h6 class="text-nowrap mr-2">
                                            Select date:
                                        </h6>
                                    </div>
                                    <div class="form-group mb-0 mr-2" *ngIf="show_pv_voltage_from_date">
                                        <input type="date" [(ngModel)]="pv_voltage_from_date" autocomplete="off"
                                            class="form-control" (change)="onFromDateSelection($event, 'pv_voltage')">
                                    </div>
    
                                    <span class="mr-2 from-to" *ngIf="show_pv_voltage_from_date">-</span>
    
                                    <div class="form-group mb-0">
                                        <input type="date" [(ngModel)]="pv_voltage_to_date" autocomplete="off"
                                            class="form-control" (change)="onToDateSelection($event, 'pv_voltage')">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                             
                            <div class="row align-items-start mb-2">

                            </div>
                            <div class="flot-wrapper">
                                <div id="chartPvVoltage" style="width: 100%; height: 450px"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </ng-template>
    </ngb-tab>

    <ngb-tab *ngIf="tab_table_status" id="tab-table" title="Table">
        <ng-template ngbTabContent>

            <div class="row mt-4">

                <div class="col-lg-12 col-xl-12 stretch-card">
                    <div class="d-grid row flex-grow">

                        <div class="col grid-margin stretch-card">
                            <div class="card" id="tab-table">
                                <div class="d-flex justify-content-between card-header align-items-center mb-4 mb-md-3">
                                    <h6 class="card-title mb-0 ">Table</h6>

                                    <div class="d-flex align-items-center justify-content-end">

                                        <button class="btn pr-1" type="button" data-toggle="collapse"
                                        data-target="#collapseExample" aria-expanded="false"
                                        aria-controls="collapseExample">
                                        <i class="feather-sliders"
                                            style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                                        </button>

                                        <div class="dropdown ml-2">

                                            <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i
                                                    class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                            </button>

                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);" name="Print"
                                                    (click)="data_param = 'table'; data_param_el = 'tab-table'; exportAsPDF($event)">
                                                    <i class="icon-sm mr-2 feather feather-printer"></i>
                                                    <span class="">Print</span>
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);"
                                                    (click)="data_param = 'table'; exportAsCSV()">
                                                    <i class="icon-sm mr-2 feather feather-download"></i>
                                                    <span class="">Export as CSV</span>
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);" name="Pdf"
                                                    (click)="data_param = 'table'; data_param_el = 'tab-table'; exportAsPDF($event)">
                                                    <i class="icon-sm mr-2 feather feather-file-text"></i>
                                                    <span class="">Export as PDF</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="collapse px-2" id="collapseExample">
                                    <div class="card-body overflow-auto">
                                        <div class="w-max-content header-right-inner-left d-flex">
                                                <div class="form-group mb-0 ml-2 mr-1">
                                            <h6 style="font-weight:700;">Range: &nbsp;</h6>
                                            </div>
                                            <div class="form-group mb-0 mr-4 mt-0">
                                                <input
                                                    style="font-weight: 500; color: rgb(236, 2, 2); border:2px solid rgba(155, 151, 151, 0.603); border-radius: 5px; height: 35px; width: 325px; padding-left: 10px; align-items: center;"
                                                    type="text" ngxDaterangepickerMd
                                                    [locale]="{displayFormat: 'DD MMM YYYY  HH:00', separator: '    To    ',  cancelLabel: 'Cancel', firstDay: 1}"
                                                    [(ngModel)]="dateselection" [showCustomRangeLabel]="true"
                                                    [alwaysShowCalendars]="true" [ranges]="ranges"
                                                    [linkedCalendars]="true" [showClearButton]="true"
                                                    (change)="onTabularDateSelection(dateselection)"
                                                    placeholder="Select please..." />
                                            </div>
                                        
                                        <div class="mb-0 ml-2 mr-3">
                                            <button class="btn theme-primary-btn" type="button" (click)="onClick()"
                                                style="width: auto;">View</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <!-- Card body table -->
                                <div class="card-body">
                                    <div class="table-responsive text-nowrap">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width="20%">Total Active Power</th>
                                                    <th width="20%">Avg. 3 Phase Current</th>
                                                    <th width="20%">Avg. Line Voltage VL-L</th>
                                                    <th width="20%">Total Active Energy</th>
                                                    <th width="20%">TIMESTAMP</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr
                                                    *ngFor="let data of table_data | slice: (page-1) * pageSize : page * pageSize">
                                                    <td>{{ data.power.toFixed(2) }}</td>
                                                    <td>{{ data.current.toFixed(2) }}</td>
                                                    <td>{{ data.voltage.toFixed(2) }}</td>
                                                    <td>{{ data.energy.toFixed(2) }}</td>
                                                    <td>{{ data.date | date: 'medium' }}</td>
                                                </tr>
                                                <!-- <tr>
                                                    <td>Total Energy </td>
                                                    <td>{{table_sum.toFixed(2)}} </td>
                                                </tr> -->

                                            </tbody>

                                        </table>

                                    </div>

                                    <br>
                                    <h5>Total Record: {{ table_data.length }}</h5>
                                    <div style="justify-content: flex-end;" class="d-flex pagination">

                                        <input class="p-btn" type="button" value="Prev"
                                            (click)="pageSelection('prev_page')">

                                        &nbsp;&nbsp;&nbsp;
                                        <label class="mt-2" style="color: rgb(1, 55, 105);"> Page </label>
                                        &nbsp;&nbsp;

                                        <select [(ngModel)]="page" (change)="pageSelection('curr_page')"
                                            style="width:80px; border-radius:5px;color:black;">
                                            <option *ngFor="let page of pages">{{ page }}</option>
                                        </select>

                                        &nbsp; &nbsp;
                                        <label class="mt-2 text-nowrap" style="color: rgb(1, 55, 105);"> of {{ pages.length | number
                                            : '1.0-0'}}</label>
                                        &nbsp; &nbsp;
                                        <input class="p-btn" type="button" value="Next"
                                            (click)="pageSelection('next_page')">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- row -->

        </ng-template>
    </ngb-tab>

    <ngb-tab *ngIf="tab_comparative_status" id="tab-comparative" title="Comparative">
        <ng-template ngbTabContent>
            <br>
            <div class="grid-margin stretch-card" id="comparative_chart">
                <div class="card">
                    <div style="overflow-x: auto;" class="d-flex justify-content-between card-header align-items-center">
                        <h6 class="card-title mb-0 mr-2 text-nowrap">{{ comparative_title }}</h6>
                        <div class="header-right d-flex align-items-center">

                            <div class="header-right-inner-right d-flex align-items-center">

                                <button class="btn pr-2" type="button" data-toggle="collapse"
                                data-target="#collapseExample" aria-expanded="false"
                                aria-controls="collapseExample">
                                <i class="feather-sliders"
                                    style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                            </button>

                                <a href="javascript:void(0);" (click)="tabLineChart('pv_comparative')" class="graph">
                                    <span [ngClass]="{'active': pv_comparative_chart_active == 'line'}">
                                        <i class="icon-sm card-header-icon ml-1 mr-1 pb-3px feather feather-trending-up"></i>
                                    </span>
                                </a>

                                <span class="divider"></span>

                                <a href="javascript:void(0);" (click)="tabBarChart('pv_comparative')" class="graph">
                                    <span [ngClass]="{'active': pv_comparative_chart_active == 'bar'}">
                                        <i class="icon-sm card-header-icon ml-2 pb-3px feather feather-bar-chart-2"></i>
                                    </span>
                                </a>

                                <i class="icon-sm card-header-icon mr-2 pl-2 pt-1 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Print"
                                            (click)="data_param = 'comparative'; data_param_el = 'comparative_chart'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-printer"></i>
                                            <span class="">Print</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'comparative'; exportAsCSV()">
                                            <i class="icon-sm mr-2 feather feather-download"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf"
                                            (click)="data_param = 'comparative'; data_param_el = 'comparative_chart'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-file-text"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="collapse px-2" id="collapseExample">
                        <div class="card-body overflow-auto">
                            <div class="w-max-content header-right-inner-left d-flex">
                                    <h6 class="text-nowrap mr-2">
                                        Select date:
                                    </h6>
                                    <input style="width:150px;" type="date" [(ngModel)]="pv_comparative_from_date" autocomplete="off"
                                        class="mr-2 form-control" (change)="onComparativeFromDateSelection($event)">

                                    <span class="mr-2 from-to">-</span>


                                    <input style="width:150px;" type="date" [(ngModel)]="pv_comparative_to_date" autocomplete="off"
                                        class="mr-4 form-control" (change)="onComparativeToDateSelection($event)">

                                    <h6 class="text-nowrap mr-2">
                                        Select Parameter:
                                    </h6>
                                    <select class="mr-2" name="param" id="param" [(ngModel)]="param" style="width: 300px">
                                        <option value=''>Select Parameter</option>
                                        <option *ngFor='let data of param_list' [value]="data.parameter">{{
                                            data.description }}
                                        </option>
                                    </select>

                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row align-items-start mb-2">
                        </div>

                        <div class="flot-wrapper">
                            <div id="chartPvComparative" style="width: 100%; height: 600px"></div>
                        </div>

                    </div>
                </div>
            </div>

        </ng-template>
    </ngb-tab>

    <ngb-tab *ngIf="tab_comparative_table_status" id="tab-comparative-table" title="Comparative Table">
        <ng-template ngbTabContent>
            <div class="row mt-4">

                <div class="col-lg-12 col-xl-12 stretch-card" id="tab-comparative-table">
                    <div class="d-grid row flex-grow">

                        <div class="col grid-margin stretch-card">
                            <div class="card" id="tab-table">
                                <div class="d-flex justify-content-between card-header align-items-center mb-md-3">

                                    <h6 class="card-title mb-0 ">Table</h6>

                                    <div class="d-flex align-items-center justify-content-end">

                                        <button class="btn pr-1" type="button" data-toggle="collapse"
                                            data-target="#collapseExample" aria-expanded="false"
                                            aria-controls="collapseExample">
                                            <i class="feather-sliders"
                                                style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                                        </button>

                                        <div class="dropdown ml-2">
                                            <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i
                                                    class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);" name="Print"
                                                    (click)="data_param = 'comparative_table'; data_param_el = 'tab-comparative-table'; exportAsPDF($event)">
                                                    <i class="icon-sm mr-2 feather feather-printer"></i>
                                                    <span class="">Print</span>
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);"
                                                    (click)="data_param = 'comparative_table'; exportAsCSV()">
                                                    <i class="icon-sm mr-2 feather feather-download"></i>
                                                    <span class="">Export as CSV</span>
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);" name="Pdf"
                                                    (click)="data_param = 'comparative_table'; data_param_el = 'tab-comparative-table'; exportAsPDF($event)">
                                                    <i class="icon-sm mr-2 feather feather-file-text"></i>
                                                    <span class="">Export as PDF</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="collapse px-2" id="collapseExample">
                                    <div class="card-body overflow-auto">
                                        <div class="w-max-content header-right-inner-left d-flex">
                                            <div class="form-group mb-0 ml-2 mr-1">
                                            <h6>Range:</h6>
                                            </div>
                                            <div class="form-group mb-0 ml-2 mr-4">
                                                <input class="dateInputresponsive"
                                                    style="font-weight: 500; color: rgb(236, 2, 2); border:2px solid rgba(155, 151, 151, 0.603); border-radius: 5px; height: 35px; width: 325px; padding-left: 10px; align-items: center;"
                                                    type="text" ngxDaterangepickerMd
                                                    [locale]="{displayFormat: 'DD MMM YYYY  HH:00', separator: '    To    ',  cancelLabel: 'Cancel', firstDay: 1}"
                                                    [(ngModel)]="dateselection" [showCustomRangeLabel]="true"
                                                    [alwaysShowCalendars]="true" [ranges]="ranges" [linkedCalendars]="true"
                                                    [showClearButton]="true"
                                                    (change)="onTabularDateSelection(dateselection)"
                                                    placeholder="Select please..." />
                                            </div>
                                            <div class="form-group mb-0 ml-2 mr-1">
                                            <h6 style="font-weight:700;" class="ml-2">Parameters:</h6>
                                            </div>
                                            <div class="form-group mb-0 ml-2 mr-3">
                                                <select name="param" id="param" [(ngModel)]="param" style="width: 300px">
                                                    <option value=''>Select Parameter</option>
                                                    <option *ngFor='let data of param_list' [value]="data.parameter">{{
                                                        data.description }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive text-nowrap">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th *ngFor="let data of device_location" width="10%">{{
                                                        data.location }}</th>

                                                    <th width="20%">TIMESTAMP</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr
                                                    *ngFor="let data of comparative_table_data | slice: (page-1)  pageSize : page * pageSize">
                                                    <td>{{ (data.value_1) ? data.value_1.toFixed(2) : '0.00' }}</td>
                                                    <td>{{ (data.value_2)? data.value_2.toFixed(2) : '0.00' }}</td>
                                                    <td *ngIf="comparative_table_value_3">{{ (data.value_3)?
                                                        data.value_3.toFixed(2) : '0.00' }}</td>
                                                    <td *ngIf="comparative_table_value_4">{{ (data.value_4)?
                                                        data.value_4.toFixed(2) : '0.00' }}</td>
                                                    <td *ngIf="comparative_table_value_5">{{ (data.value_5)?
                                                        data.value_5.toFixed(2) : '0.00' }}</td>
                                                    <td *ngIf="comparative_table_value_6">{{ (data.value_6)?
                                                        data.value_6.toFixed(2) : '0.00' }}</td>
                                                    <td *ngIf="comparative_table_value_7">{{ (data.value_7)?
                                                        data.value_5.toFixed(2) : '0.00' }}</td>
                                                    <td *ngIf="comparative_table_value_8">{{ (data.value_8)?
                                                        data.value_6.toFixed(2) : '0.00' }}</td>
                                                    <td>{{ data.date | date: 'medium' }}</td>
                                                </tr>
                                                <!-- <tr>
                                                    <td>Total Energy </td>
                                                    <td>{{table_sum.toFixed(2)}} </td>
                                                </tr> -->

                                            </tbody>

                                        </table>

                                    </div>

                                    <br>
                                    <h5>Total Record: {{ comparative_table_data.length }}</h5>
                                    <ngb-pagination class="d-flex justify-content-end"
                                        [collectionSize]="comparative_table_data.length" [(page)]="page" [maxSize]="2"
                                        [rotate]="true" [pageSize]="pageSize" [boundaryLinks]="true">

                                    </ngb-pagination>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- row -->
        </ng-template>
    </ngb-tab>

    <ngb-tab *ngIf="tab_pv_generation_report_status" id="tab-generation-table" title="Pv Daily Generation Report">
        <ng-template ngbTabContent>
            <div class="row mt-4">

                <div class="col-lg-12 col-xl-12 stretch-card">
                    <div class="d-grid row flex-grow">

                        <div class="col grid-margin stretch-card" id="tab-generation-table">
                            <div class="card" id="tab-table">

                                <div class="d-flex justify-content-between card-header align-items-center mb-4 mb-md-3">
                                    <h6 class="card-title mb-0">Table</h6>

                                    <div class="d-flex align-items-center justify-content-end">
                                        
                                        <button class="btn pr-1" type="button" data-toggle="collapse"
                                        data-target="#collapseExample" aria-expanded="false"
                                        aria-controls="collapseExample">
                                        <i class="feather-sliders"
                                            style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                                    </button>

                                        <div class="dropdown ml-2">
                                            <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i
                                                    class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);" name="Print"
                                                    (click)="data_param = 'generation-table'; data_param_el = 'tab-generation-table'; exportAsPDF($event)">
                                                    <i class="icon-sm mr-2 feather feather-printer"></i>
                                                    <span class="">Print</span>
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);"
                                                    (click)="data_param = 'generation-table'; exportAsCSV()">
                                                    <i class="icon-sm mr-2 feather feather-download"></i>
                                                    <span class="">Export as CSV</span>
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);" name="Pdf"
                                                    (click)="data_param = 'generation-table'; data_param_el = 'tab-generation-table'; exportAsPDF($event)">
                                                    <i class="icon-sm mr-2 feather feather-file-text"></i>
                                                    <span class="">Export as PDF</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>       
                                </div>
                                <div class="collapse px-2" id="collapseExample">
                                    <div class="card-body overflow-auto">
                                        <div class="w-max-content header-right-inner-left d-flex">
                                            <div class="form-group mr-2">
                                                <span>
                                                    <strong class="text-nowrap">Select date:</strong>
                                                </span>
                                            </div>
                                            <div class="form-group mr-4">
                                                <input type="date" [(ngModel)]="generation_tab_to_date_selection"
                                                    autocomplete="off" class="form-control"
                                                    (change)="onGenerationTabularToDateSelection($event)">
    
                                            </div>
                                            <div class="form-group mr-2">
                                                <span>
                                                    <strong class="text-nowrap">Pv Unit Rate (Rs.)</strong>
                                                </span>
                                            </div>
                                            <div class="form-group mr-2">
                                                <input type="number" style="width: 70px;" class="form-control mr-2"
                                                    [(ngModel)]="cost" [ngModelOptions]="{standalone: true}">
                                            </div>
                                            <div class="form-group mr-4">
                                                <button class="btn theme-primary-btn ml-2" type="button"
                                                    (click)="calculateCost()" style="width: auto;">Calculate</button>
                                            </div>
                                            <!-- <div class="form-group mb-0 mr-2">
                                                <input type="date" [(ngModel)]="generation_tab_from_date_selection"
                                                    autocomplete="off" class="form-control"
                                                    (change)="onGenerationTabularFromDateSelection($event)">
                                            </div>
                                            <span class="mr-2 from-to">
                                                -
                                            </span> -->
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive text-nowrap">
                                        <div class="row">
                                            <div class="col-md-4 col-xl-6 grid-margin stretch-card">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <div class="flot-wrapper mt-4">
                                                            <h4>Pv Daily Generation Report</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-xl-6 col-lg-4 grid-margin stretch-card"
                                                id="tab-generation-table-chart">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <div class="d-flex align-items-center justify-content-end">
                                                            <div class="dropdown ml-2">
                                                                <button class="btn p-0" type="button"
                                                                    id="dropdownMenuButton3" data-toggle="dropdown"
                                                                    aria-haspopup="true" aria-expanded="false">
                                                                    <i
                                                                        class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                                                </button>
                                                                <div class="dropdown-menu"
                                                                    aria-labelledby="dropdownMenuButton3">
                                                                    <a class="dropdown-item d-flex align-items-center"
                                                                        href="javascript:void(0);" name="Print"
                                                                        (click)="data_param = 'generation-table-chart'; data_param_el = 'tab-generation-table-chart'; exportAsPDF($event)">
                                                                        <i
                                                                            class="icon-sm mr-2 feather feather-printer"></i>
                                                                        <span class="">Print</span>
                                                                    </a>
                                                                    <a class="dropdown-item d-flex align-items-center"
                                                                        href="javascript:void(0);"
                                                                        (click)="data_param = 'generation-table-chart'; exportAsCSV()">
                                                                        <i
                                                                            class="icon-sm mr-2 feather feather-download"></i>
                                                                        <span class="">Export as CSV</span>
                                                                    </a>
                                                                    <a class="dropdown-item d-flex align-items-center"
                                                                        href="javascript:void(0);" name="Pdf"
                                                                        (click)="data_param = 'generation-table-chart'; data_param_el = 'tab-generation-table-chart'; exportAsPDF($event)">
                                                                        <i
                                                                            class="icon-sm mr-2 feather feather-file-text"></i>
                                                                        <span class="">Export as PDF</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="flot-wrapper">
                                                            <div id="pv_generation_chart"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th *ngFor="let data of device_location" width="10%">{{
                                                        data.location }}</th>

                                                    <th width="20%">TIMESTAMP</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr
                                                    *ngFor="let data of generation_table_data | slice: (page-1) * pageSize : page * pageSize">
                                                    <td>{{ (data.value_1) ? data.value_1.toFixed(2) : '0.00' }}</td>
                                                    <td>{{ (data.value_2)? data.value_2.toFixed(2) : '0.00' }}</td>
                                                    <td *ngIf="comparative_table_value_3">{{ (data.value_3)?
                                                        data.value_3.toFixed(2) : '0.00' }}</td>
                                                    <td *ngIf="comparative_table_value_4">{{ (data.value_4)?
                                                        data.value_4.toFixed(2) : '0.00' }}</td>
                                                    <td *ngIf="comparative_table_value_5">{{ (data.value_5)?
                                                        data.value_5.toFixed(2) : '0.00' }}</td>
                                                    <td *ngIf="comparative_table_value_6">{{ (data.value_6)?
                                                        data.value_6.toFixed(2) : '0.00' }}</td>
                                                    <td *ngIf="comparative_table_value_7">{{ (data.value_7)?
                                                        data.value_7.toFixed(2) : '0.00' }}</td>
                                                    <td *ngIf="comparative_table_value_8">{{ (data.value_8)?
                                                        data.value_8.toFixed(2) : '0.00' }}</td>
                                                    <td>{{ data.date | date: 'medium' }}</td>
                                                </tr>

                                            </tbody>

                                        </table>

                                    </div>

                                    <br>
                                    <h5>Total Record: {{ generation_table_data.length }}</h5>
                                    <ngb-pagination class="d-flex justify-content-end"
                                        [collectionSize]="generation_table_data.length" [(page)]="page" [maxSize]="2"
                                        [rotate]="true" [pageSize]="pageSize" [boundaryLinks]="true">

                                    </ngb-pagination>

                                    <br>
                                    <h5 style="color: rgb(255, 0, 0); width: 400px;">Total Pv Production (Actual) Kwh
                                    </h5>


                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th *ngFor="let data of device_location" width="10%">{{
                                                    data.location }}</th>

                                                <th width="20%">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of diff">
                                                <td>{{ (data.diff_1) ? data.diff_1.toFixed(2) : '0.00' }}</td>
                                                <td>{{ (data.diff_2)? data.diff_2.toFixed(2) : '0.00' }}</td>
                                                <td *ngIf="comparative_table_value_3">{{ (data.diff_3)?
                                                    data.diff_3.toFixed(2) : '0.00' }}</td>
                                                <td *ngIf="comparative_table_value_4">{{ (data.diff_4)?
                                                    data.diff_4.toFixed(2) : '0.00' }}</td>
                                                <td *ngIf="comparative_table_value_5">{{ (data.diff_5)?
                                                    data.diff_5.toFixed(2) : '0.00' }}</td>
                                                <td *ngIf="comparative_table_value_6">{{ (data.diff_6)?
                                                    data.diff_6.toFixed(2) : '0.00' }}</td>
                                                <td *ngIf="comparative_table_value_7">{{ (data.diff_7)?
                                                    data.diff_7.toFixed(2) : '0.00' }}</td>
                                                <td *ngIf="comparative_table_value_8">{{ (data.diff_8)?
                                                    data.diff_8.toFixed(2) : '0.00' }}</td>
                                                <td>{{ diff_sum.toFixed(2) }}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <br>
                                    <h5 style="color: rgb(255, 0, 0); width: 400px;">Total Pv Saving</h5>


                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th *ngFor="let data of device_location" width="10%">{{
                                                    data.location }}</th>

                                                <th width="20%">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of diff">
                                                <td>{{ data.cost_1.toFixed(2) }}</td>
                                                <td>{{ data.cost_2.toFixed(2) }}</td>
                                                <td *ngIf="comparative_table_value_3">{{ data.cost_3.toFixed(2) }}</td>
                                                <td *ngIf="comparative_table_value_4">{{ data.cost_4.toFixed(2) }}</td>
                                                <td *ngIf="comparative_table_value_5">{{ data.cost_5.toFixed(2) }}</td>
                                                <td *ngIf="comparative_table_value_6">{{ data.cost_6.toFixed(2) }}</td>
                                                <td *ngIf="comparative_table_value_7">{{ data.cost_7.toFixed(2) }}</td>
                                                <td *ngIf="comparative_table_value_8">{{ data.cost_8.toFixed(2) }}</td>
                                                <td>{{ cost_sum.toFixed(2) }}</td>
                                            </tr>
                                        </tbody>
                                    </table>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- row -->
        </ng-template>
    </ngb-tab>

</ngb-tabset>