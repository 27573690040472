<div class="row">
    <div class="col-lg-12 col-xl-12 stretch-card" id="min_max">
 
        <div class="row flex-grow">
    
            <div class="d-grid col grid-margin stretch-card">
                <div class="card">
                    <div class="d-flex justify-content-between card-header align-items-center mb-4 mb-md-3">
                        <h6 class="card-title mb-0 ">Power Monitoring</h6>
    
                        <div class="d-flex align-items-center justify-content-end">

                            <div class="dropdown ml-2">
                                <button class="btn p-0" type="button" id="dropdownMenuButton3" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                </button>
                                <div id="hideprint" class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                    <button [useExistingCss]="true" printSectionId="min_max" ngxPrint type="button"
                                    class="dropdown-item d-flex align-items-center" 
                                        name="Print" >
                                        <i class="feather feather-printer icon-sm mr-2"></i>
                                        <span class="">Print</span>
                                    </button>
                                    <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                        >
                                        <i class="feather feather-download icon-sm mr-2"></i>
                                        <span class="">Export as CSV</span>
                                    </a>
                                    <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);" name="Pdf"
                                        >
                                        <i class="feather feather-file-text icon-sm mr-2"></i>
                                        <span class="">Export as PDF</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mr-0 card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr id="table-head" style="text-align: center; color: white; background-color: #073C5C;">
                                        <th style="color: white;">Location</th>
                                        <th style="color: white;">Utility Load</th>
                                        <th style="color: white;">Pv Load</th>
                                        <th style="color: white;">Genset Load</th>
                                        <th style="color: white;">Total Load</th>
                                        <th style="color: white;">Thresold Value</th>
                                        <th style="color: white;">Thresold Time</th>
                                        <th style="color: white;">Status</th>
                                        <th style="color: white;">Condition</th>
                                    </tr>
                                </thead>
    
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    <!-- row -->