
<div class="row">
  <div class="col-md-6 offset-lg-3 pt-4 mt-3">

    <div class="card fun-blue-bg">
    <div class="card-body">
      <form class="profile-form validate-form">
       <div class="row">
         <div class="col-lg-12 mt-2">
          <div class="form-title font-weight-bold mb-3"><h4>User Profile</h4></div>
        </div>
      <div class="col-lg-12"> 
        <div class="form-group">
          <label class="text-midnight">First Name</label>
          <input type="Text" [(ngModel)]="first_name" [ngModelOptions]="{standalone: true}" name="data"
            class="form-control">
        </div>
      </div>
      <div class="col-lg-12"> 
        <div class="form-group">
          <label class="text-midnight">Last Name</label>
          <input type="text" [(ngModel)]="last_name" [ngModelOptions]="{standalone: true}"
            class="form-control">
        </div>
      </div>
      <div class="col-lg-12"> 
        <div class="form-group">
          <label class="text-midnight">Email</label>
          <input type="Text" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" class="form-control"
            readonly>
        </div>
      </div>
      <div class="col-lg-12"> 
        <div class="form-group">
          <label class="text-midnight">Company</label>
          <input type="text" [(ngModel)]="company" [ngModelOptions]="{standalone: true}" class="form-control"
            readonly>
        </div>
      </div>

      <div class="col-lg-12"> 
        <div class="mb-3 mt-4 d-block float-right">
          <button class="btn theme-primary-btn" (click)="postProfile()" true>Update</button>
        </div>
      </div>
       
      </div>
      </form>  
    </div>

    </div>

  </div>
</div>
