import { Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { DataSharedService } from 'src/app/shared/services/data-shared.service';
import { EmitterService } from 'src/app/shared/services/event-service';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { isPlatformBrowser } from '@angular/common';
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  selector: 'app-power-trend',
  templateUrl: './power-trend.component.html',
  styleUrls: ['./power-trend.component.scss']
})

export class PowerTrendComponent implements OnInit {

  private chart: am4charts.XYChart;
  public device_id: any;
  public tabular_data: any = [];
  public export_tabular_data: any = [];

  public to_date: String = '';
  public from_date: String = '';

  public location: String = 'N/A';

  public data_param: String = '';
  public data_param_el: String = '';
  public action: String = 'getChartDataByAccount';

  alwaysShowCalendars: boolean;

  selected: any = { start: moment(), end: moment() };
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'This Week': [moment().startOf('week'), moment().endOf('days')],
    'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
    'This Month': [moment().startOf('month'), moment().endOf('days')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year').format('YYYY'), moment().endOf('days')],
    'Last Year': [moment().subtract(1, 'year').startOf('year').format('YYYY'), moment().subtract(1, 'year').endOf('year')]
  }

  public pageSize = 25; public from: any = 0; public total_found: any = 0;
  public page = 1; public size: any = 25;
  public previousPage: any = 0; public show_pagination: Boolean = false;
  public flow_status: boolean = false; public energy_status: boolean = false; 

  public dropdownSettings: IDropdownSettings;
  public dropdownList = [];
  public selectedItems = [];
  public graph_type: String = 'val_';
  public params: any = ['total_w'];
  public units: any = [];
  public fixed_interval: String = '5m';
  public chart_data: any = [];
  public chart_active: String = 'line';
  public unit: string = '';
  public title: string = '';
  public main_logo = '';
  public user: any;
  public curr_date: string = '';

  public min: any  = {
    total_w_value: 0,
    total_w_timestamp: '',
    total_w_class: 'd-none',
    w_r_value: 0,
    w_r_timestamp: '',
    w_r_class: 'd-none',
    w_y_value: 0,
    w_y_timestamp: '',
    w_y_class: 'd-none',
    w_b_value: 0,
    w_b_timestamp: '',
    w_b_class: 'd-none',
  };

  public max: any  = {
    total_w_value: 0,
    total_w_timestamp: '',
    total_w_class: 'd-none',
    w_r_value: 0,
    w_r_timestamp: '',
    w_r_class: 'd-none',
    w_y_value: 0,
    w_y_timestamp: '',
    w_y_class: 'd-none',
    w_b_value: 0,
    w_b_timestamp: '',
    w_b_class: 'd-none',
  }

  constructor(private authService: LoginService, private dashboardService: DashboardService, @Inject(PLATFORM_ID) private platformId, private zone: NgZone, private eventEmitter: EmitterService, private dataShared: DataSharedService) {
    this.alwaysShowCalendars = true;
  }

  ngOnInit(): void {

    this.getFLowUnit(); this.setMainLogo(); this.currentUser();
    this.eventEmitter.listen('getSelectedSingleDevice', (device_id) => {

      if (this.dataShared.curr_menu == 'analysis~power-trend') {

        this.device_id = device_id;
        this.checkDeviceModelStatus();
      }
    });
    this.getCurrDate();
  }

  getFLowUnit() {

    let setting_body = {
      action: 'getSettings',
      meta_key: 'config',
      user_id: localStorage.getItem('user'),
      account_id: [localStorage.getItem('account')]
    }

    this.dashboardService.httpPost(setting_body).subscribe((res: any) => {
      if (res.status == 200) {

        let config = res.data?.config;
        let data = JSON.parse(config[0].meta_value);
        this.unit = (data.flow_unit) ? data.flow_unit : '';
      }
    })
  }

  setMainLogo() {
    let body = {
      "action": "getLogo",
      "account_id": localStorage.getItem('account')
    }
    this.dashboardService.httpPost(body).subscribe((res: any) => {
      this.main_logo = (res.data[0]) ? 'https://app.zainergy.com/backend/zainergy/assets/' + res.data[0].private_hash + '?key=directus-large-contain' : 'assets/images/zainergy-logo.svg';
    })
  }

  currentUser() {
    this.authService.getUser().subscribe((res: any) => {
      if (res?.data || res?.user) {
        this.user = (res.data) ? res.data : res.user;
      }
    })
  }

  checkDeviceModelStatus() {

    let body = {
      'action': 'getDeviceByID',
      'device_id': [this.dataShared.device_id]
    }

    this.dashboardService.httpPost(body).subscribe((res: any) => {

      if (res.status === 200 && res.data[this.dataShared.device_id]) {

        if (res.data[this.dataShared.device_id].device_model == 'Flow Meter') {

          this.flow_status = true;
          this.energy_status = false;
          this.getParamsUnit(['total_f']);
          this.getSelectedSingleDevice();
          this.getSingleDeviceTable();

        } else {

          this.flow_status = false;
          this.energy_status = true;
          this.DropdownSetting();
          this.getSelectedSingleDevice();
          this.getSingleDeviceTable();
        }
      }
    })
  }

  DropdownSetting() {

    this.dropdownList = [
      { parameter: 'total_w', description: 'Total Active Power' },
      { parameter: 'w_r', description: 'Active Power L1' },
      { parameter: 'w_y', description: 'Active Power L2' },
      { parameter: 'w_b', description: 'Active Power L3' }
    ]

    this.params = ['total_w'];

    this.selectedItems = [
      { parameter: 'total_w', description: 'Total Active Power' }
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'parameter',
      textField: 'description',
      showSelectedItemsAtTop: true,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      clearSearchFilter: true,
      limitSelection: 6,
      searchPlaceholderText: 'Select parmeters',
    };
    this.getParamsUnit(this.params)
    if (this.graph_type == 'all') this.dropdownSettings['singleSelection'] = true;
  }

  getParamsUnit(param) {

    if (param[0] == 'total_f') this.params = param;

    this.units = [];
    let body = {
      "action": "getParamsUnit",
      "parameter": param
    }
    this.dashboardService.httpPost(body).subscribe((res: any) => {
      this.units = res.data
    })
  }

  onClick() {
    if (this.dataShared.device_id != null) {
      this.getChartData();
      this.getSingleDeviceTable();
    }
  }

  toggleCard = (e) => this.dataShared.toggleCardFullsreen(e);

  getCurrDate() {

    let date = new Date();
    this.to_date = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
    this.from_date = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
    this.curr_date = String(date.getDate()).padStart(2, '0') + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' +  date.getFullYear();

    if (this.dataShared.device_id != null) {
      this.getSelectedSingleDevice();
      this.getSingleDeviceTable();

    }
  }

  getBody() {

    let body = {
      'action': this.action,
      'user_id': [localStorage.getItem('user')],
      'device_id': (this.device_id != undefined) ? [this.device_id] : [this.dataShared.device_id],
      'date': this.to_date,
      'fixed_interval': this.fixed_interval,
      'parameters': this.params
    }
    if (this.from_date != this.to_date) {
      delete body['date']
      body['range'] = {
        from: this.from_date,
        to: this.to_date
      }
    }

    if (this.action == 'getTabularDataByAccount') {
      delete body['fixed_interval'];
      body['sort'] = "desc";
      body['from'] = this.from;
      body['size'] = this.size;

    }

    return body;
  }

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  getSelectedSingleDevice() {
    // Chart code goes in here
    this.browserOnly(() => {
      this.getChartData();
    });
  }

  getChartData() {

    let chart_data = []; let data = []; this.action = 'getChartDataByAccount';
    
    let min_value = {
      total_w: [],
      w_r: [],
      w_y: [],
      w_b: [],
    };

    let max_value = {
      total_w: [],
      w_r: [],
      w_y: [],
      w_b: [],
    };

    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {


      if (this.params[0] == 'total_f') this.title = 'Instant Flow Rate'
      else this.title = 'Active Power (KW)';

      if (res.data.buckets && res.data.buckets[0].date_agg) {
        data = res.data.buckets[0].date_agg.buckets;
 
        if (data.length > 0) {

          for (let i = 0; i < this.params.length; i++) {

            for (let j = 0; j < data.length; j++) {

              if (this.graph_type == 'all') {
                chart_data.push({
                  date: new Date(data[j].key_as_string),
                  avg: data[j]['val_' + this.params[i]].value,
                  min: data[j]['min_' + this.params[i]].value,
                  max: data[j]['max_' + this.params[i]].value
                });

              } else {
                chart_data.push({
                  ['date_' + i]: new Date(data[j].key_as_string),
                  ['value_' + i]: data[j][this.graph_type + this.params[i]].value
                });
              }
            }
          }

          for (let i = 0; i < data.length; i++) {

            if (data[i].min_total_w) {

              min_value.total_w.push(data[i].min_total_w.value)
              max_value.total_w.push(data[i].max_total_w.value)

              this.min.total_w_class = 'd-block';
              this.max.total_w_class = 'd-block';

            } else {

              this.min.total_w_class = 'd-none';
              this.min.total_w_class = 'd-none';

            }
            
            if (data[i].min_w_r) {

              min_value.w_r.push(data[i].min_w_r.value)
              max_value.w_r.push(data[i].max_w_r.value)
    
              this.min.w_r_class = 'd-block';
              this.max.w_r_class = 'd-block';

            } else {

              this.min.w_r_class = 'd-none';
              this.max.w_r_class = 'd-none';

            }
            
            if (data[i].min_w_y) {

              min_value.w_y.push(data[i].min_w_y.value)
              max_value.w_y.push(data[i].max_w_y.value)

              this.min.w_y_class = 'd-block';
              this.max.w_y_class = 'd-block';

            } else {

              this.min.w_y_class = 'd-none';
              this.max.w_y_class = 'd-none';

            }
            
            if (data[i].min_w_b) {

              min_value.w_b.push(data[i].min_w_b.value)
              max_value.w_b.push(data[i].max_w_b.value)

              this.min.w_b_class = 'd-block';
              this.max.w_b_class = 'd-block';

            }  else {

              this.min.w_b_class = 'd-none';
              this.max.w_b_class = 'd-none';

            }
            
          }
 
          this.min.total_w_value = (this.min.total_w_class == 'd-block') ? Math.min(...min_value.total_w) : 0;
          this.max.total_w_value = (this.min.total_w_class == 'd-block') ? Math.max(...max_value.total_w) : 0;

          this.min.w_r_value = (this.min.w_r_class == 'd-block') ? Math.min(...min_value.w_r) : 0;
          this.max.w_r_value = (this.min.w_r_class == 'd-block') ? Math.max(...max_value.w_r) : 0;

          this.min.w_y_value = (this.min.w_y_class == 'd-block') ? Math.min(...min_value.w_y) : 0;
          this.max.w_y_value = (this.min.w_y_class == 'd-block') ? Math.max(...max_value.w_y) : 0;

          this.min.w_b_value = (this.min.w_b_class == 'd-block') ? Math.min(...min_value.w_b) : 0;
          this.max.w_b_value = (this.min.w_b_class == 'd-block') ? Math.max(...max_value.w_b) : 0;

          let abc;

          for (let i = 0; i < data.length; i++) {

            if (data[i].min_total_w) {

              if (this.min.total_w_value == data[i].min_total_w.value) {
                this.min.total_w_timestamp = new Date(data[i].key_as_string)
              }

              if (this.max.total_w_value == data[i].max_total_w.value) {
                this.max.total_w_timestamp = new Date(data[i].key_as_string)
              }
            } 

            if (data[i].min_w_r) {

              if (this.min.w_r_value == data[i].min_w_r.value) {
                this.min.w_r_timestamp = new Date(data[i].key_as_string)
              }

              if (this.max.w_r_value == data[i].max_w_r.value) {
                this.max.w_r_timestamp = new Date(data[i].key_as_string)
              }

            } 

            if (data[i].min_w_y) {

              if (this.min.w_y_value == data[i].min_w_y.value) {
                this.min.w_y_timestamp = new Date(data[i].key_as_string)
              }

              if (this.max.w_y_value == data[i].max_w_y.value) {
                this.max.w_y_timestamp = new Date(data[i].key_as_string)
              }

            } 

            if (data[i].min_w_b) {

              if (this.min.w_b_value == data[i].min_w_b.value) {
                this.min.w_b_timestamp = new Date(data[i].key_as_string)
              }

              if (this.max.w_b_value == data[i].max_w_b.value) {
                this.max.w_b_timestamp = new Date(data[i].key_as_string)
              }

            } 
          }

          this.chart_data = chart_data;
          let props = this.getGraphProp('line', chart_data);
          this.implementComparativeChart(props);
        }
      }
    });
  }

  getGraphProp(series, param_data) {

    let data = {
      element: 'chartTotalW',
      title: this.graph_type,
      data: param_data,
      interval: {
        'interval': 'minute',
        'value': 1
      },
      series: series,
      tooltipFormat: 'HH:mm:ss, d MMMM',
      xScrollbar: false
    };
    return data;
  }

  implementComparativeChart(data) {

    am4core.useTheme(am4themes_animated);

    this.chart = am4core.create(data.element, am4charts.XYChart);
    this.chart.data = data.data;
    this.chart.colors.step = 2;

    // Create axes
    let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.adapter.remove("getTime");

    if (this.params[0] == 'total_f') this.units = [this.unit]  

    // Create series
    if (data.data[0]) {
      if (data.data[0].avg == undefined) {
        for (let i = 0; i < this.params.length; i++) {

          if (i % 2) {
            this.createAxisAndSeries("value_" + i, "date_" + i, this.dataShared.paramters_list[this.params[i]], true, data.series, this.units[i]);
          } else {
            this.createAxisAndSeries("value_" + i, "date_" + i, this.dataShared.paramters_list[this.params[i]], false, data.series, this.units[i]);
          }
        }
      } else {
        this.createAxisAndSeries("avg", "date", 'Avg ' + this.dataShared.paramters_list[this.params[0]], false, data.series, this.units[0]);
        this.createAxisAndSeries("min", "date", 'Min ' + this.dataShared.paramters_list[this.params[0]], false, data.series, this.units[0]);
        this.createAxisAndSeries("max", "date", 'Max ' + this.dataShared.paramters_list[this.params[0]], false, data.series, this.units[0]);
      }
    }


    // Add legend
    this.chart.legend = new am4charts.Legend();
    // Add cursor
    this.chart.cursor = new am4charts.XYCursor();
  }

  createAxisAndSeries(field, date, name, opposite, type, unit) {

    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());

    let series_type;
    if (type == 'line') series_type = new am4charts.LineSeries();
    if (type == 'bar') series_type = new am4charts.ColumnSeries();

    // let units = unit;
    let series = this.chart.series.push(series_type);
    series.dataFields.valueY = field;
    series.dataFields.dateX = date;
    series.strokeWidth = 2;
    series.yAxis = valueAxis;
    series.name = name;
    series['unit'] = unit;
    series.tooltipText = "{name}: [bold]{valueY}[/] {unit}";
    if (type == 'line') series.tensionX = 0.8;
    series.showOnInit = true;

    // let interfaceColors = new am4core.InterfaceColorSet(); 

    let scrollbarX = new am4core.Scrollbar();
    scrollbarX.marginBottom = 20;
    this.chart.scrollbarX = scrollbarX;

    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.line.strokeWidth = 2;
    valueAxis.renderer.line.stroke = series.stroke;
    valueAxis.renderer.labels.template.fill = series.stroke;
    valueAxis.renderer.opposite = opposite;

  }

  dateSelection(e) {
    this.from_date = e.start['_d'].toISOString().substring(0, 10);
    this.to_date = e.end['_d'].toISOString().substring(0, 10);
  }

  loadPage(page: number) {
    if (page !== this.previousPage) {

      this.previousPage = page - 1;
      this.from = this.previousPage * this.pageSize
      this.size = 25;
      this.getSingleDeviceTable();
    }
  }

  getSingleDeviceTable() {

    let tabular_data = []; let data = [];
    this.tabular_data = []; this.action = "getTabularDataByAccount"; this.size = 25;

    // this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

    //   if (res.data.hits) {

    //     let data = res.data.hits;

    //     for (let i = 0; i < data.length; i++) {

    //       tabular_data.push({
    //         date: String(new Date(data[i]['@timestamp'])),
    //         val_total_w: (data[i].total_w) ? data[i].total_w : 0.00,
    //         val_w_r: (data[i].w_r) ? data[i].w_r : 0.00,
    //         val_w_y: (data[i].w_y) ? data[i].w_y : 0.00,
    //         val_w_b: (data[i].w_b) ? data[i].w_b : 0.00
    //       })

    //     }
    //     this.show_pagination = true;
    //     this.total_found = res.data.total_found;

    //     // this.getTableExport();
    //   }
    //   this.tabular_data = tabular_data;

    //   this.location = this.dataShared.device_detail.location;
    // });

    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      if (res.data.hits) {
        data = res.data.hits;
        data.forEach(data => {
          data['@timestamp'] = data['timestamp'] = (new Date(data['@timestamp']).toLocaleString());
          delete data['@timestamp'];

          tabular_data.push(data);

        });
        this.show_pagination = true;
        this.tabular_data = tabular_data;
        this.total_found = res.data.total_found;
        this.location = this.dataShared.device_detail.location;
      } else {
        this.tabular_data = [];
      }
    });
    this.getExportTable()
  }

  getExportTable() {

    let export_tabular_data = []; let data = []; this.export_tabular_data = []; 
    let body = {
      'action': "getTabularDataByAccount",
      'user_id': [localStorage.getItem('user')],
      'device_id': (this.device_id != undefined) ? [this.device_id] : [this.dataShared.device_id],
      'date': this.to_date,
      'parameters': this.params,
      'sort': "asc",
      'from': 0,
      'size': 9999
    }

    this.dashboardService.httpPost(body).subscribe((res: any) => {

      if (res.data.hits) {
        data = res.data.hits;
        data.forEach(data => {
          data['@timestamp'] = data['timestamp'] = (new Date(data['@timestamp']).toLocaleString());
          delete data['@timestamp'];

          export_tabular_data.push(data);

        });
        this.export_tabular_data = export_tabular_data;

      } else {
        this.export_tabular_data = [];
      }
    });
  }
  
  onItemSelectTrend(item: any) {
    if (this.graph_type == 'all') this.params = [];
    this.params.push(item['parameter']);
  }

  onItemDeSelectTrend(item: any) {
    let index = this.params.indexOf(item['parameter'], 0)
    if (index > -1) this.params.splice(index, 1);
  }

  getTitleByParamCode(key) {
    return this.dataShared.getTitleByParamCode(key);
  }

  tabLineChart(element) {

    if (element == 'line') {
      this.implementComparativeChart(this.getGraphProp('line', this.chart_data)); this.chart_active = 'line';
    }
  }

  tabBarChart(element) {

    if (element == 'bar') {
      this.implementComparativeChart(this.getGraphProp('bar', this.chart_data)); this.chart_active = 'bar';
    }
  }

  exportAsCSV() {
    this.dataShared.exportDataAsCsvFormat(this.export_tabular_data);
  }

  getDataByParam() {

    return this.chart_data;

  }

  exportAsPDF(e) {
    let data = [];
    if (data = this.getDataByParam()) this.dataShared.exportDataAsPdfFormat(data, e, this.data_param_el);
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }
}