import { Component, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { EmitterService } from 'src/app/shared/services/event-service';
import { DataSharedService } from 'src/app/shared/services/data-shared.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { ThemeHeader } from '../../common';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiFunctionsComponent } from 'src/app/widgets/api-functions/api-functions.component';

@Component({
  selector: 'zainergy-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  constructor(private dashboardService: DashboardService, private authService: LoginService, private eventEmitter: EmitterService, private dataShared: DataSharedService, private renderer: Renderer2, private modalService: NgbModal, public api: ApiFunctionsComponent) { }

  profile = ThemeHeader.profile
  profile_url = ThemeHeader.profile_url
  logout = ThemeHeader.logout
  public profile_logo = 'assets/images/faces/face1.jpg';
  public user: any;
  public device_status = 'Online';
  public device_class = 'device-default';
  public last_received = '';
  public header_title: any = ' / Dashboard / Single Device';
  public device_location: any = '';
  public card_interval: number;
  public closeResult;
  public config_interval: number;
  public separator: string = ' / ';
  public nm: any = [];
  public nm_site: any = "";
  public nm_sites: any = [];

  ngOnInit(): void {

    this.currentUser();
    this.setMainLogo();

    this.eventEmitter.listen('deviceStatus', (data) => {
      this.deviceStatus(data);
    });

    this.eventEmitter.listen('menuForHeader', (header_title) => {
      let title = ' / ' + header_title.replace(/~/gi, ' / ');
      this.header_title = title.replace(/-/gi, ' ');
      this.nmSites();
    });

    // this.card_interval = + setInterval(() => {
    //   this.getRelayStatus()
    // }, 5000);

  }

  setMainLogo() {

    // let body = {
    //   "action": "getLogo",
    //   "account_id": localStorage.getItem('account')
    // }
    // this.dashboardService.httpPost(body).subscribe((res: any) => {
    //   this.profile_logo = (res.data[0]) ? 'https://app.zainergy.com/backend/zainergy/assets/' + res.data[0].private_hash + '?key=directus-small-contain' : 'assets/images/zainergy-logo.svg';
    // })

    if (localStorage.getItem('user') == '20') {
      this.profile_logo = 'assets/images/faces/zi-solar1.png';
    }
  }

  onChangeNmSites(e) {
    this.dataShared.nm_site = e.target.value;
    this.eventEmitter.emit(('nmDevices'), [this.nm[e.target.value], this.dataShared.nm_site])
  }

  nmSites() {
    // run in every 1 seconds
    this.nm_sites = [];
    this.config_interval = + setInterval(() => {
      if (this.api.setting) {

        clearInterval(this.config_interval);

        if (this.api.setting.config) {
          this.nm = (JSON.parse(this.api.setting.config[0].meta_value).nm) ? JSON.parse(this.api.setting.config[0].meta_value).nm : undefined;
          this.separator = (this.dataShared.curr_menu == 'pv-net-metering' && this.nm && !this.nm.ke) ? ' / ' : '';
          document.getElementById('nm-select').style.display = (this.dataShared.curr_menu == 'pv-net-metering' && this.nm && !this.nm.ke) ? 'block' : 'none';

          if (this.dataShared.curr_menu == 'pv-net-metering' && this.nm && !this.nm.ke) {
            Object.keys(this.nm).forEach(e => {
              this.nm_sites.push({
                key: e,
                value: e.replace(/_/gi, ' ')
              })
            });
          }
          if (this.dataShared.curr_menu == 'pv-net-metering' && this.nm && !this.nm.ke) this.dataShared.nm_site = this.nm_sites[0].key;
          this.eventEmitter.emit(('nmDevices'), (this.dataShared.curr_menu == 'pv-net-metering' && this.nm && !this.nm.ke) ? [this.nm[this.nm_sites[0].key], this.nm_sites[0].key] : [this.nm])

        }
      }
    }, 1000);
  }

  myFunction() {
    var element = document.body;
    element.classList.toggle('text-white bg-dark');
  }

  currentUser() {
    this.authService.getUser().subscribe((res: any) => {
      if (res?.data || res?.user) {
        this.user = (res.data) ? res.data : res.user;
        this.dataShared.user = res.data.company
      }
    })
  }

  deviceStatus(data) {
    this.device_class = (data.device_status === true ? 'device-active' : 'device-warning');
    this.last_received = data.timestamp;
    this.device_location = this.dataShared.device_detail?.location;
  }

  toggleClass() {
    this.dataShared.toggleSidebar = !this.dataShared.toggleSidebar;
    let toggle_class = 'open-sidebar';

    if (!this.dataShared.toggleSidebar) this.renderer.addClass(document.body, toggle_class);
    else this.renderer.removeClass(document.body, toggle_class);
  }

  getRelayStatus() {

    let body = {
      "action": "getLatestStatsByDevice",
      "device_id": this.dataShared.device_id,
      "parameters": ["relay_status", "device_id"],
      "status": true,
      "user_id": localStorage.getItem('user'),
      "account_id": localStorage.getItem('account')
    }

    this.dashboardService.httpPost(body).subscribe((res: any) => {

      if (res.data) {
        this.dataShared.relay_status = (res.data.relay_status) ? res.data.relay_status : "abc"
        this.dataShared.relay_statu = (res.data) ? res.data : []
      }

    })
  }

  openVerticallyCentered(content) {
    this.closeResult = this.modalService.open(content, { centered: true });
  }

  logOut() {
    this.closeResult.close();
    this.authService.logout()
  }
}