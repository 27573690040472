<div class="main-wrapper">

    <zainergy-main-sidebar></zainergy-main-sidebar>

    <zainergy-control-panel></zainergy-control-panel>

    <div class="page-wrapper">

        <zainergy-header></zainergy-header>

        <div class="page-content">

            <router-outlet></router-outlet>

        </div>

    </div>

</div>