import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { DataSharedService } from 'src/app/shared/services/data-shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-button-management',
  templateUrl: './button-management.component.html',
  styleUrls: ['./button-management.component.scss']
})
export class ButtonManagementComponent implements OnInit {

  public action: string = '';
  public power_button;
  public devices: any = [];
  public device_id;
  public button: any = { control: 0, flag: 0, };
  public card_interval: number;
  public sch: any = {
    "mon": {
      "on": "",
      "off": "",
      "param": 0,
      "model": ""
    },
    "tue": {
      "on": "",
      "off": "",
      "param": 0,
      "model": ""
    },
    "wed": {
      "on": "",
      "off": "",
      "param": 0,
      "model": ""
    },
    "thu": {
      "on": "",
      "off": "",
      "param": 0,
      "model": ""
    },
    "fri": {
      "on": "",
      "off": "",
      "param": 0,
      "model": ""
    },
    "sat": {
      "on": "",
      "off": "",
      "param": 0,
      "model": ""
    },
    "sun": {
      "on": "",
      "off": "",
      "param": 0,
      "model": ""
    }
  };
  public sch_data: any = {};
  public scheduling: any = {};
  public pin: any = { code: '', status: false };
  public days: any = '';
  public modalClose: any;
  public last_updated: any = "";
  public curr_tab = 'tab-button';
  public device_params: any = [];
  public count_status: false;
  public date: string;
  public power_att;

  constructor(private dashboardService: DashboardService, private toastr: ToastrService, private modalService: NgbModal, private dataShared: DataSharedService) { }

  ngOnInit(): void {

    this.getCurrDateTime(); this.getDashboardSettings(); this.getDevices();

    this.card_interval = + setInterval(() => {
      this.getRelayStatus();

    }, 1000);

  }

  // allow premission
  getDashboardSettings() {
    this.action = 'getSettings';
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {
      if (res.status == 200) {
        let meta_value = JSON.parse(res.data?.config[0].meta_value);
        this.pin.code = meta_value.button_password
      }
    })
  }

  // for curr date and time 
  getCurrDateTime() {
    var currentdate = new Date();
    var datetime = String(currentdate.getDate()).padStart(2, "0") + "/"
      + String((currentdate.getMonth() + 1)).padStart(2, "0") + "/"
      + String(currentdate.getFullYear()).padStart(2, "0") + " @ "
      + String(currentdate.getHours()).padStart(2, "0") + ":"
      + String(currentdate.getMinutes()).padStart(2, "0") + ":"
      + String(currentdate.getSeconds()).padStart(2, "0");

    this.date = currentdate.getFullYear() + '-' + String(currentdate.getMonth() + 1).padStart(2, '0') + '-' + String(currentdate.getDate()).padStart(2, '0');
    return datetime;
  }

  // for tab change 
  tabChange($event: NgbTabChangeEvent) {

    this.curr_tab = $event.nextId;
    if (this.curr_tab == 'tab-button') this.getDevices();
  }

  // api bodies
  getBody() {

    let body = {
      action: this.action,
    }

    if (this.action == 'getSettings') {
      body['meta_key'] = 'config';
      body['user_id'] = localStorage.getItem('user');
      body['account_id'] = localStorage.getItem('account');
    }

    if (this.action == 'setButtonState') {
      body["columns"] = ["device_id", "scheduling"];
      body["account"] = localStorage.getItem('account');
      body["device_id"] = this.device_id;
      body["btf"] = this.button.flag;
      body["btc"] = this.button.control;
      body["user_id"] = localStorage.getItem('user');
      body["scheduling"] = this.scheduling;
    }

    if (this.action == 'getDevices' || this.action == 'getDeviceActivity') {
      body["account_id"] = localStorage.getItem('account');
      body["user_id"] = localStorage.getItem('user');
    }

    if (this.action == 'getButtonState') {
      body["account"] = localStorage.getItem('account'),
        body["user_id"] = localStorage.getItem('user'),
        body['device_id'] = this.device_id,
        body["columns"] = ["id", "device_id", "btc", "btf", "scheduling"]
    }
    return body;
  }

  // get device detail by account and user
  getDevices() {

    this.action = 'getDevices';
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      // set value in button 
      this.getDeviceLocationForButton(res.data);
    })
  }

  // for post api to device using aws api gateway to lambda 
  awsApiGateway(payload) {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(payload);

    fetch("https://7gusvkf0lb.execute-api.eu-central-1.amazonaws.com/reverse/reverse_lambda", {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    })
      .then(response => response.text())
      .then(result => this.countdown(this.device_id))
      .catch(error => { this.toastr.error('Some thing went wrong') });
  }

  // button function for show 
  getDeviceLocationForButton(device_detail) {

    let devices = []; this.devices = [];

    for (let i = 0; i < device_detail.length; i++) {

      if (device_detail[i].power_button == "1") {

        devices.push({
          id: device_detail[i].id,
          location: device_detail[i].location,
          device_id: device_detail[i].device_id,
          topic: device_detail[i].aws_thing_topic,
          device_model: device_detail[i].device_model,
          visibility: (device_detail[i].device_model != 'Prepaid') ? 'visible' : 'invisible',
          card_border: 'danger',
          button_status: false,
          toggle_status: false,
          power_button_parameter: device_detail[i].power_button_parameter,
          power_att: i,
          card: i * i * i,
          count_status: false,
          checkingState: i * 1,
        });

      }
    }
    this.devices = devices;
    this.checkButtonState(); this.getDeviceStatus();
  }

  // changing state as behalf save values 
  checkButtonState() {

    this.action = 'getButtonState'; this.device_id = undefined;
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      for (let i = 0; i < this.devices.length; i++) {

        for (let j = 0; j < res.data.length; j++) {

          if (res.data[j].device_id == this.devices[i].device_id) {

            this.devices[i].card_border = (res.data[j].btc == 1) ? 'success' : 'danger';
            this.devices[i].button_status = (res.data[j].btc == 1) ? true : false;
            this.devices[i].toggle_status = (res.data[j].btf == 1) ? true : false;
          }
        }
      }
    })
  }

  // get device status 
  getDeviceStatus() {

    this.action = 'getDeviceActivity';
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      if (res.status == 200) {

        for (let i = 0; i < res.data.activity.length; i++) {

          for (let j = 0; j < this.devices.length; j++) {

            if (res.data.activity[i].device_id == this.devices[j].device_id) {

              this.devices[j].status = (res.data.activity[i].status) ? 'device-active' : 'device-warning';

            }

          }
        }
      }
    })
  }

  // security pin for button 
  pincode(input_element, id, location) {

    if (this.pin.code == input_element.target.value) {

      this.pin.state = true;
      document.getElementById(location).style.display = 'flex';
      document.getElementById(id).style.display = 'none';
    }

  }

  // event handler for button
  mouseEvHandler(e, location, id) {

    if (e == false) {
      document.getElementById(location).style.opacity = '100%';
      document.getElementById(location).style.display = 'flex';
      document.getElementById(id).style.display = 'none';

    }
    else if (e == true) {
      document.getElementById(location).style.opacity = '100%';
      document.getElementById(location).style.display = 'flex';
      document.getElementById(location).style.position = 'relative';
      document.getElementById(id).style.display = 'flex';
      document.getElementById(id).style.position = 'absolute';
      document.getElementById(id).style.zIndex = '1';
    }

    else if (e == true && this.pin.state == true) {
      document.getElementById(location).style.opacity = '100%';
      document.getElementById(location).style.display = 'flex';
      document.getElementById(id).style.display = 'none';
    }
  }

  // ngb model for open 
  openModal(content, device_id, days, model) {

    if (content._declarationTContainer.localNames[0] == 'sch_model') {

      this.modalClose = this.modalService.open(content, { size: 'xl' });
      this.getSchedulingTable(device_id);

    } else {

      this.modalClose = this.modalService.open(content, { centered: true });
      this.days = days;

    }
  }

  // ngb model for close 
  clearModal(day, device_id) {

    this.modalClose.close(); this.device_id = device_id

    if (day == 'Monday') day = 'mon';
    if (day == 'Tuesday') day = 'tue';
    if (day == 'Wednesday') day = 'wed';
    if (day == 'Thursday') day = 'thu';
    if (day == 'Friday') day = 'fri';
    if (day == 'Saturday') day = 'sat';
    if (day == 'Sunday') day = 'sun';

    this.sch[day].on = "";
    this.sch[day].off = "";
    this.sch[day].param = "";

    if (this.sch_data && this.sch_data[day] && this.sch_data[day][this.sch.mon.model + 'on']) {

      this.sch_data[day]['t1_on'] = "";
      this.sch_data[day]['t1_off'] = "";
      this.sch_data[day]['t1_param'] = "";

      this.sch_data[day]['t2_on'] = "";
      this.sch_data[day]['t2_off'] = "";
      this.sch_data[day]['t2_param'] = "";

      this.sch_data[day]['t3_on'] = "";
      this.sch_data[day]['t3_off'] = "";
      this.sch_data[day]['t3_param'] = "";

      this.scheduling = this.sch_data;
    }

  }

  onChangeButton(e, device_id, topic, power_button_parameter, power_att) {

    this.device_id = device_id;

    if (e.target.checked == true) {
      this.button.control = 1;
      e.target.checked = false;
    } else {
      this.button.control = 0;
      e.target.checked = true;
    }


    if (topic == undefined) this.toastr.error('INFORMATION NOT PUBLISH')

    for (let i = 0; i < this.devices.length; i++) {

      if (this.device_id == this.devices[i].device_id) {
        this.button.flag = (this.devices[i].toggle_status == true) ? 1 : 0;
      }

    }

    let payload = {
      "topic": topic,
      "payload": {
        "btf": this.button.flag,
        "btc": this.button.control
      }
    }

    if (power_button_parameter != '' && power_button_parameter != null) payload["payload"]['m_param'] = 0
    this.awsApiGateway(payload)
  }

  onChangeButtonSate(e, device_id, topic) {

    this.device_id = device_id; this.scheduling = {};

    for (let i = 0; i < this.devices.length; i++) {

      if (this.device_id == this.devices[i].device_id) {

        this.devices[i].toggle_status = e.target.checked
        this.button.control = (this.devices[i].button_status == true) ? 1 : 0;
        this.button.flag = (e.target.checked == true) ? 1 : 0;

        if (this.devices[i].device_model == 'Prepaid') {

          if (topic == undefined) this.toastr.error('INFORMATION NOT PUBLISH')

          let payload = {
            "topic": topic,
            "payload": {
              "btf": this.button.flag
            }
          }
          this.awsApiGateway(payload)
        }
      }
    }
  }

  getSchedulingTable(device_id) {

    this.action = "getButtonState"; this.device_id = device_id; this.sch_data;
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      this.sch_data = JSON.parse(res.data[0].scheduling);

      if (res.data[0] && res.data[0].scheduling) {

        if (this.sch_data['mon']) {
          if (this.sch_data['mon']['t1_on']) {

            this.sch.mon.on = this.sch_data['mon']['t1_on']
            this.sch.mon.off = this.sch_data['mon']['t1_off']
            this.sch.mon.param = this.sch_data['mon']['t1_param']
            this.sch.mon.model = 't1_';

          } else if (this.sch_data['mon']['t2_on']) {

            this.sch.mon.on = this.sch_data['mon']['t2_on']
            this.sch.mon.off = this.sch_data['mon']['t2_off']
            this.sch.mon.param = this.sch_data['mon']['t2_param']
            this.sch.mon.model = 't2_';

          } else if (this.sch_data['mon']['t3_on']) {

            this.sch.mon.on = this.sch_data['mon']['t3_on']
            this.sch.mon.off = this.sch_data['mon']['t3_off']
            this.sch.mon.param = this.sch_data['mon']['t3_param']
            this.sch.mon.model = 't3_';

          } else {

            this.sch.mon.on = ""
            this.sch.mon.off = ""
            this.sch.mon.param = ""
            this.sch.mon.model = "";

          }
        } else {

          this.sch.mon.on = ""
          this.sch.mon.off = ""
          this.sch.mon.param = ""
          this.sch.mon.model = "";

        }

        if (this.sch_data['tue']) {
          if (this.sch_data['tue']['t1_on']) {

            this.sch.tue.on = this.sch_data['tue']['t1_on']
            this.sch.tue.off = this.sch_data['tue']['t1_off']
            this.sch.tue.param = this.sch_data['tue']['t1_param']
            this.sch.tue.model = 't1_';

          } else if (this.sch_data['tue']['t2_on']) {

            this.sch.tue.on = this.sch_data['tue']['t2_on']
            this.sch.tue.off = this.sch_data['tue']['t2_off']
            this.sch.tue.param = this.sch_data['tue']['t2_param']
            this.sch.tue.model = 't2_';

          } else if (this.sch_data['tue']['t3_on']) {

            this.sch.tue.on = this.sch_data['tue']['t3_on']
            this.sch.tue.off = this.sch_data['tue']['t3_off']
            this.sch.tue.param = this.sch_data['tue']['t3_param']
            this.sch.tue.model = 't3_';

          } else {

            this.sch.tue.on = ""
            this.sch.tue.off = ""
            this.sch.tue.param = ""
            this.sch.tue.model = "";

          }
        } else {

          this.sch.tue.on = ""
          this.sch.tue.off = ""
          this.sch.tue.param = ""
          this.sch.tue.model = "";

        }

        if (this.sch_data['wed']) {
          if (this.sch_data['wed']['t1_on']) {

            this.sch.wed.on = this.sch_data['wed']['t1_on']
            this.sch.wed.off = this.sch_data['wed']['t1_off']
            this.sch.wed.param = this.sch_data['wed']['t1_param']
            this.sch.wed.model = 't1_';

          } else if (this.sch_data['wed']['t2_on']) {

            this.sch.wed.on = this.sch_data['wed']['t2_on']
            this.sch.wed.off = this.sch_data['wed']['t2_off']
            this.sch.wed.param = this.sch_data['wed']['t2_param']
            this.sch.wed.model = 't2_';

          } else if (this.sch_data['wed']['t3_on']) {

            this.sch.wed.on = this.sch_data['wed']['t3_on']
            this.sch.wed.off = this.sch_data['wed']['t3_off']
            this.sch.wed.param = this.sch_data['wed']['t3_param']
            this.sch.wed.model = 't3_';

          } else {

            this.sch.wed.on = ""
            this.sch.wed.off = ""
            this.sch.wed.param = ""
            this.sch.wed.model = "";

          }
        } else {

          this.sch.wed.on = ""
          this.sch.wed.off = ""
          this.sch.wed.param = ""
          this.sch.wed.model = "";

        }

        if (this.sch_data['thu']) {
          if (this.sch_data['thu']['t1_on']) {

            this.sch.thu.on = this.sch_data['thu']['t1_on']
            this.sch.thu.off = this.sch_data['thu']['t1_off']
            this.sch.thu.param = this.sch_data['thu']['t1_param']
            this.sch.thu.model = 't1_';

          } else if (this.sch_data['thu']['t2_on']) {

            this.sch.thu.on = this.sch_data['thu']['t2_on']
            this.sch.thu.off = this.sch_data['thu']['t2_off']
            this.sch.thu.param = this.sch_data['thu']['t2_param']
            this.sch.thu.model = 't2_';

          } else if (this.sch_data['thu']['t3_on']) {

            this.sch.thu.on = this.sch_data['thu']['t3_on']
            this.sch.thu.off = this.sch_data['thu']['t3_off']
            this.sch.thu.param = this.sch_data['thu']['t3_param']
            this.sch.thu.model = 't3_';

          } else {

            this.sch.thu.on = ""
            this.sch.thu.off = ""
            this.sch.thu.param = ""
            this.sch.thu.model = "";

          }
        } else {

          this.sch.thu.on = ""
          this.sch.thu.off = ""
          this.sch.thu.param = ""
          this.sch.thu.model = "";

        }

        if (this.sch_data['fri']) {
          if (this.sch_data['fri']['t1_on']) {

            this.sch.fri.on = this.sch_data['fri']['t1_on']
            this.sch.fri.off = this.sch_data['fri']['t1_off']
            this.sch.fri.param = this.sch_data['fri']['t1_param']
            this.sch.fri.model = 't1_';

          } else if (this.sch_data['fri']['t2_on']) {

            this.sch.fri.on = this.sch_data['fri']['t2_on']
            this.sch.fri.off = this.sch_data['fri']['t2_off']
            this.sch.fri.param = this.sch_data['fri']['t2_param']
            this.sch.fri.model = 't2_';

          } else if (this.sch_data['fri']['t3_on']) {

            this.sch.fri.on = this.sch_data['fri']['t3_on']
            this.sch.fri.off = this.sch_data['fri']['t3_off']
            this.sch.fri.param = this.sch_data['fri']['t3_param']
            this.sch.fri.model = 't3_';

          } else {

            this.sch.fri.on = ""
            this.sch.fri.off = ""
            this.sch.fri.param = ""
            this.sch.fri.model = "";

          }
        } else {

          this.sch.fri.on = ""
          this.sch.fri.off = ""
          this.sch.fri.param = ""
          this.sch.fri.model = "";

        }

        if (this.sch_data['sat']) {
          if (this.sch_data['sat']['t1_on']) {

            this.sch.sat.on = this.sch_data['sat']['t1_on']
            this.sch.sat.off = this.sch_data['sat']['t1_off']
            this.sch.sat.param = this.sch_data['sat']['t1_param']
            this.sch.sat.model = 't1_';

          } else if (this.sch_data['sat']['t2_on']) {

            this.sch.sat.on = this.sch_data['sat']['t2_on']
            this.sch.sat.off = this.sch_data['sat']['t2_off']
            this.sch.sat.param = this.sch_data['sat']['t2_param']
            this.sch.sat.model = 't2_';

          } else if (this.sch_data['sat']['t3_on']) {

            this.sch.sat.on = this.sch_data['sat']['t3_on']
            this.sch.sat.off = this.sch_data['sat']['t3_off']
            this.sch.sat.param = this.sch_data['sat']['t3_param']
            this.sch.sat.model = 't3_';

          } else {

            this.sch.sat.on = ""
            this.sch.sat.off = ""
            this.sch.sat.param = ""
            this.sch.sat.model = "";

          }
        } else {

          this.sch.sat.on = ""
          this.sch.sat.off = ""
          this.sch.sat.param = ""
          this.sch.sat.model = "";

        }

        if (this.sch_data['sun']) {
          if (this.sch_data['sun']['t1_on']) {

            this.sch.sun.on = this.sch_data['sun']['t1_on']
            this.sch.sun.off = this.sch_data['sun']['t1_off']
            this.sch.sun.param = this.sch_data['sun']['t1_param']
            this.sch.sun.model = 't1_';

          } else if (this.sch_data['sun']['t2_on']) {

            this.sch.sun.on = this.sch_data['sun']['t2_on']
            this.sch.sun.off = this.sch_data['sun']['t2_off']
            this.sch.sun.param = this.sch_data['sun']['t2_param']
            this.sch.sun.model = 't2_';

          } else if (this.sch_data['sun']['t3_on']) {

            this.sch.sun.on = this.sch_data['sun']['t3_on']
            this.sch.sun.off = this.sch_data['sun']['t3_off']
            this.sch.sun.param = this.sch_data['sun']['t3_param']
            this.sch.sun.model = 't3_';

          } else {

            this.sch.sun.on = ""
            this.sch.sun.off = ""
            this.sch.sun.param = ""
            this.sch.sun.model = "";

          }
        } else {

          this.sch.sun.on = ""
          this.sch.sun.off = ""
          this.sch.sun.param = ""
          this.sch.sun.model = "";

        }

        this.last_updated = (this.sch_data['last_updated']) ? this.sch_data['last_updated'] : "";

      } else {
        this.sch = {
          "mon": {
            "on": "",
            "off": "",
            "param": 0,
            "model": ""
          },
          "tue": {
            "on": "",
            "off": "",
            "param": 0,
            "model": ""
          },
          "wed": {
            "on": "",
            "off": "",
            "param": 0,
            "model": ""
          },
          "thu": {
            "on": "",
            "off": "",
            "param": 0,
            "model": ""
          },
          "fri": {
            "on": "",
            "off": "",
            "param": 0,
            "model": ""
          },
          "sat": {
            "on": "",
            "off": "",
            "param": 0,
            "model": ""
          },
          "sun": {
            "on": "",
            "off": "",
            "param": 0,
            "model": ""
          }
        };

        this.last_updated = "";
      }
    })
  }

  onChangeScheduling(day, model, sch, e) {

    if (sch == 'model') {

      if (model == 't2_') {

        if (this.sch_data && this.sch_data[day]) {
          if (this.sch_data[day]['t1_on'] == undefined || this.sch_data[day]['t1_on'] == '' || this.sch_data[day]['t1_off'] == undefined || this.sch_data[day]['t1_off'] == '') {

            e.target.value = 't1_';
            this.sch[day].model = 't1_';
            return this.toastr.error('First fill Previous Schedule');

          }

        } else {

          e.target.value = 't1_';
          this.sch[day].model = 't1_';
          return this.toastr.error('First fill Previous Schedule');
        }
      }

      if (model == 't3_') {

        if (this.sch_data && this.sch_data[day]) {
          if (this.sch_data[day]['t2_on'] == undefined || this.sch_data[day]['t2_on'] == '' || this.sch_data[day]['t2_off'] == undefined || this.sch_data[day]['t2_off'] == '') {

            e.target.value = 't1_';
            this.sch[day].model = 't1_';
            return this.toastr.error('First fill Previous Schedule');

          }
        } else {

          e.target.value = 't1_';
          this.sch[day].model = 't1_';
          return this.toastr.error('First fill Previous Schedule');
        }
      }

      if (this.sch_data && this.sch_data[day]) {

        this.sch[day].on = (this.sch_data[day][model + 'on']) ? this.sch_data[day][model + 'on'] : "";
        this.sch[day].off = (this.sch_data[day][model + 'off']) ? this.sch_data[day][model + 'off'] : "";
        this.sch[day].param = (this.sch_data[day][model + 'param']) ? this.sch_data[day][model + 'param'] : "";

      } else {

        this.sch[day].on = "";
        this.sch[day].off = "";
        this.sch[day].param = "";

      }
    } else {

      if (this.sch[day].model == "") {
        this.sch[day].on = "";
        this.sch[day].off = "";
        this.sch[day].param = "";
        return this.toastr.error('Please select schedule');
      }



      if (this.sch_data) {
        (this.sch_data[day]) ? this.sch_data[day][model + sch] = e.target.value : this.sch_data[day] = { [model + sch]: e.target.value };
      } else {
        this.sch_data = { [day]: { [model + sch]: e.target.value } };
      }

      this.scheduling[day] = this.sch_data[day];
    }

  }

  updateScduling(topic) {

    if (topic == undefined) return this.toastr.error('INFORMATION NOT PUBLISH');

    this.modalClose.close();
    this.scheduling['last_updated'] = this.getCurrDateTime();

    let payload = {
      "topic": topic,
      "payload": {
        "btf": this.button.flag
      }
    }
    this.awsApiGateway(payload)
  }

  setButtonState() {

    this.action = 'setButtonState';
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      if (res.data == 'button state inserted' || res.data == 'button state updated' || res.data == 'nothing to updated') {
        this.toastr.success((res.data).toUpperCase());
        this.getDevices();
      }
      else this.toastr.error((res.data).toUpperCase());
    });
    // clearInterval(this.card_interval);
  }

  countdown(device_id) {

    for (let i = 0; i < this.devices.length; i++) {

      if (this.devices[i].device_id == device_id) {

        // this.devices[i].count_status = true;
        // document.getElementById(this.devices[i].location).style.opacity = '15%';
        // document.getElementById(this.devices[i].power_att).setAttribute('disabled', '');
        // document.getElementById(this.devices[i].device_id).setAttribute('disabled', '');
        // document.getElementById(this.devices[i].id).style.visibility = 'hidden';
        // document.getElementById(this.devices[i].id).setAttribute('disabled', '');
        // document.getElementById(this.devices[i].checkingState).style.display = 'flex'
        
        if (this.devices[i].toggle_status == true) {
          this.setButtonState();
        }

      }
      // setTimeout(() => {

      //   if (this.devices[i].device_id == device_id) {
      //     this.devices[i].count_status = false;
      //     document.getElementById(this.devices[i].location).style.opacity = '100%';
      //     document.getElementById(this.devices[i].power_att).removeAttribute('disabled');
      //     document.getElementById(this.devices[i].device_id).removeAttribute('disabled');
      //     document.getElementById(this.devices[i].id).style.visibility = 'visible';
      //     document.getElementById(this.devices[i].id).removeAttribute('disabled');
      //     document.getElementById(this.devices[i].checkingState).style.display = 'none';
      //   }
      // }, 20000);

    }

  }

  getRelayStatus() {

    for (let i = 0; i < this.devices.length; i++) {

      if (this.dataShared.relay_statu) {

        if (this.dataShared.relay_statu.device_id == this.devices[i].device_id) {

          this.devices[i].card_border = (this.dataShared.relay_status == 'on') ? 'success' : 'danger';
          // this.devices[i].toggle_status = (this.dataShared.relay_status == 'on') ? true : false;
          this.devices[i].button_status = (this.dataShared.relay_status == 'on') ? true : false;

        }
      }

    }

  }
}