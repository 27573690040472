/**
* main sidebar constants
* @author Faisal Siddiqui <faisal.siddiqui@qavitechnologies.com>
* @comapany Qavi Technologies
*/
export class ThemeSideBar {
    public static dashboardKey: string = 'dashboard';
    public static dashboardValue: string = 'Dashboard';
    public static dashboardUrl: string = '/dashboard';
    public static dashboardIcon: string = 'feather feather-monitor';

    public static dashboardSingleKey: string = 'dashboard~single';
    public static dashboardSingleValue: string = 'Single';
    public static dashboardSingleUrl: string = '/dashboard/single';

    public static dashboardCollectiveKey: string = 'dashboard~collective';
    public static dashboardCollectiveValue: string = 'Collective';
    public static dashboardCollectiveUrl: string = '/dashboard/collective';

    public static dashboardComparativeKey: string = 'dashboard~comparative';
    public static dashboardComparativeValue: string = 'Comparative';
    public static dashboardComparativeUrl: string = '/dashboard/comparative';

    public static analyticsKey: string = 'analytics';
    public static analyticsValue: string = 'Analytics';
    public static analyticsUrl: string = '/analysis';
    public static analyticsIcon: string = 'feather feather-activity';

    public static analysisEnergyKey: string = 'analysis-energy-consumption';
    public static analysisEnergyValue: string = 'Energy Trend';
    public static analysisEnergyUrl: string = '/analysis/energy-consumption';

    public static analysisEnergySingleKey: string = 'analysis~energy~single';
    public static analysisEnergySingleValue: string = 'Single';
    public static analysisEnergySingleUrl: string = '/analysis/energy/single';
    
    public static analysisEnergyCollectiveKey: string = 'analysis~energy~collective';
    public static analysisEnergyCollectiveValue: string = 'Collective';
    public static analysisEnergyCollectiveUrl: string = '/analysis/energy/collective';

    public static analysisEnergyComparativeKey: string = 'analysis~energy~comparative';
    public static analysisEnergyComparativeValue: string = 'Comparative';
    public static analysisEnergyComparativeUrl: string = '/analysis/energy/comparative';

    public static customerManagmentKey: string = 'customer-management';
    public static customerManagmentValue: string = 'Customer Management';
    public static customerManagmentUrl: string = '/customer-management';
    public static customerManagmentIcon: string = 'feather feather-users';

    public static customerManagmentConfigurationKey: string = 'customer-management~configuration';
    public static customerManagmentConfigurationValue: string = 'Configuration';
    public static customerManagmentConfigurationUrl: string = '/customer-management/configuration';

    public static customerManagmentDeviceDataStatusKey: string = 'customer-management~device-data-status';
    public static customerManagmentDeviceDataStatusValue: string = 'Device Data Status';
    public static customerManagmentDeviceDataStatusUrl: string = '/customer-management/device-data-status';
    
    public static analysisPowerKey: string = 'analysis~power-trend';
    public static analysisPowerValue: string = 'Power Trend';
    public static analysisPowerUrl: string = '/analysis/power-trend';

    public static analysisHeatMapKey: string = 'analysis~heat-map';
    public static analysisHeatMapValue: string = 'Heat Map';
    public static analysisHeatMapUrl: string = '/analysis/heat-map';

    public static analysisShareKey: string = 'analysis~share-consumption';
    public static analysisShareValue: string = 'Share Consumption';
    public static analysisShareUrl: string = '/analysis/share-consumption';

    public static analysisMinMaxKey: string = 'analysis~min-max';
    public static analysisMinMaxValue: string = 'Min Max';
    public static analysisMinMaxUrl: string = '/analysis/min-max';

    public static pvGenerationReportKey: string = 'pv-generation-report';
    public static pvGenerationReportValue: string = 'PV Generation Report';
    public static pvGenerationReportUrl: string = '/pv-generation-report';
    public static pvGenerationReportIcon: string = 'feather feather-cast';

    public static pvNetMeteringKey: string = 'pv-net-metering';
    public static pvNetMeteringValue: string = 'PV Net Metering';
    public static pvNetMeteringUrl: string = '/pv-net-metering';
    public static pvNetMeteringIcon: string = 'feather feather-cast';

    public static pvStringMonitoringKey: string = 'pv-string-monitoring';
    public static pvStringMonitoringValue: string = 'PV String Monitoring';
    public static pvStringMonitoringUrl: string = '/pv-string-monitoring';
    public static pvStringMonitoringIcon: string = 'feather feather-airplay';

    public static chartsKey: string = 'charts';
    public static chartsValue: string = 'Charts';
    public static chartsUrl: string = '/charts';
    public static chartsIcon: string = 'feather feather-activity';

    public static buttonManagementKey: string = 'button-management';
    public static buttonManagementValue: string = 'button-management';
    public static buttonManagementUrl: string = '/button-management';
    public static buttonManagementIcon: string = 'feather feather-power';

    public static statusKey: string = 'status';
    public static statusValue: string = 'Status';
    public static statusUrl: string = '/status';
    public static statusIcon: string = 'feather feather-clipboard';

    public static alarmKey: string = 'alarm-logs';
    public static alarmValue: string = 'Alarm Logs';
    public static alarmUrl: string = '/alarm-logs';
    public static alarmIcon: string = 'feather feather-bell';

    public static powerMonitoringKey: string = 'power-monitoring';
    public static powerMonitoringValue: string = 'Power Monitoring';
    public static powerMonitoringUrl: string = '/power-monitoring';
    public static powerMonitoringIcon: string = 'feather feather-bell';

    public static favoriteKey: string = 'favorite';
    public static favoriteValue: string = 'Favorite / Summary';
    public static favoriteUrl: string = '/favorite';
    public static favoriteIcon: string = 'feather feather-star';

    public static billingSummaryKey: string = 'billing-summary';
    public static billingSummaryValue: string = 'Billing Summary';
    public static billingSummaryUrl: string = '/billing-summary';
    public static billingSummaryIcon: string = 'feather feather-file-text';

    public static settingsKey: string = 'settings';
    public static settingsValue: string = 'Settings';
    public static settingsUrl: string = '/settings';
    public static settingsIcon: string = 'feather feather-settings';
}