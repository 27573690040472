<input id='step2' type='checkbox' class="main-checkbox">
<input id='step3' type='checkbox' class="main-checkbox">
<input id='step4' type='checkbox' class="main-checkbox">
<input id='step5' type='checkbox' class="main-checkbox">
<input id='step6' type='checkbox' class="main-checkbox">
<input id='step7' type='checkbox' class="main-checkbox">
<input id='step8' type='checkbox' class="main-checkbox">

<!-- account  -->
<div id="account" class="form-group">

    <form class="p-4">

        <legend><span class="number">1</span>Account</legend>

        <label class="mt-4">Status:</label>

        <div style="display: inline-flex">

            <input type="radio" id="published" name="status" (change)="onChangeButton('status', 'published')"><label
                for="published">Published</label>

            <input class="ml-5" type="radio" id="draft" name="status"
                (change)="onChangeButton('status', 'draft')"><label for="draft">Draft</label>

            <input class="ml-5" type="radio" id="deleted" name="status"
                (change)="onChangeButton('status', 'deleted')"><label for="deleted">Deleted</label>

        </div>

        <div class="form-row mt-4">

            <div class="form-group col-md-6">
                <label for="account">Account:</label>
                <select id="account" [(ngModel)]="setting.account" [ngModelOptions]="{standalone: true}">

                    <option value="" selected disabled>Select a account</option>
                    <option value="{{ account.id }}" *ngFor="let account of accounts">{{ account.name }}</option>

                </select>
            </div>

            <div class="form-group col-md-6">
                <label for="meta-key">Meta Key:</label>
                <input type="text" id="meta-key" [(ngModel)]="setting.meta_key" [ngModelOptions]="{standalone: true}">
            </div>

        </div>

        <div class="btn-group btn-group-lg" role="group" aria-label="...">

            <label for='step2' id="continue-step2" class="continue">
                <div class="btn btn-default btn-success btn-lg" role="button">Continue</div>
            </label>

        </div>
    </form>

</div>

<!-- meta-value -->
<div id="meta-value" class="form-group">

    <form class="p-4">
        <legend><span class="number">2</span>Meta Value</legend>

        <div class="form-row mt-5">

            <div class="form-group col-md-4">

                <div class="d-inline-flex">
                    <input type="checkbox" id="mv_opt_alow_disk_space_checkbox"
                        (change)="onChangeButton('alow_disk_space', $event)"><label
                        for="mv_opt_alow_disk_space_checkbox">Alow Disk
                        Space</label>
                </div>

                <input type="number" [(ngModel)]="mv_opt.allow_disk_space.value" [ngModelOptions]="{standalone: true}"
                    [disabled]="mv_opt.allow_disk_space.disabled">

            </div>

            <div class="form-group col-md-4">

                <div class="d-inline-flex">
                    <input type="checkbox" id="mv_opt_peak_checkbox" (change)="onChangeButton('peak', $event)"><label
                        for="mv_opt_peak_checkbox">Peak
                        Start ( For Energy )</label>
                </div>

                <input type="time" [(ngModel)]="mv_opt.peak.start" [ngModelOptions]="{standalone: true}"
                    [disabled]="mv_opt.peak.disabled">

            </div>

            <div class="form-group col-md-4">

                <label for="mv_opt_peak_checkbox">Peak End</label>

                <input type="time" [(ngModel)]="mv_opt.peak.end" [ngModelOptions]="{standalone: true}"
                    [disabled]="mv_opt.peak.disabled">

            </div>

        </div>

        <div class="d-inline-flex">
            <input type="checkbox" id="mv_opt_location_checkbox" (change)="onChangeButton('location', $event)"><label
                for="mv_opt_location_checkbox">Location</label>
        </div>

        <div class="form-row">

            <div class="form-group col-md-4">
                <label for="mv_opt_city">City</label>

                <ng-multiselect-dropdown id="mv_opt_city" [placeholder]="'Select city'"
                    [settings]="locationDropdownSettings" [data]="location.cities.dropdownList"
                    [(ngModel)]="location.cities.selectedItems" [ngModelOptions]="{standalone: true}">
                </ng-multiselect-dropdown>

            </div>

            <div class="form-group col-md-4">

                <label for="mv_opt_state">State</label>
                <select id="mv_opt_state" [(ngModel)]="mv_opt.location.state" [ngModelOptions]="{standalone: true}"
                    [disabled]="mv_opt.location.disabled">
                    <option value="" selected disabled>Select state</option>
                    <option value="{{ state.key }}" *ngFor="let state of location.states">{{ state.value }}</option>
                </select>
            </div>

            <div class="form-group col-md-4">

                <label for="mv_opt_country">Country</label>
                <input type="text" [(ngModel)]="mv_opt.location.country" [ngModelOptions]="{standalone: true}"
                    [disabled]="true">

            </div>

        </div>

        <div class="d-inline-flex">
            <input type="checkbox" id="mv_opt_nm_checkbox" (change)="onChangeButton('nm', $event)"><label
                for="mv_opt_nm_checkbox">Net Metering</label>
        </div>

        <div class="form-row">

            <div class="form-group col-md-4">

                <div class="d-inline-flex">
                    <input type="checkbox" class="{{mv_opt.nm.display}}"
                        (change)="onChangeButton('ke', $event)"><label>KE</label>
                </div>

                <ng-multiselect-dropdown [placeholder]="'Select ke devices'" [settings]="nmDropdownSettings"
                    [data]="mv_opt.nm.ke.dropdownList" [(ngModel)]="mv_opt.nm.ke.value"
                    [ngModelOptions]="{standalone: true}">
                </ng-multiselect-dropdown>

            </div>

            <div class="form-group col-md-4">

                <div class="d-inline-flex">
                    <input type="checkbox" class="{{mv_opt.nm.display}}"
                        (change)="onChangeButton('pv', $event)"><label>PV</label>
                </div>

                <ng-multiselect-dropdown [placeholder]="'Select pv devices'" [settings]="nmDropdownSettings"
                    [data]="mv_opt.nm.pv.dropdownList" [(ngModel)]="mv_opt.nm.pv.value"
                    [ngModelOptions]="{standalone: true}">
                </ng-multiselect-dropdown>

            </div>

            <div class="form-group col-md-4">

                <div class="d-inline-flex">
                    <input type="checkbox" class="{{mv_opt.nm.display}}"
                        (change)="onChangeButton('gs', $event)"><label>GS</label>
                </div>

                <ng-multiselect-dropdown [placeholder]="'Select gs devices'" [settings]="nmDropdownSettings"
                    [data]="mv_opt.nm.gs.dropdownList" [(ngModel)]="mv_opt.nm.gs.value"
                    [ngModelOptions]="{standalone: true}">
                </ng-multiselect-dropdown>


            </div>

        </div>

        <div class="form-row">

            <div class="form-group col-md-3">

                <div class="d-inline-flex">
                    <input type="checkbox" id="mv_opt_btn_pass_checkbox"
                        (change)="onChangeButton('btn_pass', $event)"><label for="mv_opt_btn_pass_checkbox">Button
                        Password</label>
                </div>

                <input type="text" [(ngModel)]="mv_opt.btn_password.value" [ngModelOptions]="{standalone: true}"
                    [disabled]="mv_opt.btn_password.disabled">

            </div>

            <div class="form-group col-md-3">

                <div class="d-inline-flex">
                    <input type="checkbox" id="mv_opt_level_unit_checkbox"
                        (change)="onChangeButton('level_unit', $event)"><label for="mv_opt_level_unit_checkbox">Level
                        Unit</label>
                </div>

                <input type="text" [(ngModel)]="mv_opt.level_unit.value" [ngModelOptions]="{standalone: true}"
                    [disabled]="mv_opt.level_unit.disabled">

            </div>

            <div class="form-group col-md-3">

                <div class="d-inline-flex">
                    <input type="checkbox" id="mv_opt_flow_unit_checkbox"
                        (change)="onChangeButton('flow_unit', $event)"><label for="mv_opt_flow_unit_checkbox">Flow
                        Unit</label>
                </div>

                <input type="text" [(ngModel)]="mv_opt.flow_unit.value" [ngModelOptions]="{standalone: true}"
                    [disabled]="mv_opt.flow_unit.disabled">

            </div>

            <div class="form-group col-md-3">

                <div class="d-inline-flex">
                    <input type="checkbox" id="mv_opt_flow_rate_checkbox"
                        (change)="onChangeButton('flow_rate', $event)"><label for="mv_opt_flow_rate_checkbox">Flow
                        Rate</label>
                </div>

                <input type="number" [(ngModel)]="mv_opt.flow_rate.value" [ngModelOptions]="{standalone: true}"
                    [disabled]="mv_opt.flow_rate.disabled">

            </div>


        </div>

        <div class="btn-group btn-group-lg" role="group" aria-label="...">

            <label for='step2' id="back-step2" class="back mr-2">
                <div class="btn btn-default btn-warning btn-lg" role="button">Back</div>
            </label>

            <label for='step3' id="continue-step3" class="continue">
                <div class="btn btn-default btn-success btn-lg" role="button">Continue</div>
            </label>

        </div>

    </form>

</div>

<!-- dashboard setting -->
<div id="dashboard-setting" class="form-group">

    <form class="p-4">

        <div style="justify-content: end;">

            <label class="back mr-2">
                <div class="btn btn-default btn-warning btn-lg" role="button" (click)="defaultSettings('dashboard')">
                    Default</div>
            </label>

        </div>

        <legend><span class="number">3</span>Dashboard Setting</legend>

        <div class="form-row mt-5">

            <div class="form-group col-md-6">
                <label for="setting_default_menu">Default Menu / Landing Page:</label>
                <select id="setting_default_menu" [(ngModel)]="dashboard_setting.default_menu"
                    [ngModelOptions]="{standalone: true}">

                    <option value="" selected disabled>Select a Default Menu</option>
                    <option value="{{ menu_list }}" *ngFor="let menu_list of order_menu_list">{{ menu_list }}</option>

                </select>
            </div>


        </div>

        <div class="form-row">

            <h4><span class="number">a</span></h4>

            <div class="form-group col-md-6">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_status"
                        [(ngModel)]="dashboard_setting.parent_menu_1.status" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="dashboard_status"></label>
                    <input type="text" [(ngModel)]="dashboard_setting.parent_menu_1.value"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>

            </div>
        </div>

        <hr>

        <div class="form-row">

            <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_single"
                        [(ngModel)]="dashboard_setting.parent_menu_1.chlid_menu_1.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="dashboard_single"></label>
                    <input type="text" [(ngModel)]="dashboard_setting.parent_menu_1.chlid_menu_1.value"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>

            </div>

            <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_collective"
                        [(ngModel)]="dashboard_setting.parent_menu_1.chlid_menu_2.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="dashboard_collective"></label>
                    <input type="text" [(ngModel)]="dashboard_setting.parent_menu_1.chlid_menu_2.value"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>

            </div>

            <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_comparative"
                        [(ngModel)]="dashboard_setting.parent_menu_1.chlid_menu_3.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="dashboard_comparative"></label>
                    <input type="text" [(ngModel)]="dashboard_setting.parent_menu_1.chlid_menu_3.value"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>

            </div>
        </div>

        <hr>

        <div class="form-row mt-5">

            <h4><span class="number">b</span></h4>

            <div class="form-group col-md-6">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="analytics_status"
                        [(ngModel)]="dashboard_setting.parent_menu_2.status" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="analytics_status"></label>
                    <input type="text" [(ngModel)]="dashboard_setting.parent_menu_2.value"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>

            </div>
        </div>

        <hr>

        <div class="form-row">

            <div class="form-group col-md-6">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="energy_trend_status"
                        [(ngModel)]="dashboard_setting.parent_menu_2.chlid_menu_1.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="energy_trend_status"></label>
                    <input type="text" [(ngModel)]="dashboard_setting.parent_menu_2.chlid_menu_1.value"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>

            </div>
        </div>

        <div class="form-row">

            <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="energy_trend_single"
                        [(ngModel)]="dashboard_setting.parent_menu_2.chlid_menu_1.sub_chlid_1.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="energy_trend_single"></label>
                    <input type="text" [(ngModel)]="dashboard_setting.parent_menu_2.chlid_menu_1.sub_chlid_1.value"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>

            </div>

            <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="energy_trend_collective"
                        [(ngModel)]="dashboard_setting.parent_menu_2.chlid_menu_1.sub_chlid_2.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="energy_trend_collective"></label>
                    <input type="text" [(ngModel)]="dashboard_setting.parent_menu_2.chlid_menu_1.sub_chlid_2.value"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>

            </div>

            <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="energy_trend_comparative"
                        [(ngModel)]="dashboard_setting.parent_menu_2.chlid_menu_1.sub_chlid_3.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="energy_trend_comparative"></label>
                    <input type="text" [(ngModel)]="dashboard_setting.parent_menu_2.chlid_menu_1.sub_chlid_3.value"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>

            </div>
        </div>

        <hr>

        <div class="form-row">

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="power_trend"
                        [(ngModel)]="dashboard_setting.parent_menu_2.chlid_menu_2.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="power_trend"></label>
                    <input type="text" [(ngModel)]="dashboard_setting.parent_menu_2.chlid_menu_2.value"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>

            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="heat_map"
                        [(ngModel)]="dashboard_setting.parent_menu_2.chlid_menu_3.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="heat_map"></label>
                    <input type="text" [(ngModel)]="dashboard_setting.parent_menu_2.chlid_menu_3.value"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>

            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="share_consumption"
                        [(ngModel)]="dashboard_setting.parent_menu_2.chlid_menu_4.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="share_consumption"></label>
                    <input type="text" [(ngModel)]="dashboard_setting.parent_menu_2.chlid_menu_4.value"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>
            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="min_max"
                        [(ngModel)]="dashboard_setting.parent_menu_2.chlid_menu_5.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="min_max"></label>
                    <input type="text" [(ngModel)]="dashboard_setting.parent_menu_2.chlid_menu_5.value"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>


            </div>
        </div>

        <hr>

        <div class="form-row mt-5">

            <h4><span class="number">c</span></h4>

            <div class="form-group col-md-6">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_net_metering"
                        [(ngModel)]="dashboard_setting.parent_menu_3.status" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="pv_net_metering"></label>
                    <input type="text" [(ngModel)]="dashboard_setting.parent_menu_3.value"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>

            </div>
        </div>

        <div class="form-row">

            <h4><span class="number">d</span></h4>

            <div class="form-group col-md-6">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_string_monitoring"
                        [(ngModel)]="dashboard_setting.parent_menu_4.status" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="pv_string_monitoring"></label>
                    <input type="text" [(ngModel)]="dashboard_setting.parent_menu_4.value"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>

            </div>
        </div>

        <div class="form-row">

            <h4><span class="number">e</span></h4>

            <div class="form-group col-md-6">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="charts"
                        [(ngModel)]="dashboard_setting.parent_menu_5.status" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="charts"></label>
                    <input type="text" [(ngModel)]="dashboard_setting.parent_menu_5.value"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>

            </div>
        </div>

        <div class="form-row">

            <h4><span class="number">f</span></h4>

            <div class="form-group col-md-6">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="Status"
                        [(ngModel)]="dashboard_setting.parent_menu_6.status" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="Status"></label>
                    <input type="text" [(ngModel)]="dashboard_setting.parent_menu_6.value"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>

            </div>
        </div>

        <div class="form-row">

            <h4><span class="number">g</span></h4>

            <div class="form-group col-md-6">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="alarm_logs"
                        [(ngModel)]="dashboard_setting.parent_menu_7.status" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="alarm_logs"></label>
                    <input type="text" [(ngModel)]="dashboard_setting.parent_menu_7.value"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>

            </div>
        </div>

        <div class="form-row">

            <h4><span class="number">h</span></h4>

            <div class="form-group col-md-6">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="favorite"
                        [(ngModel)]="dashboard_setting.parent_menu_8.status" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="favorite"></label>
                    <input type="text" [(ngModel)]="dashboard_setting.parent_menu_8.value"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>

            </div>
        </div>

        <div class="form-row">

            <h4><span class="number">i</span></h4>

            <div class="form-group col-md-6">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="billing_summary"
                        [(ngModel)]="dashboard_setting.parent_menu_9.status" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="billing_summary"></label>
                    <input type="text" [(ngModel)]="dashboard_setting.parent_menu_9.value"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>

            </div>
        </div>

        <div class="form-row">

            <h4><span class="number">j</span></h4>

            <div class="form-group col-md-6">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="settings"
                        [(ngModel)]="dashboard_setting.parent_menu_10.status" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="settings"></label>
                    <input type="text" [(ngModel)]="dashboard_setting.parent_menu_10.value"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>

            </div>
        </div>

        <div class="form-row">

            <h4><span class="number">k</span></h4>

            <div class="form-group col-md-6">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="button_management"
                        [(ngModel)]="dashboard_setting.parent_menu_11.status" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="button_management"></label>
                    <input type="text" [(ngModel)]="dashboard_setting.parent_menu_11.value"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>

            </div>
        </div>

        <div class="btn-group btn-group-lg mt-5" role="group" aria-label="...">

            <label for='step3' id="back-step3" class="back mr-2">
                <div class="btn btn-default btn-warning btn-lg" role="button">Back</div>
            </label>

            <label for='step4' id="continue-step4" class="continue">
                <div class="btn btn-default btn-success btn-lg" role="button">Continue</div>
            </label>

        </div>
    </form>

</div>

<!-- tab setting -->
<div id="tab-setting" class="form-group">

    <form class="p-4">

        <div style="justify-content: end;">

            <label class="back mr-2">
                <div class="btn btn-default btn-warning btn-lg" role="button" (click)="defaultSettings('tab')">Default
                </div>
            </label>

        </div>

        <legend><span class="number">4</span>Tab Setting</legend>

        <h5><span class="number mt-5 mb-2" style="font-size: larger;">a</span>Dashboard</h5>

        <hr>

        <h5><span class="number" style="font-size: large;">i</span>Single</h5>

        <div class="form-row mt-3">

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_single_trends"
                        [(ngModel)]="tab_setting.allow_tab.dashboard.single.trends"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_single_trends">Trends</label>
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_single_graph"
                        [(ngModel)]="tab_setting.allow_tab.dashboard.single.graph"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_single_graph">Graph</label>
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_single_table"
                        [(ngModel)]="tab_setting.allow_tab.dashboard.single.table"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_single_table">Table</label>
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_single_overview"
                        [(ngModel)]="tab_setting.allow_tab.dashboard.single.overview"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_single_overview">Overview</label>
                </div>
            </div>

        </div>

        <hr>

        <h5><span class="number" style="font-size: large;">ii</span>Collective</h5>

        <div class="form-row mt-3">

            <div class="form-group col-md-6">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_collective_graph"
                        [(ngModel)]="tab_setting.allow_tab.dashboard.collective.graph"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_collective_graph">Graph</label>
                </div>
            </div>

            <div class="form-group col-md-6">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_collective_table"
                        [(ngModel)]="tab_setting.allow_tab.dashboard.collective.table"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_collective_table">Table</label>
                </div>
            </div>

        </div>

        <hr>

        <h5><span class="number" style="font-size: large;">iii</span>Comparative</h5>

        <div class="form-row mt-3">

            <div class="form-group col-md-6">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_comparative_graph"
                        [(ngModel)]="tab_setting.allow_tab.dashboard.comparative.graph"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_comparative_graph">Graph</label>
                </div>
            </div>

            <div class="form-group col-md-6">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_comparative_table"
                        [(ngModel)]="tab_setting.allow_tab.dashboard.comparative.table"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_comparative_table">Table</label>
                </div>
            </div>

        </div>

        <hr>

        <h5><span class="number mt-5 mb-2" style="font-size: larger;">b</span>Analytics</h5>

        <hr>

        <h5><span class="number" style="font-size: large;">i</span>Energy Trend - Single</h5>

        <div class="form-row mt-3">

            <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="analytics_energy_trend_single_graph"
                        [(ngModel)]="tab_setting.allow_tab.analytics.energy_trend.single.graph"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="analytics_energy_trend_single_graph">Graph</label>
                </div>
            </div>

            <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input"
                        id="analytics_energy_trend_single_consumer_billing"
                        [(ngModel)]="tab_setting.allow_tab.analytics.energy_trend.single.consumer_billing"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="analytics_energy_trend_single_consumer_billing">Consumer Billing</label>
                </div>
            </div>

            <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input"
                        id="analytics_energy_trend_single_payment_summary"
                        [(ngModel)]="tab_setting.allow_tab.analytics.energy_trend.single.payment_summary"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="analytics_energy_trend_single_payment_summary">Payment Summary</label>
                </div>
            </div>

        </div>

        <hr>

        <h5><span class="number mt-5 mb-2" style="font-size: larger;">c</span>Pv String Monitoring</h5>

        <hr>

        <div class="form-row">

            <div class="form-group col-md-2">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_string_monitoring_graph"
                        [(ngModel)]="tab_setting.allow_tab.pv_string_monitoring.graph"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_string_monitoring_graph">Graph</label>
                </div>
            </div>

            <div class="form-group col-md-2">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_string_monitoring_table"
                        [(ngModel)]="tab_setting.allow_tab.pv_string_monitoring.table"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_string_monitoring_table">Table</label>
                </div>
            </div>

            <div class="form-group col-md-2">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_string_monitoring_comparative"
                        [(ngModel)]="tab_setting.allow_tab.pv_string_monitoring.comparative"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_string_monitoring_comparative">Comparative</label>
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_string_monitoring_comparative_table"
                        [(ngModel)]="tab_setting.allow_tab.pv_string_monitoring.comparative_table"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_string_monitoring_comparative_table">Comparative Table</label>
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_string_monitoring_pv_generation_report"
                        [(ngModel)]="tab_setting.allow_tab.pv_string_monitoring.pv_generation_report"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_string_monitoring_pv_generation_report">Pv Generation Report</label>
                </div>
            </div>

        </div>

        <hr>

        <h5><span class="number mt-5 mb-2" style="font-size: larger;">d</span>Pv Net Metering</h5>

        <hr>

        <div class="form-row">

            <div class="form-group col-md-2">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_net_metering_graph"
                        [(ngModel)]="tab_setting.allow_tab.pv_net_metering.graph" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_net_metering_graph">Graph</label>
                </div>
            </div>

            <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_net_metering_billing_and_income_table"
                        [(ngModel)]="tab_setting.allow_tab.pv_net_metering.billing_and_income_table"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_net_metering_billing_and_income_table">Billing and Income Table</label>
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_net_metering_saving_table"
                        [(ngModel)]="tab_setting.allow_tab.pv_net_metering.saving_table"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_net_metering_saving_table">Saving Table</label>
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_net_metering_pv_yeild_energy"
                        [(ngModel)]="tab_setting.allow_tab.pv_net_metering.pv_yeild_energy"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_net_metering_pv_yeild_energy">Pv Yeild Energy</label>
                </div>
            </div>

        </div>

        <div class="form-row">

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_net_metering_consumption"
                        [(ngModel)]="tab_setting.allow_tab.pv_net_metering.consumption"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_net_metering_consumption">Consumption</label>
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_net_metering_energy_balance"
                        [(ngModel)]="tab_setting.allow_tab.pv_net_metering.energy_balance"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_net_metering_energy_balance">Energy Balance</label>
                </div>
            </div>

            <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_net_metering_plant_performance_by_table"
                        [(ngModel)]="tab_setting.allow_tab.pv_net_metering.plant_performance_by_table"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_net_metering_plant_performance_by_table">Plant Performance By Table</label>
                </div>
            </div>

        </div>

        <div class="form-row">

            <div class="form-group col-md-6">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_net_metering_plant_performance_by_graph"
                        [(ngModel)]="tab_setting.allow_tab.pv_net_metering.plant_performance_by_graph"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_net_metering_plant_performance_by_graph">Plant Performance By Graph</label>
                </div>
            </div>

            <div class="form-group col-md-6">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input"
                        id="pv_net_metering_basis_of_plant_performance_calculation"
                        [(ngModel)]="tab_setting.allow_tab.pv_net_metering.basis_of_plant_performance_calculation"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_net_metering_basis_of_plant_performance_calculation">Basis of Plant Performance
                        Calculation</label>
                </div>
            </div>

        </div>

        <hr>

        <h5><span class="number mt-5 mb-2" style="font-size: larger;">e</span>Status</h5>

        <hr>

        <div class="form-row">

            <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="status_device_status"
                        [(ngModel)]="tab_setting.allow_tab.status.device_status" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="status_device_status">Device
                        Status</label>
                </div>
            </div>

            <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="status_memory_status"
                        [(ngModel)]="tab_setting.allow_tab.status.memory_status" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="status_memory_status">Memory
                        Status</label>
                </div>
            </div>

            <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="status_internet_quality"
                        [(ngModel)]="tab_setting.allow_tab.status.internet_quality"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="status_internet_quality">Internet Quality</label>
                </div>
            </div>

        </div>

        <hr>

        <h5><span class="number mt-5 mb-2" style="font-size: larger;">f</span>Settings</h5>

        <hr>

        <div class="form-row">

            <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="settings_alarm"
                        [(ngModel)]="tab_setting.allow_tab.settings.alarm" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="settings_alarm">Alarm</label>
                </div>
            </div>

            <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="settings_cost"
                        [(ngModel)]="tab_setting.allow_tab.settings.cost" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="settings_cost">Cost</label>
                </div>
            </div>

            <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="settings_counter_correction"
                        [(ngModel)]="tab_setting.allow_tab.settings.counter_correction"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="settings_counter_correction">Counter Correction</label>
                </div>
            </div>

        </div>


        <div class="btn-group btn-group-lg mt-5" role="group" aria-label="...">

            <label for='step4' id="back-step4" class="back mr-2">
                <div class="btn btn-default btn-warning btn-lg" role="button">Back</div>
            </label>

            <label for='step5' id="continue-step5" class="continue">
                <div class="btn btn-default btn-success btn-lg" role="button">Continue</div>
            </label>

        </div>
    </form>

</div>

<!-- kpi setting -->
<div id="kpi-setting" class="form-group">

    <form class="p-4">

        <div style="justify-content: end;">

            <label class="back mr-2">
                <div class="btn btn-default btn-warning btn-lg" role="button" (click)="defaultSettings('kpi')">Default
                </div>
            </label>

        </div>

        <legend><span class="number">5</span>Kpi Setting</legend>

        <h5><span class="number mt-5 mb-2" style="font-size: larger;">a</span>Energy Kpis ( Dashboard / Power Trend )
        </h5>

        <hr>

        <div class="form-row">

            <div class="form-group col-md-2">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_powertrend_kpis_voltage"
                        [(ngModel)]="kpi_setting.dashboard_powertrend_kpis.kpi_1.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_powertrend_kpis_voltage"></label>
                    <input type="text" [(ngModel)]="kpi_setting.dashboard_powertrend_kpis.kpi_1.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-2">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_powertrend_kpis_current"
                        [(ngModel)]="kpi_setting.dashboard_powertrend_kpis.kpi_2.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_powertrend_kpis_current"></label>
                    <input type="text" [(ngModel)]="kpi_setting.dashboard_powertrend_kpis.kpi_2.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-2">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_powertrend_kpis_Kw"
                        [(ngModel)]="kpi_setting.dashboard_powertrend_kpis.kpi_3.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_powertrend_kpis_Kw"></label>
                    <input type="text" [(ngModel)]="kpi_setting.dashboard_powertrend_kpis.kpi_3.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-2">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_powertrend_kpis_frequency"
                        [(ngModel)]="kpi_setting.dashboard_powertrend_kpis.kpi_4.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_powertrend_kpis_frequency"></label>
                    <input type="text" [(ngModel)]="kpi_setting.dashboard_powertrend_kpis.kpi_4.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_powertrend_kpis_power_factor"
                        [(ngModel)]="kpi_setting.dashboard_powertrend_kpis.kpi_5.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_powertrend_kpis_power_factor"></label>
                    <input type="text" [(ngModel)]="kpi_setting.dashboard_powertrend_kpis.kpi_5.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>
        </div>

        <hr>

        <h5><span class="number mt-5 mb-2" style="font-size: larger;">b</span>Flow Meter Kpis</h5>

        <hr>

        <div class="form-row">

            <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_flowmeter_kpis_inst_flow_rate"
                        [(ngModel)]="kpi_setting.dashboard_flowmeter_kpis.kpi_1.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_flowmeter_kpis_inst_flow_rate"></label>
                    <input type="text" [(ngModel)]="kpi_setting.dashboard_flowmeter_kpis.kpi_1.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_flowmeter_kpis_today_flow"
                        [(ngModel)]="kpi_setting.dashboard_flowmeter_kpis.kpi_2.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_flowmeter_kpis_today_flow"></label>
                    <input type="text" [(ngModel)]="kpi_setting.dashboard_flowmeter_kpis.kpi_2.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_flowmeter_kpis_monthly_flow"
                        [(ngModel)]="kpi_setting.dashboard_flowmeter_kpis.kpi_3.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_flowmeter_kpis_monthly_flow"></label>
                    <input type="text" [(ngModel)]="kpi_setting.dashboard_flowmeter_kpis.kpi_3.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

        </div>

        <div class="form-row">

            <div class="form-group col-md-6">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_flowmeter_kpis_yearly_flow"
                        [(ngModel)]="kpi_setting.dashboard_flowmeter_kpis.kpi_4.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_flowmeter_kpis_yearly_flow"></label>
                    <input type="text" [(ngModel)]="kpi_setting.dashboard_flowmeter_kpis.kpi_4.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-6">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_flowmeter_kpis_flow_counter"
                        [(ngModel)]="kpi_setting.dashboard_flowmeter_kpis.kpi_5.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_flowmeter_kpis_flow_counter"></label>
                    <input type="text" [(ngModel)]="kpi_setting.dashboard_flowmeter_kpis.kpi_5.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

        </div>

        <hr>

        <h5><span class="number mt-5 mb-2" style="font-size: larger;">c</span>Sensor Meter Kpis</h5>

        <hr>

        <div class="form-row">

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_sensor_kpis_temperature"
                        [(ngModel)]="kpi_setting.dashboard_sensor_kpis.kpi_1.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_sensor_kpis_temperature"></label>
                    <input type="text" [(ngModel)]="kpi_setting.dashboard_sensor_kpis.kpi_1.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-2">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_sensor_kpis_humidity"
                        [(ngModel)]="kpi_setting.dashboard_sensor_kpis.kpi_2.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_sensor_kpis_humidity"></label>
                    <input type="text" [(ngModel)]="kpi_setting.dashboard_sensor_kpis.kpi_2.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-2">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_sensor_kpis_pressure"
                        [(ngModel)]="kpi_setting.dashboard_sensor_kpis.kpi_3.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_sensor_kpis_pressure"></label>
                    <input type="text" [(ngModel)]="kpi_setting.dashboard_sensor_kpis.kpi_3.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-2">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_sensor_kpis_co2"
                        [(ngModel)]="kpi_setting.dashboard_sensor_kpis.kpi_4.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_sensor_kpis_co2"></label>
                    <input type="text" [(ngModel)]="kpi_setting.dashboard_sensor_kpis.kpi_4.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-2">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_sensor_kpis_live_weather"
                        [(ngModel)]="kpi_setting.dashboard_sensor_kpis.kpi_5.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_sensor_kpis_live_weather"></label>
                    <input type="text" [(ngModel)]="kpi_setting.dashboard_sensor_kpis.kpi_5.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

        </div>

        <hr>

        <h5><span class="number mt-5 mb-2" style="font-size: larger;">d</span>Energy Trend Kpis</h5>

        <hr>

        <div class="form-row">

            <div class="form-group col-md-2">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="energy_trend_kpis_kw"
                        [(ngModel)]="kpi_setting.energy_trend_kpis.kpi_1.status" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;" for="energy_trend_kpis_kw"></label>
                    <input type="text" [(ngModel)]="kpi_setting.energy_trend_kpis.kpi_1.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="energy_trend_kpis_today_energy"
                        [(ngModel)]="kpi_setting.energy_trend_kpis.kpi_2.status" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="energy_trend_kpis_today_energy"></label>
                    <input type="text" [(ngModel)]="kpi_setting.energy_trend_kpis.kpi_2.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="energy_trend_kpis_monthly_energy"
                        [(ngModel)]="kpi_setting.energy_trend_kpis.kpi_3.status" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="energy_trend_kpis_monthly_energy"></label>
                    <input type="text" [(ngModel)]="kpi_setting.energy_trend_kpis.kpi_3.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="energy_trend_kpis_yearly_energy"
                        [(ngModel)]="kpi_setting.energy_trend_kpis.kpi_4.status" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="energy_trend_kpis_yearly_energy"></label>
                    <input type="text" [(ngModel)]="kpi_setting.energy_trend_kpis.kpi_4.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

        </div>

        <div class="form-row">

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="energy_trend_kpis_energy_counter"
                        [(ngModel)]="kpi_setting.energy_trend_kpis.kpi_5.status" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="energy_trend_kpis_energy_counter"></label>
                    <input type="text" [(ngModel)]="kpi_setting.energy_trend_kpis.kpi_5.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

        </div>

        <hr>

        <h5><span class="number mt-5 mb-2" style="font-size: larger;">e</span>Pv String Monitoring Kpis</h5>

        <hr>

        <div class="form-row">

            <div class="form-group col-md-2">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_string_monitoring_kpi_voltage"
                        [(ngModel)]="kpi_setting.pv_string_monitoring_kpi.kpi_1.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_string_monitoring_kpi_voltage"></label>
                    <input type="text" [(ngModel)]="kpi_setting.pv_string_monitoring_kpi.kpi_1.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-2">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_string_monitoring_kpi_current"
                        [(ngModel)]="kpi_setting.pv_string_monitoring_kpi.kpi_2.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_string_monitoring_kpi_current"></label>
                    <input type="text" [(ngModel)]="kpi_setting.pv_string_monitoring_kpi.kpi_2.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-2">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_string_monitoring_kpi_kw"
                        [(ngModel)]="kpi_setting.pv_string_monitoring_kpi.kpi_3.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_string_monitoring_kpi_kw"></label>
                    <input type="text" [(ngModel)]="kpi_setting.pv_string_monitoring_kpi.kpi_3.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_string_monitoring_kpi_today_energy"
                        [(ngModel)]="kpi_setting.pv_string_monitoring_kpi.kpi_4.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_string_monitoring_kpi_today_energy"></label>
                    <input type="text" [(ngModel)]="kpi_setting.pv_string_monitoring_kpi.kpi_4.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-2">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_string_monitoring_kpi_weather"
                        [(ngModel)]="kpi_setting.pv_string_monitoring_kpi.kpi_5.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_string_monitoring_kpi_weather"></label>
                    <input type="text" [(ngModel)]="kpi_setting.pv_string_monitoring_kpi.kpi_5.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

        </div>

        <hr>

        <h5><span class="number mt-5 mb-2" style="font-size: larger;">f</span>Pv Net Metering Kpis</h5>

        <hr>

        <div class="form-row">

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_net_metering_kpis_total_kwh_today"
                        [(ngModel)]="kpi_setting.pv_net_metering_kpis.kpi_1.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_net_metering_kpis_total_kwh_today"></label>
                    <input type="text" [(ngModel)]="kpi_setting.pv_net_metering_kpis.kpi_1.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_net_metering_kpis_pv_yeild_energy"
                        [(ngModel)]="kpi_setting.pv_net_metering_kpis.kpi_2.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_net_metering_kpis_pv_yeild_energy"></label>
                    <input type="text" [(ngModel)]="kpi_setting.pv_net_metering_kpis.kpi_2.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_net_metering_kpis_pv_Self_use"
                        [(ngModel)]="kpi_setting.pv_net_metering_kpis.kpi_3.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_net_metering_kpis_pv_Self_use"></label>
                    <input type="text" [(ngModel)]="kpi_setting.pv_net_metering_kpis.kpi_3.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_net_metering_kpis_export_to_gird"
                        [(ngModel)]="kpi_setting.pv_net_metering_kpis.kpi_4.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_net_metering_kpis_export_to_gird"></label>
                    <input type="text" [(ngModel)]="kpi_setting.pv_net_metering_kpis.kpi_4.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

        </div>

        <div class="form-row">

            <div class="form-group col-md-6">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_net_metering_kpis_import_from_gird"
                        [(ngModel)]="kpi_setting.pv_net_metering_kpis.kpi_5.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_net_metering_kpis_import_from_gird"></label>
                    <input type="text" [(ngModel)]="kpi_setting.pv_net_metering_kpis.kpi_5.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-6">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_net_metering_kpis_genset"
                        [(ngModel)]="kpi_setting.pv_net_metering_kpis.kpi_6.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_net_metering_kpis_genset"></label>
                    <input type="text" [(ngModel)]="kpi_setting.pv_net_metering_kpis.kpi_6.name"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: auto; margin: 0px;">
                </div>
            </div>

        </div>

        <div class="btn-group btn-group-lg mt-5" role="group" aria-label="...">

            <label for='step5' id="back-step5" class="back mr-2">
                <div class="btn btn-default btn-warning btn-lg" role="button">Back</div>
            </label>

            <label for='step6' id="continue-step6" class="continue">
                <div class="btn btn-default btn-success btn-lg" role="button">Continue</div>
            </label>

        </div>

    </form>

</div>

<!-- chart-setting -->
<div id="chart-setting" class="form-group">

    <form class="p-4">

        <div style="justify-content: end;">

            <label class="back mr-2">
                <div class="btn btn-default btn-warning btn-lg" role="button" (click)="defaultSettings('chart')">Default
                </div>
            </label>

        </div>

        <legend><span class="number">6</span>Chart Setting</legend>

        <h5><span class="number mt-5 mb-2" style="font-size: larger;">a</span>Dashboard</h5>

        <hr>

        <h5><span class="number" style="font-size: large;">i</span>Single - Graph</h5>

        <div class="form-row mt-3">

            <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_single_voltage_graph"
                        [(ngModel)]="chart_setting.dashboard.single.graph.chart_1.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_single_voltage_graph"></label>
                    <input type="text" [(ngModel)]="chart_setting.dashboard.single.graph.chart_1.header"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: 100%; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_single_current_graph"
                        [(ngModel)]="chart_setting.dashboard.single.graph.chart_2.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_single_current_graph"></label>
                    <input type="text" [(ngModel)]="chart_setting.dashboard.single.graph.chart_2.header"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: 100%; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_single_power_graph"
                        [(ngModel)]="chart_setting.dashboard.single.graph.chart_3.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_single_power_graph"></label>
                    <input type="text" [(ngModel)]="chart_setting.dashboard.single.graph.chart_3.header"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: 100%; margin: 0px;">
                </div>
            </div>

        </div>

        <div class="form-row">

            <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="dashboard_single_power_factor_graph"
                        [(ngModel)]="chart_setting.dashboard.single.graph.chart_4.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="dashboard_single_power_factor_graph"></label>
                    <input type="text" [(ngModel)]="chart_setting.dashboard.single.graph.chart_4.header"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: 100%; margin: 0px;">
                </div>
            </div>

        </div>

        <hr>

        <h5><span class="number mt-5 mb-2" style="font-size: larger;">b</span>Pv String Monitoring</h5>

        <hr>

        <h5><span class="number" style="font-size: large;">i</span>Graph</h5>

        <div class="form-row mt-3">

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_string_monitoring_power_graph"
                        [(ngModel)]="chart_setting.pv_string_monitoring.graph.chart_1.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_string_monitoring_power_graph"></label>
                    <input type="text" [(ngModel)]="chart_setting.pv_string_monitoring.graph.chart_1.header"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: 100%; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_string_monitoring_current_graph"
                        [(ngModel)]="chart_setting.pv_string_monitoring.graph.chart_2.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_string_monitoring_current_graph"></label>
                    <input type="text" [(ngModel)]="chart_setting.pv_string_monitoring.graph.chart_2.header"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: 100%; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_string_monitoring_energy_graph"
                        [(ngModel)]="chart_setting.pv_string_monitoring.graph.chart_3.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_string_monitoring_energy_graph"></label>
                    <input type="text" [(ngModel)]="chart_setting.pv_string_monitoring.graph.chart_3.header"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: 100%; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_string_monitoring_voltage_graph"
                        [(ngModel)]="chart_setting.pv_string_monitoring.graph.chart_4.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_string_monitoring_voltage_graph"></label>
                    <input type="text" [(ngModel)]="chart_setting.pv_string_monitoring.graph.chart_4.header"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: 100%; margin: 0px;">
                </div>
            </div>

        </div>

        <hr>

        <h5><span class="number mt-5 mb-2" style="font-size: larger;">c</span>Pv Net Metering</h5>

        <hr>

        <h5><span class="number" style="font-size: large;">i</span>Graph</h5>

        <div class="form-row mt-3">

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_net_metering_power_trend_graph"
                        [(ngModel)]="chart_setting.pv_net_metering.graph.chart_1.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_net_metering_power_trend_graph"></label>
                    <input type="text" [(ngModel)]="chart_setting.pv_net_metering.graph.chart_1.header"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: 100%; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_net_metering_current_state_graph"
                        [(ngModel)]="chart_setting.pv_net_metering.graph.chart_2.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_net_metering_current_state_graph"></label>
                    <input type="text" [(ngModel)]="chart_setting.pv_net_metering.graph.chart_2.header"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: 100%; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_net_metering_pv_yeild_energy_graph"
                        [(ngModel)]="chart_setting.pv_net_metering.graph.chart_3.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_net_metering_pv_yeild_energy_graph"></label>
                    <input type="text" [(ngModel)]="chart_setting.pv_net_metering.graph.chart_3.header"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: 100%; margin: 0px;">
                </div>
            </div>

            <div class="form-group col-md-3">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="pv_net_metering_weather_graph"
                        [(ngModel)]="chart_setting.pv_net_metering.graph.chart_4.status"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" style="font-size: medium;"
                        for="pv_net_metering_weather_graph"></label>
                    <input type="text" [(ngModel)]="chart_setting.pv_net_metering.graph.chart_4.header"
                        [ngModelOptions]="{standalone: true}"
                        style="background: none; padding: 0px; width: 100%; margin: 0px;">
                </div>
            </div>

        </div>

        <div class="btn-group btn-group-lg mt-5" role="group" aria-label="...">

            <label for='step6' id="back-step6" class="back mr-2">
                <div class="btn btn-default btn-warning btn-lg" role="button">Back</div>
            </label>

            <label for='step7' id="continue-step7" class="continue">
                <div class="btn btn-default btn-success btn-lg" role="button">Continue</div>
            </label>

        </div>

    </form>

</div>

<!-- cost by month -->
<div id="cost-by-month" class="form-group">

    <form class="p-4">

        <div style="justify-content: end;">

            <label class="back mr-2">
                <div class="btn btn-default btn-warning btn-lg" role="button" (click)="defaultSettings('cost')">Default
                </div>
            </label>

        </div>

        <legend><span class="number">7</span>Cost by Month</legend>

        <div class="form-row mt-5">

            <div class="form-group col-md-4">
                <label for="setting_per_kwh_unit_rate(rs)">Per Kwh Unit Rate (Rs):</label>
                <input type="number" id="setting_per_kwh_unit_rate(rs)"
                    [(ngModel)]="cost_by_month_value.per_kwh_unit_rate" [ngModelOptions]="{standalone: true}">
            </div>

            <div class="form-group col-md-4">
                <label for="setting_selling_rate(rs)">Selling Rate (Rs):</label>
                <input type="number" id="setting_selling_rate(rs)" [(ngModel)]="cost_by_month_value.selling_rate"
                    [ngModelOptions]="{standalone: true}">
            </div>

            <div class="form-group col-md-4">
                <label for="setting_service_charges">Service Charges:</label>
                <input type="number" id="setting_service_charges" [(ngModel)]="cost_by_month_value.service_charges"
                    [ngModelOptions]="{standalone: true}">
            </div>

        </div>

        <div class="form-row">

            <div class="form-group col-md-4">
                <label for="setting_misc_charges">Misc Charges:</label>
                <input type="number" id="setting_misc_charges" [(ngModel)]="cost_by_month_value.misc_charges"
                    [ngModelOptions]="{standalone: true}">
            </div>

            <div class="form-group col-md-4">
                <label for="setting_late_charges">Late Charges:</label>
                <input type="number" id="setting_late_charges" [(ngModel)]="cost_by_month_value.late_charges"
                    [ngModelOptions]="{standalone: true}">
            </div>

            <div class="form-group col-md-4">
                <label for="setting_genset_unit_rate_rs">Genset Unit Rate (Rs):</label>
                <input type="number" id="setting_genset_unit_rate_rs"
                    [(ngModel)]="cost_by_month_value.genset_unit_rate_rs" [ngModelOptions]="{standalone: true}">
            </div>

        </div>

        <div class="btn-group btn-group-lg" role="group" aria-label="...">

            <label for='step7' id="back-step7" class="back mr-2">
                <div class="btn btn-default btn-warning btn-lg" role="button">Back</div>
            </label>

            <label for='step8' id="continue-step8" class="continue">
                <div class="btn btn-default btn-success btn-lg" role="button">Continue</div>
            </label>

        </div>

    </form>

</div>

<!-- solar performance parameters -->
<div id="solar-performance-parameters" class="form-group">

    <form class="p-4">
        <div class="d-inline-flex">

            <h4><span class="number">8</span>Solar Performance Parameters</h4>
            <div class="custom-control custom-switch ml-2 mt-1">
                <input type="checkbox" class="custom-control-input" id="solar_performance_parameters_status"
                    [(ngModel)]="solar_performance_parameters_status" [ngModelOptions]="{standalone: true}"
                    (change)="onChangeButton('solar_performance_parameters', $event)">
                <label class="custom-control-label" style="font-size: medium;"
                    for="solar_performance_parameters_status"></label>
            </div>

        </div>

        <div class="form-row mt-4">

            <div class="form-group col-md-3">
                <label for="solar_performance_parameters_installed_capicity">Installed Capacity:</label>
                <input type="number" id="solar_performance_parameters_installed_capicity"
                    [(ngModel)]="solar_performance_parameters.installed_capicity" [ngModelOptions]="{standalone: true}"
                    [disabled]="solar_performance_parameters_status ? false : true">
            </div>

            <div class="form-group col-md-3">
                <label for="solar_performance_parameters_predicted_generation">Predicted Generation:</label>
                <input type="number" id="solar_performance_parameters_predictied_generation"
                    [(ngModel)]="solar_performance_parameters.predicted_generation"
                    [ngModelOptions]="{standalone: true}"
                    [disabled]="solar_performance_parameters_status ? false : true">
            </div>

            <div class="form-group col-md-3">
                <label for="solar_performance_parameters_inverter_make">Inverter Make:</label>
                <input type="text" id="solar_performance_parameters_inverter_make"
                    [(ngModel)]="solar_performance_parameters.inverter_make" [ngModelOptions]="{standalone: true}"
                    [disabled]="solar_performance_parameters_status ? false : true">
            </div>

            <div class="form-group col-md-3">
                <label for="solar_performance_parameters_panel_make">Panel Make:</label>
                <input type="text" id="solar_performance_parameters_panel_make"
                    [(ngModel)]="solar_performance_parameters.panel_make" [ngModelOptions]="{standalone: true}"
                    [disabled]="solar_performance_parameters_status ? false : true">
            </div>

        </div>

        <div class="form-row">

            <div class="form-group col-md-3">
                <label for="solar_performance_parameters_location">Location:</label>
                <input type="text" id="solar_performance_parameters_location"
                    [(ngModel)]="solar_performance_parameters.location" [ngModelOptions]="{standalone: true}"
                    [disabled]="solar_performance_parameters_status ? false : true">
            </div>

            <div class="form-group col-md-3">
                <label for="solar_performance_parameters_solar_insolation">Solar Insolation:</label>
                <input type="number" id="solar_performance_parameters_solar_insolation"
                    [(ngModel)]="solar_performance_parameters.solar_insolation" [ngModelOptions]="{standalone: true}"
                    [disabled]="solar_performance_parameters_status ? false : true">
            </div>

            <div class="form-group col-md-3">
                <label for="solar_performance_parameters_average_sun_perday">Average Sun Perday:</label>
                <input type="number" id="solar_performance_parameters_average_sun_perday"
                    [(ngModel)]="solar_performance_parameters.average_sun_perday" [ngModelOptions]="{standalone: true}"
                    [disabled]="solar_performance_parameters_status ? false : true">
            </div>

            <div class="form-group col-md-3">
                <label for="solar_performance_parameters_carbon_factor">Carbon Factor:</label>
                <input type="number" id="solar_performance_parameters_carbon_factor"
                    [(ngModel)]="solar_performance_parameters.carbon_factor" [ngModelOptions]="{standalone: true}"
                    [disabled]="solar_performance_parameters_status ? false : true">
            </div>

        </div>

        <div class="btn-group btn-group-lg" role="group" aria-label="...">

            <label for='step8' id="back-step8" class="back mr-2">
                <div class="btn btn-default btn-warning btn-lg" role="button">Back</div>
            </label>

            <label class="continue">
                <a class="btn btn-default btn-success btn-lg" href="#/customer-management/configuration" role="button"
                    (click)="createSetting()">Submit</a>
            </label>

        </div>
    </form>

</div>