<div style="height:4em" class="row justify-content-end align-content-center">

  <!-- SEARCH BAR -->
  <form id="wrap" action="" autocomplete="on">
    <input id="search" name="search" type="text" [(ngModel)]="search_text" [ngModelOptions]="{standalone: true}"
      placeholder="What're we looking for ?"><input id="search_submit" type="submit">
  </form>

  <!-- CREATE BUTTON -->
  <div class="dropdown">
    <button class="add" id="dropdownMenuButton" data-toggle="dropdown"></button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <a class="dropdown-item" href="#/customer-management/new-user">New Customer</a>
      <a class="dropdown-item" href="#/customer-management/user-setting">New Setting</a>
      <a class="dropdown-item" href="#/customer-management/user-setting">Param Duplication Module</a>
    </div>
  </div>

</div>

<!-- ACCOUNT CARDS -->
<div style="justify-content:space-evenly;" class="row">

  <div class="card_customer card border-primary col-sm-12 mt-4 mb-2 ml-2 mr-2" style="max-width: 24em"
    *ngFor="let account of accounts | filter:search_text">

    <!-- ACCOUNTS -->
    <div class="card-body text-primary" id="dropdownMenuButton1" data-toggle="dropdown">

      <h5 class="card-title"> {{ account.name }} </h5>

    </div>

    <!-- COLLECTIONS LIST -->
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <a class="dropdown-item" href="$event.preventDefault()">Delete user</a>
      <a class="dropdown-item" data-toggle="modal" data-target=".bd-example-modal-lg" href="$event.preventDefault()"
        (click)="getDeviceByAccount(account)">Delete user data</a>
      <a class="dropdown-item" href="$event.preventDefault()">Backup user data</a>
    </div>

  </div>
</div>

<!-- Main Delete Modal -->
<div id="deleteModal" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="container mt-2">
        <div class="card shadow-sm">
          <div class="card-header bg-danger text-white text-center">
            <h3>Delete Records</h3>
          </div>
          <div class="card-body p-4">
            <form>
              <div class="form-group mb-4">
                <label for="account" class="form-label">Account</label>
                <input type="text" id="account" class="form-control" [value]="account.name" disabled />
              </div>
              <div class="form-group mb-4">
                <label for="device" class="form-label">Select Device</label>
                <select id="device" class="form-select" (change)="onChangeDevice($event)">
                  <option value="" disabled selected>Select a device</option>
                  <option *ngFor="let device of devices" [value]="device.device_id">{{device.location}}</option>
                </select>
              </div>
              <div class="form-group mb-4">
                <label for="dateRange" class="form-label">Select Date Range</label>
                <input type="text" class="form-control" (change)="dateSelection(selected)"
                  style="border: 1px solid rgb(204, 204, 204); border-radius: 5px; color: red;"
                  ngxDaterangepickerMd [(ngModel)]="selected" [ngModelOptions]="{standalone: true}" startKey="start"
                  [timePicker]="true" [timePickerSeconds]="false" [timePicker24Hour]="true" endKey="end"
                  [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [customRangeDirection]="true"
                  [locale]="{ displayFormat: 'DD MMM YYYY  HH:00', separator: '    To    ',  cancelLabel: 'Cancel', firstDay: 1}"
                  [showClearButton]="true" [showCancel]="true" [showWeekNumbers]="true" [ranges]="ranges"
                  [linkedCalendars]="true" placeholder="Select Range">
              </div>
              <button type="button" class="btn btn-danger w-100" data-toggle="modal" data-target="#passwordModal">Delete user data</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Password Modal -->
<div id="passwordModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="passwordModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="passwordModalLabel">Enter Password</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form (submit)="confirmDelete($event)">
          <div class="form-group">
            <label for="passwordInput" class="col-form-label">Password:</label>
            <input type="password" class="form-control" id="passwordInput" [(ngModel)]="password" name="password" required>
          </div>
          <button type="submit" class="btn btn-danger">Confirm Delete</button>
        </form>
      </div>
    </div>
  </div>
</div>
