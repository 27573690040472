<input id='step2' type='checkbox' class="main-checkbox">
<input id='step3' type='checkbox' class="main-checkbox">

<!-- new user -->
<div id="user" class="form-group">

    <form class="p-4">

        <legend><span class="number">1</span>Create New User</legend>

        <label class="mt-4">Status:</label>

        <div style="display: inline-flex">

            <input type="radio" id="user_draft" name="user_status"
                (change)="onChange('user_status', 'draft')"><label for="user_draft">Draft</label>

            <input class="ml-5" type="radio" id="user_invited" name="user_status"
                (change)="onChange('user_status', 'invited')"><label for="user_invited">Invited</label>

            <input class="ml-5" type="radio" id="user_active" name="user_status"
                (change)="onChange('user_status', 'active')"><label for="user_active">Active</label>

            <input class="ml-5" type="radio" id="user_suspended" name="user_status"
                (change)="onChange('user_status', 'suspended')"><label for="user_suspended">Suspended</label>

            <input class="ml-5" type="radio" id="user_deleted" name="user_status"
                (change)="onChange('user_status', 'deleted')"><label for="user_deleted">Deleted</label>
        </div>

        <div class="form-row mt-4">

            <div class="form-group col-md-6">
                <label for="first_name">First Name:</label>
                <input type="text" id="first_name" [(ngModel)]="user.first_name" [ngModelOptions]="{standalone: true}">
            </div>

            <div class="form-group col-md-6">
                <label for="last_name">Last Name:</label>
                <input type="text" id="last_name" [(ngModel)]="user.last_name" [ngModelOptions]="{standalone: true}">
            </div>

        </div>

        <div class="form-row">

            <div class="form-group col-md-6">
                <label for="email">Email:</label>
                <input type="email" id="email" [(ngModel)]="user.email" [ngModelOptions]="{standalone: true}">
            </div>

            <div class="form-group col-md-6">
                <label>Email Notification:</label>
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="user_email_notification"
                        [(ngModel)]="user.email_notification" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" for="user_email_notification"></label>
                </div>

            </div>

        </div>

        <div class="form-row">

            <div class="form-group col-md-6">
                <label for="password">Password:</label>
                <input type="password" id="password" [(ngModel)]="user.password" [ngModelOptions]="{standalone: true}">
            </div>

            <div class="form-group col-md-6">
                <label for="role">Role:</label>
                <select id="role" [(ngModel)]="user.role" [ngModelOptions]="{standalone: true}">
                    <option value="0" disabled>Select role</option>
                    <option value="{{role.id}}" *ngFor="let role of roles">{{ role.name }}</option>
                </select>

            </div>

        </div>

        <div class="form-row">

            <div class="form-group col-md-6">
                <label for="comapny">Comapny:</label>
                <input type="text" id="comapny" [(ngModel)]="user.company" [ngModelOptions]="{standalone: true}">
            </div>

            <div class="form-group col-md-6">
                <label for="title">Title:</label>
                <input type="text" id="title" [(ngModel)]="user.title" [ngModelOptions]="{standalone: true}">
            </div>

        </div>

        <div class="form-row">

            <div class="form-group col-md-6">

                <label for="timezone">Time Zone:</label>
                <select id="timezone" [(ngModel)]="user.timezone" [ngModelOptions]="{standalone: true}">
                    <option value="" selected disabled>Select timezone</option>
                    <option value="{{ data }}" *ngFor="let data of time_zone">{{ data }}</option>

                </select>

            </div>

            <div class="form-group col-md-6">

                <label for="locale">Locale:</label>
                <select id="locale" [(ngModel)]="user.locale" [ngModelOptions]="{standalone: true}">

                    <option value="" selected disabled>Select a language</option>
                    <option value="{{data.key}}" *ngFor="let data of languages_list">{{ data.value }}</option>

                </select>

            </div>

        </div>

        <label class="mt-4">Theme:</label>

        <div style="display: inline-flex">

            <input type="radio" id="auto" name="theme" (change)="onChange('theme', 'auto')"><label
                for="auto">Auto</label>

            <input class="ml-5" type="radio" id="light" name="theme" (change)="onChange('theme', 'light')"><label
                for="light">Light</label>

            <input class="ml-5" type="radio" id="dark" name="theme" (change)="onChange('theme', 'dark')"><label
                for="dark">Dark</label>

        </div>

        <div class="row mt-4" role="group" aria-label="...">

            <label for='step2' id="continue-step2" class="skip mr-1">
                <div class="btn btn-default btn-warning btn-lg">Skip</div>
            </label>

            <label>
                <div class="btn btn-default btn-success btn-lg" (click)="openVerticallyCentered(content, 'step2')">Continue</div>
            </label>

        </div>
    </form>
</div>

<!-- new account -->
<div id="account" class="form-group">

    <form class="p-4">

        <legend><span class="number">2</span>Create New Account</legend>
        
        <label class="mt-4">Status:</label>

        <div style="display: inline-flex">

            <input type="radio" id="account_published" name="account_status"
                (change)="onChange('account_status', 'published')"><label for="account_published">Published</label>

            <input class="ml-5" type="radio" id="account_draft" name="account_status"
                (change)="onChange('account_status', 'draft')"><label for="account_draft">Draft</label>

            <input class="ml-5" type="radio" id="account_deleted" name="account_status"
                (change)="onChange('account_status', 'deleted')"><label for="account_deleted">Deleted</label>

        </div>

        <div class="form-row mt-4">

            <div class="form-group col-md-6">
                <label for="name">Name:</label>
                <input type="text" id="name" [(ngModel)]="account.name" [ngModelOptions]="{standalone: true}">
            </div>

            <div class="form-group col-md-6">

                <label for="user">User:</label>
                <ng-multiselect-dropdown [placeholder]="'Select user'" [settings]="userDropdownSettings"
                [data]="users" [(ngModel)]="userSelectedItem" [ngModelOptions]="{standalone: true}">
            </ng-multiselect-dropdown>

                <!-- <select id="user" [(ngModel)]="account.user" [ngModelOptions]="{standalone: true}"
                    [disabled]="account.user_status">

                    <option value="" selected disabled>Select a User</option>
                    <option value="{{ user.id }}" *ngFor="let user of users">{{ user.name }}</option>

                </select> -->

            </div>

        </div>

        <div class="form-row">

            <div class="form-group col-md-6">

                <label for="sub_user">Sub User:</label>
                <select id="sub_user" [(ngModel)]="account.sub_user" [ngModelOptions]="{standalone: true}">

                    <option value="" selected disabled>Select a sub user</option>
                    <option value="{{ user.id }}" *ngFor="let user of users">{{ user.name }}</option>

                </select>

            </div>

            <div class="form-group col-md-6">
                <label for="account_expiry_date">Expiry Date:</label>
                <input type="date" id="account_expiry_date" [(ngModel)]="account.expiry_date"
                    [ngModelOptions]="{standalone: true}">
            </div>

        </div>

        <div class="btn-group btn-group-lg" role="group" aria-label="...">

            <label for='step3' id="skip-step3" class="skip mr-1">
                <div class="btn btn-default btn-warning btn-lg">Skip</div>
            </label>

            <label>
                <div class="btn btn-default btn-success btn-lg" (click)="openVerticallyCentered(content, 'step3')">Continue</div>
            </label>

        </div>
    </form>
</div>

<!-- new device -->
<div id="device" class="form-group">

    <form class="p-4">

        <legend><span class="number">3</span>Create New Devices</legend>

        <label class="mt-4">Status:</label>

        <div style="display: inline-flex">

            <input type="radio" id="device_published" name="device_status"
                (change)="onChange('device_status', 'published')"><label for="device_published">Published</label>

            <input class="ml-5" type="radio" id="device_draft" name="device_status"
                (change)="onChange('device_status', 'draft')"><label for="device_draft">Draft</label>

            <input class="ml-5" type="radio" id="device_deleted" name="device_status"
                (change)="onChange('device_status', 'deleted')"><label for="device_deleted">Deleted</label>

        </div>

        <div class="form-row mt-4">

            <div class="form-group col-md-6">
                <label for="device_name">Name:</label>
                <input type="text" id="device_name" [(ngModel)]="device.name" [ngModelOptions]="{standalone: true}">
            </div>

            <div class="form-group col-md-6">
                <label for="device_account">Account:</label>
                <select id="device_account" [(ngModel)]="device.account" [ngModelOptions]="{standalone: true}"
                    [disabled]="device.account_status" (change)="getDevicesTable()">

                    <option value="" selected disabled>Select a account</option>
                    <option value="{{ account.id }}" *ngFor="let account of accounts">{{ account.name }}</option>

                </select>
            </div>

        </div>

        <div class="form-row">

            <div class="form-group col-md-6">
                <label for="device_aws_thing_topic">Aws Thing Topic:</label>
                <input type="text" id="device_aws_thing_topic" [(ngModel)]="device.aws_thing_topic"
                    [ngModelOptions]="{standalone: true}">
            </div>

            <div class="form-group col-md-6">
                <label for="device_sub_user">Sub User:</label>
                <select id="device_sub_user" [(ngModel)]="device.sub_user" [ngModelOptions]="{standalone: true}">

                    <option value="" selected disabled>Select a sub user</option>
                    <option value="{{ user.id }}" *ngFor="let user of users">{{ user.name }}</option>

                </select>
            </div>

        </div>

        <div class="form-row">

            <div class="form-group col-md-6">
                <label for="device_device_id">Device ID:</label>
                <input type="text" id="device_device_id" [(ngModel)]="device.device_id"
                    [ngModelOptions]="{standalone: true}" readonly>
            </div>

            <div class="form-group col-md-6">
                <label for="device_slave_id">Slave ID:</label>
                <input type="text" id="device_slave_id" [(ngModel)]="device.slave_id"
                    [ngModelOptions]="{standalone: true}">
            </div>

        </div>

        <div class="form-row">

            <div class="form-group col-md-6">
                <label for="device_device_model">Device Model:</label>
                <select id="device_device_model" [(ngModel)]="device.device_model"
                    [ngModelOptions]="{standalone: true}" (change)="onChange('device_model', $event)">

                    <option value="" selected disabled>Select a device model</option>
                    <option value="{{ device_model }}" *ngFor="let device_model of device_models">{{ device_model }}
                    </option>

                </select>
            </div>

            <div class="form-group col-md-6">
                <label for="device_device_category">Device Categoray:</label>
                <select id="device_device_category" [(ngModel)]="device.device_category"
                    [ngModelOptions]="{standalone: true}" (change)="onChange('device_category', $event)">

                    <option value="" selected disabled>Select a device category</option>
                    <option value="{{ device_category.id }}" *ngFor="let device_category of device_categories">{{
                        device_category.value }}</option>

                </select>
            </div>

        </div>

        <div class="form-row">

            <div class="form-group col-md-6">
                <label for="device_device_type">Device Type (Mandatory):</label>
                <select id="device_device_type" [(ngModel)]="device.device_type" [ngModelOptions]="{standalone: true}">

                    <option value="" selected disabled>Select a device type</option>
                    <option value="{{ device_type.key }}" *ngFor="let device_type of device_types">{{ device_type.value
                        }}</option>

                </select>
            </div>

            <div class="form-group col-md-6">
                <label for="device_starting_billing_month">Starting Billing Month  ( For Prepaid ):</label>
                <input type="date" id="device_starting_billing_month" [(ngModel)]="device.starting_billing_month"
                    [ngModelOptions]="{standalone: true}">
            </div>

        </div>

        <div class="form-row">

            <div class="form-group col-md-6">
                <label for="device_max_flow_rate">Max Flow Rate:</label>
                <input type="text" id="device_max_flow_rate" [(ngModel)]="device.max_flow_rate"
                    [ngModelOptions]="{standalone: true}">
            </div>

            <div class="form-group col-md-6">
                <label for="device_motor_rpm">Motor RPM:</label>
                <input type="text" id="device_motor_rpm" [(ngModel)]="device.motor_rpm"
                    [ngModelOptions]="{standalone: true}">
            </div>

        </div>

        <div class="form-row">

            <div class="form-group col-md-6">
                <label for="device_max_temp_range">Max Temp Range:</label>
                <input type="text" id="device_max_temp_range" [(ngModel)]="device.max_temp_range"
                    [ngModelOptions]="{standalone: true}">
            </div>

            <div class="form-group col-md-6">
                <label for="device_tank_capacity">Tank Capacity:</label>
                <input type="text" id="device_tank_capacity" [(ngModel)]="device.tank_capacity"
                    [ngModelOptions]="{standalone: true}">
            </div>

        </div>

        <div class="form-row">

            <div class="form-group col-md-6">
                <label for="device_location">Location:</label>
                <input type="text" id="device_location" [(ngModel)]="device.location"
                    [ngModelOptions]="{standalone: true}">
                <span class="note">Example: Office->Room1->Cabin1</span>

            </div>

            <div class="form-group col-md-6">
                <label for="device_geo_location">Geo Location:</label>
                <input type="text" id="device_geo_location" [(ngModel)]="device.geo_location"
                    [ngModelOptions]="{standalone: true}">
            </div>

        </div>

        <div class="form-row">

            <div class="form-group col-md-6">
                <label for="device_power_button_parameter">Power Button Parameter:</label>
                <input type="text" id="device_power_button_parameter" [(ngModel)]="device.power_button_parameter"
                    [ngModelOptions]="{standalone: true}">
            </div>

        </div>

        <div class="form-row">

            <div class="form-group col-md-12">
                <label for="device_parameters">Parameters:</label>

                <ng-multiselect-dropdown [placeholder]="'Select parameters'" [settings]="dropdownSettings"
                    [data]="dropdownList" [(ngModel)]="selectedItems" [ngModelOptions]="{standalone: true}"
                    (onSelect)="onSelect($event)" (onDeSelect)="onDeSelect($event)" (onSelectAll)="onSelectAll($event)">
                </ng-multiselect-dropdown>

            </div>
        </div>

        <div class="form-row">


            <div class="form-group col-md-4">
                <label>Map:</label>
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="device_map" [(ngModel)]="device.map"
                        [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" for="device_map"></label>
                </div>

            </div>

            <div class="form-group col-md-4">
                <label>Favorite:</label>
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="device_favorite"
                        [(ngModel)]="device.favorite" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" for="device_favorite"></label>
                </div>

            </div>

            <div class="form-group col-md-4">
                <label>Power Button:</label>
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="device_power_button"
                        [(ngModel)]="device.power_button" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label" for="device_power_button"></label>
                </div>

            </div>

        </div>


        <div class="btn-group btn-group-lg" role="group" aria-label="...">

            <label class="mr-1">
                <a class="btn btn-default btn-warning btn-lg" href="#/customer-management/configuration" role="button">Skip</a>
            </label>

            <label class="add_new mr-1">
                <button type="submit" class="btn btn-default btn-info btn-lg" (click)="openVerticallyCentered(content, 'step4')">Add
                    New</button>
            </label>

            <label>
                <a class="btn btn-default btn-success btn-lg" href="#/customer-management/configuration" role="button">Submit</a>
            </label>

        </div>
    </form>

    <form class="p-4">

        <legend><span class="number">4</span>Devices</legend>

        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Device ID</th>
                    <th scope="col">Location</th>
                    <th scope="col">Slave Address</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let device of devices; let i = index">
                    <th scope="row">{{i + 1}}</th>
                    <td>{{ device.device_id }}</td>
                    <td>{{ device.location }}</td>
                    <td>{{ device.slave_id }}</td>
                </tr>
            </tbody>
        </table>

    </form>

</div>

<!-- confirmation modal -->
<ng-template #content let-modal>

    <div class="modal-header">
        <h4 class="modal-title">{{ modal_title }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"><span
                aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body">
        <p>{{ modal_body }}</p>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
        <label [for]='step' id="continue-{{step}}" class="continue {{ ok_btn_state }}">
            <div class="btn btn-danger" role="button" (click)="confirmation()">Ok</div>
        </label>
       
    </div>

</ng-template>