<ngb-tabset #t="ngbTabset" (tabChange)="tabChange($event)">

  <ngb-tab *ngIf="tab_device_status" id="device-status" title="Device Status">
    <ng-template ngbTabContent>

      <div class="row mt-4">

        <div class="col-lg-4 col-xl-4 stretch-card right-stretch-card">
          <div class="row flex-grow">

            <div class="col-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-8 col-md-12 col-xl-8">
                      <h3 class="mb-0">{{ device_activity.total }}</h3>
                    </div>
                    <div class="col-4 col-md-12 col-xl-4">
                      <div id="apexChart1" class="mt-md-3 mt-xl-0"></div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-baseline">
                    <h6 class="card-title mb-0 mt-2 echo-blue-text">No. of Devices</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-8 col-md-12 col-xl-8">
                      <h3 class="mb-0">{{ device_activity.live }}</h3>
                    </div>
                    <div class="col-4 col-md-12 col-xl-4">
                      <div id="apexChart1" class="mt-md-3 mt-xl-0"></div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-baseline">
                    <h6 class="card-title mb-0 mt-2 echo-blue-text">Live Devices</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-8 col-md-12 col-xl-8">
                      <h3 class="mb-0">{{ device_activity.active }}</h3>
                    </div>
                    <div class="col-4 col-md-12 col-xl-4">
                      <div id="apexChart1" class="mt-md-3 mt-xl-0"></div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-baseline">
                    <h6 class="card-title mb-0 mt-2 echo-blue-text">Active Devices</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-8 col-md-12 col-xl-8">
                      <h3 class="mb-0">{{ device_activity.alert }}</h3>
                    </div>
                    <div class="col-4 col-md-12 col-xl-4">
                      <div id="apexChart1" class="mt-md-3 mt-xl-0"></div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-baseline">
                    <h6 class="card-title mb-0 mt-2 echo-blue-text">Alert Devices</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-8 col-xl-8 stretch-card left-stretch-card" id="rec_act">
          <div class="row flex-grow">
            <div class="col grid-margin stretch-card">
              <div #content class="card content">
                <div class="d-flex justify-content-between card-header align-items-center mb-4 mb-md-3">
                  <h6 class="card-title mb-0 ">Recent Device Activities</h6>
                  <div class=" dropdown ml-0 position-absolute" style="display: contents;">
                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                           (click)="makePdf()">
                           <!-- data_param_el = 'rec_act'; exportAsPDF($event) -->
                            <i class="icon-sm mr-2 feather feather-file-text"></i>
                            <span class="">Export as PDF</span>
                        </a> 
                    </div>
                </div>
                </div>
                <div>
                  <div class="card-body" #status_body  id="status_body" style="height: 350px; overflow: auto;">
                    <!-- <div class="row">
                      <ul *ngFor="let data of recent_activity">
                        <li>
                          Device id: {{ data.device_id }} - Status: {{ data.status }} - Last Received : {{ data.last_received }}
                        </li>
                      </ul>
                    </div> -->

                    <div class="row" id="timeline-content">
                      <ul class="timeline">
                        <div *ngFor="let data of recent_activity">
                          <li *ngIf="data.status" class="event active">
                          <strong>Location:</strong> {{ data.location }} - <strong>Last Received:</strong> {{ data.last_received }}
                          <hr />
                        </li>
                        <li *ngIf="!data.status" class="event inactive">
                          <strong>Location:</strong> {{ data.location }} - <strong>Status:</strong> {{ data.status }} - <strong>Last Received:</strong> {{ data.last_received }}
                          <hr/>
                        </li>
                        </div>
                      </ul>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div> <!-- row -->

    </ng-template>
  </ngb-tab>

  <ngb-tab *ngIf="tab_memory_status" id="memory-status" title="Memory Status">
    <ng-template ngbTabContent>
      <div class="row mt-4">
        <div class="col-lg-4 col-xl-4 stretch-card">
          <div class="row flex-grow">
            <div class="col-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body d-flex justify-content-center flex-column">
                  <div class="row">
                    <div class="col-12 col-md-12 col-xl-8">
                      <h3 class="mb-0">{{ memory }} GB</h3>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-baseline">
                    <h6 class="card-title mb-0 mt-2 echo-blue-text">Allowed Disk Space</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body d-flex justify-content-center flex-column">
                  <div class="row">
                    <div class="col-12 col-md-12 col-xl-8">
                      <h3 class="mb-0">{{ allocate_memory }} / {{ memory }} GB</h3>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-baseline">
                    <h6 class="card-title mb-0 mt-2 echo-blue-text">Usage Disk Space</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-xl-8 stretch-card">
          <div class="row flex-grow">

            <div class="d-grid col grid-margin stretch-card">
              <div class="card">
                <div class="d-flex justify-content-between card-header align-items-center mb-4 mb-md-3">
                  <h6 class="card-title mb-0 ">Archive data</h6>
                </div>
                <div class="card-body">
                  <form class="">
                    <div class="row">
                      <div class="col-sm-3">

                        <div class="form-group">
                          <label for="duration-start">Start Duration</label>
                          <input type="date" class="form-control" id="duration-start" autocomplete="off">
                        </div>
                      </div><!-- Col -->
                      <div class="col-sm-3">

                        <div class="form-group">
                          <label for="duration-end">End Duration</label>
                          <input type="date" class="form-control" id="duration-end" autocomplete="off">
                        </div>
                      </div><!-- Col -->
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label>Type</label>
                          <select class="" name="type">
                            <option value="">Select one...</option>
                            <option value="csv">CSV</option>
                            <option value="pdf">PDF</option>
                          </select>
                        </div>
                      </div><!-- Col -->

                      <div class="col-sm-3 mb-3 d-flex align-self-end">
                        <div class="form-group w-100 mb-0">
                          <button type="submit" class="btn theme-primary-btn">Start Download</button>
                        </div>
                      </div><!-- Col -->
                    </div>
                  </form>

                  <div class="download-history mt-4 mb-3">
                    <h6 class="mb-3">Download History</h6>
                    <div class="table-responsive">
                      <table class="table">
                        <tbody>
                          <tr>
                            <td>Date : <span>11-10-2020</span></td>
                            <td class="text-right">file size : <span>50 mb</span></td>
                          </tr>
                          <tr>
                            <td>Date : <span>02-10-2020</span></td>
                            <td class="text-right">file size : <span>250 mb</span></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> <!-- row -->
    </ng-template>
  </ngb-tab>

  <ngb-tab *ngIf="tab_internet_quality" id="internet-quality" title="Internet Quality">
    <ng-template ngbTabContent>

      <div class="row mt-4">

        <div class="col-md-12 col-xl-12 grid-margin stretch-card">
          <div class="card">
            <div class="d-flex justify-content-between card-header align-items-center mb-md-3">
              <h5 class="card-title mb-0">Internet Quality</h5>

              <div class="d-flex align-items-center justify-content-end">

                <button class="btn mr-1 pr-2" type="button" data-toggle="collapse"
                  data-target="#collapseInternetQuality" aria-expanded="false"
                  aria-controls="collapseInternetQuality">
                  <i class="feather-sliders"
                      style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                </button>

                  <a href="javascript:void(0);" class="graph">
                    <span>
                      <i class="icon-sm card-header-icon mr-2 pb-3px feather feather-trending-up active"></i>
                    </span>
                  </a>
                  <span class="divider"></span>
  
                  <i class="icon-sm card-header-icon ml-2 pb-3px maximize-card feather feather-maximize-2"
                    (click)="toggleCard($event)"></i>
  
                  <div class="dropdown ml-2">
                    <button class="btn p-0" type="button" id="dropdownMenuButton3" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                      <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);">
                        <i class="icon-sm mr-2 feather feather-printer"></i>
                        <span class="">Print</span>
                      </a>
                      <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);">
                        <i class="icon-sm mr-2 feather feather-download"></i>
                        <span class="">Download</span>
                      </a>
                    </div>
                  </div>


              </div>
            </div>

            <div class="collapse px-2" id="collapseInternetQuality">
              <div class="card-body overflow-auto">
                  <div class="w-max-content header-right-inner-left d-flex">

                      <h6 class="mr-2 text-nowrap"> Select date:</h6>

                      <input type="date" [(ngModel)]="date" autocomplete="off" class="mr-4 form-control"
                        (change)="onDateSelection($event)">

                      <h6 class="mr-2 text-nowrap"> Select Parameter: </h6>
 
                      <ng-multiselect-dropdown class="mr-2" [placeholder]="'Select a device'" [settings]="dropdownSettings"
                        [data]="devices" [(ngModel)]="selected_device" (onSelect)="onDeviceSelect($event)">
                      </ng-multiselect-dropdown>
                  </div>
                </div>
              </div>
                  

            <div class="card-body">
              <div class="flot-wrapper">
                <div id="chartInternet" style="width: 100%; height: 500px"></div>
                <ul style="text-align: center; list-style-position: inside;">
                  <li>&#60; 100 ms -&#62; Excellent</li>
                  <li>&#60; 150 ms -&#62; Good</li>
                  <li>&#60; 200 ms -&#62; Average</li>
                  <li>&#62; 200 ms -&#62; Poor</li>
                </ul>
                <div style="text-align: center;"><h6><strong>.</strong> </h6></div>
                
              </div>
            </div>
          </div>
        </div>
      </div>

    </ng-template>
  </ngb-tab>

</ngb-tabset>