import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { DataSharedService } from 'src/app/shared/services/data-shared.service';
import { ToastrService } from 'ngx-toastr';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {

  public action: any = '';
  public dropdownSettings: IDropdownSettings;
  public selectedItems = [];
  public dropdownList = [];
  public closeResult;
  public step: string = '';
  public ok_btn_state: any = 'd-block';
  public modal_title: any = 'Confirmation';
  public modal_body: any = 'Are you sure?';

  // user variable
  public user: any = { first_name: '', last_name: '', email: '', email_notification: 0, password: '', role: 3, company: '', title: '', timezone: '', locale: '', theme: '', status: '' }
  public roles: any = [];
  public time_zone: any = [];
  public languages_list: any = [];

  // account variable 
  public account: any = { status: '', name: '', user: '', user_status: 'false', sub_user: null, expiry_date: null };
  public users: any = [];
  public accounts: any = [];
  public userDropdownSettings: IDropdownSettings;
  public userSelectedItem: any = [];

  // device variable 
  public device: any = { id: [], status: '', name: '', account: '', account_status: 'false', aws_thing_topic: '', sub_user: null, device_id: '', slave_id: '', device_model: '', device_category: '', starting_billing_month: null, max_flow_rate: '', motor_rpm: 0, max_temp_range: 0, tank_capacity: '', device_type: '', parameters: [], location: '', map: '', geo_location: '', favorite: '', power_button: '', power_button_parameter: '' }
  public devices: any = [];
  public collection: string = '';
  public interface: string = '';
  public device_models: any = [];
  public device_types: any = [];
  public device_categories: any = [];
  public table: string = '';

  constructor(private dashboardService: DashboardService, private dataShared: DataSharedService, private toastr: ToastrService, private modalService: NgbModal) { }

  ngOnInit(): void {

    this.time_zone = this.dataShared.timeZone
    this.time_zone.sort((a, b) => a.localeCompare(b));

    this.getRoles();
    this.getLanguagesList();

    this.getUser(false);

    this.getAccounts();
    this.getLatestDeviceID();
    this.getDirectusFieldsOptions();
    this.getDeviceCategory();
    this.getDeviceParameters();

  }

  openVerticallyCentered(content, step) {
    this.step = step;
    this.modal_title = 'Confirmation';

    if (this.step == 'step2') this.modal_body = 'Are you sure create a new user?';
    else if (this.step == 'step3') this.modal_body = 'Are you sure create a new account?';
    else if (this.step == 'step4') this.modal_body = 'Are you sure create a new devices?';

    this.ok_btn_state = 'd-block';
    this.closeResult = this.modalService.open(content, { centered: true });
  }

  confirmation() {

    if (this.step == 'step2') this.createUser();
    else if (this.step == 'step3') this.createAccount();
    else if (this.step == 'step4') this.createDevice();

  }

  getBody() {

    let body = {
      action: this.action
    }

    if (this.action == 'createuser') {

      delete body['action'];
      body["first_name"] = this.user.first_name;
      body["last_name"] = this.user.last_name;
      body["email"] = this.user.email;
      body["email_notifications"] = (this.user.email_notification) ? 1 : 0;
      body["password"] = this.user.password;
      body["role"] = this.user.role;
      body["company"] = this.user.company;
      body["title"] = this.user.title;
      body["timezone"] = this.user.timezone;
      body["locale"] = this.user.locale;
      body["theme"] = this.user.theme;
      body["status"] = this.user.status;

    }

    if (this.action == 'insertAccount') {

      body["status"] = this.account.status,
        body["name"] = this.account.name,
        body["user_id"] = this.account.user,
        body["sub_user_id"] = this.account.sub_user,
        body["expiry_date"] = this.account.expiry_date

    }

    if (this.action == 'getDirectusFieldsOptions') {

      body["collection"] = this.collection;
      body["interface"] = this.interface
    }

    if (this.action == 'getDirectusTable') {
      body['table'] = this.table;
    }

    if (this.action == 'insertDevices') {

      body['id'] = this.device.id;
      body['status'] = this.device.status;
      body['name'] = this.device.name;
      body['account'] = this.device.account;
      body['aws_thing_topic'] = this.device.aws_thing_topic;
      body['sub_user'] = this.device.sub_user;
      body['device_id'] = this.device.device_id;
      body['slave_id'] = this.device.slave_id;
      body['device_model'] = this.device.device_model;
      body['device_category'] = this.device.device_category;
      body['starting_billing_month'] = this.device.starting_billing_month;
      body['max_flow_rate'] = this.device.max_flow_rate;
      body['motor_rpm'] = this.device.motor_rpm;
      body['max_temp_range'] = this.device.max_temp_range;
      body['tank_capacity'] = this.device.tank_capacity;
      body['device_type'] = this.device.device_type;
      body['parameters'] = this.device.parameters;
      body['location'] = this.device.location;
      body['map'] = (this.device.map) ? 1 : 0;
      body['geo_location'] = this.device.geo_location;
      body['favorite'] = (this.device.favorite) ? 1 : 0;
      body['power_button'] = (this.device.power_button) ? 1 : 0;
      body['power_button_parameter'] = this.device.power_button_parameter;
    }

    if (this.action == 'getDeviceIds') {
      body['account_id'] = this.device.account
    }

    return body;
  }

  onChange(type, e) {

    if (type == 'user_status') this.user.status = e;
    else if (type == 'theme') this.user.theme = e;
    else if (type == 'account_status') this.account.status = e;
    else if (type == 'device_status') this.device.status = e;
    else if (type == 'device_model') this.device.device_category = ( e.target.value == 'Level' || e.target.value == 'Env Sensor' ) ? '1' : '2'; 
  }

  getUser(status) {

    if (status) {
      this.account.user_status = 'true';
      this.toastr.success('User are Created');
    }

    let url = 'https://app.zainergy.com/backend/zainergy/users'; let users = []; this.users = [];
    this.dashboardService.httpGet(url).subscribe((res: any) => {

      let data = res.data;
      this.account.user = (this.account.user_status == 'true') ? data[data.length - 1].id : '';

      data.forEach(e => {

        let first_name = (e.first_name != null) ? e.first_name : '';
        let last_name = (e.last_name != null) ? e.last_name : '';

        users.push({
          id: e.id,
          name: first_name + '  ' + last_name,
          isDisabled: (this.account.user_status == 'true') ? true : false
        });

        this.userSelectedItem = (this.account.user_status == 'true') ? [users[users.length - 1].name] : [];
        this.users = users
        this.userDropdownSettings = {
          singleSelection: true,
          idField: 'id',
          textField: 'name',
          showSelectedItemsAtTop: true,
          itemsShowLimit: 1,
          allowSearchFilter: true,
          clearSearchFilter: true,
          searchPlaceholderText: 'Select user',
        };

      });
    })
  }

  // user part 
  getRoles() {
    let url = 'https://app.zainergy.com/backend/zainergy/roles'; this.roles = [];
    this.dashboardService.httpGet(url).subscribe((res: any) => {

      let data = res.data;
      data.forEach(e => {

        this.roles.push({
          id: e.id,
          name: e.name
        })
      });

    })
  }

  getLanguagesList() {

    let keys = Object.keys(this.dataShared.languages_list)
    let values = Object.values(this.dataShared.languages_list)

    for (let i = 0; i < keys.length; i++) {

      this.languages_list.push({
        key: keys[i],
        value: values[i]
      })

    }
  }

  createUser() {
    this.ok_btn_state = 'd-none'; this.modal_title = 'Confirm'; this.modal_body = 'User are Created Succesfully!';

    this.action = 'createuser'; var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "PHPSESSID=tn4badmb8a62hjjk9h323uq3m6");

    var body = JSON.stringify(this.getBody());

    fetch("https://app.zainergy.com/backend/zainergy/users", {
      method: 'POST',
      headers: myHeaders,
      body: body,
      redirect: 'follow'
    })
      .then(response => response.text())
      .then(result => this.getUser(true))
      .catch(error => this.toastr.error(error));

  }

  // account part 
  createAccount() {

    this.ok_btn_state = 'd-none'; this.modal_title = 'Confirm'; this.modal_body = 'Account are Created Succesfully!'; this.action = 'insertAccount';
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      if (res.data == "account is created. ") {

        this.toastr.success(res.data)
        this.device.account_status = 'true'

      } else {

        this.toastr.error(res.data);
        this.device.account_status = 'false'

      }
      this.getAccounts();
      this.getDevicesTable();

    })
  }

  // device part 
  getAccounts() {
    this.action = 'getAccounts'; this.accounts = [];
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      this.device.account = (this.device.account_status == 'true') ? res.data[res.data.length - 1].id : '';

      res.data.forEach(e => {
        this.accounts.push({
          id: e.id,
          name: e.name
        })
      });
    })
  }

  getLatestDeviceID() {
    this.action = 'getLatestDevice'; this.device.device_id = '';
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      let data = res.data[0];

      this.device.id = [parseInt(data.id) + 1];
      this.device.device_id = parseInt(data.device_id) + 1;
    })
  }

  getDirectusFieldsOptions() {

    this.action = 'getDirectusFieldsOptions'; this.collection = 'devices'; this.interface = 'dropdown'; this.device_models = [];
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      let device_model = Object.values(res.data.device_model);
      let device_type = { key: Object.keys(res.data.device_type), value: Object.values(res.data.device_type) }

      device_model.forEach(e => {
        this.device_models.push(e);
      });

      for (let i = 0; i < device_type.key.length; i++) {
        this.device_types.push({
          key: device_type.key[i],
          value: device_type.value[i]
        })
      }
    })
  }

  getDeviceCategory() {
    this.action = 'getDeviceCategories'; this.device_categories = [];
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      let data = res.data;

      data.forEach(e => {
        this.device_categories.push({
          id: e.id,
          value: e.value
        })
      });
    })
  }

  getDeviceParameters() {
    this.action = 'getDirectusTable'; this.table = 'device_type'; let data = [];
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      res.data.forEach(e => {
        data.push({
          id: e.id,
          description: e.description + "  (" + e.parameter + ")"
        })
      });

      this.dropdownList = data;

      this.dropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'description',
        showSelectedItemsAtTop: true,
        itemsShowLimit: 1,
        allowSearchFilter: true,
        clearSearchFilter: true,
        searchPlaceholderText: 'Select parmeters',
      };
    })
  }

  onSelect(item: any) {
    this.device.parameters.push(item['id']);
  }

  onDeSelect(item: any) {
    let index = this.device.parameters.indexOf(item['id'], 0)
    if (index > -1) this.device.parameters.splice(index, 1);
  }

  onSelectAll(items: any) {
    this.device.parameters = [];
    items.forEach(e => {
      this.device.parameters.push(e.id)
    });
  }

  getDevicesTable() {
    this.action = 'getDeviceIds'; this.devices = [];
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      let data = res.data;

      data.forEach(e => {

        this.devices.push({
          device_id: e.device_id,
          location: e.location,
          slave_id: e.slave_id
        })

      });

    })
  }

  createDevice() {
    this.action = 'insertDevices'; this.ok_btn_state = 'd-none'; this.modal_title = 'Confirm'; this.modal_body = 'Device are Created Succesfully!';
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      this.device.name = '';
      this.device.aws_thing_topic = '';
      this.device.sub_user = null;
      this.device.slave_id = '';
      this.device.device_model = '';
      this.device.device_category = '';
      this.device.starting_billing_month = null;
      this.device.max_flow_rate = '';
      this.device.motor_rpm = 0;
      this.device.max_temp_range = 0;
      this.device.tank_capacity = '';
      this.device.device_type = '';
      this.device.parameters = [];
      this.device.location = '';
      this.device.map = '';
      this.device.geo_location = '';
      this.device.favorite = '';
      this.device.power_button = '';
      this.device.power_button_parameter = '';
      this.selectedItems = [];

      this.getLatestDeviceID();
      this.getDevicesTable();

    })
  }
}