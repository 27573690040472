<ngb-tabset #t="ngbTabset" (tabChange)="tabChange($event)" #tabset>

    <ngb-tab *ngIf="tab_trends_status" id="tab-trends" title="Trends">
        <ng-template ngbTabContent>

            <br />

            <zainergy-dashboard-cards *ngIf="energy_status" for="dashboard"></zainergy-dashboard-cards>
            <zainergy-dashboard-cards *ngIf="flow_meter_status" for="flow"></zainergy-dashboard-cards>
            <zainergy-dashboard-cards *ngIf="sensor_status" for="sensor"></zainergy-dashboard-cards>
            <zainergy-dashboard-cards *ngIf="level_status" for="level"></zainergy-dashboard-cards>

            <!-- <zainergy-dashboard-cards class="d-none" for="sensor"></zainergy-dashboard-cards> -->

            <div class="row">

                <div class="col-md-12 col-xl-4 grid-margin stretch-card">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center" id="graph">

                            <h5 *ngIf="source_type && !flow_meter_status" class="card-title mb-0">Energy Profile /
                                Utility</h5>

                            <h5 *ngIf="!source_type && chain_analysis_chart_status && !flow_meter_status"
                                class="card-title mb-0">Energy Profile</h5>

                            <h5 *ngIf="!chain_analysis_chart_status && !flow_meter_status && !sensor_status && !level_status"
                                class="card-title mb-0">Motor
                                Speed (RPM)</h5>

                            <h5 *ngIf="!chain_analysis_chart_status && !flow_meter_status && !sensor_status && level_status"
                                class="card-title mb-0">Today's Refilling</h5>

                            <h5 *ngIf="sensor_status" class="card-title mb-0">Temperature<span>&#8451;</span></h5>

                            <h5 *ngIf="flow_meter_status" class="card-title mb-0">Flow Rate Profile</h5>

                            <div class="header-right d-flex align-items-center">

                                <i class="icon-sm card-header-icon ml-4 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>
                            </div>
                        </div>

                        <div class="card-body">
                            <select *ngIf="chain_analysis_chart_status" class="form-select form-select-lg mb-3"
                                aria-label=".form-select-lg example" [(ngModel)]="type"
                                (change)="getEnergyProfileAndChainAnalysis(type)">
                                <option disabled>Select Type</option>
                                <option value="Daily" selected>Today</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Yearly">Yearly</option>
                            </select>

                            <select *ngIf="level_status" class="form-select form-select-lg mb-3"
                                aria-label=".form-select-lg example" [(ngModel)]="type"
                                (change)="getLevelPieChartData(type)">
                                <option disabled>Select Type</option>
                                <option value="Daily" selected>Today</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Yearly">Yearly</option>
                            </select>

                            <!-- <div class="energy-consumption"> -->
                            <app-pie-chart *ngIf="chain_analysis_chart_status"></app-pie-chart>
                            <div *ngIf="!chain_analysis_chart_status && !level_status" id="chartdiv"
                                style="width: 100%; height: 385px">
                            </div>
                            <div *ngIf="level_status" id="refellingPieChart" style="width: 100%; height: 300px">
                            </div>
                            <div style="margin-top: auto;">
                            <h4 *ngIf="level_status" style="text-align: center;">{{ refelling_energy_profile_type }}: {{
                                refelling_pie_chart_sum | currency: ' ' }} {{ device_unit }}</h4>

                            <h4 *ngIf="chain_analysis_chart_status && !flow_meter_status" style="text-align: center;">{{
                                energy_profile_type }}: {{ pie_chart_sum | currency: ' ' }} Kwh</h4>
                            <h4 *ngIf="flow_meter_status " style="text-align: center;">{{ energy_profile_type }}: {{
                                pie_chart_sum | currency: ' ' }} {{ unit }}</h4>
                            </div>
                            <!-- </div> -->

                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-xl-4 grid-margin stretch-card">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">

                            <h5 *ngIf="source_type" class="card-title mb-0">Energy Profile / Genset</h5>

                            <h5 *ngIf="!source_type && chain_analysis_chart_status" class="card-title mb-0">Chain
                                Analysis - {{ type }}</h5>

                            <h5 *ngIf="!chain_analysis_chart_status && !sensor_status && !level_status"
                                class="card-title mb-0">Motor
                                Torque (%)</h5>

                            <h5 *ngIf="sensor_status" class="card-title mb-0">Humudity%</h5>
                            <h5 *ngIf="level_status" class="card-title mb-0">Tank Capacity</h5>
                        </div>

                        <div class="card-body pb-0">
                            <select *ngIf="source_type" class="form-select form-select-lg mb-3"
                                aria-label=".form-select-lg example" [(ngModel)]="genset_type"
                                (change)="getGensetPieChartData(genset_type)">
                                <option disabled>Select Type</option>
                                <option value="Daily" selected>Today</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Yearly">Yearly</option>
                            </select>
                            
                            <table *ngIf="!source_type && chain_analysis_chart_status" class="table mt-3">
                                <tbody>
                                    <tr>
                                        <td>
                                            <h5>Current</h5>
                                        </td>
                                        <td>
                                            <h5 *ngIf="!flow_meter_status">{{ energy_consumption.current | currency: ' '
                                                }} Kwh</h5>
                                            <h5 *ngIf="flow_meter_status">{{ energy_consumption.current | currency: ' '
                                                }} {{ unit }}</h5>
                                        </td>
                                    </tr>
                                    <tr style="background-color: white;">
                                        <td>
                                            <h5>Last</h5>
                                        </td>
                                        <td>
                                            <h5 *ngIf="!flow_meter_status">{{ energy_consumption.last | currency: ' ' }}
                                                Kwh</h5>
                                            <h5 *ngIf="flow_meter_status">{{ energy_consumption.last | currency: ' ' }}
                                                {{ unit }}</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="flot-wrapper">

                                <div *ngIf="!source_type && chain_analysis_chart_status" id="chartChain"
                                    style="width: 100%; height: 200px"></div>

                                <div *ngIf="!source_type && !chain_analysis_chart_status && !level_status"
                                    id="guageChart" style="width: 100%; height: 385px"></div>

                                <div *ngIf="level_status" id="capacityChart" style="width: 100%; height: 385px"></div>

                                <div *ngIf="source_type" id="gensetPieChart" style="width: auto; height: 310px;"></div>

                                <h4 *ngIf="source_type" style="text-align: center;">{{ genset_energy_profile_type }}: {{
                                    genset_pie_chart_sum | currency: ' ' }} Kwh</h4>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-xl-4 grid-margin stretch-card">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center" id="graph">
                            <h5 *ngIf="!flow_meter_status && !level_status" class="card-title mb-0">Min Max</h5>
                            <h5 *ngIf="flow_meter_status && !level_status" class="card-title mb-0">Instant Flow Rate</h5>
                            <h5 *ngIf="level_status" class="card-title mb-0">Today's Consumption</h5>
                        </div>

                        <div *ngIf="!flow_meter_status && !level_status" class="min-max-card-body card-body justify-content-center align-middle" style="display:flex;">
                            <div class="w-fill-available p-1 align-self-center">
                            <div class="row justify-content-center">
                                <div class="ml-1 mr-1 mb-2 col-md-12 col-xl-5 stretch-card card bg-light"
                                    style="max-height: 10%">
                                    <!-- <div class="card-body"> -->
                                    <h4 class="card-title mt-2" style="text-align: center;">{{ min_max_name.card_1 }}
                                    </h4>
                                    <h4 class="card-title mb-2 mt-2" style="color: green; text-align: center;">Min.: {{
                                        min_max.card_1_min.toFixed(1) }} {{ min_max_unit.card_1 }}</h4>
                                    <h4 class="card-title" style="color: rgb(255, 0, 0); text-align: center;">Max.: {{
                                        min_max.card_1_max.toFixed(1) }} {{ min_max_unit.card_1 }}</h4>
                                    <!-- </div> -->
                                </div>
                                <div class="ml-1 mr-1 mb-2 col-md-12 col-xl-5 stretch-card card bg-light">
                                    <h4 class="card-title mt-2" style="text-align: center;">{{ min_max_name.card_2 }}
                                    </h4>
                                    <h4 class="card-title mb-2 mt-2" style="color: green; text-align: center;">Min.: {{
                                        min_max.card_2_min.toFixed(1) }} {{ min_max_unit.card_2 }}</h4>
                                    <h4 class="card-title" style="color: rgb(255, 0, 0); text-align: center;">Max.: {{
                                        min_max.card_2_max.toFixed(1) }} {{ min_max_unit.card_2 }}</h4>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="ml-1 mr-1 mb-2 col-md-12 col-xl-5  stretch-card card bg-light"
                                    style="max-height: 10%">
                                    <!-- <div class="card-body"> -->
                                    <h4 class="card-title mt-2" style="text-align: center;">{{ min_max_name.card_3 }}
                                    </h4>
                                    <h4 class="card-title mb-2 mt-2" style="color: green; text-align: center;">Min.: {{
                                        min_max.card_3_min.toFixed(1) }} {{ min_max_unit.card_3 }}</h4>
                                    <h4 class="card-title" style="color: rgb(255, 0, 0); text-align: center;">Max.: {{
                                        min_max.card_3_max.toFixed(1) }} {{ min_max_unit.card_3 }}</h4>
                                    <!-- </div> -->
                                </div>
                                <div class="ml-1 mr-1 mb-2 col-md-12 col-xl-5  stretch-card card bg-light">
                                    <h4 class="card-title mt-2" style="text-align: center;">{{ min_max_name.card_4 }}
                                    </h4>
                                    <h4 class="card-title mb-2 mt-2" style="color: green; text-align: center;">Min.: {{
                                        min_max.card_4_min.toFixed(1) }} {{ min_max_unit.card_4 }}</h4>
                                    <h4 class="card-title" style="color: rgb(255, 0, 0); text-align: center;">Max.: {{
                                        min_max.card_4_max.toFixed(1) }} {{ min_max_unit.card_4 }}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div *ngIf="flow_meter_status && !level_status" class="card-body">
                            <div id="guageChart" style="width: 100%; height: 385px"></div>
                        </div>
                        <div *ngIf="level_status" class="card-body">
                            <select class="form-select form-select-lg mb-3"
                                aria-label=".form-select-lg example" [(ngModel)]="type"
                                (change)="getLevelPieChartData(type)">
                                <option disabled>Select Type</option>
                                <option value="Daily" selected>Today</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Yearly">Yearly</option>
                            </select>

                            <div *ngIf="level_status" id="consumptionPieChart" style="width: 100%; height: 300px">
                            </div>

                            <h4 *ngIf="level_status" style="text-align: center;">{{ consumption_energy_profile_type }}:
                                {{
                                consumption_pie_chart_sum | currency: ' ' }} {{ device_unit }}</h4>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">   
                <div class="col-md-12 col-xl-12 grid-margin stretch-card d-grid">
                    <div class="card">
                        <div style="overflow-x: auto;" class="d-flex justify-content-between card-header align-items-center" id="graph">
                            <h5 class="card-title mb-0">Graph</h5>

                            <div class="header-right d-flex align-items-center">

                                    <button class="btn pr-1" type="button" data-toggle="collapse"
                                        data-target="#collapseExample" aria-expanded="false"
                                        aria-controls="collapseExample">
                                        <i class="feather-sliders"
                                            style="font-size: x-large; color: #ff0000;; font-weight: 500;"></i>
                                    </button>


                                <a href="javascript:void(0);" (click)="tabLineChart('line')" class="graph mt-2">
                                    <span [ngClass]="{'active': chart_active == 'line'}"><i
                                            class="icon-sm card-header-icon mr-1 ml-1 pb-3px feather feather-trending-up"></i></span>
                                </a>

                                <span class="divider"></span>

                                <a href="javascript:void(0);" (click)="tabBarChart('bar')" class="graph mt-2">
                                    <span [ngClass]="{'active': chart_active == 'bar'}"><i
                                            class="icon-sm card-header-icon ml-1 pb-3px feather feather-bar-chart-2"></i></span>
                                </a>

                                <i class="icon-sm card-header-icon mr-2 pl-2 pt-1 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-0">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Print">
                                            <i class="icon-sm mr-2 feather feather-printer"></i>
                                            <span class="">Print</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param= 'trend_graph'; data_param_el = 'graph'; exportAsCSV()">
                                            <i class="icon-sm mr-2 feather feather-download"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf" (click)="data_param= 'trend_graph'; data_param_el = 'graph'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-file-text"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="collapse px-2" id="collapseExample">
                            <div class="card-body overflow-auto">
                                <div class="w-max-content align-items-center header-right-inner-left d-flex">

                                    <div class="form-group mb-0 mr-2">
                                        <h6>Parameters:</h6>
                                    </div>

                                    <div class="form-group mb-0 mr-2">
                                        <ng-multiselect-dropdown [placeholder]="'Select parameters'"
                                            [settings]="dropdownSettings_trend" [data]="dropdownList"
                                            [(ngModel)]="selectedItems_trend" (onSelect)="onItemSelectTrend($event)"
                                            (onDeSelect)="onItemDeSelectTrend($event)">
                                        </ng-multiselect-dropdown>
                                    </div>

                                    <div class="form-group mb-0 mr-2">
                                        <h6>Range:</h6>
                                    </div>

                                    <div class="form-group mb-0 mr-2">
                                        <input type="text"
                                            style="width: 200px; height: 36px; border:  1px solid rgb(204, 204, 204); border-radius: 5px 5px 5px 5px;"
                                            ngxDaterangepickerMd [(ngModel)]="selected" startKey="start" endKey="end"
                                            [showCustomRangeLabel]="true" [autoApply]="true" [closeOnAutoApply]="true"
                                            [ranges]="ranges" [linkedCalendars]="true" placeholder="Select Range">

                                    </div>

                                    <div class="form-group mb-0 mr-2">
                                        <h6 class="text-nowrap">Graph Type:</h6>
                                    </div>

                                    <div class="form-group mb-0 mr-2" style="max-width: 120px;">
                                        <select style="width:8em;"  class="form-select" aria-label="Default select example"
                                            [(ngModel)]="graph_type" (change)="checkDeviceModelStatus()">
                                            <option disabled>Select Graph Type</option>
                                            <option value="all">All</option>
                                            <option value="val_" selected>Average</option>
                                            <option value="min_">Minimum</option>
                                            <option value="max_">Maximum</option>
                                        </select>
                                    </div>

                                    <div class="form-group mb-0 mr-2">
                                        <h6 class="text-nowrap">Fixed Interval:</h6>
                                    </div>

                                    <div class="form-group mb-0 mr-2" style="max-width: 120px;">
                                        <select style="width:8em;" class="form-select" aria-label="Default select example"
                                            [(ngModel)]="fixed_interval">
                                            <option disabled>Select Fixed Interval</option>
                                            <option value="1s">1 Second</option>
                                            <option value="1m">1 Minute</option>
                                            <option value="5m" selected>5 Minute</option>
                                            <option value="15m">15 Minute</option>
                                            <option value="30m">30 Minute</option>
                                            <option value="1h">1 Hour</option>
                                        </select>
                                    </div>

                                    <div class="form-group mb-0">

                                        <button type="button" class="btn" (click)="onClick()">
                                            <i class="feather-eye"
                                                style="font-size: x-large; color: #ff0000;; font-weight: 500;"></i>
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="card-body">
                            <div class="flot-wrapper" style="margin-top: -15px;">
                                <app-amcharts style="width: 100%;"></app-amcharts>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">

                <div class="col-md-12 col-xl-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center" id="graph">
                            <h5 class="card-title mb-0">Table</h5>

                            <div class="header-right d-flex align-items-center">

                                <i class="icon-sm card-header-icon ml-4 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="tabset.select('tab-table')"></i>

                            </div>
                        </div>
                        <div class="d-grid card-body">
                            <div class="table-respoDnsive" style="height: 500px;">

                                <table class="table table-bordered" id="myTable">
                                    <thead>
                                        <tr>
                                            <th *ngFor="let param of params_trend; let i = index"
                                                (click)="sortTable(i)">{{
                                                getTitleByParamCode(param) }}
                                                <svg *ngIf="dir == 'asc'" xmlns="http://www.w3.org/2000/svg" width="18"
                                                    height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-arrow-up">
                                                    <line x1="12" y1="19" x2="12" y2="5"></line>
                                                    <polyline points="5 12 12 5 19 12"></polyline>
                                                </svg>

                                                <svg *ngIf="dir == 'desc'" xmlns="http://www.w3.org/2000/svg" width="18"
                                                    height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-arrow-down">
                                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                                    <polyline points="19 12 12 19 5 12"></polyline>
                                                </svg>
                                            </th>
                                            <!-- <th width="20%">Timestamp</th> -->
                                            <th width="20%">Timestamp</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of tabular_data_trend">
                                            <td *ngFor="let param of params_trend">{{ (data[param]) ?
                                                data[param].toFixed(2) : '0.00' }}</td>
                                            <td>{{ data['timestamp'] }}
                                                <!-- {{ data['@timestamp'] | date:'EEEE, MMMM d, yyyy hh:mm:ss a' }} -->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
          
            
            <div class="row">

                <div class="col-md-12 col-xl-12 grid-margin stretch-card d-none {{inverter_trend_status}}">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center" id="graph">
                            <h5 class="card-title mb-0">Inverter MPPT Data</h5>

                            <div class="header-right d-flex align-items-center">

                            </div>
                        </div>
                        <div class="card-body">
                            <div class="table-respoDnsive" style="height: 500px;">
                                
                                <table class="table table-bordered" id="myTable">
                                    <thead>
                                        <tr>
                                            <th>MPPT</th>
                                            <th class="text-center">DC Input Voltage (V)</th>
                                            <th class="text-center">DC Input Current (A)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of inverter_mppt_table">
                                            <td>{{ data.string }}</td>
                                            <td class="text-center">{{ data.voltage.toFixed(2) }}</td>
                                            <td class="text-center">{{ data.current.toFixed(2) }}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <h6>Last Updated: {{last_updated_inverter_table | date: 'short'}}</h6>
                            </div>

                        </div>
                    </div>
                </div>

                
                <div class="col-md-12 col-xl-12 grid-margin stretch-card {{inverter_trend_status}}">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center" id="graph">
                            <h5 class="card-title mb-0">Inverter String Data</h5>

                            <div class="header-right d-flex align-items-center">

                            </div>
                        </div>
                        <div class="card-body">
                            <div class="table-respoDnsive" style="height: 500px;">
                                
                                <table class="table table-bordered" id="myTable">
                                    <thead>
                                        <tr>
                                            <th>String</th>
                                            <th class="text-center">DC Input Voltage (V)</th>
                                            <th class="text-center">DC Input Current (A)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of inverter_table">
                                            <td>{{ data.string }}</td>
                                            <td class="text-center">{{ data.voltage.toFixed(2) }}</td>
                                            <td class="text-center">{{ data.current.toFixed(2) }}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <h6>Last Updated: {{last_updated_inverter_table | date: 'short'}}</h6>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="row">

                <!-- <div class="col-md-12 col-xl-4 grid-margin stretch-card">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">

                            <h5 class="card-title mb-0">Power Button</h5>

                            <div class="header-right d-flex align-items-center">

                                <i class="icon-sm card-header-icon ml-4 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                            </div>
                        </div>

                        <div class="card-body" style="background-color: #181818e6; align-items: center;">

                            power button
                            <div class="power-switch">
                                <input type="checkbox" />
                                <div class="button">
                                    <svg class="power-off">
                                        <use xlink:href="#line" class="line" />
                                        <use xlink:href="#circle" class="circle" />
                                    </svg>
                                    <svg class="power-on">
                                        <use xlink:href="#line" class="line" />
                                        <use xlink:href="#circle" class="circle" />
                                    </svg>
                                </div>
                            </div>

                            SVG
                            <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
                                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" id="line">
                                    <line x1="75" y1="34" x2="75" y2="58" />
                                </symbol>
                                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" id="circle">
                                    <circle cx="75" cy="80" r="35" />
                                </symbol>
                            </svg>
                            end
                        </div>

                    </div>
                </div> -->

                <div class="col-md-12 col-xl-12 grid-margin stretch-card {{ map_status }}">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">

                            <h5 class="card-title mb-0">Device Geo Location</h5>
                        </div>

                        <agm-map style="height: 700px;" [latitude]="lat" [longitude]="lng" [zoom]="zoom">

                            <!-- default location -->
                            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>

                            <!-- dynamic location -->
                            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                        </agm-map>


                    </div>
                </div>
            </div>

        </ng-template>
    </ngb-tab>

    <ngb-tab *ngIf="tab_graph_status" id="tab-graph" title="Graph">
        <ng-template ngbTabContent>

            <br />

            <zainergy-dashboard-cards *ngIf="energy_status" for="dashboard"></zainergy-dashboard-cards>
            <zainergy-dashboard-cards *ngIf="flow_meter_status" for="flow"></zainergy-dashboard-cards>

            <div class="row">

                <div class="col-md-12 col-xl-6 grid-margin stretch-card" id="chart_voltage"
                    *ngIf="voltage_status && energy_status">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="card-title mb-0 ">{{ voltage_header }}</h6>

                            <div class="header-right d-flex align-items-center">

                                <p>
                                    <button class="btn pr-1" type="button" data-toggle="collapse"
                                        data-target="#collapseVoltage" aria-expanded="false"
                                        aria-controls="collapseVoltage">
                                        <i class="feather-sliders"
                                            style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                                    </button>
                                </p>

                                <a href="javascript:void(0);" (click)="tabLineChart('voltage')" class="graph">
                                    <span [ngClass]="{'active': vol_chart_active == 'line'}"><i
                                            class="icon-sm card-header-icon ml-1 mr-1 pb-3px feather feather-trending-up"></i></span>
                                </a>

                                <span class="divider"></span>

                                <a href="javascript:void(0);" (click)="tabBarChart('voltage')" class="graph">
                                    <span [ngClass]="{'active': vol_chart_active == 'bar'}"><i
                                            class="icon-sm card-header-icon ml-1 pb-3px feather feather-bar-chart-2"></i></span>
                                </a>

                                <i class="icon-sm card-header-icon mr-2 pl-2 pt-1 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Print"
                                            (click)="data_param = 'voltage'; data_param_el = 'chart_voltage'; exportAsPDF($event)">
                                            <i class="feather feather-printer icon-sm mr-2"></i>
                                            <span class="">Print</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'voltage'; exportAsCSV()">
                                            <i class="feather feather-download icon-sm mr-2"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf"
                                            (click)="data_param = 'voltage'; data_param_el = 'chart_voltage'; exportAsPDF($event)">
                                            <i class="feather feather-file-text icon-sm mr-2"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="card-body">
                            <div class="collapse px-2" id="collapseVoltage">
                                <div class="card-body overflow-auto">
                                    <div class="w-max-content d-inline-flex align-items-center">

                                        <div class="form-group mb-0 mr-2">
                                            <input type="date" [(ngModel)]="vl_from_date_selection" autocomplete="off"
                                                class="form-control" (change)="onFromDateSelection($event, 'voltage')">
                                        </div>

                                        <span class="mr-2 from-to">-</span>

                                        <div class="form-group mb-0">
                                            <input type="date" [(ngModel)]="vl_to_date_selection" autocomplete="off"
                                                class="form-control" (change)="onToDateSelection($event, 'voltage')">
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="row align-items-start mb-2"></div>

                            <div class="flot-wrapper">
                                <div id="chartVoltage" style="width: 100%; height: 450px"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-xl-6 grid-margin stretch-card" id="chart_current"
                    *ngIf="current_status && energy_status">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="card-title mb-0">{{ current_header }}</h6>

                            <div class="header-right d-flex align-items-center">

                                <p>
                                    <button class="btn pr-1" type="button" data-toggle="collapse"
                                        data-target="#collapseCurrent" aria-expanded="false"
                                        aria-controls="collapseCurrent">
                                        <i class="feather-sliders"
                                            style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                                    </button>
                                </p>

                                <a href="javascript:void(0);" (click)="tabLineChart('current')" class="graph">
                                    <span [ngClass]="{'active': curr_chart_active == 'line'}"><i
                                            class="icon-sm card-header-icon  mr-1 ml-1 pb-3px feather feather-trending-up"></i></span>
                                </a>

                                <span class="divider"></span>

                                <a href="javascript:void(0);" (click)="tabBarChart('current')" class="graph">
                                    <span [ngClass]="{'active': curr_chart_active == 'bar'}"><i
                                            class="icon-sm card-header-icon ml-1 pb-3px feather feather-bar-chart-2"></i></span>
                                </a>

                                <i class="icon-sm card-header-icon mr-2 pl-2 pt-1 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Print"
                                            (click)="data_param = 'current'; data_param_el = 'chart_current'; exportAsPDF($event)">
                                            <i class="feather feather-printer icon-sm mr-2"></i>
                                            <span class="">Print</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'current'; exportAsCSV()">
                                            <i class="icon-sm mr-2 feather feather-download"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf"
                                            (click)="data_param = 'current'; data_param_el = 'chart_current'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-file-text"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="card-body">
                            <div class="collapse px-2" id="collapseCurrent">
                                <div class="card-body overflow-auto">
                                    <div class="w-max-content d-inline-flex align-items-center">

                                        <div class="form-group mb-0 mr-2">
                                            <input type="date" [(ngModel)]="curr_from_date_selection" autocomplete="off"
                                                class="form-control" (change)="onFromDateSelection($event, 'current')">
                                        </div>

                                        <span class="mr-2 from-to">-</span>

                                        <div class="form-group mb-0">
                                            <input type="date" [(ngModel)]="curr_to_date_selection" autocomplete="off"
                                                class="form-control" (change)="onToDateSelection($event, 'current')">
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="row align-items-start mb-2">


                            </div>
                            <div class="flot-wrapper">
                                <div id="chartCurr" style="width: 100%; height: 450px"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-xl-6 grid-margin stretch-card" id="chart_kilo_watt"
                    *ngIf="kw_status && energy_status">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="card-title mb-0">{{ kw_header }}</h6>

                            <div class="header-right d-flex align-items-center">

                                    <button class="btn pr-1" type="button" data-toggle="collapse"
                                        data-target="#collapseKiloWatt" aria-expanded="false"
                                        aria-controls="collapseKiloWatt">
                                        <i class="feather-sliders"
                                            style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                                    </button>

                                <a href="javascript:void(0);" (click)="tabLineChart('kilo_watt')" class="graph">
                                    <span [ngClass]="{'active': kw_chart_active == 'line'}"><i
                                            class="icon-sm card-header-icon  mr-1 ml-1 pb-3px feather feather-trending-up"></i></span>
                                </a>
                                <span class="divider"></span>

                                <a href="javascript:void(0);" (click)="tabBarChart('kilo_watt')" class="graph">
                                    <span [ngClass]="{'active': kw_chart_active == 'bar'}"><i
                                            class="icon-sm card-header-icon ml-1 pb-3px feather feather-bar-chart-2"></i></span>
                                </a>

                                <i class="icon-sm card-header-icon mr-2 pl-2 pt-1 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Print"
                                            (click)="data_param = 'kilo_watt'; data_param_el = 'chart_kilo_watt'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-printer"></i>
                                            <span class="">Print</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'kilo_watt'; exportAsCSV()">
                                            <i class="icon-sm mr-2 feather feather-download"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf"
                                            (click)="data_param = 'kilo_watt'; data_param_el = 'chart_kilo_watt'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-file-text"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="card-body">
                            <div class="collapse px-2" id="collapseKiloWatt">
                                <div class="card-body">
                                    <div class="d-inline-flex justify-content-end w-max-content align-items-center">

                                        <div class="form-group mb-0 mr-2">
                                            <input type="date" [(ngModel)]="kw_from_date_selection" autocomplete="off"
                                                class="form-control" (change)="onFromDateSelection($event, 'kilo_watt')">
                                        </div>

                                        <span class="mr-2 from-to">-</span>

                                        <div class="form-group mb-0">
                                            <input type="date" [(ngModel)]="kw_to_date_selection" autocomplete="off"
                                                class="form-control" (change)="onToDateSelection($event, 'kilo_watt')">
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="row align-items-start mb-2"></div>

                            <div class="flot-wrapper">
                                <div id="chartKiloWatt" style="width: 100%; height: 450px"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-xl-6 grid-margin stretch-card" id="chart_power_factor"
                    *ngIf="power_status && energy_status">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="card-title mb-0">{{ power_header }}</h6>

                            <div class="header-right d-flex align-items-center justify-content-between">

                                <p>
                                    <button class="btn pr-1" type="button" data-toggle="collapse"
                                        data-target="#collapsePowerFactor" aria-expanded="false"
                                        aria-controls="collapsePowerFactor">
                                        <i class="feather-sliders"
                                            style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                                    </button>
                                </p>

                                <a href="javascript:void(0);" (click)="tabLineChart('power_factor')" class="graph">
                                    <span [ngClass]="{'active': pf_chart_active == 'line'}"><i
                                            class="icon-sm card-header-icon  mr-1 ml-1 pb-3px feather feather-trending-up"></i></span>
                                </a>

                                <span class="divider"></span>

                                <a href="javascript:void(0);" (click)="tabBarChart('power_factor')" class="graph">
                                    <span [ngClass]="{'active': pf_chart_active == 'bar'}"><i
                                            class="icon-sm card-header-icon ml-1 pb-3px feather feather-bar-chart-2"></i></span>
                                </a>

                                <i class="icon-sm card-header-icon mr-2 pl-2 pt-1 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Print"
                                            (click)="data_param = 'power_factor'; data_param_el = 'chart_power_factor'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-printer"></i>
                                            <span class="">Print</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'power_factor'; exportAsCSV()">
                                            <i class="icon-sm mr-2 feather feather-download"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf"
                                            (click)="data_param = 'power_factor'; data_param_el = 'chart_power_factor'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-file-text"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="card-body">
                            <div class="collapse px-2" id="collapsePowerFactor">
                                <div class="card-body overflow-auto">
                                    <div class="w-max-content d-inline-flex justify-content-end align-items-center">

                                        <div class="form-group mb-0 mr-2">
                                            <input type="date" [(ngModel)]="pf_from_date_selection" autocomplete="off"
                                                class="form-control" (change)="onFromDateSelection($event, 'power_factor')">
                                        </div>

                                        <span class="mr-2 from-to">-</span>

                                        <div class="form-group mb-0">
                                            <input type="date" [(ngModel)]="pf_to_date_selection" autocomplete="off"
                                                class="form-control" (change)="onToDateSelection($event, 'power_factor')">
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="row align-items-start mb-2">


                            </div>
                            <div class="flot-wrapper">
                                <div id="chartPowerFactor" style="width: 100%; height: 450px"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-xl-6 grid-margin stretch-card" id="chart_inst_flow" *ngIf="flow_meter_status">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="card-title mb-0"> INSTANT FLOW RATE US.GPM</h6>

                            <div class="header-right d-flex align-items-center">

                                    <button class="btn pr-1" type="button" data-toggle="collapse"
                                        data-target="#collapseInstflow" aria-expanded="false"
                                        aria-controls="collapseInstflow">
                                        <i class="feather-sliders"
                                            style="font-size: x-large; color: #ff0000;; font-weight: 500;"></i>
                                    </button>

                                <a href="javascript:void(0);" (click)="tabLineChart('inst_flow')" class="graph">
                                    <span [ngClass]="{'active': f_chart_active == 'line'}"><i
                                            class="icon-sm card-header-icon  mr-1 ml-1 pb-3px feather feather-trending-up"></i></span>
                                </a>
                                <span class="divider"></span>

                                <a href="javascript:void(0);" (click)="tabBarChart('inst_flow')" class="graph">
                                    <span [ngClass]="{'active': f_chart_active == 'bar'}"><i
                                            class="icon-sm card-header-icon ml-1 pb-3px feather feather-bar-chart-2"></i></span>
                                </a>

                                <i class="icon-sm card-header-icon mr-2 pl-2 pt-1 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Print"
                                            (click)="data_param = 'inst_flow'; data_param_el = 'chart_inst_flow'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-printer"></i>
                                            <span class="">Print</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'inst_flow'; exportAsCSV()">
                                            <i class="icon-sm mr-2 feather feather-download"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf"
                                            (click)="data_param = 'inst_flow'; data_param_el = 'chart_inst_flow'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-file-text"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="card-body">
                                                       <div class="collapse px-2" id="collapseInstflow">
                                <div class="card-body overflow-auto">
                                    <div class="w-max-content d-inline-flex justify-content-end align-items-center">

                                        <div class="form-group mb-0 mr-2">
                                            <input type="date" [(ngModel)]="f_from_date_selection" autocomplete="off"
                                                class="form-control" (change)="onFromDateSelection($event, 'inst_flow')">
                                        </div>

                                        <span class="mr-2 from-to">-</span>

                                        <div class="form-group mb-0">
                                            <input type="date" [(ngModel)]="f_to_date_selection" autocomplete="off"
                                                class="form-control" (change)="onToDateSelection($event, 'inst_flow')">
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="row align-items-start mb-2"></div>

                            <div class="flot-wrapper">
                                <div id="chartInstFlow" style="width: 100%; height: 450px"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-xl-6 grid-margin stretch-card" id="chart_total_flow"
                    *ngIf="flow_meter_status">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center">
                            <h6 class="card-title mb-0">TOTAL FLOW US.GAL</h6>

                            <div class="header-right d-flex align-items-center justify-content-between">

                                <p>
                                    <button class="btn pr-1" type="button" data-toggle="collapse"
                                        data-target="#collapseTotalFlow" aria-expanded="false"
                                        aria-controls="collapseTotalFlow">
                                        <i class="feather-sliders"
                                            style="font-size: x-large; color: #ff0000;; font-weight: 500;"></i>
                                    </button>
                                </p>

                                <a href="javascript:void(0);" (click)="tabLineChart('total_flow')" class="graph">
                                    <span [ngClass]="{'active': fh_chart_active == 'line'}"><i
                                            class="icon-sm card-header-icon  mr-1 ml-1 pb-3px feather feather-trending-up"></i></span>
                                </a>

                                <span class="divider"></span>

                                <a href="javascript:void(0);" (click)="tabBarChart('total_flow')" class="graph">
                                    <span [ngClass]="{'active': fh_chart_active == 'bar'}"><i
                                            class="icon-sm card-header-icon ml-1 pb-3px feather feather-bar-chart-2"></i></span>
                                </a>

                                <i class="icon-sm card-header-icon mr-2 pl-2 pt-1 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Print"
                                            (click)="data_param = 'total_flow'; data_param_el = 'chart_total_flow'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-printer"></i>
                                            <span class="">Print</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'total_flow'; exportAsCSV()">
                                            <i class="icon-sm mr-2 feather feather-download"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            name="Pdf"
                                            (click)="data_param = 'total_flow'; data_param_el = 'chart_total_flow'; exportAsPDF($event)">
                                            <i class="icon-sm mr-2 feather feather-file-text"></i>
                                            <span class="">Export as PDF</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="card-body">
                            <div class="collapse px-2" id="collapseTotalFlow">
                                <div class="card-body overflow-auto">
                                    <div class="w-max-content d-inline-flex justify-content-end align-items-center">

                                        <div class="form-group mb-0 mr-2">
                                            <input type="date" [(ngModel)]="fh_from_date_selection" autocomplete="off"
                                                class="form-control" (change)="onFromDateSelection($event, 'total_flow')">
                                        </div>

                                        <span class="mr-2 from-to">-</span>

                                        <div class="form-group mb-0">
                                            <input type="date" [(ngModel)]="fh_to_date_selection" autocomplete="off"
                                                class="form-control" (change)="onToDateSelection($event, 'total_flow')">
                                        </div>

                                    </div>
                                    </div>
                                </div>
                            <div class="row align-items-start mb-2">


                            </div>
                            <div class="flot-wrapper">
                                <div id="chartTotalFlow" style="width: 100%; height: 450px"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngb-tab>

    <ngb-tab *ngIf="tab_table_status" id="tab-table" title="Table">
        <ng-template ngbTabContent>

            <div class="row mt-4">

                <div class="col-lg-12 col-xl-12 stretch-card" id="tab_data">
                    <div class="row flex-grow">

                        <div class="d-grid col grid-margin stretch-card">
                            <div class="card">
                                <div class="d-flex justify-content-between card-header align-items-center mb-4 mb-md-3">
                                    <h6 class="card-title mb-0 ">Table</h6>

                                    <div class="d-flex align-items-center justify-content-end">

                                        <button class="btn pr-1" type="button" data-toggle="collapse"
                                            data-target="#collapseExample" aria-expanded="false"
                                            aria-controls="collapseExample">
                                            <i class="feather-sliders"
                                            style="font-size: x-large; color: #ff0000;; font-weight: 500;"></i>
                                        </button>

                                        <div class="dropdown ml-2">
                                            <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i
                                                    class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);" name="Print"
                                                    (click)="data_param = 'table'; data_param_el = 'tab_data'; exportAsPDF($event)">
                                                    <i class="icon-sm mr-2 feather feather-printer"></i>
                                                    <span class="">Print</span>
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);"
                                                    (click)="data_param = 'table'; exportAsCSV()">
                                                    <i class="icon-sm mr-2 feather feather-download"></i>
                                                    <span class="">Export as CSV (Upto 9999)</span>
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);" name="Pdf"
                                                    (click)="data_param = 'table'; data_param_el = 'tab_data'; exportAsPDF($event)">
                                                    <i class="icon-sm mr-2 feather feather-file-text"></i>
                                                    <span class="">Export as PDF (Upto 9999)</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="collapse px-2" id="collapseExample">
                                    <div class="card-body overflow-auto">
                                        <div class="w-max-content align-items-center header-right-inner-left d-flex">

                                            <div class="form-group mb-0 mr-2">
                                                <h6>Range:</h6>
                                            </div>

                                            <div class="form-group mb-0 mr-2">
                                                <input type="text" class="form-control"
                                                    (change)="onTabularDateSelection(tabular_selected)"
                                                    style="width: 325px; height: 36px; border:  1px solid rgb(204, 204, 204); border-radius: 5px 5px 5px 5px; color: red;"
                                                    ngxDaterangepickerMd [(ngModel)]="tabular_selected" startKey="start"
                                                    [timePicker]="true" [timePickerSeconds]="false"
                                                    [timePicker24Hour]="true" endKey="end" [showCustomRangeLabel]="true"
                                                    [alwaysShowCalendars]="true" [customRangeDirection]="true"
                                                    [locale]="{ displayFormat: 'DD MMM YYYY  HH:00', separator: '    To    ',  cancelLabel: 'Cancel', firstDay: 1}"
                                                    [showClearButton]="true" [showCancel]="true"
                                                    [showWeekNumbers]="true" [ranges]="ranges" [linkedCalendars]="true"
                                                    placeholder="Select Range">

                                            </div>

                                            <div class="form-group mb-0 ml-2 mr-2">
                                                <h6 class="text-nowrap">Parameters:</h6>
                                            </div>

                                            <div class="form-group mb-0 d-inline-flex justify-content-between">
                                                <ng-multiselect-dropdown [placeholder]="'Select parameters'"
                                                    [settings]="dropdownSettings" [data]="dropdownList"
                                                    [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)"
                                                    (onDeSelect)="onItemDeSelect($event)">
                                                </ng-multiselect-dropdown>

                                                <button id="featureIcon" type="button" class="btn" (click)="onClick()">
                                                    <i class="ml-3 feather-eye"
                                                        style="font-size: x-large; color: #ff0000;; font-weight: 500;"></i>
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="card-body">
                                    <div class="table-respoDnsive">
                                        <table class="table table-bordered" id="myTable">
                                            <thead>
                                                <tr>
                                                    <th *ngFor="let param of params; let i = index"
                                                        (click)="sortTable(i)">{{
                                                        getTitleByParamCode(param) }}
                                                        <svg *ngIf="dir == 'asc'" xmlns="http://www.w3.org/2000/svg"
                                                            width="18" height="18" viewBox="0 0 24 24" fill="none"
                                                            stroke="currentColor" stroke-width="2"
                                                            stroke-linecap="round" stroke-linejoin="round"
                                                            class="feather feather-arrow-up">
                                                            <line x1="12" y1="19" x2="12" y2="5"></line>
                                                            <polyline points="5 12 12 5 19 12"></polyline>
                                                        </svg>

                                                        <svg *ngIf="dir == 'desc'" xmlns="http://www.w3.org/2000/svg"
                                                            width="18" height="18" viewBox="0 0 24 24" fill="none"
                                                            stroke="currentColor" stroke-width="2"
                                                            stroke-linecap="round" stroke-linejoin="round"
                                                            class="feather feather-arrow-down">
                                                            <line x1="12" y1="5" x2="12" y2="19"></line>
                                                            <polyline points="19 12 12 19 5 12"></polyline>
                                                        </svg>
                                                    </th>
                                                    <th width="20%">Timestamp</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of tabular_data">
                                                    <td *ngFor="let param of params">{{ (data[param]) ?
                                                        data[param] : '0.00' }}</td>
                                                    <td>{{ data['timestamp'] }}
                                                        <!-- {{ data['@timestamp'] | date:'EEEE, MMMM d, yyyy hh:mm:ss a' }} -->
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="total_found">
                                        Total Found: <b>{{ total_found }}</b>
                                    </div>

                                    <div style="justify-content: flex-end;" class="pagination d-flex">

                                        <input class="p-btn" type="button" value="Prev"
                                            (click)="pageSelection('prev_page')">

                                        &nbsp;&nbsp;&nbsp;
                                        <label class="mt-2" style="color: rgb(1, 55, 105);"> Page </label>
                                        &nbsp;&nbsp;

                                        <select [(ngModel)]="page" (change)="pageSelection('curr_page')"
                                            style="width:80px; border-radius:5px;color:black;">
                                            <option *ngFor="let page of pages">{{ page }}</option>
                                        </select>

                                        &nbsp; &nbsp;
                                        <label class="mt-2 text-nowrap" style="color: rgb(1, 55, 105);"> of {{ pages.length | number
                                            : '1.0-0'}}</label>
                                        &nbsp; &nbsp;
                                        <input class="p-btn" type="button" value="Next"
                                            (click)="pageSelection('next_page')">
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- row -->

        </ng-template>
    </ngb-tab>

    <ngb-tab *ngIf="tab_overview_status" id="tab-overview" title="Overview">
        <ng-template ngbTabContent>

            <br />

            <div class="row">

                <div class="col-md-12 grid-margin stretch-card" id="chart_overview">
                    <div class="row flex-grow">
                        <div class="d-grid col grid-margin stretch-card">
                            <div class="card">
                                <div class="d-flex justify-content-between card-header align-items-center">
                                    <h6 class="card-title mb-0 ">{{ overview_title }}</h6>

                                    <div class="header-right d-flex align-items-center">

                                        <button class="btn pr-1" type="button" data-toggle="collapse"
                                        data-target="#collapseExample" aria-expanded="false"
                                        aria-controls="collapseExample">
                                        <i class="feather-sliders"
                                            style="font-size: x-large; color: #ff0000;; font-weight: 500;"></i>
                                </button>

                                        <a href="javascript:void(0);" (click)="tabLineChart('overview')" class="graph">
                                            <span [ngClass]="{'active': ov_chart_active == 'line'}">
                                                <i class="icon-sm card-header-icon mr-1 ml-1 pb-3px feather feather-trending-up"></i>
                                            </span>
                                        </a>

                                        <span class="divider"></span>

                                        <a href="javascript:void(0);" (click)="tabBarChart('overview')" class="graph">
                                            <span [ngClass]="{'active': ov_chart_active == 'bar'}">
                                                <i class="icon-sm card-header-icon ml-1 pb-3px feather feather-bar-chart-2"></i>
                                            </span>
                                        </a>

                                        <i class="icon-sm card-header-icon mr-2 pl-2 pt-1 pb-3px maximize-card feather feather-maximize-2"
                                            (click)="toggleCard($event)"></i>

                                        <div class="dropdown ml-2">
                                            <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                                    name="Print"
                                                    (click)="data_param = 'overview'; data_param_el = 'chart_overview'; exportAsPDF($event)">
                                                    <i class="icon-sm mr-2 feather feather-printer"></i>
                                                    <span class="">Print</span>
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                                    (click)="data_param = 'overview'; exportAsCSV()">
                                                    <i class="icon-sm mr-2 feather feather-download"></i>
                                                    <span class="">Export as CSV</span>
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                                    name="Pdf"
                                                    (click)="data_param = 'overview'; data_param_el = 'chart_overview'; exportAsPDF($event)">
                                                    <i class="icon-sm mr-2 feather feather-file-text"></i>
                                                    <span class="">Export as PDF</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="collapse px-2" id="collapseExample">
                                    <div class="card-body overflow-auto">
                                        <div class="w-max-content align-items-center header-right-inner-left d-flex">
                                        
                                        <h6 class="text-nowrap mr-2">Select Parameter:</h6>
                                        
                                        <select class="mr-4" [(ngModel)]="overview_param" (change)="selectedOverviewParam()">
                                            <option value="" selected>Please select params</option>
                                            <option *ngFor="let param of overview_params" [ngValue]="param">
                                                {{ param.description }}</option>
                                        </select>

                                        <h6 class="mr-2 text-nowrap">Select Date:</h6>

                                        <input type="date" [(ngModel)]="vl_from_date_selection" autocomplete="off"
                                        class="mr-2 form-control" (change)="overviewFromDateSelection($event)">

                                        <span class="mr-2 from-to">-</span>

                                        <input type="date" [(ngModel)]="vl_to_date_selection" autocomplete="off"
                                                class="form-control" (change)="overviewToDateSelection($event)">

                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    
                                    <div class="row align-items-start mb-2">

                                    </div>
                                    <div class="flot-wrapper">
                                        <div id="chartOverview" style="width: 100%; height: 600px"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </ng-template>
    </ngb-tab>

</ngb-tabset>