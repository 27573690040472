import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'zainergy-recursive-sidebar-menu',
  template: ``
})

export class RecursiveSidebarMenuComponent implements OnInit {

  @Input() sidebar_menu_hierarchy;

  constructor() { }

  ngOnInit(): void {
  }

}
