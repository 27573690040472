import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CustomerManagementRoutingModule } from './customer-management-routing.module';
import { ConfigurationComponent } from './configuration/configuration.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NewUserComponent } from './new-user/new-user.component';
import { UserSettingComponent } from './user-setting/user-setting.component';
import { ParamDuplicationModuleComponent } from './param-duplication-module/param-duplication-module.component';
import { DeviceDataStatusComponent } from './device-data-status/device-data-status.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

@NgModule({
  declarations: [ConfigurationComponent, NewUserComponent, UserSettingComponent, ParamDuplicationModuleComponent, DeviceDataStatusComponent],
  imports: [
    CommonModule,
    CustomerManagementRoutingModule,
    Ng2SearchPipeModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxDaterangepickerMd.forRoot()
  ],
  exports: [
    ConfigurationComponent,
    NewUserComponent
  ]
})
export class CustomerManagementModule { }
