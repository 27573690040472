import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { GoogleMapsModule } from '@angular/google-maps'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// import { ChartsModule } from 'ng2-charts';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { WidgetsModule } from '../widgets/widgets.module';
import { SingleComponent } from './single/single.component';
import { CollectiveComponent } from './collective/collective.component';
import { ComparativeComponent } from './comparative/comparative.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { AgmCoreModule } from '@agm/core';

@NgModule({
  declarations: [SingleComponent, CollectiveComponent, ComparativeComponent/* , CardsComponent */],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    GoogleMapsModule,
    // ChartsModule,
    FormsModule,
    NgbModule,
    ScrollingModule,
    WidgetsModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBtsD_tq5mK7-coGTZGpMOelwaq_8maEv8'
    })
  ]
})

export class DashboardModule { }