<div class="row">
    <div class="col-lg-12 col-xl-12 grid-margin stretch-card"> 
        <div class="row flex-grow">
            <div class="d-grid col grid-margin stretch-card">
                <div class="card" id="share_consumption">
    <div class="d-flex justify-content-between card-header align-items-center">
        <h6 class="card-title mb-0">Share Consumption {{ location }}</h6>

        <div class="header-right d-flex align-items-center">

            <div class="d-flex align-items-center justify-content-end">

                <p>
                    <button class="btn pr-2" type="button" data-toggle="collapse"
                        data-target="#collapseExample" aria-expanded="false"
                        aria-controls="collapseExample">
                        <i class="feather-sliders"
                            style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                    </button>
                </p>
                <i class="icon-sm card-header-icon pb-3px maximize-card feather feather-maximize-2"
                    (click)="toggleCard($event)"></i>

                <div class="dropdown ml-2">
                    <button class="btn p-0" type="button" id="dropdownMenuButton3" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);" name="Print"
                            (click)="data_param_el = 'share_consumption'; exportAsPDF($event)">
                            <i class="feather feather-printer icon-sm mr-2"></i>
                            <span class="">Print</span>
                        </a>
                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                            (click)="data_param_el = 'share_consumption'; exportAsCSV()">
                            <i class="feather feather-download icon-sm mr-2"></i>
                            <span class="">Export as CSV</span>
                        </a>
                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);" name="Pdf"
                            (click)="data_param_el = 'share_consumption'; exportAsPDF($event)">
                            <i class="feather feather-file-text icon-sm mr-2"></i>
                            <span class="">Export as PDF</span>
                        </a>

                    </div>
                </div>
            </div>
        </div>
    </div>
        <div class="collapse px-2" id="collapseExample">
            <div class="card-body overflow-auto">
                <div class="w-max-content header-right-inner-left d-flex">
                    <h6 class="mr-2 text-nowrap">
                        Select Parameter:
                    </h6>
                    <select class="mr-4" [(ngModel)]="param" (change)="onChangeParam($event)">
                        <option value="default" [disabled]="true">Select Parameter</option>
                        <option *ngFor="let data of param_list" [value]="data.parameter">{{ data.description }}
                        </option>
                    </select>
                    <h6 class="mr-2 text-nowrap">
                        Date select:
                    </h6>
                    <input type="date" [(ngModel)]="date_selection" autocomplete="off" class="form-control"
                        (change)="onDateSelection($event)">
                </div>
            </div>
    </div>
    <div class="card-body">
        <div class="row align-items-start mb-2">
        </div>
        <div class="flot-wrapper" style="margin-top: -15px;">
            <div id="chartKW" style="width: 100%; height: 600px"></div>
        </div>

        <div *ngIf="show_total" style="text-align: center;">

            <strong *ngIf="energy_status">
                <h5>Today's Total (KWh): {{ sum.toFixed(2) }}</h5>
            </strong>

            <strong *ngIf="flow_status">
                <h5>Today's Total {{ unit }}: {{ sum.toFixed(2) }}</h5>
            </strong>
        </div>
    </div>

</div>
</div>
</div>
</div>