import { Component, OnInit, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DataSharedService } from 'src/app/shared/services/data-shared.service';
// amCharts imports
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})

@Injectable({
  providedIn: 'root'
})
export class PieChartComponent implements OnInit {

  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2, private dataShared: DataSharedService) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  ngOnInit(): void { }

  getGraphProp(param_data) {

    let data = {
      element: 'piechart',
      title: '',
      data: param_data,
      interval: {
        'interval': 'minute',
        'value': 5
      },
      tooltipFormat: 'HH:mm:ss, d MMMM',
      xScrollbar: false
    };

    return data;
  }

  implementChart(data) {
    am4core.useTheme(am4themes_animated);

    let chart = am4core.create(data.element, am4charts.PieChart);
    chart.data = data.data;

    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "diff";
    pieSeries.dataFields.category = "date";
    pieSeries.labels.template.text = "";
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.fillOpacity = 100;
    pieSeries.strokeWidth = 0;

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.slices.template.cornerRadius = 5;

    chart.hiddenState.properties.radius = am4core.percent(0);
  }
}