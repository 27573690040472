import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CardsComponent } from '../components/cards/cards.component';
import { FormsModule } from '@angular/forms';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { AmchartsComponent } from './amcharts/amcharts.component';
import { ApiFunctionsComponent } from './api-functions/api-functions.component';
import { CountdownComponent } from './countdown/countdown.component';

@NgModule({
  declarations: [
    CardsComponent,
    PieChartComponent,
    AmchartsComponent,
    ApiFunctionsComponent,
    CountdownComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule
  ],
  exports: [
    CardsComponent,
    PieChartComponent,
    AmchartsComponent,
    ApiFunctionsComponent,
    CountdownComponent
  ]
})
export class WidgetsModule { }