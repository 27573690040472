import { Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { EmitterService } from 'src/app/shared/services/event-service';
import { ToastrService } from 'ngx-toastr';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { isPlatformBrowser } from '@angular/common';
import { __await } from 'tslib';
import { DataSharedService } from 'src/app/shared/services/data-shared.service';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-collective',
  templateUrl: './collective.component.html',
  styleUrls: ['./collective.component.scss']
})
export class CollectiveComponent implements OnInit {

  private chart: am4charts.XYChart;
  public device_id: any;
  public parameters: any = [];
  public curr_tab = 'tab-graph';
  public tab_graph_status: boolean = false;
  public tab_table_status: boolean = false;

  public date_to: String = '';
  public date_from: String = '';
  public intervals: any = [];
  public interval: any = null;

  public vl_from_date_selection: String = '';
  public curr_from_date_selection: String = '';
  public kw_from_date_selection: String = '';
  public pf_from_date_selection: String = '';
  public tab_from_date_selection: String = '';

  public vl_to_date_selection: String = '';
  public curr_to_date_selection: String = '';
  public kw_to_date_selection: String = '';
  public pf_to_date_selection: String = '';
  public tab_to_date_selection: String = '';

  public param: any = [];
  public params: any = [];
  public device_info: any = [];
  public device_array: any = [];
  public device_id_array: any = [];
  public data_param: String = '';
  public data_param_el: String = '';
  public collective_devices: any = [];
  public param_data: any = [];
  public tablular_data: any = [];

  public vol_data: any = []; public vol_chart_active: String = 'line';
  public curr_data: any = []; public curr_chart_active: String = 'line';
  public kw_data: any = []; public kw_chart_active: String = 'line';
  public pf_data: any = []; public pf_chart_active: String = 'line';

  public pageSize = 50;
  public page = 1;

  constructor(
    private dashboardService: DashboardService,
    @Inject(PLATFORM_ID) private platformId,
    private zone: NgZone,
    private toastr: ToastrService,
    private eventEmitter: EmitterService,
    private dataShared: DataSharedService,
  ) {
    this.parameters = ['avg_vl_pp', 'avg_curr_3p', 'total_w', 'avg_pf_3p'];
  }

  ngOnInit(): void {

    this.getDashboardSettings()

    this.eventEmitter.listen('groupDevices', () => {
      this.collective_devices = [];
      this.dataShared.panel_devices.map(elem => this.collective_devices.push(elem.device_id));
      if (this.curr_tab == 'tab-graph') this.getSingleDeviceGraph();
      else if (this.curr_tab == 'tab-table') {

        this.getSingleDeviceTable();
      }
    });

    this.getCurrDate();

  }

  getDashboardSettings() {

    let setting_body = {
      action: 'getSettings',
      meta_key: 'config',
      user_id: localStorage.getItem('user'),
      account_id: [localStorage.getItem('account')]
    }

    this.dashboardService.httpPost(setting_body).subscribe((res: any) => {
      if (res.status == 200) {

        let config = res.data?.config;
        let data = (localStorage.getItem('sub_user') == '0') ? JSON.parse(config[0].tab_setting) : JSON.parse(config[0].sub_tab_setting);
        this.tab_graph_status = data.allow_tab.dashboard.collective.graph
        this.tab_table_status = data.allow_tab.dashboard.collective.table

      }
    })
  }


  toggleCard = (e) => this.dataShared.toggleCardFullsreen(e);

  resetSinleDevice() {
    this.params = []; this.date_from = ''; this.date_to = ''; this.getCurrDate();
  }

  getCurrDate() {
    let date = new Date();

    this.date_from = this.vl_from_date_selection = this.curr_from_date_selection = this.kw_from_date_selection = this.pf_from_date_selection = this.tab_from_date_selection = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');

    this.date_to = this.vl_to_date_selection = this.curr_to_date_selection = this.kw_to_date_selection = this.pf_to_date_selection = this.tab_to_date_selection = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
  }

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  getSingleDeviceGraph() {
    // Chart code goes in here
    this.browserOnly(() => {
      this.getChartData();
    });
  }

  getChartData() {

    let voltage_data = []; this.vol_data = [];
    let curr_data = []; this.curr_data = [];
    let kw_data = []; this.kw_data = [];
    let pf_data = []; this.pf_data = [];

    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      for (let index = 0; index < res.data.buckets.length; index++) {

        let sum_vol, avg_vol = 0;
        let sum_curr;
        let sum_kw;
        let sum_pf, avg_pf = 0;

        for (let j = 0; j < res.data.buckets[index].date_agg.buckets.length; j++) {

          if (res.data.buckets.length == 2 && index == 1) {
            sum_vol = res.data.buckets[0].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[1].date_agg.buckets[j].val_avg_vl_pp.value;
            sum_curr = res.data.buckets[0].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[1].date_agg.buckets[j].val_avg_curr_3p.value;
            sum_kw = res.data.buckets[0].date_agg.buckets[j].val_total_w.value + res.data.buckets[1].date_agg.buckets[j].val_total_w.value;
            sum_pf = res.data.buckets[0].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[1].date_agg.buckets[j].val_avg_pf_3p.value;
          } else if (res.data.buckets.length == 3 && index == 2) {
            sum_vol = res.data.buckets[0].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[1].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[2].date_agg.buckets[j].val_avg_vl_pp.value;
            sum_curr = res.data.buckets[0].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[1].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[2].date_agg.buckets[j].val_avg_curr_3p.value;
            sum_kw = res.data.buckets[0].date_agg.buckets[j].val_total_w.value + res.data.buckets[1].date_agg.buckets[j].val_total_w.value + res.data.buckets[2].date_agg.buckets[j].val_total_w.value;
            sum_pf = res.data.buckets[0].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[1].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[2].date_agg.buckets[j].val_avg_pf_3p.value;
          } else if (res.data.buckets.length == 4 && index == 3) {
            sum_vol = res.data.buckets[0].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[1].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[2].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[3].date_agg.buckets[j].val_avg_vl_pp.value;
            sum_curr = res.data.buckets[0].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[1].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[2].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[3].date_agg.buckets[j].val_avg_curr_3p.value;
            sum_kw = res.data.buckets[0].date_agg.buckets[j].val_total_w.value + res.data.buckets[1].date_agg.buckets[j].val_total_w.value + res.data.buckets[2].date_agg.buckets[j].val_total_w.value + res.data.buckets[3].date_agg.buckets[j].val_total_w.value;
            sum_pf = res.data.buckets[0].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[1].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[2].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[3].date_agg.buckets[j].val_avg_pf_3p.value;
          } else if (res.data.buckets.length == 5 && index == 4) {
            sum_vol = res.data.buckets[0].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[1].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[2].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[3].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[4].date_agg.buckets[j].val_avg_vl_pp.value;
            sum_curr = res.data.buckets[0].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[1].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[2].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[3].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[4].date_agg.buckets[j].val_avg_curr_3p.value;
            sum_kw = res.data.buckets[0].date_agg.buckets[j].val_total_w.value + res.data.buckets[1].date_agg.buckets[j].val_total_w.value + res.data.buckets[2].date_agg.buckets[j].val_total_w.value + res.data.buckets[3].date_agg.buckets[j].val_total_w.value + res.data.buckets[4].date_agg.buckets[j].val_total_w.value;
            sum_pf = res.data.buckets[0].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[1].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[2].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[3].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[4].date_agg.buckets[j].val_avg_pf_3p.value;
          } else if (res.data.buckets.length == 6 && index == 5) {
            sum_vol = res.data.buckets[0].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[1].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[2].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[3].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[4].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[5].date_agg.buckets[j].val_avg_vl_pp.value;
            sum_curr = res.data.buckets[0].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[1].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[2].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[3].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[4].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[5].date_agg.buckets[j].val_avg_curr_3p.value;
            sum_kw = res.data.buckets[0].date_agg.buckets[j].val_total_w.value + res.data.buckets[1].date_agg.buckets[j].val_total_w.value + res.data.buckets[2].date_agg.buckets[j].val_total_w.value + res.data.buckets[3].date_agg.buckets[j].val_total_w.value + res.data.buckets[4].date_agg.buckets[j].val_total_w.value + res.data.buckets[5].date_agg.buckets[j].val_total_w.value;
            sum_pf = res.data.buckets[0].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[1].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[2].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[3].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[4].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[5].date_agg.buckets[j].val_avg_pf_3p.value;
          }

          if (sum_curr !== undefined) {
            avg_vol = sum_vol / res.data.buckets.length;

            avg_pf = sum_pf / res.data.buckets.length;

            voltage_data.push({
              date: new Date(res.data.buckets[0].date_agg.buckets[j].key_as_string),
              value: avg_vol
            });

            curr_data.push({
              date: new Date(res.data.buckets[0].date_agg.buckets[j].key_as_string),
              value: sum_curr
            });

            kw_data.push({
              date: new Date(res.data.buckets[0].date_agg.buckets[j].key_as_string),
              value: sum_kw
            });

            pf_data.push({
              date: new Date(res.data.buckets[0].date_agg.buckets[j].key_as_string),
              value: avg_pf
            });
          }

        }

        this.vol_data = voltage_data; this.curr_data = curr_data;
        this.kw_data = kw_data; this.pf_data = pf_data;
      }

      let voltage_prop = this.getGraphProp('voltage', this.vol_data);
      let curr_prop = this.getGraphProp('current', this.curr_data);
      let kw_prop = this.getGraphProp('kilo_watt', this.kw_data);
      let pf_prop = this.getGraphProp('power_factor', this.pf_data);

      this.implementLineChart(voltage_prop, 'Volt');
      this.implementLineChart(curr_prop, 'Amp');
      this.implementLineChart(kw_prop, 'Kw');
      this.implementLineChart(pf_prop, 'Power Factor');

    });

  }

  getChartDataByParam(element) {

    this.param_data = [];
    let param_data = [];

    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      if (res.data.buckets && res.data.buckets[0].date_agg) {

        for (let index = 0; index < res.data.buckets.length; index++) {

          let sum, avg = 0;

          for (let j = 0; j < res.data.buckets[index].date_agg.buckets.length; j++) {

            if (res.data.buckets.length == 2 && index == 1) {

              sum = res.data.buckets[0].date_agg.buckets[j]['val_' + this.params].value + res.data.buckets[1].date_agg.buckets[j]['val_' + this.params].value;

            } else if (res.data.buckets.length == 3 && index == 2) {
              sum = res.data.buckets[0].date_agg.buckets[j]['val_' + this.params].value + res.data.buckets[1].date_agg.buckets[j]['val_' + this.params].value + res.data.buckets[2].date_agg.buckets[j]['val_' + this.params].value;

            } else if (res.data.buckets.length == 4 && index == 3) {
              sum = res.data.buckets[0].date_agg.buckets[j]['val_' + this.params].value + res.data.buckets[1].date_agg.buckets[j]['val_' + this.params].value + res.data.buckets[2].date_agg.buckets[j]['val_' + this.params].value + res.data.buckets[3].date_agg.buckets[j]['val_' + this.params].value;

            } else if (res.data.buckets.length == 5 && index == 4) {
              sum = res.data.buckets[0].date_agg.buckets[j]['val_' + this.params].value + res.data.buckets[1].date_agg.buckets[j]['val_' + this.params].value + res.data.buckets[2].date_agg.buckets[j]['val_' + this.params].value + res.data.buckets[3].date_agg.buckets[j]['val_' + this.params].value + res.data.buckets[4].date_agg.buckets[j]['val_' + this.params].value;

            } else if (res.data.buckets.length == 6 && index == 5) {
              sum = res.data.buckets[0].date_agg.buckets[j]['val_' + this.params].value + res.data.buckets[1].date_agg.buckets[j]['val_' + this.params].value + res.data.buckets[2].date_agg.buckets[j]['val_' + this.params].value + res.data.buckets[3].date_agg.buckets[j]['val_' + this.params].value + res.data.buckets[4].date_agg.buckets[j]['val_' + this.params].value + res.data.buckets[5].date_agg.buckets[j]['val_' + this.params].value;

            }
            if (sum !== undefined) {
              avg = sum / res.data.buckets.length;

              if (this.params == 'avg_vl_pp' || 'avg_pf_3p') {
                param_data.push({
                  date: new Date(res.data.buckets[0].date_agg.buckets[j].key_as_string),
                  value: avg
                });
              } else {
                param_data.push({
                  date: new Date(res.data.buckets[0].date_agg.buckets[j].key_as_string),
                  value: sum
                });
              }

            }
          }
        }
      }

      this.param_data = param_data;
      let props = this.getGraphProp(element, this.param_data);

      if (element == 'voltage' && this.vol_chart_active == 'line') {this.implementLineChart(props, 'Volt'); this.vol_data = this.param_data}
      else if (element == 'voltage' && this.vol_chart_active == 'bar') {this.implementBarChart(props, 'Volt'); this.vol_data = this.param_data}
      if (element == 'current' && this.curr_chart_active == 'line') {this.implementLineChart(props, 'Amp'); this.curr_data = this.param_data}
      else if (element == 'current' && this.curr_chart_active == 'bar') {this.implementBarChart(props, 'Amp'); this.curr_data = this.param_data}
      if (element == 'kilo_watt' && this.kw_chart_active == 'line') {this.implementLineChart(props, 'Kw'); this.kw_data = this.param_data}
      else if (element == 'kilo_watt' && this.kw_chart_active == 'bar') {this.implementBarChart(props, 'Kw'); this.kw_data = this.param_data}
      if (element == 'power_factor' && this.pf_chart_active == 'line') {this.implementLineChart(props, 'Power'); this.pf_data = this.param_data}
      else if (element == 'power_factor' && this.pf_chart_active == 'bar') {this.implementBarChart(props, 'Power'); this.pf_data = this.param_data}

    });
  }

  getBody() {

    let body = {
      'action': 'getChartDataByAccount',
      'user_id': [localStorage.getItem('user')],
      'device_id': this.collective_devices,
      'date': this.date_to,
      'fixed_interval': (this.dataShared.group_interval == "") ? '5m' : this.dataShared.group_interval,
      'parameters': (this.params.length > 0) ? this.params : this.parameters
    };

    if (this.date_from != this.date_to) {

      let range = {
        from: this.date_from,
        to: this.date_to,
      };

      delete body['date'];
      body['range'] = range

    }

    return body;
  }

  getGraphProp(param, param_data) {

    let data = {
      element: '',
      title: '',
      data: param_data,
      interval: {
        'interval': 'second',
        'value': 1
      },
      tooltipFormat: 'HH:mm:ss, d MMMM',
      xScrollbar: false
    };

    if (param == 'voltage') {
      data.element = 'chartVoltage'
      data.title = 'Voltage (Volts)'
    } else if (param == 'current') {
      data.element = 'chartCurr'
      data.title = 'Current (Amps)'
    } else if (param == 'kilo_watt') {
      data.element = 'chartKiloWatt'
      data.title = 'Power (KW)'
    } else if (param == 'power_factor') {
      data.element = 'chartPowerFactor'
      data.title = 'Power Factor'
    }
    return data;
  }

  implementLineChart(data, name) {

    am4core.useTheme(am4themes_animated);

    let chart = am4core.create(data.element, am4charts.XYChart);
    chart.paddingRight = 20;

    chart.data = data.data;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());

    dateAxis.baseInterval = {
      'timeUnit': data.interval.interval,
      'count': data.interval.value
    };

    dateAxis.tooltipDateFormat = data.tooltipFormat;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.title.text = data.title;

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = 'date';
    series.dataFields.valueY = 'value';
    series.name = name;
    series.tooltipText = '[bold]{name} : {valueY}[/]';
    series.fillOpacity = 0;
    series.strokeWidth = 1;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.opacity = 0;

    dateAxis.start = 0;
    dateAxis.keepSelection = true;

  }

  implementBarChart(data, name) {

    am4core.useTheme(am4themes_animated);

    let chart = am4core.create(data.element, am4charts.XYChart);
    chart.paddingRight = 20;

    chart.data = data.data;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());

    dateAxis.baseInterval = {
      'timeUnit': data.interval.interval,
      'count': data.interval.value
    };

    dateAxis.tooltipDateFormat = data.tooltipFormat;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.title.text = data.title;

    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.dateX = 'date';
    series.dataFields.valueY = 'value';
    series.name = name;
    series.tooltipText = '[bold]{name} : {valueY}[/]';
    series.fillOpacity = 1;
    series.strokeWidth = 1;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.opacity = 0;

    dateAxis.start = 0;
    dateAxis.keepSelection = true;

  }

  onFromDateSelection(e, param) {
    this.date_from = e.target.value;
    if (param == 'voltage') this.params = ['avg_vl_pp'];
    else if (param == 'current') this.params = ['avg_curr_3p'];
    else if (param == 'kilo_watt') this.params = ['total_w'];
    else if (param == 'power_factor') this.params = ['avg_pf_3p'];

    this.getChartDataByParam(param);
  }

  onToDateSelection(e, param) {
    this.date_to = e.target.value;
    if (param == 'voltage') this.params = ['avg_vl_pp'];
    else if (param == 'current') this.params = ['avg_curr_3p'];
    else if (param == 'kilo_watt') this.params = ['total_w'];
    else if (param == 'power_factor') this.params = ['avg_pf_3p'];

    this.getChartDataByParam(param);
  }
  onTabularFromDateSelection(e) {
    this.date_from = e.target.value;
    this.getSingleDeviceTable();
  }

  onTabularToDateSelection(e) {
    this.date_to = e.target.value;
    this.getSingleDeviceTable();
  }

  tabChange($event: NgbTabChangeEvent) {

    this.curr_tab = $event.nextId;

     if (this.curr_tab == 'tab-graph' && this.device_id) {
      this.eventEmitter.emit('getSelectedSingleDevice', this.device_id);
    } else if (this.curr_tab == 'tab-table') {
      this.resetSinleDevice();
      this.getSingleDeviceTable();
    }

  }

  onChangeInterval(e) {

    this.interval = e.target.value;
    this.getSingleDeviceTable();
  }

  setInterval() {

    this.intervals = [
      "1s", "5s", "1m", "15m", "30m", "1h"
    ]

  }

  getSingleDeviceTable() {

    let tab_data = [];
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {
      for (let index = 0; index < res.data.buckets.length; index++) {

        let sum_vol, avg_vol = 0;
        let sum_curr;
        let sum_kw;
        let sum_pf, avg_pf = 0;

        for (let j = 0; j < res.data.buckets[index].date_agg.buckets.length; j++) {


          if (res.data.buckets.length == 2) {
            sum_vol = res.data.buckets[0].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[1].date_agg.buckets[j].val_avg_vl_pp.value;
            sum_curr = res.data.buckets[0].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[1].date_agg.buckets[j].val_avg_curr_3p.value;
            sum_kw = res.data.buckets[0].date_agg.buckets[j].val_total_w.value + res.data.buckets[1].date_agg.buckets[j].val_total_w.value;
            sum_pf = res.data.buckets[0].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[1].date_agg.buckets[j].val_avg_pf_3p.value;
          } else if (res.data.buckets.length == 3) {
            sum_vol = res.data.buckets[0].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[1].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[2].date_agg.buckets[j].val_avg_vl_pp.value;
            sum_curr = res.data.buckets[0].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[1].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[2].date_agg.buckets[j].val_avg_curr_3p.value;
            sum_kw = res.data.buckets[0].date_agg.buckets[j].val_total_w.value + res.data.buckets[1].date_agg.buckets[j].val_total_w.value + res.data.buckets[2].date_agg.buckets[j].val_total_w.value;
            sum_pf = res.data.buckets[0].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[1].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[2].date_agg.buckets[j].val_avg_pf_3p.value;
          } else if (res.data.buckets.length == 4 && index == 3) {
            sum_vol = res.data.buckets[0].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[1].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[2].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[3].date_agg.buckets[j].val_avg_vl_pp.value;
            sum_curr = res.data.buckets[0].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[1].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[2].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[3].date_agg.buckets[j].val_avg_curr_3p.value;
            sum_kw = res.data.buckets[0].date_agg.buckets[j].val_total_w.value + res.data.buckets[1].date_agg.buckets[j].val_total_w.value + res.data.buckets[2].date_agg.buckets[j].val_total_w.value + res.data.buckets[3].date_agg.buckets[j].val_total_w.value;
            sum_pf = res.data.buckets[0].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[1].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[2].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[3].date_agg.buckets[j].val_avg_pf_3p.value;
          } else if (res.data.buckets.length == 5 && index == 4) {
            sum_vol = res.data.buckets[0].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[1].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[2].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[3].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[4].date_agg.buckets[j].val_avg_vl_pp.value;
            sum_curr = res.data.buckets[0].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[1].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[2].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[3].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[4].date_agg.buckets[j].val_avg_curr_3p.value;
            sum_kw = res.data.buckets[0].date_agg.buckets[j].val_total_w.value + res.data.buckets[1].date_agg.buckets[j].val_total_w.value + res.data.buckets[2].date_agg.buckets[j].val_total_w.value + res.data.buckets[3].date_agg.buckets[j].val_total_w.value + res.data.buckets[4].date_agg.buckets[j].val_total_w.value;
            sum_pf = res.data.buckets[0].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[1].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[2].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[3].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[4].date_agg.buckets[j].val_avg_pf_3p.value;
          } else if (res.data.buckets.length == 6 && index == 5) {
            sum_vol = res.data.buckets[0].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[1].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[2].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[3].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[4].date_agg.buckets[j].val_avg_vl_pp.value + res.data.buckets[5].date_agg.buckets[j].val_avg_vl_pp.value;
            sum_curr = res.data.buckets[0].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[1].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[2].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[3].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[4].date_agg.buckets[j].val_avg_curr_3p.value + res.data.buckets[5].date_agg.buckets[j].val_avg_curr_3p.value;
            sum_kw = res.data.buckets[0].date_agg.buckets[j].val_total_w.value + res.data.buckets[1].date_agg.buckets[j].val_total_w.value + res.data.buckets[2].date_agg.buckets[j].val_total_w.value + res.data.buckets[3].date_agg.buckets[j].val_total_w.value + res.data.buckets[4].date_agg.buckets[j].val_total_w.value + res.data.buckets[5].date_agg.buckets[j].val_total_w.value;
            sum_pf = res.data.buckets[0].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[1].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[2].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[3].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[4].date_agg.buckets[j].val_avg_pf_3p.value + res.data.buckets[5].date_agg.buckets[j].val_avg_pf_3p.value;
          }

          if (sum_curr !== undefined) {
            avg_vol = sum_vol / res.data.buckets.length;

            avg_pf = sum_pf / res.data.buckets.length;


            tab_data.push({
              date: new Date(res.data.buckets[0].date_agg.buckets[j].key_as_string),
              voltage: avg_vol,
              current: sum_curr,
              kw: sum_kw,
              power_factor: avg_pf
            });
          }


        }

        this.tablular_data = tab_data;

      }
    });
    this.setInterval()
  }

  tabLinkChart(element) {

    if (element == 'voltage' && this.vol_chart_active != 'line' && this.vl_from_date_selection == this.vl_to_date_selection) {
      this.implementLineChart(this.getGraphProp(element, this.vol_data), 'Volt'); this.vol_chart_active = 'line';
    } else if (element == 'voltage' && this.vol_chart_active != 'line' && this.vl_from_date_selection !== this.vl_to_date_selection) {
      this.implementLineChart(this.getGraphProp(element, this.param_data), 'Volt'); this.vol_chart_active = 'line';
    } else if (element == 'current' && this.curr_chart_active != 'line' && this.curr_from_date_selection == this.curr_to_date_selection) {
      this.implementLineChart(this.getGraphProp(element, this.curr_data), 'Amp'); this.curr_chart_active = 'line';
    } else if (element == 'current' && this.curr_chart_active != 'line' && this.curr_from_date_selection !== this.curr_to_date_selection) {
      this.implementBarChart(this.getGraphProp(element, this.param_data), 'Amp'); this.curr_chart_active = 'line';
    } else if (element == 'kilo_watt' && this.kw_chart_active != 'line' && this.kw_from_date_selection == this.kw_to_date_selection) {
      this.implementLineChart(this.getGraphProp(element, this.kw_data), 'Kw'); this.kw_chart_active = 'line';
    } else if (element == 'kilo_watt' && this.kw_chart_active != 'line' && this.kw_from_date_selection !== this.kw_to_date_selection) {
      this.implementLineChart(this.getGraphProp(element, this.param_data), 'Kw'); this.kw_chart_active = 'line';
    } else if (element == 'power_factor' && this.pf_chart_active != 'line' && this.pf_from_date_selection == this.pf_to_date_selection) {
      this.implementLineChart(this.getGraphProp(element, this.pf_data), 'Power'); this.pf_chart_active = 'line';
    } else if (element == 'power_factor' && this.pf_chart_active != 'line' && this.pf_from_date_selection !== this.pf_to_date_selection) {
      this.implementLineChart(this.getGraphProp(element, this.param_data), 'Power'); this.pf_chart_active = 'line';
    }
  }

  tabBarChart(element) {

    if (element == 'voltage' && this.vol_chart_active != 'bar' && this.vl_from_date_selection == this.vl_to_date_selection) {
      this.implementBarChart(this.getGraphProp(element, this.vol_data), 'Volt'); this.vol_chart_active = 'bar';
    } else if (element == 'voltage' && this.vol_chart_active != 'bar' && this.vl_from_date_selection !== this.vl_to_date_selection) {
      this.implementBarChart(this.getGraphProp(element, this.param_data), 'Volt'); this.vol_chart_active = 'bar';
    } else if (element == 'current' && this.curr_chart_active != 'bar' && this.curr_from_date_selection == this.curr_to_date_selection) {
      this.implementBarChart(this.getGraphProp(element, this.curr_data), 'Amp'); this.curr_chart_active = 'bar';
    } else if (element == 'current' && this.curr_chart_active != 'bar' && this.curr_from_date_selection !== this.curr_to_date_selection) {
      this.implementBarChart(this.getGraphProp(element, this.param_data), 'Amp'); this.curr_chart_active = 'bar';
    } else if (element == 'kilo_watt' && this.kw_chart_active != 'bar' && this.kw_to_date_selection == this.kw_from_date_selection) {
      this.implementBarChart(this.getGraphProp(element, this.kw_data), 'Kw'); this.kw_chart_active = 'bar';
    } else if (element == 'kilo_watt' && this.kw_chart_active != 'bar' && this.kw_to_date_selection !== this.kw_from_date_selection) {
      this.implementBarChart(this.getGraphProp(element, this.param_data), 'Kw'); this.kw_chart_active = 'bar';
    } else if (element == 'power_factor' && this.pf_chart_active != 'bar' && this.pf_from_date_selection == this.pf_to_date_selection) {
      this.implementBarChart(this.getGraphProp(element, this.pf_data), 'Power'); this.pf_chart_active = 'bar';
    } else if (element == 'power_factor' && this.pf_chart_active != 'bar' && this.pf_from_date_selection !== this.pf_to_date_selection) {
      this.implementBarChart(this.getGraphProp(element, this.param_data), 'Power'); this.pf_chart_active = 'bar';
    }

  }

  exportAsCSV() {
    let data = [];
    if (data = this.getDataByParam()) this.dataShared.exportDataAsCsvFormat(data);
  }

  getDataByParam() {

    if (this.data_param == 'voltage') return this.vol_data;
    else if (this.data_param == 'current') return this.curr_data;
    else if (this.data_param == 'kilo_watt') return this.kw_data;
    else if (this.data_param == 'power_factor') return this.pf_data;
    else if (this.data_param == 'table') return this.tablular_data;

  }

  exportAsPDF(e) {
    let data = [];
    if (data = this.getDataByParam()) this.dataShared.exportDataAsPdfFormat(data, e, this.data_param_el);
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

}
