<div class="container-login">
    <div class="wrap-login">
      <div class="login-right-side">
        <div   class="wrap-login">
            <div class="login-right-side">
              <form class="login-form validate-form">
                <div class="login-form-title">
                  Forgot Password
                  <span class="">
                    Enter your email           
                  </span>
                </div>
                <div class="form-group">
                  <label for="email" class="text-white">Email</label>
                  <input type="email" class="form-control" id="email"  name="email">
                  <!-- <small *ngIf="emailRememberPrompt" class="error">Please enter correct email</small> -->
        
                </div>
        
                <div class="mb-4 mt-2 d-block">
                  <button class="login-form-btn " >Find Account</button>
                </div>
              </form>
            </div>
            <div class="login-left-side d-flex">
              <div class="m-auto z-index-1 ">
                <img class="w-100" src="./assets/media/Zainergy-logo.svg" alt="Zainengy" />
              </div>
              <img src="./assets/media/login-leftside-element.svg" class="leftside-element" alt="" />
            </div>
          </div>
      </div>

  </div>
  </div>