import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-device-data-status',
  templateUrl: './device-data-status.component.html',
  styleUrls: ['./device-data-status.component.scss']
})
export class DeviceDataStatusComponent implements OnInit {

  public action: string = '';
  public ids: any = { user_id: [], account_id: [] };
  public account;
  public accounts: any = [];
  public devices: any = [];
  public device_by_account: any = [];
  public device_list: boolean = false;
  public devices_status: [];
  public DropdownSettings: IDropdownSettings;

  constructor(private dashboardService: DashboardService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getAccounts();
  }

  getBody() {
    let body = {
      action: this.action
    }

    if (this.action == 'getDevices') {
      body['account_id'] = this.ids.account_id;
      body['user_id'] = this.ids.user_id;
      body['status'] = true;
    }

    if (this.action == 'getSettings') {
      body['account_id'] = this.ids.account_id;
      body['user_id'] = this.ids.user_id;
      body['columns'] = ["meta_value", "account"]
    }

    return body;
  }

  getAccounts() {
    this.action = 'getAccounts';
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {
      let data = (res.status == 200) ? res.data : [];
      this.accounts = data;
      this.accountDropdownSettings()
    })
  }

  accountDropdownSettings() {
    this.DropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      showSelectedItemsAtTop: true,
      itemsShowLimit: 6,
      allowSearchFilter: true,
      clearSearchFilter: true,
      limitSelection: 5,
    };
  }

  getIDs() {
    this.ids.user_id = []; this.ids.account_id = [];
    this.accounts.forEach(i => {
      this.account.forEach(j => {
        if (i.id == j.id) {
          this.ids.user_id.push(i.user)
          this.ids.account_id.push(i.id)
        }
      })
    })
  }

  getData() {
    this.getMemoryUsage();
    this.getDevices();
  }

  getMemoryUsage() {
    this.action = 'getSettings'; this.getIDs(); this.validation();
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {
      if ((res.status == 200 ? res.data : [])) {
        
        let allocated_memory = res.data.allocated_memory;

        // add memory usage in account 
        this.accounts.forEach(i => {
          this.account.forEach(j => {
            
            if (i.id == j.id) j.user = i.user
            
            // add allocated memory in account 
            this.ids.user_id.forEach(k => {
              if (j.user == k) j.allocated_memory = allocated_memory[k] 
            })

            // add allow disk space in account 
            res.data.config.forEach(l => {
              if (j.id == l.account) j.allow_disk_space = JSON.parse(l.meta_value).allow_disk_space
            })
          })
        })
      }
    })
  }

  getDevices() {
    this.device_list = false; this.devices = []; this.action = 'getDevices'; this.device_list = false; this.getIDs(); this.validation(); 
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {
      if ((res.status == 200 ? res.data : [])) {
        this.devices = res.data;

        // add devices in account 
        this.account.forEach(i => {
          this.device_by_account = [];
          this.devices.forEach(j => {
            if (i.id == j.account) {
              this.device_by_account.push(j);
              this.device_list = true;
            }
          });
          i.devices = this.device_by_account;
        })
      }
    })
  }

  validation() {
    if (!this.account || this.account.length == 0) {
      return this.toastr.error('Account field is required');
    }

    if (!this.ids.user_id || this.ids.user_id.length == 0) {
      return this.toastr.error('something went wrong please reload this page');
    }
  }

  onItemSelect() {
    this.device_list = false;
  }

  onDeSelect() {
    this.device_list = false;
  }

  onSelectAll() {
    this.device_list = false;
  }

  onDeSelectAll() {
    this.device_list = false;
  }

  clearData() {
    this.account;
    this.device_list = false;
    this.devices_status = [];
  }
}