import { Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { DataSharedService } from '../shared/services/data-shared.service';
import { EmitterService } from '../shared/services/event-service';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { isPlatformBrowser } from '@angular/common';
import { DashboardService } from '../shared/services/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as moment from 'moment';

@Component({
  selector: 'app-pv-string-monitoring',
  templateUrl: './pv-string-monitoring.component.html',
  styleUrls: ['./pv-string-monitoring.component.scss']
})
export class PvStringMonitoringComponent implements OnInit {

  private chart: am4charts.XYChart;
  public valueAxis: am4charts.ValueAxis;
  public device_id: any;
  public comparative_devices: any = []; public device_location: any = [];
  public parameters: any = [];
  public param: string = '';
  public param_list: any;
  public curr_tab = 'tab-graph';
  public tab_graph_status: boolean = false;
  public tab_table_status: boolean = false;
  public tab_comparative_status: boolean = false;
  public tab_comparative_table_status: boolean = false;
  public tab_pv_generation_report_status: boolean = false;

  public data_param: String = '';
  public data_param_el: String = '';
  public table_data: any = [];
  public comparative_table_data: any = []; public comparative_table_value_3: Boolean = false; public comparative_table_value_4: Boolean = false; public comparative_table_value_5: Boolean = false; public comparative_table_value_6: Boolean = false;
  public comparative_table_value_7: Boolean = false;
  public comparative_table_value_8: Boolean = false;
  public generation_table_data: any = [];
  public diff: any = [];
  public diff_sum: any = 0.00;
  public cost: number = 0.00;
  public cost_sum: any = 0.00;
  public interval: string;
  public pv_power_bar_variance_data: any = []; public pv_power_bar_data: any = []; public pv_power_chart_active: String = 'bar';
  public pv_energy_bar_variance_data: any = []; public pv_energy_bar_data: any = []; public pv_energy_chart_active: String = 'bar';
  public pv_current_bar_variance_data: any = []; public pv_current_bar_data: any = []; public pv_current_chart_active: String = 'bar';
  public pv_voltage_bar_variance_data: any = []; public pv_voltage_bar_data: any = []; public pv_voltage_chart_active: String = 'bar';
  public params: any = []; public pv_comparative_data: any = []; public param_bar_data: any; public param_bar_variance_data: any; public pv_comparative_chart_active: String = 'line';
  public chart_data: any = []; 
  public date_from: String = '';
  public date_to: String = '';
  public table_sum: any = 0;
  public tabular_export_data: any = [];
  public generation_table_export_data: any = [];

  public pv_power_from_date: String = ''; public show_pv_power_from_date: Boolean = true;
  public pv_energy_from_date: String = ''; public show_pv_energy_from_date: Boolean = true;
  public pv_current_from_date: String = ''; public show_pv_current_from_date: Boolean = true;
  public pv_voltage_from_date: String = ''; public show_pv_voltage_from_date: Boolean = true;
  public pv_comparative_from_date: String = '';
  public tab_from_date_selection: String = '';
  public comparative_tab_from_date_selection: String = '';
  public generation_tab_from_date_selection: String = '';

  public pv_power_to_date: String = '';
  public pv_energy_to_date: String = '';
  public pv_current_to_date: String = '';
  public pv_voltage_to_date: String = '';
  public pv_comparative_to_date: String = '';
  public tab_to_date_selection: String = '';
  public comparative_tab_to_date_selection: String = '';
  public generation_tab_to_date_selection: String = '';

  public power_header: string = 'GRAPH';
  public current_header: string = 'GRAPH';
  public energy_header: string = 'GRAPH';
  public voltage_header: string ='GRAPH';

  public power_status: boolean = true;
  public current_status: boolean = true;
  public energy_status: boolean = true;
  public voltage_status: boolean =true;
  
  public pv_power_prop: any = {title: ''};
  public pv_current_prop: any = {title: ''};
  public pv_energy_prop: any = {title: ''};
  public pv_voltage_prop: any = {title: ''};

  public location: string = 'LOCATION';
  public comparative_title: string = 'String Comparative';
  public pv_energy_sum: any; public show_energy_sum: Boolean = false;
  public gentration_date = '';

  public from: any = 0;
  public page = 1; public size: any = 50;
  public pageSize = 25;
  public pages: any = [];
  public total_found: any = 0;
  public last_updated_inverter_table: string = '';
  
  alwaysShowCalendars: boolean;
  dateselection: any = { startDate: moment(), endDate: moment()};
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Week': [moment().startOf('week'), moment().endOf('days')],
    'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
    'This Month': [moment().startOf('month'), moment().endOf('days')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }

  constructor(
    private dataShared: DataSharedService,
    private eventEmitter: EmitterService,
    private dashboardService: DashboardService,
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId,
    private zone: NgZone,
  ) {
    this.alwaysShowCalendars = true;
    this.parameters = ['total_w', 'total_wh', 'avg_curr_3p', 'avg_vl_pp'];
    this.param_list = [{ description: 'Total Active Power', parameter: 'total_w', unit: 'Kw' }, { description: 'Avg. 3P Current', parameter: 'avg_curr_3p', unit: 'Amps' }, { description: 'Avg. Line Voltage VL-L', parameter: 'avg_vl_pp', unit: 'Volts' }, { description: 'Total Energy', parameter: 'total_wh', unit: 'Kwh' }]

  }

  ngOnInit(): void {
    this.getDashboardSettings()
    this.eventEmitter.listen('getSelectedSingleDevice', (device_id) => {
      // if (this.dataShared.curr_menu == 'pv-string-monitoring') {
        this.device_id = device_id;
        this.resetSinleDevice();
        this.getDeviceLocation();

        if (this.curr_tab == 'tab-graph') this.getSingleDeviceGraph();
        else if (this.curr_tab == 'tab-table') this.getTableData();

      // }
    });

    this.eventEmitter.listen('groupDevices', () => {
      this.comparative_devices = [];
      // if (this.dataShared.curr_menu == 'pv-string-monitoring') {
        this.dataShared.panel_devices.map(elem => { this.comparative_devices.push(elem.device_id) });
        this.getSelectedDeviceChartData();

      // }
    });

    this.getCurrDate();
  }

  getDashboardSettings() {
  
    let setting_body = {
      action: 'getSettings',
      meta_key: 'config',
      user_id: localStorage.getItem('user'),
      account_id: [localStorage.getItem('account')]
    }

    this.dashboardService.httpPost(setting_body).subscribe((res: any) => {
      if (res.status == 200) {

        let config = res.data?.config;
        let data = (localStorage.getItem('sub_user') == '0') ? JSON.parse(config[0].tab_setting) : JSON.parse(config[0].sub_tab_setting);
        this.tab_graph_status = data.allow_tab.pv_string_monitoring.graph
        this.tab_table_status = data.allow_tab.pv_string_monitoring.table
        this.tab_comparative_status = data.allow_tab.pv_string_monitoring.comparative
        this.tab_comparative_table_status = data.allow_tab.pv_string_monitoring.comparative_table
        this.tab_pv_generation_report_status = data.allow_tab.pv_string_monitoring.pv_generation_report
      }
    })
  }

  getCost() {

    let months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
    let setting_body = {
      action: 'getSettings',
      meta_key: 'config',
      user_id: localStorage.getItem('user'),
      account_id: [localStorage.getItem('account')]
    }

    this.dashboardService.httpPost(setting_body).subscribe((res: any) => {
      if (res.status == 200) {

        let config = res.data?.config;
        let cost = JSON.parse(config[0].cost_by_month);
        let month = months[parseInt((this.date_from).slice(5, 7)) - 1];
        let year = (this.date_from).slice(0, 4)
        
        if (cost[month][year]) {
          this.cost = cost[month][year]['per_kwh_unit_rate(rs)'];
        } else {
          this.insertCost();
        }
      }
    })
  }
  
  insertCost() {

    let months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

    let body = {
      "action": "insertCost",
      "account_id": localStorage.getItem('account'),
      "year": this.date_from.slice(0, 4)
    }
    this.dashboardService.httpPost(body).subscribe((res: any) => {
      this.cost = res.data[months[parseInt((this.date_from).slice(5, 7)) - 1]][this.date_from.slice(0, 4)]['per_kwh_unit_rate(rs)'];
    })
  }

  toggleCard = (e) => this.dataShared.toggleCardFullsreen(e);

  resetSinleDevice() {
    this.params = []; this.date_from = ''; this.date_to = ''; this.getCurrDate();
  }

  getCurrDate() {
    let date = new Date();
    this.date_from = this.pv_power_from_date = this.pv_energy_from_date = this.pv_current_from_date = this.pv_voltage_from_date = this.tab_from_date_selection = this.pv_comparative_from_date = this.comparative_tab_from_date_selection = this.generation_tab_from_date_selection = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
    this.date_to = this.pv_power_to_date = this.pv_energy_to_date = this.pv_current_to_date = this.pv_voltage_to_date = this.tab_to_date_selection = this.pv_comparative_to_date = this.comparative_tab_to_date_selection = this.generation_tab_to_date_selection = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
  }

  getDeviceLocation() {
    let body = {
      'action': 'getDeviceByID',
      'device_id': [this.device_id]
    }
    this.dashboardService.httpPost(body).subscribe((res: any) => {
      
      if (res.data[this.device_id]) {
        this.location = res.data[this.device_id].location;
      }
      
    })
  }

  tabChange($event: NgbTabChangeEvent) {
    this.curr_tab = this.dataShared.curr_tab = $event.nextId;

    if (this.curr_tab == 'tab-comparative' || this.curr_tab == 'tab-comparative-table') {
      if (this.dataShared.panel_devices.length == 0) {
        this.toastr.info('Select minimum 2 or maximum 8 devices', '', {
          timeOut: 10000,
        });
      }
    } else if (this.curr_tab == 'tab-generation-table') {
      if (this.dataShared.panel_devices.length == 0) {
        this.toastr.info('Select minimum 2 or maximum 8 devices', '', {
          timeOut: 10000,
        });
      } else {
        this.getGenerationTableData();
      }
    } else if (this.curr_tab == 'tab-table') {
      this.resetSinleDevice();
      this.getTableData();
    }
  }

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  getSelectedDeviceChartData() {
    if (this.comparative_devices.length < 2 || this.comparative_devices.length > 8) {
      this.toastr.error('Please select at least 2 or maximum 8 devices.');
    } else if (this.curr_tab == 'tab-generation-table') {
      this.getGenerationTableData();
    } else if (this.param == '') {
      this.toastr.error('Please select any parameter');
    } else {
      this.browserOnly(() => {
        this.params = [this.param]
        if (this.curr_tab == 'tab-comparative') this.getComparativeGraph();
        else if (this.curr_tab == 'tab-comparative-table') this.getComparativeTableData();
      });
    }
  }

  onFromDateSelection(e, param) {
    this.date_from = e.target.value;
    if (param == 'pv_power') { this.params = ['total_w']; }
    else if (param == 'pv_energy') { this.params = ['total_wh']; }
    else if (param == 'pv_current') { this.params = ['avg_curr_3p']; }
    else if (param == 'pv_voltage') { this.params = ['avg_vl_pp']; }

    this.getChartDataByParam(param);
  }

  onToDateSelection(e, param) {
    this.date_to = e.target.value;
    if (param == 'pv_power') { this.params = ['total_w']; }
    else if (param == 'pv_energy') { this.params = ['total_wh']; }
    else if (param == 'pv_current') { this.params = ['avg_curr_3p']; }
    else if (param == 'pv_voltage') { this.params = ['avg_vl_pp']; }
    this.getChartDataByParam(param);
  }

  onTabularFromDateSelection(e) {
    this.date_from = e.target.value;
    this.getTableData();
  }

  onTabularToDateSelection(e) {
    this.date_to = e.target.value;
    this.getTableData();
  }
  onTabularDateSelection(e) {
    this.date_from = e.startDate['_d'].toISOString().substring(0, 10);
    this.date_to = e.endDate['_d'].toISOString().substring(0, 10);
    
  }

  onClick() {
       this.getTableData();
  }

  onComparativeTabularFromDateSelection(e) {
    this.date_from = e.target.value;
  }

  onComparativeTabularToDateSelection(e) {
    this.date_to = e.target.value;
  }

  onGenerationTabularFromDateSelection(e) {
    this.date_from = e.target.value;
  }

  onGenerationTabularToDateSelection(e) {
    this.date_to = e.target.value;
  }

  onComparativeToDateSelection(e) {
    this.date_to = e.target.value

  }

  onComparativeFromDateSelection(e) {
    this.date_from = e.target.value

  }

  getChartDataByParam(element) {

    let param_data = [];
    let param_bar_variance_data = [];

    if (this.pv_power_chart_active == 'bar-variance' && element == 'pv_power') { this.pv_power_from_date = this.pv_power_to_date; this.date_from = this.date_to; }
    else if (this.pv_energy_chart_active == 'bar-variance' && element == 'pv_energy') { this.pv_energy_from_date = this.pv_energy_to_date; this.date_from = this.date_to; }
    else if (this.pv_current_chart_active == 'bar-variance' && element == 'pv_current') { this.pv_current_from_date = this.pv_current_to_date; this.date_from = this.date_to; }
    else if (this.pv_voltage_chart_active == 'bar-variance' && element == 'pv_voltage') { this.pv_voltage_from_date = this.pv_voltage_to_date; this.date_from = this.date_to; }

    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      if (res.data.buckets && res.data.buckets[0].date_agg) {
        let data = res.data.buckets[0].date_agg.buckets;

        let prev_wh = 0; let diff_wh = 0;

        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (data[i]['val_' + this.params].value >= prev_wh || element !== 'pv_energy') {
              if (i != 0 && data[i]['val_' + this.params].value != 0) diff_wh = data[i]['val_' + this.params].value - prev_wh;

              param_bar_variance_data.push({
                date: (data[i].key_as_string).slice(11, 16),
                value: (element == 'pv_energy') ? diff_wh : data[i]['val_' + this.params].value
              });

              param_data.push({
                date: new Date(data[i].key_as_string),
                value: (element == 'pv_energy') ? diff_wh : data[i]['val_' + this.params].value
              });

              if (data[i]['val_' + this.params].value != 0) prev_wh = data[i]['val_' + this.params].value;

            }

          }
        }
      }

      this.param_bar_data = param_data;
      this.param_bar_variance_data = param_bar_variance_data;
      let props = this.getGraphProp(element, param_data);
      let bar_variance_props = this.getGraphProp(element, param_bar_variance_data);

      if (element == 'pv_power' && this.pv_power_chart_active == 'bar-variance') this.implementBarVarianceChart(bar_variance_props);
      else if (element == 'pv_power' && this.pv_power_chart_active == 'bar') this.implementBarChart('Power', props);
      else if (element == 'pv_energy' && this.pv_energy_chart_active == 'bar-variance') this.implementBarVarianceChart(bar_variance_props);
      else if (element == 'pv_energy' && this.pv_energy_chart_active == 'bar') this.implementBarChart('Energy', props);
      else if (element == 'pv_current' && this.pv_current_chart_active == 'bar-variance') this.implementBarVarianceChart(bar_variance_props);
      else if (element == 'pv_current' && this.pv_current_chart_active == 'bar') this.implementBarChart('Current', props);
      else if (element == 'pv_voltage' && this.pv_voltage_chart_active == 'bar-variance') this.implementBarVarianceChart(bar_variance_props);
      else if (element == 'pv_voltage' && this.pv_voltage_chart_active == 'bar') this.implementBarChart('Voltage', props);

    });
  }

  getSingleDeviceGraph() {
    // Chart code goes in here
    this.browserOnly(() => {

      if (this.dataShared.device_id != null) {
        this.getChartData();
        this.getBarVarianceData();
        this.getEnergyChartData();
      }
    });
  }

  getChartData() {

    let pv_power_bar_data = []; let pv_current_bar_data = []; let pv_voltage_bar_data = [];
    this.interval = '5m';
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      if (res.data.buckets && res.data.buckets[0].date_agg) {
        let data = res.data.buckets[0].date_agg.buckets;

        if (data.length > 0) {

          for (let i = 0; i < data.length; i++) {

            pv_power_bar_data.push({
              date: new Date(data[i].key_as_string),
              value: data[i].val_total_w.value
            });

            pv_current_bar_data.push({
              date: new Date(data[i].key_as_string),
              value: data[i].val_avg_curr_3p.value
            });

            pv_voltage_bar_data.push({
              date: new Date(data[i].key_as_string),
              value: data[i].val_avg_vl_pp.value
            });

          }

          this.pv_power_bar_data = pv_power_bar_data; this.pv_current_bar_data = pv_current_bar_data; this.pv_voltage_bar_data = pv_voltage_bar_data;

        }
      }

      this.pv_power_prop = this.getGraphProp('pv_power', pv_power_bar_data);
      this.pv_current_prop = this.getGraphProp('pv_current', pv_current_bar_data);
      this.pv_voltage_prop = this.getGraphProp('pv_voltage', pv_voltage_bar_data);
      this.dynamicChartValues();
    });

  }

  getBarVarianceData() {

    let pv_power_bar_variance_data = []; let pv_current_bar_variance_data = []; let pv_voltage_bar_variance_data = [];
    this.interval = '1h';
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      if (res.data.buckets && res.data.buckets[0].date_agg) {
        let data = res.data.buckets[0].date_agg.buckets;

        if (data.length > 0) {

          for (let i = 0; i < data.length; i++) {

            pv_power_bar_variance_data.push({
              date: (data[i].key_as_string).slice(11, 16),
              value: data[i].val_total_w.value
            });

            pv_current_bar_variance_data.push({
              date: (data[i].key_as_string).slice(11, 16),
              value: data[i].val_avg_curr_3p.value
            });

            pv_voltage_bar_variance_data.push({
              date: (data[i].key_as_string).slice(11, 16),
              value: data[i].val_avg_vl_pp.value
            });

          }

          this.pv_power_bar_variance_data = pv_power_bar_variance_data; 
          this.pv_current_bar_variance_data = pv_current_bar_variance_data; 
          this.pv_voltage_bar_variance_data = pv_voltage_bar_variance_data;

        }
      }

    });

  }

  getEnergyChartData() {

    let pv_energy_bar_data = []; let pv_energy_bar_variance_data = [];
    this.interval = '1h';
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      if (res.data.buckets && res.data.buckets[0].date_agg) {
        let data = res.data.buckets[0].date_agg.buckets;

        if (data.length > 0) {

          let prev_wh = 0; let diff_wh = 0;

          for (let i = 0; i < data.length; i++) {

            if (data[i].val_total_wh.value >= prev_wh) {

              if (i != 0 && data[i].val_total_wh.value != 0) diff_wh = data[i].val_total_wh.value - prev_wh;

              pv_energy_bar_data.push({
                date: new Date(data[i].key_as_string),
                value: diff_wh
              });

              pv_energy_bar_variance_data.push({
                date: (data[i].key_as_string).slice(11, 16),
                value: diff_wh
              });

              if (data[i].val_total_wh.value != 0) prev_wh = data[i].val_total_wh.value;

            } else {

              if (i != 0 && data[i].val_total_wh.value != 0) diff_wh = diff_wh / 2;

              pv_energy_bar_data.push({
                date: new Date(data[i].key_as_string),
                value: diff_wh
              });

              pv_energy_bar_variance_data.push({
                date: (data[i].key_as_string).slice(11, 16),
                value: diff_wh
              });

              if (data[i].val_total_wh.value != 0) prev_wh = prev_wh + diff_wh;

            }

          }

          this.pv_energy_bar_data = pv_energy_bar_data; this.pv_energy_bar_variance_data = pv_energy_bar_variance_data;
        }
      }
      this.pv_energy_prop = this.getGraphProp('pv_energy', pv_energy_bar_data);
    });
  }

  getComparativeGraph() {

    let data = [];
    this.interval = '5m';
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {
      
      for (let index = 0; index < res.data.buckets.length; index++) {

        for (let j = 0; j < res.data.buckets[index].date_agg.buckets.length; j++) {

          data.push({
            id: res.data.buckets[index].key,
            ['date_' + res.data.buckets[index].key]: new Date(res.data.buckets[index].date_agg.buckets[j].key_as_string),
            ['value_' + res.data.buckets[index].key]: res.data.buckets[index].date_agg.buckets[j]['val_' + this.param].value

          });
          this.pv_comparative_data = data;
        }
      }
      
      this.pv_comparative_data.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
      
      let prop = this.getGraphProp('pv_comparative', this.pv_comparative_data);
      this.implementLineComparativeChart(prop);
      this.getComparativeTableData();
    });

    for (let index = 0; index < this.param_list.length; index++) {
      if (this.param_list[index].parameter == this.param) {
        this.comparative_title = this.param_list[index].description + '  (' + this.param_list[index].unit + ')'
      }
    }

  }

  dynamicChartValues() {

    let setting_body = {
      action: 'getSettings',
      meta_key: 'config',
      user_id: localStorage.getItem('user'),
      account_id: [localStorage.getItem('account')]
    }

    this.dashboardService.httpPost(setting_body).subscribe((res: any) => {
      if (res.status == 200) {

        let config = res.data?.config;
        let data = (localStorage.getItem('sub_user') == '0') ? JSON.parse(config[0].chart_setting) : JSON.parse(config[0].sub_chart_setting);; 

        this.power_header = (data.pv_string_monitoring.graph.chart_1.header) ? data.pv_string_monitoring.graph.chart_1.header : '';
        this.current_header = (data.pv_string_monitoring.graph.chart_2.header) ? data.pv_string_monitoring.graph.chart_2.header : '';
        this.energy_header = (data.pv_string_monitoring.graph.chart_3.header) ? data.pv_string_monitoring.graph.chart_3.header : '';
        this.voltage_header = (data.pv_string_monitoring.graph.chart_4.header) ? data.pv_string_monitoring.graph.chart_4.header : '';

        this.pv_power_prop.title = (data.pv_string_monitoring.graph.chart_1.title) ? data.pv_string_monitoring.graph.chart_1.title : '';
        this.pv_current_prop.title = (data.pv_string_monitoring.graph.chart_2.title) ? data.pv_string_monitoring.graph.chart_2.title : '';
        this.pv_energy_prop.title = (data.pv_string_monitoring.graph.chart_3.title) ? data.pv_string_monitoring.graph.chart_3.title : '';
        this.pv_voltage_prop.title = (data.pv_string_monitoring.graph.chart_4.title) ? data.pv_string_monitoring.graph.chart_4.title : '';
 
        let power_unit = (data.pv_string_monitoring.graph.chart_1.unit) ? data.pv_string_monitoring.graph.chart_1.unit : '';
        let current_unit = (data.pv_string_monitoring.graph.chart_2.unit) ? data.pv_string_monitoring.graph.chart_2.unit : '';
        let energy_unit = (data.pv_string_monitoring.graph.chart_3.unit) ? data.pv_string_monitoring.graph.chart_3.unit : '';
        let voltage_unit = (data.pv_string_monitoring.graph.chart_4.unit) ? data.pv_string_monitoring.graph.chart_4.unit : '';

        this.power_status = (data.pv_string_monitoring.graph.chart_1.status) ? data.pv_string_monitoring.graph.chart_1.status : '';
        this.current_status = (data.pv_string_monitoring.graph.chart_2.status) ? data.pv_string_monitoring.graph.chart_2.status : '';
        this.energy_status = (data.pv_string_monitoring.graph.chart_3.status) ? data.pv_string_monitoring.graph.chart_3.status : '';
        this.voltage_status = (data.pv_string_monitoring.graph.chart_4.status) ? data.pv_string_monitoring.graph.chart_4.status : '';
       
        this.implementBarChart(power_unit, this.pv_power_prop);
        this.implementBarChart(current_unit, this.pv_current_prop);
        this.implementBarChart(energy_unit, this.pv_energy_prop);
        this.implementBarChart(voltage_unit, this.pv_voltage_prop);
      }
    })

  }

  getBody() {

    let body = {
      'action': 'getChartDataByAccount',
      'user_id': [localStorage.getItem('user')],
      'device_id': (this.comparative_devices.length == 0) ? [this.dataShared.device_id] : this.comparative_devices,
      'date': this.date_to,
      'fixed_interval': this.interval,
      'parameters': (this.params.length > 0) ? this.params : this.parameters
    };

    if (this.date_from != this.date_to && this.curr_tab != 'tab-generation-table') {
      delete body['date'];

      let range = {
        from: this.date_from,
        to: this.date_to,
      };
      body['range'] = range
    }

    return body;
  }

  getGraphProp(param, param_data) {

    let data = {
      element: '',
      title: '',
      data: param_data,
      interval: {
        'interval': 'second',
        'value': 1
      },
      tooltipFormat: 'HH:mm:ss, d MMMM',
      xScrollbar: true
    };

    if (param == 'pv_power') {
      data.element = 'chartPvStPower'
      data.title = 'PV Power (KWh)'
    } else if (param == 'pv_current') {
      data.element = 'chartPvCurrent'
      data.title = 'PV Current'
    } else if (param == 'pv_energy') {
      data.element = 'chartPvEnergy'
      data.title = 'PV Energy'
      if (this.pv_energy_chart_active == 'bar-variance') {
        this.pv_energy_sum = 0;
        this.pv_energy_sum = param_data.map(a => a.value).reduce(function (a, b) {
          return a + b;
        });
        this.show_energy_sum = true
      } else if (this.pv_energy_chart_active == 'bar') {
        this.show_energy_sum = false;
      }

    }  else if (param == 'pv_voltage') {
      data.element = 'chartPvVoltage'
      data.title = 'PV Voltage'
    } else if (param == 'pv_comparative') {
      data.element = 'chartPvComparative'
      data.title = 'PV Voltage'
    } else if (param == 'pv_generation'){
      data.element = 'pv_generation_chart'
    }

    return data;
  }

  implementBarChart(name, data) {

    am4core.useTheme(am4themes_animated);

    let chart = am4core.create(data.element, am4charts.XYChart);
    chart.paddingRight = 20;

    chart.data = data.data;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());

    dateAxis.tooltipDateFormat = data.tooltipFormat;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.title.text = data.title;
    // valueAxis.min = 0;

    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.dateX = 'date';
    series.dataFields.valueY = 'value';
    series.name = name;
    series.tooltipText = ' [bold]{name} : {valueY}[/]';
    series.fillOpacity = 1;
    if (name == 'Energy') series.strokeWidth = 5;
    else if (name !== 'Energy') series.strokeWidth = 5;
    // series.stroke = am4core.color("#CDA2AB");

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.opacity = 0;

    let scrollbarX = new am4core.Scrollbar();
    scrollbarX.marginBottom = 20;
    chart.scrollbarX = scrollbarX;

    dateAxis.start = 0;
    dateAxis.keepSelection = true;
      // chart.svgContainer.autoResize = false;
      // chart.svgContainer.measure(); 

  }

  implementBarVarianceChart(data) {

    am4core.useTheme(am4themes_animated);

    let chart = am4core.create(data.element, am4charts.XYChart);
    chart.paddingRight = 20;

    chart.data = data.data;

    // Populate data
    for (var i = 0; i < (chart.data.length - 1); i++) {
      chart.data[i].valueNext = chart.data[i + 1].value;
    }

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "date";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "date";

    // Add series for showing variance arrows
    let series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = "valueNext";
    series2.dataFields.openValueY = "value";
    series2.dataFields.categoryX = "date";
    series2.columns.template.width = 1;
    series2.fill = am4core.color("#555");
    series2.stroke = am4core.color("#555");

    // Add a triangle for arrow tip
    let arrow = series2.bullets.push(new am4core.Triangle);
    arrow.width = 10;
    arrow.height = 10;
    arrow.horizontalCenter = "middle";
    arrow.verticalCenter = "top";
    arrow.dy = -1;

    // Set up a rotation adapter which would rotate the triangle if its a negative change
    arrow.adapter.add("rotation", function (rotation, target) {
      return getVariancePercent(target.dataItem) < 0 ? 180 : rotation;
    });

    // Set up a rotation adapter which adjusts Y position
    arrow.adapter.add("dy", function (dy, target) {
      return getVariancePercent(target.dataItem) < 0 ? 1 : dy;
    });

    // Add a label
    let label = series2.bullets.push(new am4core.Label);
    label.padding(10, 10, 10, 10);
    label.text = "";
    label.fill = am4core.color("#0c0");
    label.strokeWidth = 0;
    label.horizontalCenter = "middle";
    label.verticalCenter = "bottom";
    label.fontWeight = "bolder";

    // Adapter for label text which calculates change in percent
    label.adapter.add("textOutput", function (text, target) {
      let percent = getVariancePercent(target.dataItem);
      return percent ? percent + "%" : text;
    });

    // Adapter which shifts the label if it's below the variance column
    label.adapter.add("verticalCenter", function (center, target) {
      return getVariancePercent(target.dataItem) < 0 ? "top" : center;
    });

    // Adapter which changes color of label to red
    label.adapter.add("fill", function (fill, target) {
      return getVariancePercent(target.dataItem) < 0 ? am4core.color("#c00") : fill;
    });

    function getVariancePercent(dataItem) {
      if (dataItem) {
        let value = dataItem.valueY;
        let openValue = dataItem.openValueY;
        let change = value - openValue;
        return Math.round(change / openValue * 100);
      }
      return 0;
    }
  }

  implementLineComparativeChart(data) {

    am4core.useTheme(am4themes_animated);

    this.chart = am4core.create(data.element, am4charts.XYChart);
    this.chart.data = data.data;
    this.chart.colors.step = 2;

    // Create axes
    let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.adapter.remove("getTime");

    // Create series
    for (let index = 0; index < this.comparative_devices.length; index++) {
      if (index % 2) {
        this.createAxisAndSeries("value_" + this.dataShared.panel_devices[index].device_id, "date_" + this.dataShared.panel_devices[index].device_id, this.dataShared.panel_devices[index].location, true);
      } else {
        this.createAxisAndSeries("value_" + this.dataShared.panel_devices[index].device_id, "date_" + this.dataShared.panel_devices[index].device_id, this.dataShared.panel_devices[index].location, false);
      }
    }

    // Add legend
    this.chart.legend = new am4charts.Legend();
    // Add cursor
    this.chart.cursor = new am4charts.XYCursor();
  }

  createAxisAndSeries(field, date, name, opposite) {

    this.valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    let series = this.chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = field;
    series.dataFields.dateX = date;
    series.strokeWidth = 2;
    series.name = name;
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.tensionX = 0.8;
    series.showOnInit = true;

    let scrollbarX = new am4core.Scrollbar();
    scrollbarX.marginBottom = 20;
    this.chart.scrollbarX = scrollbarX;

    this.valueAxis.renderer.line.strokeWidth = 2;
    this.valueAxis.renderer.line.stroke = series.stroke;
    this.valueAxis.renderer.labels.template.fill = series.stroke;
    this.valueAxis.renderer.opposite = opposite;

  }

  implementBarComparativeChart(data) {

    am4core.useTheme(am4themes_animated);

    this.chart = am4core.create(data.element, am4charts.XYChart);
    this.chart.data = data.data;
    this.chart.colors.step = 2;

    // Create axes
    let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.adapter.remove("getTime");

    // Create series
    if (this.comparative_devices.length < 2 || this.comparative_devices.length > 8) {
      this.toastr.error("Minimum 2 and Maximum 8 Parameters are allowed")
    } else {
      for (let index = 0; index < this.comparative_devices.length; index++) {
        if (index % 2) {
          this.createBarAxisAndSeries("value_" + this.dataShared.panel_devices[index].device_id, "date_" + this.dataShared.panel_devices[index].device_id, this.dataShared.panel_devices[index].location, true);
        } else {
          this.createBarAxisAndSeries("value_" + this.dataShared.panel_devices[index].device_id, "date_" + this.dataShared.panel_devices[index].device_id, this.dataShared.panel_devices[index].location, false);
        }

      }
    }

    // Add legend
    this.chart.legend = new am4charts.Legend();
    // Add cursor
    this.chart.cursor = new am4charts.XYCursor();
  }

  createBarAxisAndSeries(field, date, name, opposite) {

    this.valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    let series = this.chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = field;
    series.dataFields.dateX = date;
    series.strokeWidth = 2;
    series.name = name;
    series.tooltipText = "{name}: [bold]{valueY}[/]";

    series.showOnInit = true;
    // Add scrollbar
    let scrollbarX = new am4core.Scrollbar();
    scrollbarX.marginBottom = 20;
    this.chart.scrollbarX = scrollbarX;

    // Add cursor
    this.valueAxis.renderer.line.strokeWidth = 2;
    this.valueAxis.renderer.line.stroke = series.stroke;
    this.valueAxis.renderer.labels.template.fill = series.stroke;
    this.valueAxis.renderer.opposite = opposite;
  }

  tabLineChart(element) {

    if (element == 'pv_comparative' && this.pv_comparative_chart_active != 'line') {
      this.implementLineComparativeChart(this.getGraphProp(element, this.pv_comparative_data)); this.pv_comparative_chart_active = 'line';
    }

  }

  tabBarChart(element) {

    if (element == 'pv_power' && this.pv_power_chart_active != 'bar') {
      this.pv_power_chart_active = 'bar'; this.implementBarChart('Power', this.getGraphProp(element, this.pv_power_bar_data)); this.getCurrDate(); this.show_pv_power_from_date = true;
    } else if (element == 'pv_energy' && this.pv_energy_chart_active != 'bar') {
      this.pv_energy_chart_active = 'bar'; this.implementBarChart('Energy', this.getGraphProp(element, this.pv_energy_bar_data)); this.getCurrDate(); this.show_pv_energy_from_date = true;
    } else if (element == 'pv_current' && this.pv_current_chart_active != 'bar') {
      this.pv_current_chart_active = 'bar'; this.implementBarChart('Current', this.getGraphProp(element, this.pv_current_bar_data)); this.getCurrDate(); this.show_pv_current_from_date = true;
    } else if (element == 'pv_voltage' && this.pv_voltage_chart_active != 'bar') {
      this.pv_voltage_chart_active = 'bar'; this.implementBarChart('Voltage', this.getGraphProp(element, this.pv_voltage_bar_data)); this.getCurrDate(); this.show_pv_voltage_from_date = true;
    } else if (element == 'pv_comparative' && this.pv_comparative_chart_active != 'bar') {
      this.implementBarComparativeChart(this.getGraphProp(element, this.pv_comparative_data)); this.pv_comparative_chart_active = 'bar';
    }

  }

  tabBarVarianceChart(element) {

    if (element == 'pv_power' && this.pv_power_chart_active != 'bar-variance') {
      this.pv_power_chart_active = 'bar-variance'; this.getCurrDate(); this.implementBarVarianceChart(this.getGraphProp(element, this.pv_power_bar_variance_data)); this.show_pv_power_from_date = false;
    } else if (element == 'pv_energy' && this.pv_energy_chart_active != 'bar-variance') {
      this.pv_energy_chart_active = 'bar-variance'; this.getCurrDate(); this.implementBarVarianceChart(this.getGraphProp(element, this.pv_energy_bar_variance_data)); this.show_pv_energy_from_date = false;
    } else if (element == 'pv_current' && this.pv_current_chart_active != 'bar-variance') {
      this.pv_current_chart_active = 'bar-variance'; this.getCurrDate(); this.implementBarVarianceChart(this.getGraphProp(element, this.pv_current_bar_variance_data)); this.show_pv_current_from_date = false;
    } else if (element == 'pv_voltage' && this.pv_voltage_chart_active != 'bar-variance') {
      this.pv_voltage_chart_active = 'bar-variance'; this.getCurrDate(); this.implementBarVarianceChart(this.getGraphProp(element, this.pv_voltage_bar_variance_data)); this.show_pv_voltage_from_date = false;
    }
  }

  getTableData() {

    this.table_data = [];
    this.interval = '1s';
    this.params = this.parameters;
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {
     

      if (res.data.buckets) {

        let data = res.data.buckets[0].date_agg.buckets

        for (let i = 0; i < data.length; i++) {
          
          this.table_data.push({
            date: new Date(data[i].key_as_string),
            power: data[i].val_total_w.value,
            current: data[i].val_avg_curr_3p.value,
            energy: data[i].val_total_wh.value,
            voltage: data[i].val_avg_vl_pp.value
          })
      }

      let length = (parseInt(String(data.length / 50)) < parseFloat(String(data.length / 50))) ? parseInt(String(data.length / 50)) + 1 : parseInt(String(data.length / 50));
      this.pages = [];
      for (let i = 1; i <= length; i++) {
      this.pages.push(i);
    }
       
        this.table_sum = 0;

        this.table_sum = this.table_data.map(a => a.energy).reduce(function (a, b) {
          return a + b;
        });


      } else {
        this.toastr.error('data not available');
      }
    })
  }

  getComparativeTableData() {

    this.comparative_table_data = []; 
    this.interval = '1s'; let data = [];
     let data_0 = []; let data_1 = []; let data_2 = []; let data_3 = []; let data_4 = []; let data_5 = [];  let data_6 = []; let data_7 = [];
    this.comparative_table_value_3 = false; this.comparative_table_value_4 = false; this.comparative_table_value_5 = false; this.comparative_table_value_6 = false; this.comparative_table_value_7 = false; this.comparative_table_value_8 = false;
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      for (let index = 0; index < this.comparative_devices.length; index++) {
        data.push(res.data.buckets[index]);
      }

      data.sort((a, b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0));

      if (this.comparative_devices.length == 2) { data_0 = data[0].date_agg.buckets; data_1 = data[1].date_agg.buckets; }
      else if (this.comparative_devices.length == 3) { data_0 = data[0].date_agg.buckets; data_1 = data[1].date_agg.buckets; data_2 = data[2].date_agg.buckets; this.comparative_table_value_3 = true; }
      else if (this.comparative_devices.length == 4) { data_0 = data[0].date_agg.buckets; data_1 = data[1].date_agg.buckets; data_2 = data[2].date_agg.buckets; data_3 = data[3].date_agg.buckets; this.comparative_table_value_3 = true; this.comparative_table_value_4 = true; }
      else if (this.comparative_devices.length == 5) { data_0 = data[0].date_agg.buckets; data_1 = data[1].date_agg.buckets; data_2 = data[2].date_agg.buckets; data_3 = data[3].date_agg.buckets; data_4 = data[4].date_agg.buckets; this.comparative_table_value_3 = true; this.comparative_table_value_4 = true; this.comparative_table_value_5 = true; }
      else if (this.comparative_devices.length == 6) { data_0 = data[0].date_agg.buckets; data_1 = data[1].date_agg.buckets; data_2 = data[2].date_agg.buckets; data_3 = data[3].date_agg.buckets; data_4 = data[4].date_agg.buckets; data_5 = data[5].date_agg.buckets; this.comparative_table_value_3 = true; this.comparative_table_value_4 = true; this.comparative_table_value_5 = true; this.comparative_table_value_6 = true; }
      else if (this.comparative_devices.length == 7) { data_0 = data[0].date_agg.buckets; data_1 = data[1].date_agg.buckets; data_2 = data[2].date_agg.buckets; data_3 = data[3].date_agg.buckets; data_4 = data[4].date_agg.buckets; data_5 = data[5].date_agg.buckets; data_6 = data[6].date_agg.buckets;  this.comparative_table_value_3 = true; this.comparative_table_value_4 = true; this.comparative_table_value_5 = true; this.comparative_table_value_6 = true; this.comparative_table_value_7 = true; }
      else if (this.comparative_devices.length == 8) { data_0 = data[0].date_agg.buckets; data_1 = data[1].date_agg.buckets; data_2 = data[2].date_agg.buckets; data_3 = data[3].date_agg.buckets; data_4 = data[4].date_agg.buckets; data_5 = data[5].date_agg.buckets; data_6 = data[6].date_agg.buckets; data_7 = data[7].date_agg.buckets;  this.comparative_table_value_3 = true; this.comparative_table_value_4 = true; this.comparative_table_value_5 = true; this.comparative_table_value_6 = true; this.comparative_table_value_7 = true; this.comparative_table_value_8 = true; }

      if (res.data.buckets[0].date_agg.buckets) {

        let date = []; let lenght = [];
        lenght.push(data_0.length, data_1.length, data_2.length, data_3.length, data_4.length, data_5.length, data_6.length, data_7.length)

        let max = Math.max(...lenght);
        this.dataShared.panel_devices.sort((a, b) => (a.device_id > b.device_id) ? 1 : ((b.device_id > a.device_id) ? -1 : 0));
        this.device_location = this.dataShared.panel_devices;
  
        for (let index = 0; index < lenght.length; index++) {

          if (max == lenght[index]) {
            date = res.data.buckets[index].date_agg.buckets
          }

        }

        for (let i = 0; i < max; i++) {

          if (this.comparative_devices.length == 2) {

            this.comparative_table_data.push({ 
              date: new Date(date[i].key_as_string), 
              value_1: data_0[i]?.['val_' + this.param].value, 
              value_2: data_1[i]?.['val_' + this.param].value 
            })
            
              this.tabular_export_data.push({ 
                date: new Date(date[i].key_as_string), 
                [this.dataShared.panel_devices[0].location]: data_0[i]?.['val_' + this.param].value, 
                [this.dataShared.panel_devices[1].location]: data_1[i]?.['val_' + this.param].value
              })
            
          } else if (this.comparative_devices.length == 3) {

            this.comparative_table_data.push({ 
              date: new Date(date[i].key_as_string), 
              value_1: data_0[i]?.['val_' + this.param].value, 
              value_2: data_1[i]?.['val_' + this.param].value, 
              value_3: data_2[i]?.['val_' + this.param].value 
            })

            this.tabular_export_data.push({ 
              date: new Date(date[i].key_as_string), 
              [this.dataShared.panel_devices[0].location]: data_0[i]?.['val_' + this.param].value, 
              [this.dataShared.panel_devices[1].location]: data_1[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[2].location]: data_2[i]?.['val_' + this.param].value
            })

          } else if (this.comparative_devices.length == 4) {
            
            this.comparative_table_data.push({ 
              date: new Date(date[i].key_as_string), 
              value_1: data_0[i]?.['val_' + this.param].value, 
              value_2: data_1[i]?.['val_' + this.param].value, 
              value_3: data_2[i]?.['val_' + this.param].value, 
              value_4: data_3[i]?.['val_' + this.param].value 
            })

            this.tabular_export_data.push({ 
              date: new Date(date[i].key_as_string), 
              [this.dataShared.panel_devices[0].location]: data_0[i]?.['val_' + this.param].value, 
              [this.dataShared.panel_devices[1].location]: data_1[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[2].location]: data_2[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[3].location]: data_3[i]?.['val_' + this.param].value
            })

          } else if (this.comparative_devices.length == 5) {
            
            this.comparative_table_data.push({ 
              date: new Date(date[i].key_as_string), 
              value_1: data_0[i]?.['val_' + this.param].value, 
              value_2: data_1[i]?.['val_' + this.param].value, 
              value_3: data_2[i]?.['val_' + this.param].value, 
              value_4: data_3[i]?.['val_' + this.param].value, 
              value_5: data_4[i]?.['val_' + this.param].value 
            })

            this.tabular_export_data.push({ 
              date: new Date(date[i].key_as_string), 
              [this.dataShared.panel_devices[0].location]: data_0[i]?.['val_' + this.param].value, 
              [this.dataShared.panel_devices[1].location]: data_1[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[2].location]: data_2[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[3].location]: data_3[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[4].location]: data_4[i]?.['val_' + this.param].value
            })

          } else if (this.comparative_devices.length == 6) {
            
            this.comparative_table_data.push({ 
              date: new Date(date[i].key_as_string), 
              value_1: data_0[i]?.['val_' + this.param].value, 
              value_2: data_1[i]?.['val_' + this.param].value, 
              value_3: data_2[i]?.['val_' + this.param].value, 
              value_4: data_3[i]?.['val_' + this.param].value, 
              value_5: data_4[i]?.['val_' + this.param].value, 
              value_6: data_5[i]?.['val_' + this.param].value 
            })

            this.tabular_export_data.push({ 
              date: new Date(date[i].key_as_string), 
              [this.dataShared.panel_devices[0].location]: data_0[i]?.['val_' + this.param].value, 
              [this.dataShared.panel_devices[1].location]: data_1[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[2].location]: data_2[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[3].location]: data_3[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[4].location]: data_4[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[5].location]: data_5[i]?.['val_' + this.param].value
            })
          } else if (this.comparative_devices.length == 7) {

            this.comparative_table_data.push({ 
              date: new Date(date[i].key_as_string), 
              value_1: data_0[i]?.['val_' + this.param].value, 
              value_2: data_1[i]?.['val_' + this.param].value, 
              value_3: data_2[i]?.['val_' + this.param].value, 
              value_4: data_3[i]?.['val_' + this.param].value, 
              value_5: data_4[i]?.['val_' + this.param].value, 
              value_6: data_5[i]?.['val_' + this.param].value,
              value_7: data_6[i]?.['val_' + this.param].value  
            })

            this.tabular_export_data.push({ 
              date: new Date(date[i].key_as_string), 
              [this.dataShared.panel_devices[0].location]: data_0[i]?.['val_' + this.param].value, 
              [this.dataShared.panel_devices[1].location]: data_1[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[2].location]: data_2[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[3].location]: data_3[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[4].location]: data_4[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[5].location]: data_5[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[6].location]: data_6[i]?.['val_' + this.param].value 
            })

          } else if (this.comparative_devices.length == 8) {

            this.comparative_table_data.push({ 
              date: new Date(date[i].key_as_string), 
              value_1: data_0[i]?.['val_' + this.param].value, 
              value_2: data_1[i]?.['val_' + this.param].value, 
              value_3: data_2[i]?.['val_' + this.param].value, 
              value_4: data_3[i]?.['val_' + this.param].value, 
              value_5: data_4[i]?.['val_' + this.param].value, 
              value_6: data_5[i]?.['val_' + this.param].value,
              value_7: data_6[i]?.['val_' + this.param].value,
              value_8: data_7[i]?.['val_' + this.param].value  
            })

            this.tabular_export_data.push({ 
              date: new Date(date[i].key_as_string), 
              [this.dataShared.panel_devices[0].location]: data_0[i]?.['val_' + this.param].value, 
              [this.dataShared.panel_devices[1].location]: data_1[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[2].location]: data_2[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[3].location]: data_3[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[4].location]: data_4[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[5].location]: data_5[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[6].location]: data_6[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[7].location]: data_7[i]?.['val_' + this.param].value 
            })

          }
        }
      }
    })
  }

  calculateCost() {
    this.getGenerationTableData();
  }

  getGenerationTableData() {
    
    this.getCost(); this.gentration_date = 'single';
    this.comparative_table_data = []; this.interval = '1s'; this.diff = []; let data = []; let data_0 = []; let data_1 = []; let data_2 = []; let data_3 = []; let data_4 = []; let data_5 = [];  let data_6 = []; let data_7 = [];
    this.comparative_table_value_3 = false; this.comparative_table_value_4 = false; this.comparative_table_value_5 = false; this.comparative_table_value_6 = false; this.comparative_table_value_7 = false; this.comparative_table_value_8 = false;
    this.cost_sum = 0.00; this.diff_sum = 0.00; this.generation_table_data = []; this.chart_data = []; this.generation_table_export_data = []; this.params = ['total_wh']; let lenght = []; let date = []; 
    
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      if (res.data.buckets) {
        for (let index = 0; index < this.comparative_devices.length; index++) {
          data.push(res.data.buckets[index]);
        }
    
        data.sort((a, b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0));
        if (this.comparative_devices.length == 2) {
          
           data_0 = data[0].date_agg.buckets; 
           data_1 = data[1].date_agg.buckets; 
           lenght.push(data_0.length, data_1.length);
  
          } else if (this.comparative_devices.length == 3) {
  
            data_0 = data[0].date_agg.buckets; 
            data_1 = data[1].date_agg.buckets; 
            data_2 = data[2].date_agg.buckets; 
            this.comparative_table_value_3 = true; 
            lenght.push(data_0.length, data_1.length, data_2.length); 
  
          } else if (this.comparative_devices.length == 4) {
  
            data_0 = data[0].date_agg.buckets; 
            data_1 = data[1].date_agg.buckets; 
            data_2 = data[2].date_agg.buckets; 
            data_3 = data[3].date_agg.buckets; 
            this.comparative_table_value_3 = true; 
            this.comparative_table_value_4 = true; 
            lenght.push(data_0.length, data_1.length, data_2.length, data_3.length);
  
          } else if (this.comparative_devices.length == 5) { 
            
            data_0 = data[0].date_agg.buckets; 
            data_1 = data[1].date_agg.buckets; 
            data_2 = data[2].date_agg.buckets; 
            data_3 = data[3].date_agg.buckets; 
            data_4 = data[4].date_agg.buckets; 
            this.comparative_table_value_3 = true; 
            this.comparative_table_value_4 = true; 
            this.comparative_table_value_5 = true; 
            lenght.push(data_0.length, data_1.length, data_2.length, data_3.length, data_4.length);
  
          } else if (this.comparative_devices.length == 6) { 
            
            data_0 = data[0].date_agg.buckets; 
            data_1 = data[1].date_agg.buckets; 
            data_2 = data[2].date_agg.buckets; 
            data_3 = data[3].date_agg.buckets; 
            data_4 = data[4].date_agg.buckets; 
            data_5 = data[5].date_agg.buckets; 
            this.comparative_table_value_3 = true; 
            this.comparative_table_value_4 = true; 
            this.comparative_table_value_5 = true; 
            this.comparative_table_value_6 = true; 
            lenght.push(data_0.length, data_1.length, data_2.length, data_3.length, data_4.length, data_5.length);
          
          } else if (this.comparative_devices.length == 7) { 
            
            data_0 = data[0].date_agg.buckets; 
            data_1 = data[1].date_agg.buckets; 
            data_2 = data[2].date_agg.buckets; 
            data_3 = data[3].date_agg.buckets; 
            data_4 = data[4].date_agg.buckets; 
            data_5 = data[5].date_agg.buckets; 
            data_6 = data[6].date_agg.buckets;  
            this.comparative_table_value_3 = true; 
            this.comparative_table_value_4 = true; 
            this.comparative_table_value_5 = true; 
            this.comparative_table_value_6 = true; 
            this.comparative_table_value_7 = true; 
            lenght.push(data_0.length, data_1.length, data_2.length, data_3.length, data_4.length, data_5.length, data_6.length);
  
          } else if (this.comparative_devices.length == 8) { 
            
            data_0 = data[0].date_agg.buckets; 
            data_1 = data[1].date_agg.buckets; 
            data_2 = data[2].date_agg.buckets; 
            data_3 = data[3].date_agg.buckets; 
            data_4 = data[4].date_agg.buckets; 
            data_5 = data[5].date_agg.buckets; 
            data_6 = data[6].date_agg.buckets; 
            data_7 = data[7].date_agg.buckets; 
            this.comparative_table_value_3 = true; 
            this.comparative_table_value_4 = true; 
            this.comparative_table_value_5 = true; 
            this.comparative_table_value_6 = true; 
            this.comparative_table_value_7 = true; 
            this.comparative_table_value_8 = true; 
            lenght.push(data_0.length, data_1.length, data_2.length, data_3.length, data_4.length, data_5.length, data_6.length, data_7.length);
          
          }
  
          let min = Math.min(...lenght)
          this.dataShared.panel_devices.sort((a, b) => (a.device_id > b.device_id) ? 1 : ((b.device_id > a.device_id) ? -1 : 0));
          this.device_location = this.dataShared.panel_devices;
     
          for (let index = 0; index < lenght.length; index++) {
            if (min == lenght[index]) {
              date = res.data.buckets[index].date_agg.buckets
            }
          }
          
          for (let i = 0; i < min; i++) {
  
            if (this.comparative_devices.length == 2) {
  
                this.generation_table_data.push({ 
                  date: new Date(date[i]?.key_as_string), 
                  value_1: data_0[i]?.val_total_wh.value, 
                  value_2: data_1[i]?.val_total_wh.value 
                })
  
                this.generation_table_export_data.push({ 
                  date: new Date(date[i].key_as_string), 
                  [this.dataShared.panel_devices[0].location]: data_0[i]?.val_total_wh.value.toFixed(2), 
                  [this.dataShared.panel_devices[1].location]: data_1[i]?.val_total_wh.value.toFixed(2)
                })
  
              } else if (this.comparative_devices.length == 3) { 
                
                this.generation_table_data.push({ 
                  date: new Date(date[i]?.key_as_string), 
                  value_1: data_0[i]?.val_total_wh.value, 
                  value_2: data_1[i]?.val_total_wh.value, 
                  value_3: data_2[i]?.val_total_wh.value 
                })
  
                this.generation_table_export_data.push({ 
                  date: new Date(date[i].key_as_string), 
                  [this.dataShared.panel_devices[0].location]: data_0[i]?.val_total_wh.value.toFixed(2), 
                  [this.dataShared.panel_devices[1].location]: data_1[i]?.val_total_wh.value.toFixed(2),
                  [this.dataShared.panel_devices[2].location]: data_2[i]?.val_total_wh.value.toFixed(2)
                })
  
              } else if (this.comparative_devices.length == 4) { 
                
                this.generation_table_data.push({ 
                  date: new Date(date[i]?.key_as_string), 
                  value_1: data_0[i]?.val_total_wh.value, 
                  value_2: data_1[i]?.val_total_wh.value, 
                  value_3: data_2[i]?.val_total_wh.value, 
                  value_4: data_3[i]?.val_total_wh.value 
                })
  
                this.generation_table_export_data.push({ 
                  date: new Date(date[i].key_as_string), 
                  [this.dataShared.panel_devices[0].location]: data_0[i]?.val_total_wh.value.toFixed(2), 
                  [this.dataShared.panel_devices[1].location]: data_1[i]?.val_total_wh.value.toFixed(2),
                  [this.dataShared.panel_devices[2].location]: data_2[i]?.val_total_wh.value.toFixed(2),
                  [this.dataShared.panel_devices[3].location]: data_3[i]?.val_total_wh.value.toFixed(2)
                })
  
              } else if (this.comparative_devices.length == 5) { 
                
                this.generation_table_data.push({ 
                  date: new Date(date[i]?.key_as_string), 
                  value_1: data_0[i]?.val_total_wh.value, 
                  value_2: data_1[i]?.val_total_wh.value, 
                  value_3: data_2[i]?.val_total_wh.value, 
                  value_4: data_3[i]?.val_total_wh.value, 
                  value_5: data_4[i]?.val_total_wh.value 
                })
  
                this.generation_table_export_data.push({ 
                  date: new Date(date[i].key_as_string), 
                  [this.dataShared.panel_devices[0].location]: data_0[i]?.val_total_wh.value.toFixed(2), 
                  [this.dataShared.panel_devices[1].location]: data_1[i]?.val_total_wh.value.toFixed(2),
                  [this.dataShared.panel_devices[2].location]: data_2[i]?.val_total_wh.value.toFixed(2),
                  [this.dataShared.panel_devices[3].location]: data_3[i]?.val_total_wh.value.toFixed(2),
                  [this.dataShared.panel_devices[4].location]: data_4[i]?.val_total_wh.value.toFixed(2)
                })
  
              } else if (this.comparative_devices.length == 6) {
                
                this.generation_table_data.push({ 
                  date: new Date(date[i].key_as_string), 
                  value_1: data_0[i]?.val_total_wh.value, 
                  value_2: data_1[i]?.val_total_wh.value, 
                  value_3: data_2[i]?.val_total_wh.value, 
                  value_4: data_3[i]?.val_total_wh.value, 
                  value_5: data_4[i]?.val_total_wh.value, 
                  value_6: data_5[i]?.val_total_wh.value 
                })
  
                this.generation_table_export_data.push({ 
                  date: new Date(date[i].key_as_string), 
                  [this.dataShared.panel_devices[0].location]: data_0[i]?.val_total_wh.value.toFixed(2), 
                  [this.dataShared.panel_devices[1].location]: data_1[i]?.val_total_wh.value.toFixed(2),
                  [this.dataShared.panel_devices[2].location]: data_2[i]?.val_total_wh.value.toFixed(2),
                  [this.dataShared.panel_devices[3].location]: data_3[i]?.val_total_wh.value.toFixed(2),
                  [this.dataShared.panel_devices[4].location]: data_4[i]?.val_total_wh.value.toFixed(2),
                  [this.dataShared.panel_devices[5].location]: data_5[i]?.val_total_wh.value.toFixed(2)
                })
  
              } else if (this.comparative_devices.length == 7) {
  
                this.generation_table_data.push({ 
                  date: new Date(date[i].key_as_string), 
                  value_1: data_0[i]?.val_total_wh.value, 
                  value_2: data_1[i]?.val_total_wh.value, 
                  value_3: data_2[i]?.val_total_wh.value, 
                  value_4: data_3[i]?.val_total_wh.value, 
                  value_5: data_4[i]?.val_total_wh.value, 
                  value_6: data_5[i]?.val_total_wh.value,
                  value_7: data_6[i]?.val_total_wh.value 
                })
    
                this.generation_table_export_data.push({ 
                  date: new Date(date[i].key_as_string), 
                  [this.dataShared.panel_devices[0].location]: data_0[i]?.val_total_wh.value.toFixed(2), 
                  [this.dataShared.panel_devices[1].location]: data_1[i]?.val_total_wh.value.toFixed(2),
                  [this.dataShared.panel_devices[2].location]: data_2[i]?.val_total_wh.value.toFixed(2),
                  [this.dataShared.panel_devices[3].location]: data_3[i]?.val_total_wh.value.toFixed(2),
                  [this.dataShared.panel_devices[4].location]: data_4[i]?.val_total_wh.value.toFixed(2),
                  [this.dataShared.panel_devices[5].location]: data_5[i]?.val_total_wh.value.toFixed(2),
                  [this.dataShared.panel_devices[6].location]: data_6[i]?.val_total_wh.value.toFixed(2)
                })
              } else if (this.comparative_devices.length == 8) {
    
                this.generation_table_data.push({ 
                  date: new Date(date[i].key_as_string), 
                  value_1: data_0[i]?.val_total_wh.value, 
                  value_2: data_1[i]?.val_total_wh.value, 
                  value_3: data_2[i]?.val_total_wh.value, 
                  value_4: data_3[i]?.val_total_wh.value, 
                  value_5: data_4[i]?.val_total_wh.value, 
                  value_6: data_5[i]?.val_total_wh.value,
                  value_7: data_6[i]?.val_total_wh.value,
                  value_8: data_7[i]?.val_total_wh.value
                })
    
                this.generation_table_export_data.push({ 
                  date: new Date(date[i].key_as_string), 
                  [this.dataShared.panel_devices[0].location]: data_0[i]?.val_total_wh.value.toFixed(2), 
                  [this.dataShared.panel_devices[1].location]: data_1[i]?.val_total_wh.value.toFixed(2),
                  [this.dataShared.panel_devices[2].location]: data_2[i]?.val_total_wh.value.toFixed(2),
                  [this.dataShared.panel_devices[3].location]: data_3[i]?.val_total_wh.value.toFixed(2),
                  [this.dataShared.panel_devices[4].location]: data_4[i]?.val_total_wh.value.toFixed(2),
                  [this.dataShared.panel_devices[5].location]: data_5[i]?.val_total_wh.value.toFixed(2),
                  [this.dataShared.panel_devices[6].location]: data_6[i]?.val_total_wh.value.toFixed(2),
                  [this.dataShared.panel_devices[7].location]: data_7[i]?.val_total_wh.value.toFixed(2)
                })
              }
          }
  
          if (this.comparative_devices.length == 2) {
            this.diff.push({
              diff_1: this.generation_table_data[this.generation_table_data.length - 1].value_1 - this.generation_table_data[0].value_1,
              diff_2: this.generation_table_data[this.generation_table_data.length - 1].value_2 - this.generation_table_data[0].value_2,
              cost_1: (this.generation_table_data[this.generation_table_data.length - 1].value_1 - this.generation_table_data[0].value_1) * this.cost,
              cost_2: (this.generation_table_data[this.generation_table_data.length - 1].value_2 - this.generation_table_data[0].value_2) * this.cost,
              device_location_1: this.device_location[0].location, 
              device_location_2: this.device_location[1].location
            });
            this.diff_sum = this.diff[0].diff_1 + this.diff[0].diff_2;
            this.cost_sum = this.diff[0].cost_1 + this.diff[0].cost_2;
          } else if (this.comparative_devices.length == 3) {
            this.diff.push({
              diff_1: this.generation_table_data[this.generation_table_data.length - 1].value_1 - this.generation_table_data[0].value_1,
              diff_2: this.generation_table_data[this.generation_table_data.length - 1].value_2 - this.generation_table_data[0].value_2,
              diff_3: this.generation_table_data[this.generation_table_data.length - 1].value_3 - this.generation_table_data[0].value_3,
              cost_1: (this.generation_table_data[this.generation_table_data.length - 1].value_1 - this.generation_table_data[0].value_1) * this.cost,
              cost_2: (this.generation_table_data[this.generation_table_data.length - 1].value_2 - this.generation_table_data[0].value_2) * this.cost,
              cost_3: (this.generation_table_data[this.generation_table_data.length - 1].value_3 - this.generation_table_data[0].value_3) * this.cost,
              device_location_1: this.device_location[0].location, 
              device_location_2: this.device_location[1].location,
              device_location_3: this.device_location[2].location
            });
            this.diff_sum = this.diff[0].diff_1 + this.diff[0].diff_2 + this.diff[0].diff_3;
            this.cost_sum = this.diff[0].cost_1 + this.diff[0].cost_2 + this.diff[0].cost_3;
          } else if (this.comparative_devices.length == 4) {
            this.diff.push({
              diff_2: this.generation_table_data[this.generation_table_data.length - 1].value_2 - this.generation_table_data[0].value_2,
              diff_1: this.generation_table_data[this.generation_table_data.length - 1].value_1 - this.generation_table_data[0].value_1,
              diff_3: this.generation_table_data[this.generation_table_data.length - 1].value_3 - this.generation_table_data[0].value_3,
              diff_4: this.generation_table_data[this.generation_table_data.length - 1].value_4 - this.generation_table_data[0].value_4,
              cost_1: (this.generation_table_data[this.generation_table_data.length - 1].value_1 - this.generation_table_data[0].value_1) * this.cost,
              cost_2: (this.generation_table_data[this.generation_table_data.length - 1].value_2 - this.generation_table_data[0].value_2) * this.cost,
              cost_3: (this.generation_table_data[this.generation_table_data.length - 1].value_3 - this.generation_table_data[0].value_3) * this.cost,
              cost_4: (this.generation_table_data[this.generation_table_data.length - 1].value_4 - this.generation_table_data[0].value_4) * this.cost,
              device_location_1: this.device_location[0].location, 
              device_location_2: this.device_location[1].location,
              device_location_3: this.device_location[2].location, 
              device_location_4: this.device_location[3].location,
            })
            this.diff_sum = this.diff[0].diff_1 + this.diff[0].diff_2 + this.diff[0].diff_3 + this.diff[0].diff_4;
            this.cost_sum = this.diff[0].cost_1 + this.diff[0].cost_2 + this.diff[0].cost_3 + this.diff[0].cost_4;
          } else if (this.comparative_devices.length == 5) {
            this.diff.push({
              diff_1: this.generation_table_data[this.generation_table_data.length - 1].value_1 - this.generation_table_data[0].value_1,
              diff_2: this.generation_table_data[this.generation_table_data.length - 1].value_2 - this.generation_table_data[0].value_2,
              diff_3: this.generation_table_data[this.generation_table_data.length - 1].value_3 - this.generation_table_data[0].value_3,
              diff_4: this.generation_table_data[this.generation_table_data.length - 1].value_4 - this.generation_table_data[0].value_4,
              diff_5: this.generation_table_data[this.generation_table_data.length - 1].value_5 - this.generation_table_data[0].value_5,
              cost_1: (this.generation_table_data[this.generation_table_data.length - 1].value_1 - this.generation_table_data[0].value_1) * this.cost,
              cost_2: (this.generation_table_data[this.generation_table_data.length - 1].value_2 - this.generation_table_data[0].value_2) * this.cost,
              cost_3: (this.generation_table_data[this.generation_table_data.length - 1].value_3 - this.generation_table_data[0].value_3) * this.cost,
              cost_4: (this.generation_table_data[this.generation_table_data.length - 1].value_4 - this.generation_table_data[0].value_4) * this.cost,
              cost_5: (this.generation_table_data[this.generation_table_data.length - 1].value_5 - this.generation_table_data[0].value_5) * this.cost,
              device_location_1: this.device_location[0].location, 
              device_location_2: this.device_location[1].location,
              device_location_3: this.device_location[2].location, 
              device_location_4: this.device_location[3].location,
              device_location_5: this.device_location[4].location
            });
            this.diff_sum = this.diff[0].diff_1 + this.diff[0].diff_2 + this.diff[0].diff_3 + this.diff[0].diff_4 + this.diff[0].diff_5
            this.cost_sum = this.diff[0].cost_1 + this.diff[0].cost_2 + this.diff[0].cost_3 + this.diff[0].cost_4 + this.diff[0].cost_5
          } else if (this.comparative_devices.length == 6) {
            this.diff.push({
              diff_1: this.generation_table_data[this.generation_table_data.length - 1].value_1 - this.generation_table_data[0].value_1,
              diff_2: this.generation_table_data[this.generation_table_data.length - 1].value_2 - this.generation_table_data[0].value_2,
              diff_3: this.generation_table_data[this.generation_table_data.length - 1].value_3 - this.generation_table_data[0].value_3,
              diff_4: this.generation_table_data[this.generation_table_data.length - 1].value_4 - this.generation_table_data[0].value_4,
              diff_5: this.generation_table_data[this.generation_table_data.length - 1].value_5 - this.generation_table_data[0].value_5,
              diff_6: this.generation_table_data[this.generation_table_data.length - 1].value_6 - this.generation_table_data[0].value_6,
              cost_1: (this.generation_table_data[this.generation_table_data.length - 1].value_1 - this.generation_table_data[0].value_1) * this.cost,
              cost_2: (this.generation_table_data[this.generation_table_data.length - 1].value_2 - this.generation_table_data[0].value_2) * this.cost,
              cost_3: (this.generation_table_data[this.generation_table_data.length - 1].value_3 - this.generation_table_data[0].value_3) * this.cost,
              cost_4: (this.generation_table_data[this.generation_table_data.length - 1].value_4 - this.generation_table_data[0].value_4) * this.cost,
              cost_5: (this.generation_table_data[this.generation_table_data.length - 1].value_5 - this.generation_table_data[0].value_5) * this.cost,
              cost_6: (this.generation_table_data[this.generation_table_data.length - 1].value_6 - this.generation_table_data[0].value_6) * this.cost,
              device_location_1: this.device_location[0].location, 
              device_location_2: this.device_location[1].location,
              device_location_3: this.device_location[2].location, 
              device_location_4: this.device_location[3].location,
              device_location_5: this.device_location[4].location, 
              device_location_6: this.device_location[5].location,
            });
            this.diff_sum = this.diff[0].diff_1 + this.diff[0].diff_2 + this.diff[0].diff_3 + this.diff[0].diff_4 + this.diff[0].diff_5 + this.diff[0].diff_6
            this.cost_sum = this.diff[0].cost_1 + this.diff[0].cost_2 + this.diff[0].cost_3 + this.diff[0].cost_4 + this.diff[0].cost_5 + this.diff[0].cost_6
          } else if (this.comparative_devices.length == 7) {
            this.diff.push({
              diff_1: this.generation_table_data[this.generation_table_data.length - 1].value_1 - this.generation_table_data[0].value_1,
              diff_2: this.generation_table_data[this.generation_table_data.length - 1].value_2 - this.generation_table_data[0].value_2,
              diff_3: this.generation_table_data[this.generation_table_data.length - 1].value_3 - this.generation_table_data[0].value_3,
              diff_4: this.generation_table_data[this.generation_table_data.length - 1].value_4 - this.generation_table_data[0].value_4,
              diff_5: this.generation_table_data[this.generation_table_data.length - 1].value_5 - this.generation_table_data[0].value_5,
              diff_6: this.generation_table_data[this.generation_table_data.length - 1].value_6 - this.generation_table_data[0].value_6,
              diff_7: this.generation_table_data[this.generation_table_data.length - 1].value_7 - this.generation_table_data[0].value_7,
              cost_1: (this.generation_table_data[this.generation_table_data.length - 1].value_1 - this.generation_table_data[0].value_1) * this.cost,
              cost_2: (this.generation_table_data[this.generation_table_data.length - 1].value_2 - this.generation_table_data[0].value_2) * this.cost,
              cost_3: (this.generation_table_data[this.generation_table_data.length - 1].value_3 - this.generation_table_data[0].value_3) * this.cost,
              cost_4: (this.generation_table_data[this.generation_table_data.length - 1].value_4 - this.generation_table_data[0].value_4) * this.cost,
              cost_5: (this.generation_table_data[this.generation_table_data.length - 1].value_5 - this.generation_table_data[0].value_5) * this.cost,
              cost_6: (this.generation_table_data[this.generation_table_data.length - 1].value_6 - this.generation_table_data[0].value_6) * this.cost,
              cost_7: (this.generation_table_data[this.generation_table_data.length - 1].value_7 - this.generation_table_data[0].value_7) * this.cost,
              device_location_1: this.device_location[0].location, 
              device_location_2: this.device_location[1].location,
              device_location_3: this.device_location[2].location, 
              device_location_4: this.device_location[3].location,
              device_location_5: this.device_location[4].location, 
              device_location_6: this.device_location[5].location,
              device_location_7: this.device_location[6].location
            });
            this.diff_sum = this.diff[0].diff_1 + this.diff[0].diff_2 + this.diff[0].diff_3 + this.diff[0].diff_4 + this.diff[0].diff_5 + this.diff[0].diff_6 + this.diff[0].diff_7
            this.cost_sum = this.diff[0].cost_1 + this.diff[0].cost_2 + this.diff[0].cost_3 + this.diff[0].cost_4 + this.diff[0].cost_5 + this.diff[0].cost_6 + this.diff[0].cost_7
          } else if (this.comparative_devices.length == 8) {
            this.diff.push({
              diff_1: this.generation_table_data[this.generation_table_data.length - 1].value_1 - this.generation_table_data[0].value_1,
              diff_2: this.generation_table_data[this.generation_table_data.length - 1].value_2 - this.generation_table_data[0].value_2,
              diff_3: this.generation_table_data[this.generation_table_data.length - 1].value_3 - this.generation_table_data[0].value_3,
              diff_4: this.generation_table_data[this.generation_table_data.length - 1].value_4 - this.generation_table_data[0].value_4,
              diff_5: this.generation_table_data[this.generation_table_data.length - 1].value_5 - this.generation_table_data[0].value_5,
              diff_6: this.generation_table_data[this.generation_table_data.length - 1].value_6 - this.generation_table_data[0].value_6,
              diff_7: this.generation_table_data[this.generation_table_data.length - 1].value_7 - this.generation_table_data[0].value_7,
              diff_8: this.generation_table_data[this.generation_table_data.length - 1].value_8 - this.generation_table_data[0].value_8,
              cost_1: (this.generation_table_data[this.generation_table_data.length - 1].value_1 - this.generation_table_data[0].value_1) * this.cost,
              cost_2: (this.generation_table_data[this.generation_table_data.length - 1].value_2 - this.generation_table_data[0].value_2) * this.cost,
              cost_3: (this.generation_table_data[this.generation_table_data.length - 1].value_3 - this.generation_table_data[0].value_3) * this.cost,
              cost_4: (this.generation_table_data[this.generation_table_data.length - 1].value_4 - this.generation_table_data[0].value_4) * this.cost,
              cost_5: (this.generation_table_data[this.generation_table_data.length - 1].value_5 - this.generation_table_data[0].value_5) * this.cost,
              cost_6: (this.generation_table_data[this.generation_table_data.length - 1].value_6 - this.generation_table_data[0].value_6) * this.cost,
              cost_7: (this.generation_table_data[this.generation_table_data.length - 1].value_7 - this.generation_table_data[0].value_7) * this.cost,
              cost_8: (this.generation_table_data[this.generation_table_data.length - 1].value_8 - this.generation_table_data[0].value_8) * this.cost,
              device_location_1: this.device_location[0].location, 
              device_location_2: this.device_location[1].location,
              device_location_3: this.device_location[2].location, 
              device_location_4: this.device_location[3].location,
              device_location_5: this.device_location[4].location, 
              device_location_6: this.device_location[5].location,
              device_location_7: this.device_location[6].location,
              device_location_8: this.device_location[7].location
            });
            this.diff_sum = this.diff[0].diff_1 + this.diff[0].diff_2 + this.diff[0].diff_3 + this.diff[0].diff_4 + this.diff[0].diff_5 + this.diff[0].diff_6 + this.diff[0].diff_7 + this.diff[0].diff_8;
            this.cost_sum = this.diff[0].cost_1 + this.diff[0].cost_2 + this.diff[0].cost_3 + this.diff[0].cost_4 + this.diff[0].cost_5 + this.diff[0].cost_6 + this.diff[0].cost_7 + this.diff[0].cost_8;
          }
  
          for (let index = 1; index <= this.comparative_devices.length; index++ ) {
            this.chart_data.push({
              date_from: this.generation_tab_from_date_selection,
              date_to: this.generation_tab_to_date_selection,
              device: this.diff[0]['device_location_' + index],
              energy_produce: this.diff[0]['diff_' + index].toFixed(2) 
            })
          }
      }

      let prop = this.getGraphProp('pv_generation', this.chart_data);
      this.implementCurvedColumnsChart(prop);
    })

  }

  implementCurvedColumnsChart(data) {

    am4core.useTheme(am4themes_animated);

    let chart = am4core.create(data.element, am4charts.XYChart);
    chart.paddingRight = 20;
    chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect
    chart.data = data.data;
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.items = [{
      "label": "...",
      "menu": [
        { "type": "png", "label": "Image" }
      
      ]
    }];
    chart.exporting.menu.align = "left";
    chart.exporting.menu.verticalAlign = "top";

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "device";  
    categoryAxis.renderer.minGridDistance = 40;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    let series = chart.series.push(new am4charts.CurvedColumnSeries());
    series.dataFields.categoryX = "device";
    series.dataFields.valueY = "energy_produce";
    series.tooltipText = "{valueY.energy_produce}"  
    
    
    series.columns.template.strokeOpacity = 0;

    series.columns.template.fillOpacity = 0.75;

    let hoverState = series.columns.template.states.create("hover");
    hoverState.properties.fillOpacity = 1;
    hoverState.properties.tension = 0.4;

    chart.cursor = new am4charts.XYCursor();

    // Add distinctive colors for each column using adapter
    series.columns.template.adapter.add("fill", function (fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });

  }

  exportAsCSV() {
    let data = [];
    if (data = this.getDataByParam()) this.dataShared.exportDataAsCsvFormat(data);
  }

  getDataByParam() {

    if (this.data_param == 'power' && this.pv_power_chart_active == 'bar') return this.param_bar_data;
    else if (this.data_param == 'power' && this.pv_power_chart_active == 'bar-variance') return this.param_bar_variance_data;
    else if (this.data_param == 'power' && this.params.length == 0 && this.pv_power_chart_active == 'bar') return this.pv_power_bar_data;
    else if (this.data_param == 'power' && this.params.length == 0 && this.pv_power_chart_active == 'bar-variance') return this.pv_power_bar_variance_data;
    else if (this.data_param == 'energy' && this.pv_energy_chart_active == 'bar') return this.param_bar_data;
    else if (this.data_param == 'energy' && this.pv_energy_chart_active == 'bar-variance') return this.param_bar_variance_data;
    else if (this.data_param == 'energy' && this.params.length == 0 && this.pv_energy_chart_active == 'bar') return this.pv_energy_bar_data;
    else if (this.data_param == 'energy' && this.params.length == 0 && this.pv_energy_chart_active == 'bar-variance') return this.pv_energy_bar_variance_data;
    else if (this.data_param == 'current' && this.pv_current_chart_active == 'bar') return this.param_bar_data;
    else if (this.data_param == 'current' && this.pv_current_chart_active == 'bar-variance') return this.param_bar_variance_data;
    else if (this.data_param == 'current' && this.params.length == 0 && this.pv_current_chart_active == 'bar') return this.pv_current_bar_data;
    else if (this.data_param == 'current' && this.params.length == 0 && this.pv_current_chart_active == 'bar-variance') return this.pv_current_bar_variance_data;
    else if (this.data_param == 'voltage' && this.pv_voltage_chart_active == 'bar') return this.param_bar_data;
    else if (this.data_param == 'voltage' && this.pv_voltage_chart_active == 'bar-variance') return this.param_bar_variance_data;
    else if (this.data_param == 'voltage' && this.params.length == 0 && this.pv_voltage_chart_active == 'bar') return this.pv_voltage_bar_data;
    else if (this.data_param == 'voltage' && this.params.length == 0 && this.pv_voltage_chart_active == 'bar-variance') return this.pv_voltage_bar_variance_data;
    else if (this.data_param == 'comparative') return this.tabular_export_data;
    else if (this.data_param == 'comparative_table') return this.tabular_export_data;
    else if (this.data_param == 'table') return this.table_data;
    else if (this.data_param == 'generation-table') return this.generation_table_export_data;
    else if (this.data_param == 'generation-table-chart') return this.chart_data;
  }

  exportAsPDF(e) {
    let data = [];
    if (data = this.getDataByParam()) this.dataShared.exportDataAsPdfFormat(data, e, this.data_param_el);
  }
  pageSelection(e) {

    if (e == 'prev_page') {
      this.page = (this.page == 1) ? 1 : parseInt(this.page.toString()) - 1;
    }

    if (e == 'curr_page') {
      this.page = parseInt(this.page.toString());
    }

    if (e == 'next_page') {
      this.page = (this.pages.length == this.page) ? this.page : parseInt((this.page).toString()) + 1;      
    }
  }
}