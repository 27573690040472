<div id="main-wrapper">

  <!-- Left Sidebar - style you can find in sidebar.scss  -->

  <aside>
    <app-sidebar></app-sidebar>
  </aside>

  <!-- End Left Sidebar - style you can find in sidebar.scss  -->

  <!-- Page wrapper  -->

  <!-- Start right Content -->
  <div class="content-page">
    <!-- Start content -->
    <div class="content">
      <!-- Start page content wrapper -->
      <div class="page-content-wrapper">
        <div class="container-fluid">

          <div class="row">

            <div class="col-lg-12">
              <div id="content-body" class="col-12 col-md-12 col-xl-12 pl-4 pr-4 bd-content">
                <div class="row">
                  <div class="col-md-12 text-right mt-3">

                    <div class="user-name justify-content-end d-flex align-items-center">
                      <img class="mr-3 profile-img" src="./assets/media/icons/profile.svg">
                      <a class="text-midnight mr-3 mb-0" href="javascript:void(0);"
                        [routerLink]="(['/profile'])">{{name}}</a>

                      <hr class="divider" />
                      <a class="text-center ml-3 text-midnight" href="javascript:void(0);"
                        (click)="signOut()">Logout</a>

                    </div>
                  </div>
                </div>

                <!-- <app-dashboard></app-dashboard> -->
                <router-outlet></router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Page content Wrapper -->
    </div>
    <!-- End content -->