import { Component, OnInit, Injectable, Renderer2, RendererFactory2, Input  } from '@angular/core';
import { DataSharedService } from 'src/app/shared/services/data-shared.service';
import { DashboardService } from 'src/app/shared/services/dashboard.service'; 
// amCharts imports
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

@Component({
  selector: 'app-amcharts',
  templateUrl: './amcharts.component.html',
  styleUrls: ['./amcharts.component.scss']
})

@Injectable({
  providedIn: 'root'
})

export class AmchartsComponent implements OnInit {
  @Input() for: string;

  private chart: am4charts.XYChart;
  private renderer: Renderer2;
  public unit = '';

  constructor(rendererFactory: RendererFactory2, private dataShared: DataSharedService, private dashboardService: DashboardService) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  ngOnInit(): void {
  }

  getGraphProp(param_data, series) {
    let data = {
      element: '',
      title: '',
      data: param_data,
      series: series,
      tooltipFormat: 'HH:mm:ss, d MMMM',
      xScrollbar: false
    };

    if (this.dataShared.fixed_interval == '1s') {
      data['interval'] = {
        'interval': 'second',
        'value': 1
      }
    } else if (this.dataShared.fixed_interval == '1m') {
      data['interval'] = {
        'interval': 'minute',
        'value': 1
      }
    } else if (this.dataShared.fixed_interval == '5m') {
      data['interval'] = {
        'interval': 'minute',
        'value': 5
      }
    } else if (this.dataShared.fixed_interval == '15m') {
      data['interval'] = {
        'interval': 'minute',
        'value': 15
      }
    } else if (this.dataShared.fixed_interval == '30m') {
      data['interval'] = {
        'interval': 'minute',
        'value': 30
      }
    } else if (this.dataShared.fixed_interval == '1h') {
      data['interval'] = {
        'interval': 'hour',
        'value': 1
      }
    }
    data.element = 'comparativechartdiv'
    // if (this.for == 'comparative') { 
    //   data.element = 'comparativechartdiv'
    // } else if (this.for == 'pie') {
    //   data.element = 'piechartdiv'
    // }
    return data;
  }

  implementComparativeChart(data, params, units) {
 
    am4core.useTheme(am4themes_animated);

    this.chart = am4core.create(data.element, am4charts.XYChart);
    this.chart.data = data.data;
    this.chart.colors.step = 2;

    // Create axes
    let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.adapter.remove("getTime");

    // Create series
    if (data.data[0]) {
      if (data.data[0].avg == undefined) {
        for (let i = 0; i < params.length; i++) {
        
          if (i % 2) {
            this.createAxisAndSeries("value_" + i, "date_" + i, this.dataShared.paramters_list[params[i]], true, data.series, units[i]);
          } else {  
            this.createAxisAndSeries("value_" + i, "date_" + i, this.dataShared.paramters_list[params[i]], false, data.series, units[i]);
          }
        }
      } else {
        this.createAxisAndSeries("avg", "date", 'Avg ' + this.dataShared.paramters_list[params[0]], false, data.series, units[0]);
        this.createAxisAndSeries("min", "date", 'Min ' + this.dataShared.paramters_list[params[0]], false, data.series, units[0]);
        this.createAxisAndSeries("max", "date", 'Max ' + this.dataShared.paramters_list[params[0]], false, data.series, units[0]);
      }
    }
  

    // Add legend
    this.chart.legend = new am4charts.Legend();
    // Add cursor
    this.chart.cursor = new am4charts.XYCursor();
  }

  createAxisAndSeries(field, date, name, opposite, type, unit) {

    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());

    let series_type;
    if (type == 'line') series_type = new am4charts.LineSeries();
    if (type == 'bar') series_type = new am4charts.ColumnSeries();
    
    // let units = unit;
    let series = this.chart.series.push(series_type);
    series.dataFields.valueY = field;
    series.dataFields.dateX = date;
    series.strokeWidth = 2;
    series.yAxis = valueAxis;
    series.name = name;
    series['unit'] = unit;
    series.tooltipText = "{name}: [bold]{valueY}[/] {unit}";
    if (type == 'line') series.tensionX = 0.8;
    series.showOnInit = true;

    // let interfaceColors = new am4core.InterfaceColorSet(); 

    let scrollbarX = new am4core.Scrollbar();
    scrollbarX.marginBottom = 20;
    this.chart.scrollbarX = scrollbarX;

    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.line.strokeWidth = 2;
    valueAxis.renderer.line.stroke = series.stroke;
    valueAxis.renderer.labels.template.fill = series.stroke;
    valueAxis.renderer.opposite = opposite;

  }

  implementPieChart(data) {

    am4core.useTheme(am4themes_animated);

    let chart = am4core.create(data.element, am4charts.PieChart);
    chart.data = data.data;

    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "litres";
    pieSeries.dataFields.category = "country";
    pieSeries.labels.template.text = "";
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.fillOpacity = 100;
    pieSeries.strokeWidth = 0;

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.slices.template.cornerRadius = 5;

    chart.hiddenState.properties.radius = am4core.percent(0);
  }
}