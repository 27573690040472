<h2 class="mt-5">Device Data Status</h2>

<hr/><br/>

<h5 class="mb-3">Accounts<span class="required-star"> *</span></h5>

<ng-multiselect-dropdown [placeholder]="'Select Account'" [settings]="DropdownSettings" [data]="accounts"
    [(ngModel)]="account" [ngModelOptions]="{standalone: true}" (onSelect)="onItemSelect()"
    (onDeSelect)="onDeSelect()">
</ng-multiselect-dropdown>

<small class="help-text">Select account for device</small>

<div *ngIf="device_list">

    <div *ngFor="let data of account">
        <h4 class="mt-5 mb-0" >{{ data.name }} ( {{ data.allocated_memory }} / {{ data.allow_disk_space }}GB )</h4>
        
        <br> <hr>

        <div *ngIf="devices.length > 0">

            <div style="justify-content: space-evenly;" class="row">

                <div class="card_customer card border-primary col-sm-12 mt-4 mb-2 ml-2 mr-2" style="max-width: 26em"
                    *ngFor="let device of data.devices">
                    <span class="device-status" [ngClass]="(device.device_status) ? 'active' : (device.device_status === false) ? 'warning' : 'inactive'"></span>
							<!-- <span class="device-status" :class="(device.device_status) ? 'active' : (device.device_status === false) ? 'warning' : 'inactive'"></span>														 -->

                    <div class="card-header bg-transparent border-primary">
                        <h5>{{ device.location }}</h5>
                    </div>
                    <div class="card-body">
                        <ul>
                            <li> {{ device.device_id }} </li>
                            <li> {{ device.name }} </li>
                            <li *ngIf="device.timestamp != ''">{{ device.timestamp }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div>
    <button (click)="getData()">Get Device Status</button>
    <button style="margin-right: 10px;" (click)="clearData()">Clear</button>
</div>