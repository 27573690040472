import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConfigurationComponent } from './configuration/configuration.component';
import { NewUserComponent } from './new-user/new-user.component';
import { UserSettingComponent } from './user-setting/user-setting.component';
import { ParamDuplicationModuleComponent } from './param-duplication-module/param-duplication-module.component';
import { DeviceDataStatusComponent } from './device-data-status/device-data-status.component';

const routes: Routes = [
  // { path: '' },
  { path: 'configuration', component: ConfigurationComponent },
  { path: 'new-user', component: NewUserComponent },
  { path: 'user-setting', component: UserSettingComponent },
  { path: 'param-duplication-module', component: ParamDuplicationModuleComponent },
  { path: 'device-data-status', component: DeviceDataStatusComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerManagementRoutingModule { }
