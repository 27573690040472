<ngb-tabset #t="ngbTabset" id="button-management-panel" (tabChange)="tabChange($event)" #tabset>

    <ngb-tab id="tab-button" title="Button">
      <ng-template ngbTabContent>
        <div class="row">
  
          <div class="col-12 col-xl-12 stretch-card">
            <div class="row">
  
              <div class="card-row-adjust col-sm-12 col-lg grid-margin stretch-card mt-4" style="min-height: 139px;"
                *ngFor="let device of devices; let i = index">
  
                <div class="card bg-light border-{{ device.card_border }} mb-3">
  
                  <div class="card-header border-{{ device.card_border }} d-inline-flex justify-content-between">
                    <h5 class="checkingState" id="{{ device.checkingState }}"> Please wait while checking state.... </h5>
                    <h5> {{device.location}} </h5>
                    <li class="nav-item d-inline-flex">
                      <span style="border-right: 1px solid #ff300b !important;" class="divider"></span>
                      <span class="ml-2 device-status" style="top: 25%;" [ngClass]="device.status" ></span>
                  </li>
                  </div>
  
                  <div (mouseenter)="mouseEvHandler(true, device.location, device.id)"
                    (mouseleave)="mouseEvHandler(false, device.location, device.id)" id="{{ device.card }}"
                    class="row justify-content-between card-body">
                      <!-- Pincode -->
                      <div class="l-0 t-30 h-70 position-absolute w-100 border-danger bg-secondary" id="{{ device.id }}"
                        style="display: none; opacity: 80%;">
                
                        <div class="d-flex align-items-center justify-content-center w-100">
                        <h4 class="text-white">Enter Pin :</h4>
                        <input class="shadow-sm display-4 text-dark rounded h-25 w-30 bg-white" onfocus="this.value=''" (keyup)="pincode($event, device.id, device.location)"
                          type="password">
                          </div>
                      </div>
                      <app-countdown *ngIf="device.count_status"style="margin-top:40px; margin-left: 200px; position: absolute; z-index:1;"></app-countdown>
                      <div class="w-100 row justify-content-between" id="{{ device.location }}">
  
                        <!-- switch toggle state -->
                        <div class="col-1 switch-toggle">
                          <input type="checkbox" id="{{ device.device_id }}" [(ngModel)]="device.toggle_status"
                            (change)="onChangeButtonSate($event, device.device_id, device.topic)">
                          <label for="{{ device.device_id }}"></label>
                        </div>
  
                        <!-- scheduling trigger modal -->
                        <button class="scheduling col-1 border-0 bg-transparent {{device.visibility}}"
                          (click)="openModal(sch_model, device.device_id, '', '')">
                          <i style="margin-left:-20px;" class="theme-icon-4 scheduling-icon"></i>
                        </button>
  
                        <!-- Scheduling modal -->
                        <ng-template #sch_model let-modal>
  
                          <div class="modal-header">
                            <h4 class="modal-title">{{device.location}}</h4>
  
                            <button type="button" class="close" aria-label="Close"
                              (click)="modal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>
  
                          </div>
  
                          <div class="modal-body">
  
                            <table class="table">
                              <thead>
                                <tr>
                                  <th style="font-size: 16px !important; text-align: center;">Days</th>
                                  <th style="font-size: 16px !important; text-align: center;">Schedule</th>
                                  <th style="font-size: 16px !important; text-align: center;">On</th>
                                  <th style="font-size: 16px !important; text-align: center;">Off</th>
                                  <th style="font-size: 16px !important;"
                                    *ngIf="device.power_button_parameter != '' && device.power_button_parameter != null">
                                    {{ device.power_button_parameter }}</th>
                                  <th style="font-size: 16px !important;">Action</th>
                                </tr>
                              </thead>
                              <tbody>
  
                                <!-- mon  -->
                                <tr>
  
                                  <td style="font-size: 16px !important;" class="text-center">Monday</td>
  
                                  <td>
                                    <select [(ngModel)]="sch.mon.model"
                                      (change)="onChangeScheduling('mon', sch.mon.model, 'model', $event)">
                                      <option value="" disabled>Select Schedule</option>
                                      <option value="t1_">Schedule 1</option>
                                      <option value="t2_">Schedule 2</option>
                                      <option value="t3_">Schedule 3</option>
                                    </select>
                                  </td>
  
                                  <!-- on  -->
                                  <td class="text-center"><input type="time" [(ngModel)]="sch.mon.on"
                                      value="{{sch.mon.on}}"
                                      (change)="onChangeScheduling('mon', sch.mon.model, 'on', $event)" /></td>
  
                                  <!-- off  -->
                                  <td class="text-center"><input type="time" [(ngModel)]="sch.mon.off"
                                      value="{{sch.mon.off}}"
                                      (change)="onChangeScheduling('mon', sch.mon.model, 'off', $event)" /></td>
  
                                  <!-- param -->
                                  <td
                                    *ngIf="device.power_button_parameter != '' && device.power_button_parameter != null">
                                    <div class="input-group mb-1 mt-1" style="max-width: 110px;">
                                      <input type="number" class="form-control" placeholder="Param"
                                        [(ngModel)]="sch.mon.param" value="{{sch.mon.param}}"
                                        (change)="onChangeScheduling('mon', sch.mon.model, 'param', $event)">
                                    </div>
                                  </td>
  
                                  <td>
                                    <!-- clear button -->
                                    <button class="border-0  btn btn-warning"
                                      (click)="openModal(sch_clear, device.device_id, 'Monday', sch.mon.model)">
                                      Clear
                                    </button>
                                  </td>
  
                                </tr>
  
                                <!-- tue  -->
                                <tr>
  
                                  <td style="font-size: 16px !important;" class="text-center">Tuesday</td>
  
                                  <td>
                                    <select [(ngModel)]="sch.tue.model"
                                      (change)="onChangeScheduling('tue', sch.tue.model, 'model', $event)">
                                      <option value="" disabled>Select Schedule</option>
                                      <option value="t1_">Schedule 1</option>
                                      <option value="t2_">Schedule 2</option>
                                      <option value="t3_">Schedule 3</option>
                                    </select>
                                  </td>
  
                                  <!-- on  -->
                                  <td class="text-center"><input type="time" [(ngModel)]="sch.tue.on"
                                      value="{{sch.tue.on}}"
                                      (change)="onChangeScheduling('tue', sch.tue.model, 'on', $event)" /></td>
  
                                  <!-- off  -->
                                  <td class="text-center"><input type="time" [(ngModel)]="sch.tue.off"
                                      value="{{sch.tue.off}}"
                                      (change)="onChangeScheduling('tue', sch.tue.model, 'off', $event)" /></td>
  
                                  <!-- param -->
                                  <td
                                    *ngIf="device.power_button_parameter != '' && device.power_button_parameter != null">
                                    <div class="input-group mb-1 mt-1" style="max-width: 110px;">
                                      <input type="number" class="form-control" placeholder="Param"
                                        [(ngModel)]="sch.tue.param" value="{{sch.tue.param}}"
                                        (change)="onChangeScheduling('tue', sch.tue.model, 'param', $event)">
                                    </div>
                                  </td>
  
                                  <td>
                                    <!-- clear button -->
                                    <button class="border-0  btn btn-warning"
                                      (click)="openModal(sch_clear, device.device_id, 'Tuesday', sch.tue.model)">
                                      Clear
                                    </button>
                                  </td>
  
                                </tr>
  
                                <!-- wed -->
                                <tr>
  
                                  <td style="font-size: 16px !important;" class="text-center">Wednesday
                                  </td>
  
                                  <td>
                                    <select [(ngModel)]="sch.wed.model"
                                      (change)="onChangeScheduling('wed', sch.wed.model, 'model', $event)">
                                      <option value="" disabled>Select Schedule</option>
                                      <option value="t1_">Schedule 1</option>
                                      <option value="t2_">Schedule 2</option>
                                      <option value="t3_">Schedule 3</option>
                                    </select>
                                  </td>
  
                                  <!-- on  -->
                                  <td class="text-center"><input type="time" [(ngModel)]="sch.wed.on"
                                      value="{{sch.wed.on}}"
                                      (change)="onChangeScheduling('wed', sch.wed.model, 'on', $event)" /></td>
  
                                  <!-- off  -->
                                  <td class="text-center"><input type="time" [(ngModel)]="sch.wed.off"
                                      value="{{sch.wed.off}}"
                                      (change)="onChangeScheduling('wed', sch.wed.model, 'off', $event)" /></td>
  
                                  <!-- param -->
                                  <td
                                    *ngIf="device.power_button_parameter != '' && device.power_button_parameter != null">
                                    <div class="input-group mb-1 mt-1" style="max-width: 110px;">
                                      <input type="number" class="form-control" placeholder="Param"
                                        [(ngModel)]="sch.wed.param" value="{{sch.wed.param}}"
                                        (change)="onChangeScheduling('wed', sch.wed.model, 'param', $event)">
                                    </div>
                                  </td>
  
                                  <td>
                                    <!-- clear button -->
                                    <button class="border-0  btn btn-warning"
                                      (click)="openModal(sch_clear, device.device_id, 'Wednesday', sch.wed.model)">
                                      Clear
                                    </button>
                                  </td>
  
                                </tr>
  
                                <!-- thu -->
                                <tr>
  
                                  <td style="font-size: 16px !important;" class="text-center">Thursday
                                  </td>
  
                                  <td>
                                    <select [(ngModel)]="sch.thu.model"
                                      (change)="onChangeScheduling('thu', sch.thu.model, 'model', $event)">
                                      <option value="" disabled>Select Schedule</option>
                                      <option value="t1_">Schedule 1</option>
                                      <option value="t2_">Schedule 2</option>
                                      <option value="t3_">Schedule 3</option>
                                    </select>
                                  </td>
  
                                  <!-- on  -->
                                  <td class="text-center"><input type="time" [(ngModel)]="sch.thu.on"
                                      value="{{sch.thu.on}}"
                                      (change)="onChangeScheduling('thu', sch.thu.model, 'on', $event)" /></td>
  
                                  <!-- off  -->
                                  <td class="text-center"><input type="time" [(ngModel)]="sch.thu.off"
                                      value="{{sch.thu.off}}"
                                      (change)="onChangeScheduling('thu', sch.thu.model, 'off', $event)" /></td>
  
                                  <!-- param -->
                                  <td
                                    *ngIf="device.power_button_parameter != '' && device.power_button_parameter != null">
                                    <div class="input-group mb-1 mt-1" style="max-width: 110px;">
                                      <input type="number" class="form-control" placeholder="Param"
                                        [(ngModel)]="sch.thu.param" value="{{sch.thu.param}}"
                                        (change)="onChangeScheduling('thu', sch.thu.model, 'param', $event)">
                                    </div>
                                  </td>
  
                                  <td>
                                    <!-- clear button -->
                                    <button class="border-0 btn btn-warning"
                                      (click)="openModal(sch_clear, device.device_id, 'Thursday', sch.thu.model)">
                                      Clear
                                    </button>
                                  </td>
  
                                </tr>
  
                                <!-- fri -->
                                <tr>
  
                                  <td style="font-size: 16px !important;" class="text-center">Friday</td>
  
                                  <td>
                                    <select [(ngModel)]="sch.fri.model"
                                      (change)="onChangeScheduling('fri', sch.fri.model, 'model', $event)">
                                      <option value="" disabled>Select Schedule</option>
                                      <option value="t1_">Schedule 1</option>
                                      <option value="t2_">Schedule 2</option>
                                      <option value="t3_">Schedule 3</option>
                                    </select>
                                  </td>
  
                                  <!-- on  -->
                                  <td class="text-center"><input type="time" [(ngModel)]="sch.fri.on"
                                      value="{{sch.fri.on}}"
                                      (change)="onChangeScheduling('fri', sch.fri.model, 'on', $event)" /></td>
  
                                  <!-- off  -->
                                  <td class="text-center"><input type="time" [(ngModel)]="sch.fri.off"
                                      value="{{sch.fri.off}}"
                                      (change)="onChangeScheduling('fri', sch.fri.model, 'off', $event)" /></td>
  
                                  <!-- param -->
                                  <td
                                    *ngIf="device.power_button_parameter != '' && device.power_button_parameter != null">
                                    <div class="input-group mb-1 mt-1" style="max-width: 110px;">
                                      <input type="number" class="form-control" placeholder="Param"
                                        [(ngModel)]="sch.fri.param" value="{{sch.fri.param}}"
                                        (change)="onChangeScheduling('fri', sch.fri.model, 'param', $event)">
                                    </div>
                                  </td>
  
                                  <td>
                                    <!-- clear button -->
                                    <button class="border-0  btn btn-warning"
                                      (click)="openModal(sch_clear, device.device_id, 'Friday', sch.fri.model)">
                                      Clear
                                    </button>
                                  </td>
  
                                </tr>
  
                                <!-- sat -->
                                <tr>
  
                                  <td style="font-size: 16px !important;" class="text-center">Saturday
                                  </td>
  
                                  <td>
                                    <select [(ngModel)]="sch.sat.model"
                                      (change)="onChangeScheduling('sat', sch.sat.model, 'model', $event)">
                                      <option value="" disabled>Select Schedule</option>
                                      <option value="t1_">Schedule 1</option>
                                      <option value="t2_">Schedule 2</option>
                                      <option value="t3_">Schedule 3</option>
                                    </select>
                                  </td>
  
                                  <!-- on  -->
                                  <td class="text-center"><input type="time" [(ngModel)]="sch.sat.on"
                                      value="{{sch.sat.on}}"
                                      (change)="onChangeScheduling('sat', sch.sat.model, 'on', $event)" /></td>
  
                                  <!-- off  -->
                                  <td class="text-center"><input type="time" [(ngModel)]="sch.sat.off"
                                      value="{{sch.sat.off}}"
                                      (change)="onChangeScheduling('sat', sch.sat.model, 'off', $event)" /></td>
  
                                  <!-- param -->
                                  <td
                                    *ngIf="device.power_button_parameter != '' && device.power_button_parameter != null">
                                    <div class="input-group mb-1 mt-1" style="max-width: 110px;">
                                      <input type="number" class="form-control" placeholder="Param"
                                        [(ngModel)]="sch.sat.param" value="{{sch.sat.param}}"
                                        (change)="onChangeScheduling('sat', sch.sat.model, 'param', $event)">
                                    </div>
                                  </td>
  
                                  <td>
                                    <!-- clear button -->
                                    <button class="border-0  btn btn-warning"
                                      (click)="openModal(sch_clear, device.device_id, 'Saturday', sch.sat.model)">
                                      Clear
                                    </button>
                                  </td>
  
                                </tr>
  
                                <!-- sun -->
                                <tr>
  
                                  <td style="font-size: 16px !important;" class="text-center">Sunday</td>
  
                                  <td>
                                    <select [(ngModel)]="sch.sun.model"
                                      (change)="onChangeScheduling('sun', sch.sun.model, 'model', $event)">
                                      <option value="" disabled>Select Schedule</option>
                                      <option value="t1_">Schedule 1</option>
                                      <option value="t2_">Schedule 2</option>
                                      <option value="t3_">Schedule 3</option>
                                    </select>
                                  </td>
  
                                  <!-- on  -->
                                  <td class="text-center"><input type="time" [(ngModel)]="sch.sun.on"
                                      value="{{sch.sun.on}}"
                                      (change)="onChangeScheduling('sun', sch.sun.model, 'on', $event)" /></td>
  
                                  <!-- off  -->
                                  <td class="text-center"><input type="time" [(ngModel)]="sch.sun.off"
                                      value="{{sch.sun.off}}"
                                      (change)="onChangeScheduling('sun', sch.sun.model, 'off', $event)" /></td>
  
                                  <!-- param -->
                                  <td
                                    *ngIf="device.power_button_parameter != '' && device.power_button_parameter != null">
                                    <div class="input-group mb-1 mt-1" style="max-width: 110px;">
                                      <input type="number" class="form-control" placeholder="Param"
                                        [(ngModel)]="sch.sun.param" value="{{sch.sun.param}}"
                                        (change)="onChangeScheduling('sun', sch.sun.model, 'param', $event)">
                                    </div>
                                  </td>
  
                                  <td>
                                    <!-- clear button -->
                                    <button class="border-0  btn btn-warning"
                                      (click)="openModal(sch_clear, device.device_id, 'Sunday', sch.sun.model)">
                                      Clear
                                    </button>
                                  </td>
  
                                </tr>
                              </tbody>
                            </table>
  
                            <h5>Last Updated : {{ last_updated }} </h5>
  
                          </div>
  
                          <div class="modal-footer">
  
                            <button type="button" class="btn btn-light" (click)="modal.close('Close click')">
                              Close
                            </button>
                            <button type="button" class="btn btn-primary" (click)="updateScduling(device.topic)">Update
                              changes</button>
                          </div>
  
                        </ng-template>
                        <!-- End modal  -->
  
                        <!-- Clear modal -->
                        <ng-template #sch_clear let-modal>
  
                          <div class="modal-header">
  
                            <h4 class="modal-title" class="text-center">{{ days }} - {{device.location}}</h4>
  
  
                            <button type="button" class="close" aria-label="Close"
                              (click)="modal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>
  
                          </div>
  
                          <div class="modal-body">
  
                            <h5 class="text-danger" class="text-center">Are you sure you want to clear "{{ days
                              }}" values?</h5>
  
                          </div>
  
                          <div class="modal-footer">
                            <button type="button" class="btn btn-light"
                              (click)="modal.dismiss('cancel click')">Cancel</button>
                            <button type="button" class="btn btn-danger"
                              (click)="clearModal(days, device.device_id)">Ok</button>
                          </div>
  
                        </ng-template>
                        <!-- End modal  -->
  
                        <!-- button -->
                        <label class="power justify-content-end">
                          <input id="{{ device.power_att }}" type="checkbox" [(ngModel)]="device.button_status"
                            (change)="onChangeButton($event, device.device_id, device.topic, device.power_button_parameter, device.power_att)">
                          <div>
                            <svg viewBox="0 0 44 44">
                              <path d="M22,6 C31,6 38,13 38,22 C38,31 31,38 22,38 C13,38 6,31 6,22 C6,13 13,6 22,6 L22,28"
                                id="path"></path>
                            </svg>
                          </div>
                        </label>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-tab>
  
  </ngb-tabset>