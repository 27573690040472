<ngb-tabset #t="ngbTabset" (tabChange)="tabChange($event)" #tabset>

    <ngb-tab *ngIf="tab_graph_status" id="tab-graph" title="Graph">
        <ng-template ngbTabContent>

            <div class="row mt-4">
                <div class="col-md-12 col-xl-12 grid-margin stretch-card" id="com_params">
                    <div class="d-grid row flex-grow">
                        <div class="col grid-margin stretch-card">
                            <div class="card">
                                <div class="d-flex justify-content-between card-header align-items-center mb-md-3">
                                    <h6 class="card-title mb-0 ">{{ (description)? description : 'Graph' }}</h6>

                                    <div class="header-right d-flex align-items-center">
                                        <div class="header-right-inner d-flex">
                                            <div class="d-flex align-items-center">

                                                <button class="btn pr-1" type="button" data-toggle="collapse"
                                                    data-target="#collapseExample" aria-expanded="false"
                                                    aria-controls="collapseExample">
                                                    <i class="feather-sliders"
                                                        style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                                                </button>

                                                <a href="javascript:void(0);" (click)="tabLinkChart('com_chart')">
                                                    <i class="icon-sm card-header-icon mr-1 ml-1 pb-3px feather feather-trending-up"
                                                        [ngClass]="{'active': chart_active == 'line'}"></i>
                                                </a>
                                                <span class="divider"></span>
                                                <a href="javascript:void(0);" (click)="tabBarChart('com_chart')">
                                                    <i class="icon-sm card-header-icon ml-1 pb-3px feather feather-bar-chart-2"
                                                        [ngClass]="{'active': chart_active == 'bar'}"></i>
                                                </a>
                                                <i class="icon-sm card-header-icon mr-2 pl-2 pt-1 pb-3px maximize-card feather feather-maximize-2"
                                                    (click)="toggleCard($event)"></i>
                                                <div class="dropdown ml-2">
                                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                                        data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                        <i
                                                            class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                                        <a class="dropdown-item d-flex align-items-center"
                                                            href="javascript:void(0);" name="Print"
                                                            (click)="data_param = 'com_chart'; data_param_el = 'com_params'; exportAsPDF($event)">
                                                            <i class="icon-sm mr-2 feather feather-printer"></i>
                                                            <span class="">Print</span>
                                                        </a>
                                                        <a class="dropdown-item d-flex align-items-center"
                                                            href="javascript:void(0);"
                                                            (click)="data_param = 'com_chart'; exportAsCSV()">
                                                            <i class="icon-sm mr-2 feather feather-download"></i>
                                                            <span class="">Export as CSV</span>
                                                        </a>
                                                        <a class="dropdown-item d-flex align-items-center"
                                                            href="javascript:void(0);" name="Pdf"
                                                            (click)="data_param = 'com_chart'; data_param_el = 'com_params'; exportAsPDF($event)">
                                                            <i class="icon-sm mr-2 feather feather-file-text"></i>
                                                            <span class="">Export as PDF</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                        <div class="collapse px-2" id="collapseExample">
                                            <div class="card-body overflow-auto">
                                                <div class="w-max-content d-inline-flex align-items-center">
                                                    <div class="form-group mr-2 mb-0">
                                                        <h6>Select Date:</h6>
                                                    </div>
                                                    <div class="form-group mb-0 mr-2">
                                                        <input type="date" [(ngModel)]="from_date_selection"
                                                            autocomplete="off" class="form-control"
                                                            (change)="onFromDateSelection($event)">
                                                    </div>
                                                    <span class="mr-2 from-to">-</span>
                                                    <div class="form-group mb-0 mr-4">
                                                        <input type="date" [(ngModel)]="date_selection"
                                                            autocomplete="off" class="form-control"
                                                            (change)="onDateSelection($event)">
                                                    </div>
                                                    <div class="form-group mb-0 mr-2">
                                                        <h6>Select Parameter:</h6>
                                                    </div>
                                                    <div class="header-inner-select">
                                                        <select class="mr-3" name="param" id="param" [(ngModel)]="param"
                                                            style="width: 300px">
                                                            <option value=''>Select Parameter</option>
                                                            <option *ngFor='let data of param_list'
                                                                [value]="data.parameter">{{
                                                                data.description }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="flot-wrapper">
                                                <div id="lineChart" style="width: 100%; height: 500px"></div>
                                            </div>
                                        </div>
                                    
                                
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
        </ng-template>
    </ngb-tab>

    <ngb-tab *ngIf="tab_table_status" id="tab-table" title="Table">
        <ng-template ngbTabContent>
            <div class="row mt-4">

                <div class="col-lg-12 col-xl-12 stretch-card" id="table-data">
                    <div class="d-grid row flex-grow">

                        <div class="col grid-margin stretch-card">
                            <div class="card" id="tab-table">
                                <div class="d-flex justify-content-between card-header align-items-center mb-md-3">
                                    <h6 class="card-title mb-0 ">{{ (description)? description : 'Table' }}</h6>

                                    <div class="d-flex align-items-center justify-content-end">

                                        <button class="btn ml-1 pr-2" type="button" data-toggle="collapse"
                                            data-target="#collapseExample" aria-expanded="false"
                                            aria-controls="collapseExample">
                                            <i class="feather-sliders"
                                                style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                                        </button>

                                        <div class="dropdown ml-2">
                                            <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i
                                                    class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);" name="Print"
                                                    (click)="data_param = 'table'; data_param_el = 'table-data'; exportAsPDF($event)">
                                                    <i class="icon-sm mr-2 feather feather-printer"></i>
                                                    <span class="">Print</span>
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);"
                                                    (click)="data_param = 'table'; exportAsCSV()">
                                                    <i class="icon-sm mr-2 feather feather-download"></i>
                                                    <span class="">Export as CSV</span>
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center"
                                                    href="javascript:void(0);" name="Pdf"
                                                    (click)="data_param = 'table'; data_param_el = 'table-data'; exportAsPDF($event)">
                                                    <i class="icon-sm mr-2 feather feather-file-text"></i>
                                                    <span class="">Export as PDF</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="collapse px-2" id="collapseExample">
                                    <div class="card-body overflow-auto">
                                        <div class="w-max-content d-inline-flex align-items-center">
                                            <div class="form-group mr-2 mb-0">
                                                <h6>Select Date:</h6>
                                            </div>
                                            <div class="form-group mb-0 mr-2">
                                                <input type="date" [(ngModel)]="table_from_date_selection"
                                                    autocomplete="off" class="form-control"
                                                    (change)="onFromDateSelection($event)">
                                            </div>
                                            <span class="mr-2 from-to">
                                                -
                                            </span>
                                            <div class="form-group mb-0 mr-4">
                                                <input type="date" [(ngModel)]="table_to_date_selection"
                                                    autocomplete="off" class="form-control"
                                                    (change)="onDateSelection($event)">

                                            </div>
                                            <div class="form-group mb-0 mr-2">
                                                <h6>Select Parameter:</h6>
                                            </div>
                                            <div class="form-group mb-0 mr-3">
                                                <select name="param" id="param" [(ngModel)]="param"
                                                    style="width: 300px">
                                                    <option value=''>Select Parameter</option>
                                                    <option *ngFor='let data of param_list' [value]="data.parameter">{{
                                                        data.description }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th *ngFor="let data of device_location" width="auto">{{
                                                        data.location }}</th>

                                                    <th width="auto">TIMESTAMP</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr
                                                    *ngFor="let data of comparative_table_data | slice: (page-1) * pageSize : page * pageSize">
                                                    <td>{{ (data.value_1) ? data.value_1.toFixed(2) : '0.00' }}</td>
                                                    <td>{{ (data.value_2)? data.value_2.toFixed(2) : '0.00' }}</td>
                                                    <td *ngIf="comparative_table_value_3">{{ (data.value_3)?
                                                        data.value_3.toFixed(2) : '0.00' }}</td>
                                                    <td *ngIf="comparative_table_value_4">{{ (data.value_4)?
                                                        data.value_4.toFixed(2) : '0.00' }}</td>
                                                    <td *ngIf="comparative_table_value_5">{{ (data.value_5)?
                                                        data.value_5.toFixed(2) : '0.00' }}</td>
                                                    <td *ngIf="comparative_table_value_6">{{ (data.value_6)?
                                                        data.value_6.toFixed(2) : '0.00' }}</td>
                                                    <td *ngIf="comparative_table_value_7">{{ (data.value_7)?
                                                        data.value_7.toFixed(2) : '0.00' }}</td>
                                                    <td *ngIf="comparative_table_value_8">{{ (data.value_8)?
                                                        data.value_8.toFixed(2) : '0.00' }}</td>
                                                    <td>{{ data.date | date: 'medium' }}</td>
                                                </tr>
                                                <!-- <tr>
                                                    <td>Total Energy </td>
                                                    <td>{{table_sum.toFixed(2)}} </td>
                                                </tr> -->

                                            </tbody>

                                        </table>

                                    </div>

                                    <br>
                                    <h5>Total Record: {{ comparative_table_data.length }}</h5>
                                    <ngb-pagination class="d-flex justify-content-end"
                                        [collectionSize]="comparative_table_data.length" [(page)]="page" [maxSize]="2"
                                        [rotate]="true" [pageSize]="pageSize" [boundaryLinks]="true">

                                    </ngb-pagination>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- row -->
        </ng-template>
    </ngb-tab>

</ngb-tabset>