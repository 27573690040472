import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../shared/services/dashboard.service';
import { DataSharedService } from '../shared/services/data-shared.service';
import * as moment from 'moment';

@Component({
  selector: 'app-pv-genration-report',
  templateUrl: './pv-genration-report.component.html',
  styleUrls: ['./pv-genration-report.component.scss']
})
export class PvGenrationReportComponent implements OnInit {

  public pv_genration_report: any = [];
  public date_from: String = '';
  public date_to: String = '';
  alwaysShowCalendars: boolean;

  selected: any = { start: moment(), end: moment() };
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'This Week': [moment().startOf('week'), moment().endOf('days')],
    'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
    'This Month': [moment().startOf('month'), moment().endOf('days')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year').format('YYYY'), moment().endOf('days')],
    'Last Year': [moment().subtract(1, 'year').startOf('year').format('YYYY'), moment().subtract(1, 'year').endOf('year')]
  }

  public data_param: String = '';
  public data_param_el: String = '';

  constructor(private dashboardService: DashboardService, private dataShared: DataSharedService) { }

  ngOnInit(): void {
    this.getCurrDate()
    this.getPvGenerationReport()
  }

  getCurrDate() {
    let date = new Date();
    this.date_from = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0')
    this.date_to = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0')
  }

  dateSelection(e) {
    this.date_from = e.start['_d'].toISOString().substring(0, 10);
    this.date_to = e.end['_d'].toISOString().substring(0, 10);
    this.selected.start['_d'].toISOString().substring(0, 4);

    this.getPvGenerationReport()
  }

  onClick() {
    this.getPvGenerationReport()
  }

  getBody() {
    let body = {
      "action": "getRealTimePvGeneration",
      "account_id": localStorage.getItem('account'),
      "user_id": localStorage.getItem('user'),
      "range": {
        "from": this.date_from,
        "to": this.date_to
      }
    };
    return body;
  }

  getPvGenerationReport() {
    this.pv_genration_report = [];
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {
      this.pv_genration_report = res.data
    })
  }

  exportAsCSV() {
    let data = [];
    if (data = this.getDataByParam()) this.dataShared.exportDataAsCsvFormat(data, this.data_param_el);
  }

  getDataByParam() {
    return this.pv_genration_report;
  }

  exportAsPDF(e) {
    let data = [];
    if (data = this.getDataByParam()) this.dataShared.exportDataAsPdfFormat(data, e, this.data_param_el, this.date_from, this.date_to);
  }

}