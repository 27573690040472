import { Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { isPlatformBrowser } from '@angular/common';
import { EmitterService } from 'src/app/shared/services/event-service';
import { ToastrService } from 'ngx-toastr';
import { DataSharedService } from 'src/app/shared/services/data-shared.service';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-comparative',
  templateUrl: './comparative.component.html',
  styleUrls: ['./comparative.component.scss']
})
export class ComparativeComponent implements OnInit {

  private chart: am4charts.XYChart;
  public chart_active: String = 'line';
  public toggleFullScreen: Boolean = false;

  public comparative_devices: any = [];
  public description: String = '';
  public device_location: any = [];
  public curr_tab = 'tab-graph';
  public tab_graph_status: boolean = false;
  public tab_table_status: boolean = false;

  public comparative_table_data: any = [];
  public comparative_table_value_3: Boolean = false;
  public comparative_table_value_4: Boolean = false;
  public comparative_table_value_5: Boolean = false;
  public comparative_table_value_6: Boolean = false;
  public comparative_table_value_7: Boolean = false;
  public comparative_table_value_8: Boolean = false;
  public export_data: any = [];
  public tabular_export_data: any = [];
  public param_data: any = [];

  public param: string = '';
  public param_list: any;
  public data_param_el: String = '';
  public data_param: String = '';

  public date: String = '';
  public fromDate: String = '';

  public date_selection: String = '';
  public from_date_selection: String = '';
  public table_to_date_selection: String = '';
  public table_from_date_selection: String = '';

  public pageSize = 25;
  public page = 1;

  constructor(private dashboardService: DashboardService, private eventEmitter: EmitterService, private toastr: ToastrService, private dataShared: DataSharedService, @Inject(PLATFORM_ID) private platformId, private zone: NgZone) { }

  ngOnInit(): void {
    this.getDashboardSettings()

    this.eventEmitter.listen('groupDevices', () => {
      if (this.dataShared.curr_menu == 'dashboard~comparative') {
        this.comparative_devices = [];
        this.dataShared.panel_devices.map(elem => { this.comparative_devices.push(elem.device_id) });
        this.getSelectedDeviceChartData();

      }
    });

    this.eventEmitter.listen('device_type', (device_type) => {
      this.getFirstDeviceID(device_type);
    });

    this.getFirstDeviceID(this.dataShared.device_type);

    this.getCurrDate();
  }

  getDashboardSettings() {

    let setting_body = {
      action: 'getSettings',
      meta_key: 'config',
      user_id: localStorage.getItem('user'),
      account_id: [localStorage.getItem('account')]
    }

    this.dashboardService.httpPost(setting_body).subscribe((res: any) => {
      if (res.status == 200) {

        let config = res.data?.config;
        let data = (localStorage.getItem('sub_user') == '0') ? JSON.parse(config[0].tab_setting) : JSON.parse(config[0].sub_tab_setting);
        this.tab_graph_status = data.allow_tab.dashboard.comparative.graph
        this.tab_table_status = data.allow_tab.dashboard.comparative.table

      }
    })
  }

  toggleCard = (e) => this.dataShared.toggleCardFullsreen(e);

  resetSinleDevice() {
    this.date = ''; this.fromDate = ''; this.getCurrDate();
  }

  tabChange($event: NgbTabChangeEvent) {

    this.curr_tab = $event.nextId;
    this.resetSinleDevice();
    this.getSelectedDeviceChartData();

  }

  getCurrDate() {
    let date = new Date();
    this.date = this.date_selection = this.table_to_date_selection = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
    this.fromDate = this.from_date_selection = this.table_from_date_selection = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
  }

  getFirstDeviceID(device_type) {
    let body = {
      'action': 'getDevices',
      'account_id': localStorage.getItem('account'),
      'device_type': device_type
    }

    this.dashboardService.httpPost(body).subscribe((res: any) => {
      if (res.status == 200) {
        this.paramList(res.data[0].device_id)
      }
    })
  }

  paramList(device_id) {

    this.param_list = [];

    let body = {
      'action': 'getDeviceByID',
      'device_id': [device_id]
    }

    this.dashboardService.httpPost(body).subscribe((res: any) => {

      if (res.status == 200 && res.data[device_id]) {
        res.data[device_id].parameters.forEach((data) => {
          this.param_list.push(data)
        })
      }

      for (let index = 0; index < this.param_list.length; index++) {
        if (this.param_list[index].parameter == this.param) {
          this.description = this.param_list[index].description + ' ' + this.param_list[index].unit
        }
      }
    })
  }

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  getSelectedDeviceChartData() {
    this.dataShared.panel_devices.sort((a,b) => (a.device_id > b.device_id) ? 1 : ((b.device_id > a.device_id) ? -1 : 0))
    if (this.comparative_devices.length < 2 || this.comparative_devices.length > 8) {
      this.toastr.error('Please select at least 2 or maximum 8 devices.');
    } else if (this.param == '') {
      this.toastr.error('Please select any parameter');
    } else {
      this.browserOnly(() => {
        if (this.curr_tab == 'tab-graph') this.getChartData();
        else if (this.curr_tab == 'tab-table') this.getComparativeTableData();
      });
    }
  }

  getChartData() {

    let data = [];
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      for (let index = 0; index < res.data.buckets.length; index++) {

        for (let j = 0; j < res.data.buckets[index].date_agg.buckets.length; j++) {

          data.push({
            id: res.data.buckets[index].key,
            ['date_' + res.data.buckets[index].key]: new Date(res.data.buckets[index].date_agg.buckets[j].key_as_string),
            ['value_' + res.data.buckets[index].key]: res.data.buckets[index].date_agg.buckets[j]['val_' + this.param].value
          });

          this.param_data = data;
        }
      }
      this.param_data.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));

      let prop = this.getGraphProp('com_chart', this.param_data);
      this.implementLineChart(prop);
    });
    this.getComparativeTableData();
  }

  getBody() {

    let body = {
      'action': 'getChartDataByAccount',
      'user_id': [localStorage.getItem('user')],
      'device_id': this.comparative_devices,
      'date': this.fromDate,
      'fixed_interval':'1s',
      "parameters": [this.param]
    }

    if (this.fromDate != this.date) {

      let range = {
        from: this.fromDate,
        to: this.date,
      };

      delete body['date'];
      body['range'] = range
    }

    return body;
  }

  getGraphProp(param, param_data) {

    let data = {
      element: '',
      data: param_data,
      interval: {
        'interval': 'second',
        'value': 1
      },
      tooltipFormat: 'HH:mm:ss, d MMMM',
      xScrollbar: false
    };

    if (param == 'com_chart') {
      data.element = 'lineChart'
    }

    return data;
  }

  implementLineChart(data) {

    am4core.useTheme(am4themes_animated);

    this.chart = am4core.create(data.element, am4charts.XYChart);
    this.chart.data = data.data;
    this.chart.colors.step = 2;

    // Create axes
    let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.adapter.remove("getTime");

    // Create series

    for (let index = 0; index < this.comparative_devices.length; index++) {

        if (index % 2) {
          this.createAxisAndSeries("value_" + this.dataShared.panel_devices[index].device_id, "date_" + this.dataShared.panel_devices[index].device_id, this.dataShared.panel_devices[index].location, true);
        } else {
          this.createAxisAndSeries("value_" + this.dataShared.panel_devices[index].device_id, "date_" + this.dataShared.panel_devices[index].device_id, this.dataShared.panel_devices[index].location, false);
        }
    }

    // Add legend
    this.chart.legend = new am4charts.Legend();
    // Add cursor
    this.chart.cursor = new am4charts.XYCursor();
  }

  createAxisAndSeries(field, date, name, opposite) {

    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());

    let series = this.chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = field;
    series.dataFields.dateX = date;
    series.strokeWidth = 2;
    series.name = name;
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.tensionX = 0.8;
    series.showOnInit = true;

    let scrollbarX = new am4core.Scrollbar();
    scrollbarX.marginBottom = 20;
    this.chart.scrollbarX = scrollbarX;

    valueAxis.renderer.line.strokeWidth = 2;
    valueAxis.renderer.line.stroke = series.stroke;
    valueAxis.renderer.labels.template.fill = series.stroke;
    valueAxis.renderer.opposite = opposite;

  }

  implementBarChart(data) {

    am4core.useTheme(am4themes_animated);

    this.chart = am4core.create(data.element, am4charts.XYChart);
    this.chart.data = data.data;
    this.chart.colors.step = 2;

    // Create axes
    let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.adapter.remove("getTime");

    // Create series

    for (let index = 0; index < this.comparative_devices.length; index++) {

      if (index % 2) {
        this.createBarAxisAndSeries("value_" + this.dataShared.panel_devices[index].device_id, "date_" + this.dataShared.panel_devices[index].device_id, this.dataShared.panel_devices[index].location, true);
      } else {
        this.createBarAxisAndSeries("value_" + this.dataShared.panel_devices[index].device_id, "date_" + this.dataShared.panel_devices[index].device_id, this.dataShared.panel_devices[index].location, false);
      }

  }

    // Add legend
    this.chart.legend = new am4charts.Legend();
    // Add cursor
    this.chart.cursor = new am4charts.XYCursor();

  }

  createBarAxisAndSeries(field, date, name, opposite) {

    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());

    let series = this.chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = field;
    series.dataFields.dateX = date;
    series.strokeWidth = 2;
    series.name = name;
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.showOnInit = true;

    // Add scrollbar
    let scrollbarX = new am4core.Scrollbar();
    scrollbarX.marginBottom = 20;
    this.chart.scrollbarX = scrollbarX;

    // Add cursor

    valueAxis.renderer.line.strokeWidth = 2;
    valueAxis.renderer.line.stroke = series.stroke;
    valueAxis.renderer.labels.template.fill = series.stroke;
    valueAxis.renderer.opposite = opposite;
  }

  getComparativeTableData() {

    this.comparative_table_data = []; let data = []; let data_0 = []; let data_1 = []; let data_2 = []; let data_3 = []; let data_4 = []; let data_5 = []; let data_6 = []; let data_7 = [];
    this.comparative_table_value_3 = false; this.comparative_table_value_4 = false; this.comparative_table_value_5 = false; this.comparative_table_value_6 = false;
    this.comparative_table_value_7 = false; this.comparative_table_value_8 = false;
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      for (let index = 0; index < this.comparative_devices.length; index++ ) {
        data.push(res.data.buckets[index]);
      }
      data.sort((a,b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0));

      if (this.comparative_devices.length == 2) { data_0 = data[0].date_agg.buckets; data_1 = data[1].date_agg.buckets; }
      else if (this.comparative_devices.length == 3) { data_0 = data[0].date_agg.buckets; data_1 = data[1].date_agg.buckets; data_2 = data[2].date_agg.buckets; this.comparative_table_value_3 = true; }
      else if (this.comparative_devices.length == 4) { data_0 = data[0].date_agg.buckets; data_1 = data[1].date_agg.buckets; data_2 = data[2].date_agg.buckets; data_3 = data[3].date_agg.buckets; this.comparative_table_value_3 = true; this.comparative_table_value_4 = true; }
      else if (this.comparative_devices.length == 5) { data_0 = data[0].date_agg.buckets; data_1 = data[1].date_agg.buckets; data_2 = data[2].date_agg.buckets; data_3 = data[3].date_agg.buckets; data_4 = data[4].date_agg.buckets; this.comparative_table_value_3 = true; this.comparative_table_value_4 = true; this.comparative_table_value_5 = true; }
      else if (this.comparative_devices.length == 6) { data_0 = data[0].date_agg.buckets; data_1 = data[1].date_agg.buckets; data_2 = data[2].date_agg.buckets; data_3 = data[3].date_agg.buckets; data_4 = data[4].date_agg.buckets; data_5 = data[5].date_agg.buckets; this.comparative_table_value_3 = true; this.comparative_table_value_4 = true; this.comparative_table_value_5 = true; this.comparative_table_value_6 = true; }
      else if (this.comparative_devices.length == 7) { data_0 = data[0].date_agg.buckets; data_1 = data[1].date_agg.buckets; data_2 = data[2].date_agg.buckets; data_3 = data[3].date_agg.buckets; data_4 = data[4].date_agg.buckets; data_5 = data[5].date_agg.buckets; data_6 = data[6].date_agg.buckets;  this.comparative_table_value_3 = true; this.comparative_table_value_4 = true; this.comparative_table_value_5 = true; this.comparative_table_value_6 = true; this.comparative_table_value_7 = true; }
      else if (this.comparative_devices.length == 8) { data_0 = data[0].date_agg.buckets; data_1 = data[1].date_agg.buckets; data_2 = data[2].date_agg.buckets; data_3 = data[3].date_agg.buckets; data_4 = data[4].date_agg.buckets; data_5 = data[5].date_agg.buckets; data_6 = data[6].date_agg.buckets; data_7 = data[7].date_agg.buckets;  this.comparative_table_value_3 = true; this.comparative_table_value_4 = true; this.comparative_table_value_5 = true; this.comparative_table_value_6 = true; this.comparative_table_value_7 = true; this.comparative_table_value_8 = true; }


      if (res.data.buckets[0].date_agg.buckets) {

        let date = []; let lenght = [];

        lenght.push(data_0.length, data_1.length, data_2.length, data_3.length, data_4.length, data_5.length, data_6.length, data_7.length)

        let max = Math.max(...lenght)
        this.device_location = this.dataShared.panel_devices;
        for (let index = 0; index < lenght.length; index++) {

          if (max == lenght[index]) {
            date = res.data.buckets[index].date_agg.buckets
          }

        }

        for (let i = 0; i < max; i++) {

          if (this.comparative_devices.length == 2) {

            this.comparative_table_data.push({
              date: new Date(date[i].key_as_string),
              value_1: data_0[i]?.['val_' + this.param].value,
              value_2: data_1[i]?.['val_' + this.param].value
            })

            this.tabular_export_data.push({
              date: new Date(date[i].key_as_string),
              [this.dataShared.panel_devices[0].location]: data_0[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[1].location]: data_1[i]?.['val_' + this.param].value
            })

          } else if (this.comparative_devices.length == 3) {

            this.comparative_table_data.push({
              date: new Date(date[i].key_as_string),
              value_1: data_0[i]?.['val_' + this.param].value,
              value_2: data_1[i]?.['val_' + this.param].value,
              value_3: data_2[i]?.['val_' + this.param].value
            })

            this.tabular_export_data.push({
              date: new Date(date[i].key_as_string),
              [this.dataShared.panel_devices[0].location]: data_0[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[1].location]: data_1[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[2].location]: data_2[i]?.['val_' + this.param].value
            })

          } else if (this.comparative_devices.length == 4) {

            this.comparative_table_data.push({
              date: new Date(date[i].key_as_string),
              value_1: data_0[i]?.['val_' + this.param].value,
              value_2: data_1[i]?.['val_' + this.param].value,
              value_3: data_2[i]?.['val_' + this.param].value,
              value_4: data_3[i]?.['val_' + this.param].value
            })

            this.tabular_export_data.push({
              date: new Date(date[i].key_as_string),
              [this.dataShared.panel_devices[0].location]: data_0[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[1].location]: data_1[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[2].location]: data_2[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[3].location]: data_3[i]?.['val_' + this.param].value
            })

          } else if (this.comparative_devices.length == 5) {

            this.comparative_table_data.push({
              date: new Date(date[i].key_as_string),
              value_1: data_0[i]?.['val_' + this.param].value,
              value_2: data_1[i]?.['val_' + this.param].value,
              value_3: data_2[i]?.['val_' + this.param].value,
              value_4: data_3[i]?.['val_' + this.param].value,
              value_5: data_4[i]?.['val_' + this.param].value
            })

            this.tabular_export_data.push({
              date: new Date(date[i].key_as_string),
              [this.dataShared.panel_devices[0].location]: data_0[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[1].location]: data_1[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[2].location]: data_2[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[3].location]: data_3[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[4].location]: data_4[i]?.['val_' + this.param].value
            })

          } else if (this.comparative_devices.length == 6) {

            this.comparative_table_data.push({
              date: new Date(date[i].key_as_string),
              value_1: data_0[i]?.['val_' + this.param].value,
              value_2: data_1[i]?.['val_' + this.param].value,
              value_3: data_2[i]?.['val_' + this.param].value,
              value_4: data_3[i]?.['val_' + this.param].value,
              value_5: data_4[i]?.['val_' + this.param].value,
              value_6: data_5[i]?.['val_' + this.param].value
            })

            this.tabular_export_data.push({
              date: new Date(date[i].key_as_string),
              [this.dataShared.panel_devices[0].location]: data_0[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[1].location]: data_1[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[2].location]: data_2[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[3].location]: data_3[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[4].location]: data_4[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[5].location]: data_5[i]?.['val_' + this.param].value
            })

          } else if (this.comparative_devices.length == 7) {

            this.comparative_table_data.push({
              date: new Date(date[i].key_as_string),
              value_1: data_0[i]?.['val_' + this.param].value,
              value_2: data_1[i]?.['val_' + this.param].value,
              value_3: data_2[i]?.['val_' + this.param].value,
              value_4: data_3[i]?.['val_' + this.param].value,
              value_5: data_4[i]?.['val_' + this.param].value,
              value_6: data_5[i]?.['val_' + this.param].value,
              value_7: data_6[i]?.['val_' + this.param].value
            })

            this.tabular_export_data.push({
              date: new Date(date[i].key_as_string),
              [this.dataShared.panel_devices[0].location]: data_0[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[1].location]: data_1[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[2].location]: data_2[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[3].location]: data_3[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[4].location]: data_4[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[5].location]: data_5[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[6].location]: data_6[i]?.['val_' + this.param].value
            })

          } else if (this.comparative_devices.length == 8) {

            this.comparative_table_data.push({
              date: new Date(date[i].key_as_string),
              value_1: data_0[i]?.['val_' + this.param].value,
              value_2: data_1[i]?.['val_' + this.param].value,
              value_3: data_2[i]?.['val_' + this.param].value,
              value_4: data_3[i]?.['val_' + this.param].value,
              value_5: data_4[i]?.['val_' + this.param].value,
              value_6: data_5[i]?.['val_' + this.param].value,
              value_7: data_6[i]?.['val_' + this.param].value,
              value_8: data_7[i]?.['val_' + this.param].value
            })

            this.tabular_export_data.push({
              date: new Date(date[i].key_as_string),
              [this.dataShared.panel_devices[0].location]: data_0[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[1].location]: data_1[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[2].location]: data_2[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[3].location]: data_3[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[4].location]: data_4[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[5].location]: data_5[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[6].location]: data_6[i]?.['val_' + this.param].value,
              [this.dataShared.panel_devices[7].location]: data_7[i]?.['val_' + this.param].value
            })

          }

        }

      }

    })

  }

  onDateSelection(e) {
    this.date = e.target.value;
  }

  onFromDateSelection(e) {
    this.fromDate = e.target.value;
  }

  tabLinkChart(element) {

    if (element == 'com_chart' && this.chart_active != 'line') {
      this.implementLineChart(this.getGraphProp(element, this.param_data)); this.chart_active = 'line';
    }

  }

  tabBarChart(element) {
    this.implementBarChart(this.getGraphProp(element, this.param_data)); this.chart_active = 'bar';
    if (this.chart_active != 'bar') {
    }
  }

  exportAsCSV() {
    let data = [];
    if (data = this.getDataByParam()) this.dataShared.exportDataAsCsvFormat(data);
  }

  getDataByParam() {
    if (this.data_param == 'com_chart') return this.tabular_export_data;
    else if (this.data_param == 'table') return this.tabular_export_data;
  }

  exportAsPDF(e) {
    let data = [];
    if (data = this.getDataByParam()) this.dataShared.exportDataAsPdfFormat(data, e, this.data_param_el);
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }
}
