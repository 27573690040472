<div class="scroll-down">
  
  <img  id="bg-login" src="../../../assets/images/login-page-2.JPG" alt="error">
  <img id="white-logo" src="../../../assets/media/Zainergy-logo.svg" alt="">
  
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
<path d="M16 3C8.832031 3 3 8.832031 3 16s5.832031 13 13 13 13-5.832031 13-13S23.167969 3 16 3zm0 2c6.085938 0 11 4.914063 11 11 0 6.085938-4.914062 11-11 11-6.085937 0-11-4.914062-11-11C5 9.914063 9.914063 5 16 5zm-1 4v10.28125l-4-4-1.40625 1.4375L16 23.125l6.40625-6.40625L21 15.28125l-4 4V9z"/> 
</svg></div>
<div class="container"></div>
<div class="modal">
<div class="modal-container">
  <div class="modal-left">
    
      <h5 class="modal-desc">Welcome to our login page</h5>
    
    <div class="input-block">
      <label for="email" class="input-label"> login id</label>
      <input  type="text" [ngClass]="{'val-border': emailPrompt || showError}" [(ngModel)]="emailId" name="email" id="email" placeholder="Email">
      <small class="error" *ngIf="emailPrompt"> Email is Required!</small>
    </div>
    <div class=" input-block">
      <label for="password" class="input-label">Password</label>
      <div class="d-inline flex">
      <input style="width:90%;" type="password" [ngClass]="{'val-border': passwordPrompt || showError}" [(ngModel)]="password" name="password" id="password" placeholder="Password">
      <i (click)="Input()" style="justify-items:end; width: 10%; height: 10%; z-index: 1;" class="{{eye}}"></i>
    </div>
      
      <small class="error" *ngIf="passwordPrompt"> Password is Required!</small>
    </div>
    <i class="error" *ngIf="showError">Invalid Username or Password!</i>
    <div class="modal-buttons">
      <button class="input-button" (click)="login()">Login</button>
    </div>
  </div>

  <div class="modal-right">
    <img  id="bg-img" src="../../../assets/images/website logo.png"  alt="">
  </div>
   <img id="logo-in-modelright" src="../../../assets/images/zainergy-logo.svg" alt="error">  
    
  <button class="icon-button close-button">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
  <path d="M 25 3 C 12.86158 3 3 12.86158 3 25 C 3 37.13842 12.86158 47 25 47 C 37.13842 47 47 37.13842 47 25 C 47 12.86158 37.13842 3 25 3 z M 25 5 C 36.05754 5 45 13.94246 45 25 C 45 36.05754 36.05754 45 25 45 C 13.94246 45 5 36.05754 5 25 C 5 13.94246 13.94246 5 25 5 z M 16.990234 15.990234 A 1.0001 1.0001 0 0 0 16.292969 17.707031 L 23.585938 25 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 25 26.414062 L 32.292969 33.707031 A 1.0001 1.0001 0 1 0 33.707031 32.292969 L 26.414062 25 L 33.707031 17.707031 A 1.0001 1.0001 0 0 0 32.980469 15.990234 A 1.0001 1.0001 0 0 0 32.292969 16.292969 L 25 23.585938 L 17.707031 16.292969 A 1.0001 1.0001 0 0 0 16.990234 15.990234 z"></path>
</svg>
    </button>
</div>
<button class="modal-button"><strong>Click here to login</strong></button>
</div>