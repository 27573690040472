import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../shared/services/dashboard.service';
import { Router } from '@angular/router';
import { EmitterService } from '../shared/services/event-service';
import { DataSharedService } from '../shared/services/data-shared.service';

@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.scss']
})
export class FavoriteComponent implements OnInit {

  public favorite_devices: any = [];

  constructor(
    private dashboardService: DashboardService,
    private dataShared: DataSharedService,
    private router: Router,
    private eventEmitter: EmitterService,
  ) { }

  ngOnInit(): void {
    this.getFavoriteDevices();
   
  }

  getFavoriteDevices() {

    let body = {
      'action': 'getDevicesByAccount',
      'user_id': localStorage.getItem('user'),
      'favorite': true
    }

    this.dashboardService.httpPost(body).subscribe((res: any) => {
      if (res.status == 200 && res.data) {
        this.deviceData(Object.values(res.data));
      }
    })
  }

  deviceData(device) {

    let device_id = []; let data = [];

    for (let index = 0; index < device.length; index++) {

      device_id.push(device[index].device_id)

      let body = {
        'action': 'getLatestStatsByDevice',
        'user_id': localStorage.getItem('user'),
        'account_id': localStorage.getItem('account'),
        'device_id': device[index].device_id,
        'parameters': ['avg_vl_pp', 'avg_curr_3p', 'total_w', 'frequency', 'avg_pf_3p', 'total_wh']
      };

      this.dashboardService.httpPost(body).subscribe((res: any) => {

        if (res.status == 200) {

          data.push({
            device_id: device[index].device_id,
            location: device[index].location,
            voltage: res.data.avg_vl_pp.toFixed(2),
            current: res.data.avg_curr_3p.toFixed(2),
            power: res.data.total_w.toFixed(2),
            frequency: res.data.frequency.toFixed(2),
            power_factor: res.data.avg_pf_3p.toFixed(2),
            energy:res.data.total_wh.toFixed(2)
          })

        }

        this.favorite_devices = data;

      });

    }
  }

  gotoDevice(device) {
    this.dataShared.device_id = device;
    this.eventEmitter.emit('menuFromPanelControl', false);
    this.dataShared.curr_menu = 'dashboard~single';
    this.eventEmitter.emit('selectedDevice', device);
    this.router.navigate(['/dashboard/single']);
  }

  toggleFavorite(device_id) {

    let body = {
      'action': 'updateDeviceById',
      'account_id': localStorage.getItem('account'),
      'device_id': device_id,
      'data': { 'favorite': 0 }
    }

    this.dashboardService.httpPost(body).subscribe((res: any) => {
      if (res.status === 200) this.getFavoriteDevices();
    });
  }
}